import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Grid } from '@mui/material';

import { updatePricesAction, updatePricesState } from '../../../features/price/update_prices';
import { updatePricesNotificationData } from '../../../core/utils/notification_utils';
import useNotification from '../../../core/hooks/use_notification';
import { TileButton } from '../../common/button';

const Settings = () => {
    const dispatch = useDispatch();
    const { showNotification } = useNotification();

    const updatePrices = useSelector(updatePricesState);

    const onSubmit = () => {
        dispatch(updatePricesAction())
            .then(response => showNotification(updatePricesNotificationData(response)));
    };

    return (
        <Grid>
            <Grid mb={ 4 } container item justifyContent="center">
                <TileButton onClick={ onSubmit } isLoading={ updatePrices?.loading }>
                    Zaktualizuj ceny
                </TileButton>
            </Grid>
        </Grid>
    );
};

export default Settings;
