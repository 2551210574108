import React from 'react';

import useNotification from '../../../../../core/hooks/use_notification';
import { useDispatch, useSelector } from 'react-redux';
import { getContactPersonsState } from '../../../../../features/food_partner/get_contact_persons';
import { deleteContactPersonAction, deleteContactPersonState } from '../../../../../features/food_partner/delete_contact_person';
import { deleteContactPersonNotificationData } from '../../../../../core/utils/notification_utils';
import { ApiRequestStatusEnum } from '../../../../../core/enums/common/api';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { DeleteButton } from '../../../../common/button';
import ContactPersonDialog from './contact_person_dialog';

const ContactPersonsList = ({ foodPartnerId, refreshList }) => {
    const { showNotification } = useNotification();
    const dispatch = useDispatch();

    const getContactPersons = useSelector(getContactPersonsState);
    const deleteContactPerson = useSelector(deleteContactPersonState);

    const onDeleteContactPerson = (contactPersonId) => {
        dispatch(deleteContactPersonAction({ foodPartnerId, contactPersonId }))
            .then(response => {
                showNotification(deleteContactPersonNotificationData(response));
                response?.meta?.requestStatus === ApiRequestStatusEnum.FULFILLED && refreshList();
            });
    }

    return (
        <TableContainer>
            <Table stickyHeader>
                <TableHead>
                    <TableRow>
                        <TableCell>Nazwa</TableCell>
                        <TableCell>Nazwisko</TableCell>
                        <TableCell>Rola</TableCell>
                        <TableCell>Email</TableCell>
                        <TableCell align="center">Podgląd/edycja</TableCell>
                        <TableCell align="center">Usuń</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        getContactPersons?.data?.map(contactPerson => (
                            <TableRow
                                key={ contactPerson?.id }
                                hover
                            >
                                <TableCell>{ contactPerson?.name }</TableCell>
                                <TableCell>{ contactPerson?.surname }</TableCell>
                                <TableCell>{ contactPerson?.role }</TableCell>
                                <TableCell>{ contactPerson?.email }</TableCell>
                                <TableCell align="center">
                                    <ContactPersonDialog foodPartnerId={ foodPartnerId }  contactPersonId={ contactPerson?.id } refreshList={ refreshList } displaySmallIcon/>
                                </TableCell>
                                <TableCell align="center">
                                    <DeleteButton onDeleteClick={ () => onDeleteContactPerson(contactPerson?.id) } isLoading={ deleteContactPerson?.loading }/>
                                </TableCell>
                            </TableRow>
                        ))
                    }
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default ContactPersonsList;
