import { deepFreeze } from '../../utils/misc_utils';

export const PodSuccessMessageEnum = deepFreeze({
    CREATE_ADVERTISEMENTS: 'Pomyślnie dodano reklamę',
    UPDATE_ADVERTISEMENTS_DISPLAY_ORDER: 'Pomyślnie zaktualizowano kolejność reklam',
    UPDATE_ADVERTISEMENT_DISPLAY_TIME: 'Pomyślnie zaktualizowano daty obowiązywania reklamy',
    UPDATE_ADVERTISEMENT_PRODUCTS: 'Pomyślnie zaktualizowano powiązane produkty',
    DELETE_ADVERTISEMENT: 'Pomyślnie usunięto reklamę',
    CREATE_POD: 'Pomyślnie dodano punkt sprzedaży',
    UPDATE_POD: 'Pomyślnie zaktualizowano punkt sprzedaży',
    DELETE_INDOOR_PARTNER: 'Pomyślnie usunięto partnera handlowego',
    CREATE_INDOOR_PARTNER: 'Pomyślnie dodano partnera handlowego',
    UPDATE_INDOOR_PARTNER: 'Pomyślnie zaktualizowano partnera handlowego',
});

export const PodErrorMessageEnum = deepFreeze({
    GET_DEVICES: 'Pobranie urządzeń nie powiodło się',
    GET_POD: 'Pobranie poda nie powiodło się',
    GET_PODS: 'Pobranie podów nie powiodło się',
    GET_ADVERTISEMENTS: 'Pobranie reklam nie powiodło się',
    CREATE_ADVERTISEMENTS: 'Dodanie reklamy nie powiodło się',
    UPDATE_ADVERTISEMENTS_DISPLAY_ORDER: 'Aktualizacja kolejności reklam nie powiodła się',
    UPDATE_ADVERTISEMENT_DISPLAY_TIME: 'Aktualizacja dat obowiązywania reklamy nie powiodła się',
    UPDATE_ADVERTISEMENT_PRODUCTS: 'Aktualizacja powiązanych produktów nie powiodła się',
    DELETE_ADVERTISEMENT: 'Usunięcie reklamy nie powiodło się',
    GET_ALL_INDOOR_PARTNERS: 'Pobranie partnerów handlowych nie powiodło się',
    GET_INDOOR_PARTNERS: 'Pobranie partnerów handlowych nie powiodło się',
    GET_INDOOR_PARTNER: 'Pobranie partnera handlowego nie powiodło się',
    DELETE_INDOOR_PARTNER: 'Usunięcie partnera handlowego nie powiodło się',
    CREATE_INDOOR_PARTNER: 'Dodanie partnera handlowego nie powiodło się',
    UPDATE_INDOOR_PARTNER: 'Aktualizacja partnera handlowego nie powiodła się',
    CREATE_POD: 'Dodanie punktu sprzedaży nie powiodła się',
    UPDATE_POD: 'Aktualizacja punktu sprzedaży nie powiodła się',
});

const PodServerErrorMessageEnum = deepFreeze({
    DISPLAY_ORDER_CANNOT_BE_EMPTY: {
        message: 'Brak kolejności reklamy',
        errorCode: '400.3'
    },
    INVALID_TIMES_COMBINATION: {
        message: 'Niepoprawna kombinacja dat',
        errorCode: '400.7'
    },
    CANNOT_CHANGE_DISPLAY_DATE_FROM_FOR_ACTIVE_ADVERTISEMENT: {
        message: 'Zmiana daty od jest niemożliwa dla aktywnej reklamy',
        errorCode: '400.8'
    },
    CANNOT_DELETE_INDOOR_PARTNER_WITH_ASSIGNED_PODS: {
        message: 'Partner handlowy nie może być usunięty. Istnieją przypisane pody.',
        errorCode: '400.9'
    },
    CANNOT_ASSIGN_DEVICE_TO_POD: {
        message: 'Nie można przypisać urządzenia do punktu sprzedaży',
        errorCode: '400.12'
    }
});

export const getPodErrorMessage = (message, defaultErrorMessage) => {
    for (let key in PodServerErrorMessageEnum) {
        if (PodServerErrorMessageEnum.hasOwnProperty(key)) {
            if (message?.substring(0, message?.indexOf(' ')) === PodServerErrorMessageEnum[key].errorCode) {
                return PodServerErrorMessageEnum[key].message;
            }
        }
    }

    return defaultErrorMessage;
};
