import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import webClient from '../../core/web_client';
import { buildQueryString, extractErrorData, extractResponseData } from '../../core/utils/api_utils';
import { DEFAULT_STATE, STATE__API__FAIL, STATE__API__STARTED, STATE__API__SUCCESS } from '../../core/utils/store_utils';
import { invalidateToken } from '../authorization/get_token';
import { WAREHOUSE_DOCUMENTS_PATH } from '../../core/constants';

export const getGenerateWarehouseDocumentRequestsAction = createAsyncThunk(
    'inventory/getGenerateWarehouseDocumentRequestsAction',
    async (queryParams, { fulfillWithValue, rejectWithValue }) => {
        try {
            const response = await webClient.get(`${ WAREHOUSE_DOCUMENTS_PATH }/generate-requests${ buildQueryString(queryParams) }`);
            return fulfillWithValue(extractResponseData(response));
        } catch (error) {
            return rejectWithValue(extractErrorData(error));
        }
    }
);

const getGenerateWarehouseDocumentRequestsSlice = createSlice({
    name: 'getGenerateWarehouseDocumentRequestsSlice',
    initialState: DEFAULT_STATE,
    reducers: {
        invalidateGenerateWarehouseDocumentRequests() {
            return DEFAULT_STATE;
        }
    },
    extraReducers: builder => {
        builder.addCase(getGenerateWarehouseDocumentRequestsAction.pending, () => STATE__API__STARTED);
        builder.addCase(getGenerateWarehouseDocumentRequestsAction.fulfilled, (_, { payload }) => STATE__API__SUCCESS(payload));
        builder.addCase(getGenerateWarehouseDocumentRequestsAction.rejected, (_, { payload }) => STATE__API__FAIL(payload));
        builder.addCase(invalidateToken, () => DEFAULT_STATE);
    }
});

export const { invalidateGenerateWarehouseDocumentRequests } = getGenerateWarehouseDocumentRequestsSlice.actions;
export const getGenerateWarehouseDocumentRequestsState = (state) => state.inventory.getGenerateWarehouseDocumentRequests;
export default getGenerateWarehouseDocumentRequestsSlice.reducer;
