import React from 'react';
import { useSelector } from 'react-redux';

import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import moment from 'moment';
import { FieldArray, Form, Formik } from 'formik';

import { getBusinessDataState } from '../../../../features/store/get_business_data';
import { MISC_DATE_ISO_DATE_ONLY_FORMAT, MISC_DATE_ISO_FORMAT, MISC_DATE_POLISH_DATE_ONLY_FORMAT, MISC_DATE_POLISH_FORMAT } from '../../../../core/constants';
import ExpirationPromotionDialog from './expiration_promotion_dialog';
import ProductPromotionDialog from './product_promotion_dialog';

const BusinessDataList = ({ pod, refreshList }) => {
    const getBusinessData = useSelector(getBusinessDataState);

    const initialValues = {
        businessData: getBusinessData?.data?.map(resourceData => (
            {
                ...resourceData
            }
        ))
    };

    return (
        <Formik
            initialValues={ initialValues }
            validateOnChange={ false }
            validateOnBlur={ false }
            enableReinitialize={ true }>
            { ({ values }) => (
                <Form>
                    <TableContainer>
                        <Table stickyHeader>
                            <TableHead>
                                <TableRow>
                                    <TableCell align="center">Nazwa produktu</TableCell>
                                    <TableCell align="center">Liczba zatowarowanych produktów</TableCell>
                                    <TableCell align="center">Aktualna liczba produktów</TableCell>
                                    <TableCell align="center">Data zatowarowania</TableCell>
                                    <TableCell align="center">Data przydatności</TableCell>
                                    <TableCell align="center">Cena bazowa</TableCell>
                                    <TableCell align="center" colSpan={ 2 }>Cena z promocją na produkt</TableCell>
                                    <TableCell align="center" colSpan={ 2 }>Cena z promocją na krótki termin przydatności</TableCell>
                                    <TableCell align="center">% czasu który minął</TableCell>
                                    <TableCell align="center">% sprzedanych produktów</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <FieldArray name="businessData">
                                    { () => (
                                        values?.businessData?.map((resource, index) => (
                                            <TableRow key={ index } hover>
                                                <TableCell><b>{ resource?.productName }</b><br/> Partia: { resource?.productBatchId }</TableCell>
                                                <TableCell align="right">{ resource?.numberOfStockedItems }</TableCell>
                                                <TableCell align="right">{ resource?.numberOfStockedItems - resource?.numberOfSoldItems }</TableCell>
                                                <TableCell>
                                                    {
                                                        moment(resource?.stockingDate, MISC_DATE_ISO_FORMAT)
                                                            .format(MISC_DATE_POLISH_FORMAT)
                                                    }
                                                </TableCell>
                                                <TableCell>
                                                    {
                                                        moment(resource?.expiryDate, MISC_DATE_ISO_DATE_ONLY_FORMAT)
                                                            .format(MISC_DATE_POLISH_DATE_ONLY_FORMAT)
                                                    }
                                                </TableCell>
                                                <TableCell align="right" sx={ { backgroundColor: '#f5f5f5' } }>{ resource?.basicGrossPrice?.toFixed(2) }</TableCell>
                                                <TableCell align="right" sx={ { backgroundColor: resource?.productPromotionGrossPrice ? '#dcffcd' : resource?.containsNextOrActiveProductPromotion ? '#ffe5b4' : '-' } }>
                                                    {
                                                        resource?.productPromotionGrossPrice?.toFixed(2) ||
                                                        (resource?.containsNextOrActiveProductPromotion && '%')
                                                    }
                                                </TableCell>
                                                <TableCell align="center" sx={ { backgroundColor: resource?.productPromotionGrossPrice ? '#dcffcd' : resource?.containsNextOrActiveProductPromotion ? '#ffe5b4' : '-' } }>
                                                    <ProductPromotionDialog resource={ resource } podId={ pod.id } refreshList={ refreshList }/>
                                                </TableCell>
                                                <TableCell align="right" sx={ { backgroundColor: resource?.excludeExpirationPromotion ? '#ffa2a2' : resource?.expirationPromotionGrossPrice ? '#dcffcd' : resource?.containsNextOrActiveExpirationPromotion ? '#FFE5B4' : '-' } }>
                                                    {
                                                        resource?.expirationPromotionGrossPrice?.toFixed(2) ||
                                                        (resource?.containsNextOrActiveExpirationPromotion && '%')
                                                    }
                                                </TableCell>
                                                <TableCell align="center" sx={ { backgroundColor: resource?.excludeExpirationPromotion ? '#ffa2a2' : resource?.expirationPromotionGrossPrice ? '#dcffcd' : resource?.containsNextOrActiveExpirationPromotion ? '#FFE5B4' : '-' } }>
                                                    <ExpirationPromotionDialog resource={ resource } podId={ pod.id } refreshList={ refreshList }/>
                                                </TableCell>
                                                <TableCell align="right">{ resource?.timePercentage }%</TableCell>
                                                <TableCell align="right">{ resource?.salePercentage }%</TableCell>
                                            </TableRow>
                                        ))
                                    ) }
                                </FieldArray>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Form>
            ) }
        </Formik>
    );
};

export default BusinessDataList;
