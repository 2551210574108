import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Grid, LinearProgress, Typography } from '@mui/material';
import * as Yup from 'yup';
import { Form, Formik } from 'formik';

import useNotification from '../../../../../core/hooks/use_notification';
import AutomaticExpirationPromotionSettingList from './automatic_expiration_promotion_setting_list';
import { getExpirationPromotionSettingAction, getExpirationPromotionSettingState } from '../../../../../features/price/get_expiration_promotion_setting';
import { getExpirationPromotionSettingNotificationData, updateExpirationPromotionSettingNotificationData } from '../../../../../core/utils/notification_utils';
import { SubmitButton } from '../../../../common/button';
import RadioGroupFormik from '../../../../common/radio_group';
import { yesNoOptions } from '../../../../common/form_utils';
import { updateExpirationPromotionSettingAction, updateExpirationPromotionSettingState } from '../../../../../features/price/update_expiration_promotion_setting';

const SettingForm = ({ tab }) => {
    const { showErrorMessage, showNotification } = useNotification();
    const dispatch = useDispatch();

    const refreshExpirationPromotionSetting = useCallback(() => {
        dispatch(getExpirationPromotionSettingAction())
            .then(response => showErrorMessage(getExpirationPromotionSettingNotificationData(response)));
    }, [dispatch, showErrorMessage]);

    const getExpirationPromotionSettings = useSelector(getExpirationPromotionSettingState);
    const updateExpirationPromotionSettings = useSelector(updateExpirationPromotionSettingState);

    useEffect(() => {
        refreshExpirationPromotionSetting();
    }, [refreshExpirationPromotionSetting]);


    const initialValues = {
        notificationEnabled: getExpirationPromotionSettings?.data?.notificationEnabled || false,
    }

    const schema = Yup.object().shape({
        notificationEnabled: Yup.bool().required()
    });

    const onSubmit = (values) => {
        const form = { ...values };

        dispatch(updateExpirationPromotionSettingAction({ form }))
            .then(response => showNotification(updateExpirationPromotionSettingNotificationData(response)))
    };

    return (
        <Grid mb={ 6 }>
            <Grid container mb={ 4 } rowGap={ 4 } justifyContent="space-between">
                <Grid container>
                    <Typography variant="h2">Ustawienia ogólne</Typography>
                </Grid>
                <Grid container>
                    {
                        getExpirationPromotionSettings?.loading &&
                        <LinearProgress/>
                    }
                    {
                        getExpirationPromotionSettings?.data &&
                        <Formik
                            initialValues={ initialValues }
                            validationSchema={ schema }
                            validateOnChange={ false }
                            validateOnBlur={ false }
                            onSubmit={ onSubmit }>
                            { () =>
                                <Grid component={ Form } container rowGap={ 4 }>
                                    <Grid container item>
                                        <RadioGroupFormik
                                            name="notificationEnabled"
                                            label="Wysyłaj notyfikacje o promocjach na krótki termin przydatności"
                                            options={ yesNoOptions }
                                        />
                                    </Grid>
                                    <Grid container item mt={ 2 } justifyContent="flex-end">
                                        <SubmitButton isLoading={ updateExpirationPromotionSettings?.loading }>Zapisz</SubmitButton>
                                    </Grid>
                                </Grid>
                            }
                        </Formik>
                    }
                </Grid>
            </Grid>
            <Grid container mb={ 4 } rowGap={ 4 } justifyContent="space-between">
                <Grid container>
                    <Typography variant="h2">Automatycze promocje</Typography>
                </Grid>
                <Grid container>
                    <AutomaticExpirationPromotionSettingList tab={ tab } />
                </Grid>
            </Grid>
        </Grid>
    );
};

export default SettingForm;
