import React, { useCallback, useState } from 'react';

import Dropzone from 'react-dropzone';
import { Box, FormHelperText, Grid, IconButton, Typography } from '@mui/material';
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import AttachmentOutlinedIcon from '@mui/icons-material/AttachmentOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { makeStyles } from 'tss-react/mui';
import DownloadIcon from '@mui/icons-material/Download';
import { CustomIconButton } from './button';

const FileInput = ({ validate, onAttach, label, isAttached, text, errorMessage, onRemove, uploadedFileName }) => {
    const { classes, cx } = useStyles();

    const [hasError, setHasError] = useState(false);
    const [filename, setFilename] = useState(uploadedFileName);

    const onDrop = useCallback((acceptedFiles) => {
        setHasError(false);
        const file = acceptedFiles[0];

        if (!file || !validate(file)) {
            setHasError(true);
            return;
        }

        setFilename(file.name);

        onAttach && onAttach(file);
    }, [onAttach, validate]);


    return (
        <Grid className={ cx(classes.root) }>
            {
                label &&
                <Box mb={ 1 }>
                    <Typography variant="h6">{ label }</Typography>
                </Box>
            }
            <Grid container item spacing={ 2 }>
                <Grid container item xs={ 10 }>
                    {
                        !isAttached &&
                        <Grid>
                            <Dropzone
                                maxFiles={ 1 }
                                multiple={ false }
                                onDrop={ onDrop }
                            >
                                { (dropzoneState) => (
                                    <Grid container alignItems="center" { ...dropzoneState.getRootProps({ className: cx(classes.dropzone) }) }>
                                        <input { ...dropzoneState.getInputProps() }/>
                                        <AddBoxOutlinedIcon/>
                                        <Box ml={ 2 }>
                                            { text }
                                        </Box>
                                    </Grid>
                                ) }
                            </Dropzone>
                            {
                                hasError &&
                                <FormHelperText error>
                                    { errorMessage }
                                </FormHelperText>
                            }
                        </Grid>
                    }
                    {
                        isAttached &&
                        <Grid container alignItems="center" className={ cx(classes.attachedFileArea) }>
                            <AttachmentOutlinedIcon/>
                            <Grid ml={ 2 }>
                                { filename || 'Załączono' }
                            </Grid>
                        </Grid>
                    }
                </Grid>
                <Grid container item xs={ 2 }>
                    {
                        isAttached &&
                        <IconButton
                            onClick={ () => onRemove && onRemove() }
                            title="Usuń"
                        >
                            <CloseOutlinedIcon/>
                        </IconButton>
                    }
                </Grid>
            </Grid>
        </Grid>
    );
};

export const FilesInput = ({ disabled, onAttach, text, label, maxFiles, acceptedFileExtensions, maxTotalFilesSizeInMB, uploadedFiles }) => {
    const { classes, cx } = useStyles();

    const [error, setError] = useState(false);

    const onDrop = useCallback((acceptedFiles) => {
        setError(false);

        if (acceptedFiles?.length === 0) {
            setError('Niepoprawny plik.');
        }

        if (!checkFilesType(acceptedFiles, acceptedFileExtensions)) {
            setError('Niepoprawny format pliku.');
            return;
        }

        if (maxTotalFilesSizeInMB && uploadedFiles && !checkTotalFilesSize([...acceptedFiles, ...uploadedFiles], maxTotalFilesSizeInMB)) {
            setError('Przekroczony dozwolony łączny rozmiar plików.');
            return;
        }

        onAttach && onAttach(acceptedFiles);
    }, [acceptedFileExtensions, uploadedFiles, maxTotalFilesSizeInMB, onAttach]);

    if (maxFiles > 1 && uploadedFiles?.length >= maxFiles) {
        return (
            <Grid>
                <Typography variant="h6">Maksymalna liczba plików została osiągnięta.</Typography>
            </Grid>
        );
    }

    return (
        <Grid className={ cx(classes.root) }>
            {
                label &&
                <Grid mb={ 1 }>
                    <Typography variant="h6">{ label }</Typography>
                </Grid>
            }
            <Grid container item>
                <Grid item>
                    <Dropzone
                        disabled={ disabled }
                        multiple={ false }
                        onDrop={ onDrop }
                    >
                        { (dropzoneState) => (
                            <Grid container alignItems="center" { ...dropzoneState.getRootProps({ className: cx(classes.dropzone) }) }>
                                <input { ...dropzoneState.getInputProps() }/>
                                <AddBoxOutlinedIcon/>
                                <Grid ml={ 2 }>
                                    { text }
                                </Grid>
                            </Grid>
                        ) }
                    </Dropzone>
                    {
                        error &&
                        <FormHelperText error>
                            { error }
                        </FormHelperText>
                    }
                </Grid>
            </Grid>
        </Grid>
    );
};

export const UploadedFiles = ({ label, uploadedFiles, onRemove, downloadOption, ondDownloadClick, isLoading, removeOption }) => {
    const { classes, cx } = useStyles();

    return (
        <Grid className={ cx(classes.root) }>
            {
                uploadedFiles?.length > 0 && label &&
                <Grid mb={ 1 }>
                    <Typography variant="h6">{ label }</Typography>
                </Grid>
            }
            <Grid container item rowGap={ 2 }>
                {
                    uploadedFiles?.map((uploadedFile, index) => (
                        <Grid container key={ index } spacing={ 2 }>
                            <Grid container item xs={ (downloadOption && removeOption) ? 10 : 11 }>
                                <Grid container alignItems="center" className={ cx(classes.attachedFileArea) }>
                                    <AttachmentOutlinedIcon/>
                                    <Grid ml={ 2 }>
                                        { uploadedFile?.name || uploadedFile?.fileName || 'Załączono' }
                                    </Grid>
                                </Grid>
                            </Grid>
                            {
                                downloadOption &&
                                <Grid container item xs={ 1 } alignContent="center">
                                    {
                                        uploadedFile?.fileUrl &&
                                        <CustomIconButton
                                            title="Pobierz"
                                            isLoading={ isLoading }
                                            onClick={ () => ondDownloadClick(uploadedFile?.fileUrl) }
                                            icon={ <DownloadIcon/> }/>
                                    }
                                </Grid>
                            }
                            {
                                removeOption &&
                                <Grid container item xs={ 1 }>
                                    <IconButton
                                        onClick={ () => onRemove && onRemove(index) }
                                        title="Usuń"
                                    >
                                        <CloseOutlinedIcon/>
                                    </IconButton>
                                </Grid>
                            }
                        </Grid>
                    ))
                }
            </Grid>
        </Grid>
    );
};

const checkTotalFilesSize = (files, maxSizeInMB) => {
    const totalSize = files.reduce((sum, file) => sum + (file.size || file.sizeInBytes), 0);

    return totalSize <= maxSizeInMB * 1024 * 1024;
};

const getFileExtension = (file) => {
    if (!file.name.match(/\./)) {
        return '';
    }

    return file.name.split('.').pop().toLowerCase();
};

const checkFilesType = (files, acceptedFileExtensions) => {
    for (const file of files) {
        if (!acceptedFileExtensions.includes(getFileExtension(file))) {
            return false;
        }
    }

    return true;
};

const useStyles = makeStyles()(theme => ({
    root: {
        width: '100%'
    },
    dropzone: {
        border: '2px dashed ' + theme.palette.secondary.main,
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        height: '50px',
        width: '100%'
    },
    attachedFileArea: {
        background: theme.palette.secondary.light,
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        height: '50px',
        width: '100%'
    }
}));

export default FileInput;

