import React from 'react';

import { Grid } from '@mui/material';

import { Button } from '../../../common/button';

const Buttons = ({ onReturn, onSubmit }) => (
    <Grid container item mt={ 2 } justifyContent="space-between">
        <Grid item>
            <Button onClick={ () => onReturn() }>Wróć</Button>
        </Grid>
        <Grid item>
            <Button onClick={ () => onSubmit() }>Dalej</Button>
        </Grid>
    </Grid>
);

export default Buttons;
