import { combineReducers } from '@reduxjs/toolkit';

import getNotifications from './get_notifications';
import createNotification from './create_notification';
import getNotificationTypes from './get_notification_types';
import deleteNotification from './delete_notification';
import getDeliveryNotifications from './get_delivery_notifications';
import deleteDeliveryNotification from './delete_delivery_notification';
import getDeliveryNotification from './get_delivery_notification';
import createDeliveryNotification from './create_delivery_notification';
import updateDeliveryNotification from './update_delivery_notification';

export default combineReducers({
    getNotifications, createNotification, getNotificationTypes, deleteNotification, getDeliveryNotifications, deleteDeliveryNotification,
    getDeliveryNotification, createDeliveryNotification, updateDeliveryNotification
});
