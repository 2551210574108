import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import * as Yup from 'yup';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import { Form, Formik } from 'formik';
import { Grid, IconButton } from '@mui/material';
import DialogActions from '@mui/material/DialogActions';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import moment from 'moment';

import useNotification from '../../../../core/hooks/use_notification';
import { Button, SubmitButton } from '../../../common/button';
import { updateAdvertisementDisplayTimeAction, updateAdvertisementDisplayTimeState } from '../../../../features/pod/update_advertisement_display_time';
import { updateAdvertisementDisplayTimeNotificationData } from '../../../../core/utils/notification_utils';
import { ApiRequestStatusEnum } from '../../../../core/enums/common/api';
import { AdvertisementStatusEnum } from '../../../../core/enums/pod/advertisement';
import { DateTimePickerFormik } from '../../../common/date_picker';
import { MISC_DATE_ISO_FORMAT } from '../../../../core/constants';
import { parseDateString } from '../../../../core/utils/date_utils';

const AdvertisementDisplayTimeDialog = ({ advertisement, refreshList }) => {
    const { showNotification } = useNotification();
    const dispatch = useDispatch();

    const updateAdvertisementDisplayTime = useSelector(updateAdvertisementDisplayTimeState);

    const [open, setOpen] = useState(false);

    const isAdvertisementActive = advertisement.status === AdvertisementStatusEnum.ACTIVE;

    const initialValues = {
        displayTimeFrom: advertisement?.displayTimeFrom ? moment(advertisement?.displayTimeFrom) : '',
        displayTimeTo: advertisement?.displayTimeTo ? moment(advertisement?.displayTimeTo) : ''
    };

    const schema = Yup.object().shape({
        displayTimeFrom: Yup.date()
            .when('displayTimeTo', {
                is: (displayTimeTo) => !displayTimeTo && !isAdvertisementActive,
                then: Yup.date()
                    .transform(parseDateString)
                    .nullable()
                    .required('Data od lub do wymagana'),
                otherwise: Yup.date()
                    .transform(parseDateString)
                    .nullable()
                    .notRequired()
            }),
        displayTimeTo: Yup.date()
            .when('displayTimeFrom', {
                is: (displayTimeFrom) => !displayTimeFrom,
                then: Yup.date()
                    .transform(parseDateString)
                    .nullable()
                    .required(isAdvertisementActive ? 'Data do wymagana' : 'Data od lub do wymagana'),
                otherwise: Yup.date()
                    .transform(parseDateString)
                    .nullable()
                    .notRequired()
            })
    }, ['displayTimeFrom', 'displayTimeTo']);

    const onClose = () => setOpen(false);

    const onSubmit = (values) => {
        const displayTimeFrom = isAdvertisementActive ? undefined : values?.displayTimeFrom?.format(MISC_DATE_ISO_FORMAT);
        const displayTimeTo = values?.displayTimeTo?.format(MISC_DATE_ISO_FORMAT);

        const form = {
            displayTimeFrom,
            displayTimeTo
        };

        dispatch(updateAdvertisementDisplayTimeAction({ id: advertisement?.id, form }))
            .then(response => {
                showNotification(updateAdvertisementDisplayTimeNotificationData(response));
                if (response?.meta?.requestStatus === ApiRequestStatusEnum.FULFILLED) {
                    refreshList && refreshList();
                    onClose();
                }
            });
    };

    return (
        <>
            <IconButton title="Zmień daty obowiązywania" onClick={ () => setOpen(true) }>
                <CalendarMonthIcon/>
            </IconButton>
            <Dialog open={ open } onClose={ onClose } maxWidth={ 'lg' }>
                <DialogTitle>Zmień daty obowiązywania</DialogTitle>
                <DialogContent sx={ { paddingTop: '6px !important' } }>
                    <Formik
                        initialValues={ initialValues }
                        validationSchema={ schema }
                        validateOnChange={ false }
                        validateOnBlur={ false }
                        onSubmit={ onSubmit }>
                        { () =>
                            <Grid component={ Form } container rowGap={ 4 }>
                                <Grid container item md={ 12 } lg={ 6 } direction="column" rowGap={ 4 }>
                                    <Grid container item>
                                        <Grid item>
                                            <DateTimePickerFormik
                                                name="displayTimeFrom"
                                                label="Data od"
                                                disabled={ isAdvertisementActive }
                                                InputLabelProps={ { shrink: true } }
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container item>
                                        <Grid item>
                                            <DateTimePickerFormik
                                                name="displayTimeTo"
                                                label="Data do"
                                                required={ isAdvertisementActive }
                                                InputLabelProps={ { shrink: true } }
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid container item mt={ 2 } justifyContent="flex-end">
                                    <DialogActions sx={ { gap: 2 } }>
                                        <SubmitButton isLoading={ updateAdvertisementDisplayTime?.loading }>Zapisz</SubmitButton>
                                        <Button onClick={ onClose }>Zamknij</Button>
                                    </DialogActions>
                                </Grid>
                            </Grid>
                        }
                    </Formik>
                </DialogContent>
            </Dialog>
        </>
    );
};

export default AdvertisementDisplayTimeDialog;
