import { deepFreeze } from '../../utils/misc_utils';

export const SuggestedShelfEnum = deepFreeze({
    F1_WRAPS: 'F1_WRAPS',
    F2_LUNCHES_OR_WRAPS: 'F2_LUNCHES_OR_WRAPS',
    F3_SALADS_OR_SUSHI: 'F3_SALADS_OR_SUSHI',
    F4_BAGELS: 'F4_BAGELS',
    F5_SANDWICHES: 'F5_SANDWICHES',
    F6_DAIRY: 'F6_DAIRY',
    F7_DRINKS: 'F7_DRINKS',
    D1_BARS: 'D1_BARS',
    D2_CHIPS_OR_MOUSSES: 'D2_CHIPS_OR_MOUSSES',
    NOT_SPECIFIED: 'NOT_SPECIFIED'
});