import React from 'react';

import { Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { useNavigate } from 'react-router-dom';

import { GET_ROUTE_WAREHOUSE_DOCUMENT } from '../../../../../../core/constants';
import { FormRouteEnum } from '../../../../../../core/enums/common/route';

const WarehouseDocumentList = ({ documents }) => {
    const navigate = useNavigate();

    return (
        <Grid container mb={ 2 } >
            <Grid mb={ 1 } paddingTop={ 2 } >
                <Typography variant="h6">Wygenerowane powiązane dokumenty</Typography>
            </Grid>
            <TableContainer>
                <Table stickyHeader>
                    <TableHead>
                        <TableRow>
                            <TableCell>Typ</TableCell>
                            <TableCell align="left">Numer</TableCell>
                            <TableCell align="center">Podgląd</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            documents?.map(document => (
                                <TableRow
                                    key={ document?.internalId }
                                    hover
                                >
                                    <TableCell>{ document?.documentType }</TableCell>
                                    <TableCell align="left">{ document?.documentNumber }</TableCell>
                                    <TableCell align="center">
                                        <SearchIcon onClick={ () => navigate(GET_ROUTE_WAREHOUSE_DOCUMENT(document?.internalId), { state: { action: FormRouteEnum.VIEW } }) } cursor="pointer"/>
                                    </TableCell>
                                </TableRow>
                            ))
                        }
                    </TableBody>
                </Table>
            </TableContainer>
        </Grid>
    );
};

export default WarehouseDocumentList;
