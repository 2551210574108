import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import webClient from '../../core/web_client';
import { EXPIRATION_PROMOTION_SETTINGS_PATH } from '../../core/constants';
import { extractErrorData, extractResponseData } from '../../core/utils/api_utils';
import { DEFAULT_STATE, STATE__API__FAIL, STATE__API__STARTED, STATE__API__SUCCESS } from '../../core/utils/store_utils';
import { invalidateToken } from '../authorization/get_token';

export const getExpirationPromotionSettingAction = createAsyncThunk(
    'price/getExpirationPromotionSettingAction',
    async (_, { fulfillWithValue, rejectWithValue }) => {
        try {
            const response = await webClient.get(`${EXPIRATION_PROMOTION_SETTINGS_PATH}`);
            return fulfillWithValue(extractResponseData(response));
        } catch (error) {
            return rejectWithValue(extractErrorData(error));
        }
    }
);

const getExpirationPromotionSettingSlice = createSlice({
    name: 'getExpirationPromotionSettingsSlice',
    initialState: DEFAULT_STATE,
    extraReducers: builder => {
        builder.addCase(getExpirationPromotionSettingAction.pending, () => STATE__API__STARTED);
        builder.addCase(getExpirationPromotionSettingAction.fulfilled, (_, { payload }) => STATE__API__SUCCESS(payload));
        builder.addCase(getExpirationPromotionSettingAction.rejected, (_, { payload }) => STATE__API__FAIL(payload));
        builder.addCase(invalidateToken, () => DEFAULT_STATE);
    }
});

export const getExpirationPromotionSettingState = (state) => state.price.getExpirationPromotionSetting;
export default getExpirationPromotionSettingSlice.reducer;