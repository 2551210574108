import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { DEFAULT_STATE, STATE__API__FAIL, STATE__API__STARTED, STATE__API__SUCCESS } from '../../core/utils/store_utils';
import { invalidateToken } from '../authorization/get_token';
import webClient from '../../core/web_client';
import { extractErrorData, extractResponseData } from '../../core/utils/api_utils';
import { FOOD_PARTNERS_PATH } from '../../core/constants';

export const getFoodPartnersForReservationAction = createAsyncThunk(
    'foodPartner/getFoodPartnersForReservationAction',
    async (_, { fulfillWithValue, rejectWithValue }) => {
        try {
            const response = await webClient.get(`${ FOOD_PARTNERS_PATH }/reservation`);
            return fulfillWithValue(extractResponseData(response));
        } catch (error) {
            return rejectWithValue(extractErrorData(error));
        }
    }
);

const getFoodPartnersForReservationSlice = createSlice({
    name: 'getFoodPartnersForReservationSlice',
    initialState: DEFAULT_STATE,
    reducers: {},
    extraReducers: builder => {
        builder.addCase(getFoodPartnersForReservationAction.pending, () => STATE__API__STARTED);
        builder.addCase(getFoodPartnersForReservationAction.fulfilled, (_, { payload }) => STATE__API__SUCCESS(payload));
        builder.addCase(getFoodPartnersForReservationAction.rejected, (_, { payload }) => STATE__API__FAIL(payload));
        builder.addCase(invalidateToken, () => DEFAULT_STATE);
    }
});

export const getFoodPartnersForReservationState = (state) => state.foodPartner.getFoodPartnersForReservation;
export default getFoodPartnersForReservationSlice.reducer;
