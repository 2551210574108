import React, { useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import * as Yup from 'yup';
import { Box, Grid, LinearProgress } from '@mui/material';
import { Form, Formik } from 'formik';

import useNotification from '../../../../core/hooks/use_notification';
import { createIndoorPartnerNotificationData, getIndoorPartnerNotificationData, updateIndoorPartnerNotificationData } from '../../../../core/utils/notification_utils';
import { GET_ROUTE_INDOOR_PARTNER_EDIT, ROUTE_PODS } from '../../../../core/constants';
import TextFieldFormik from '../../../common/text_field';
import { Button, SubmitButton } from '../../../common/button';
import { getIndoorPartnerAction, getIndoorPartnerState } from '../../../../features/pod/get_indoor_partner';
import { createIndoorPartnerAction, createIndoorPartnerState } from '../../../../features/pod/create_indoor_partner';
import { ApiRequestStatusEnum } from '../../../../core/enums/common/api';
import { updateIndoorPartnerAction, updateIndoorPartnerState } from '../../../../features/pod/update_indoor_partner';

const IndoorPartnerForm = () => {
    const { showErrorMessage, showNotification } = useNotification();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { indoorPartnerId } = useParams();
    const location = useLocation();

    const getIndoorPartner = useSelector(getIndoorPartnerState);
    const createIndoorPartner = useSelector(createIndoorPartnerState);
    const updateIndoorPartner = useSelector(updateIndoorPartnerState);

    const initialValues = {
        name: (indoorPartnerId && getIndoorPartner?.data?.name) || '',
        address: (indoorPartnerId && getIndoorPartner?.data?.address) || ''
    };

    const schema = Yup.object().shape({
        name: Yup.string().required(),
        address: Yup.string().required()
    });

    useEffect(() => {
        indoorPartnerId && dispatch(getIndoorPartnerAction(indoorPartnerId))
            .then(response => showErrorMessage((getIndoorPartnerNotificationData(response))));
    }, [indoorPartnerId, dispatch, showErrorMessage]);

    const onSubmit = (values) => {
        const form = { ...values };

        if (!indoorPartnerId) {
            dispatch(createIndoorPartnerAction(form))
                .then(response => {
                    showNotification(createIndoorPartnerNotificationData(response));
                    response?.meta?.requestStatus === ApiRequestStatusEnum.FULFILLED && navigate(GET_ROUTE_INDOOR_PARTNER_EDIT(response?.payload.id), { state: { tab: location?.state?.tab } });
                });
        } else {
            dispatch(updateIndoorPartnerAction({ form, id: indoorPartnerId }))
                .then(response => {
                    showNotification(updateIndoorPartnerNotificationData(response));
                });
        }
    };

    const renderView = () => {
        if (getIndoorPartner?.loading) {
            return (
                <Box mt={ 6 }>
                    <LinearProgress/>
                </Box>
            );
        }

        return (
            <Formik
                initialValues={ initialValues }
                validationSchema={ schema }
                validateOnChange={ false }
                validateOnBlur={ false }
                onSubmit={ onSubmit }>
                { () =>
                    <Grid component={ Form } container rowGap={ 2 }>
                        <Grid container item>
                            <TextFieldFormik name="name" label="Nazwa" required/>
                        </Grid>
                        <Grid container item>
                            <TextFieldFormik name="address" label="Adres" required/>
                        </Grid>
                        <Grid container item mt={ 2 } justifyContent="flex-end">
                            <SubmitButton isLoading={ indoorPartnerId ? updateIndoorPartner?.loading : createIndoorPartner?.loading }>
                                Zapisz
                            </SubmitButton>
                        </Grid>
                    </Grid>
                }
            </Formik>
        );
    };

    return (
        <Grid mb={ 6 }>
            <Grid container mb={ 4 } justifyContent="space-between" rowGap={ 2 }>
                <Grid container item>
                    <Button onClick={ () => navigate(ROUTE_PODS, { state: { tab: location?.state?.tab } }) }>
                        Wróć
                    </Button>
                </Grid>
            </Grid>
            {
                renderView()
            }
        </Grid>
    );
};

export default IndoorPartnerForm;
