import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import webClient from '../../core/web_client';
import { SUPPLIERS_PATH } from '../../core/constants';
import { extractErrorData, extractResponseData } from '../../core/utils/api_utils';
import { DEFAULT_STATE, STATE__API__FAIL, STATE__API__STARTED, STATE__API__SUCCESS } from '../../core/utils/store_utils';
import { invalidateToken } from '../authorization/get_token';

export const createSupplierAction = createAsyncThunk(
    'store/createSupplierAction',
    async (data, { fulfillWithValue, rejectWithValue }) => {
        try {
            const response = await webClient.post(SUPPLIERS_PATH, data);
            return fulfillWithValue(extractResponseData(response));
        } catch (error) {
            return rejectWithValue(extractErrorData(error));
        }
    }
);

const createSupplierSlice = createSlice({
    name: 'createSupplierSlice',
    initialState: DEFAULT_STATE,
    reducers: {},
    extraReducers: builder => {
        builder.addCase(createSupplierAction.pending, () => STATE__API__STARTED);
        builder.addCase(createSupplierAction.fulfilled, (_, { payload }) => STATE__API__SUCCESS(payload));
        builder.addCase(createSupplierAction.rejected, (_, { payload }) => STATE__API__FAIL(payload));
        builder.addCase(invalidateToken, () => DEFAULT_STATE);
    }
});

export const createSupplierState = (state) => state.store.createSupplier;
export default createSupplierSlice.reducer;
