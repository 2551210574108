import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { Box, Grid, LinearProgress } from '@mui/material';

import { getNotificationTypesNotificationData, getPodsNotificationData } from '../../../../core/utils/notification_utils';
import { getAllPodsAction, getAllPodsState } from '../../../../features/pod/get_all_pods';
import useNotification from '../../../../core/hooks/use_notification';
import NotificationForm from './notification_form';
import { getNotificationTypesAction, getNotificationTypesState } from '../../../../features/communication/get_notification_types';
import { ROUTE_NOTIFICATIONS } from '../../../../core/constants';
import { Button } from '../../../common/button';

const NotificationPage = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();

    const getAllPods = useSelector(getAllPodsState);
    const getNotificationTypes = useSelector(getNotificationTypesState);

    const { showErrorMessage } = useNotification();

    useEffect(() => {
        dispatch(getAllPodsAction())
            .then(response => showErrorMessage(getPodsNotificationData(response)));
        dispatch(getNotificationTypesAction())
            .then(response => showErrorMessage(getNotificationTypesNotificationData(response)));
    }, [dispatch, showErrorMessage]);

    return (
        <Grid mb={ 6 }>
            <Box mb={ 4 }>
                <Button onClick={ () => navigate(ROUTE_NOTIFICATIONS, { state: { queryParams: location?.state?.queryParams, tab: location?.state?.tab } }) }>
                    Wróć
                </Button>
            </Box>
            {
                (getAllPods?.loading || getNotificationTypes?.loading) &&
                <LinearProgress/>
            }
            {
                (getAllPods?.data && getNotificationTypes?.data) &&
                <NotificationForm/>
            }
        </Grid>
    );
};

export default NotificationPage;
