import { PromotionCampaignStatusEnum, PromotionCampaignTypeEnum } from '../../../core/enums/price/promotion_campaign';

export const getPromotionCampaignStatus = (status) => {
    switch (status) {
        case PromotionCampaignStatusEnum.ACTIVE:
            return 'Aktywny';
        case PromotionCampaignStatusEnum.READY_TO_ACTIVE:
            return 'Gotowy do aktywacji';
        case PromotionCampaignStatusEnum.DEACTIVATED:
            return 'Nieaktywny';
        case PromotionCampaignStatusEnum.EXPIRED:
            return 'Wygasły';
        case PromotionCampaignStatusEnum.DELETED:
            return 'Usunięty';
        default:
            return PromotionCampaignStatusEnum.UNKNOWN;
    }
};

export const getUpdatePromotionCampaignStatus = (status) => {
    switch (status) {
        case PromotionCampaignStatusEnum.ACTIVE:
            return PromotionCampaignStatusEnum.DEACTIVATED;
        case PromotionCampaignStatusEnum.READY_TO_ACTIVE:
        case PromotionCampaignStatusEnum.DEACTIVATED:
            return PromotionCampaignStatusEnum.ACTIVE;
        default:
            return PromotionCampaignStatusEnum.UNKNOWN;
    }
};


export const getButtonText = (status) => {
    switch (status) {
        case PromotionCampaignStatusEnum.ACTIVE:
            return 'Dezaktywuj';
        case PromotionCampaignStatusEnum.READY_TO_ACTIVE:
        case PromotionCampaignStatusEnum.DEACTIVATED:
            return 'Aktywuj';
        default:
            return PromotionCampaignStatusEnum.UNKNOWN;
    }
};

export const getPromotionCampaignTypeOptions = [
    { value: PromotionCampaignTypeEnum.PRODUCTS.value, label: PromotionCampaignTypeEnum.PRODUCTS.label },
    { value: PromotionCampaignTypeEnum.FOOD_TYPE.value, label: PromotionCampaignTypeEnum.FOOD_TYPE.label },
    { value: PromotionCampaignTypeEnum.ATTRIBUTE.value, label: PromotionCampaignTypeEnum.ATTRIBUTE.label },
    { value: PromotionCampaignTypeEnum.FOOD_PARTNER.value, label: PromotionCampaignTypeEnum.FOOD_PARTNER.label },
];
