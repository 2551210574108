import { Check as CheckIcon, Clear as ClearIcon, HourglassEmpty as HourglassEmptyIcon } from '@mui/icons-material';
import { Icon } from '@mui/material';

import { FoodPartnerOrderEmailStatusEnum, FoodPartnerOrderStatusEnum } from '../../../core/enums/inventory/food_partner_order';

export const getFoodPartnerOrderStatus = (status) => {
    switch (status) {
        case FoodPartnerOrderStatusEnum.CREATED.value:
            return FoodPartnerOrderStatusEnum.CREATED.label;
        case FoodPartnerOrderStatusEnum.IN_PROGRESS.value:
            return FoodPartnerOrderStatusEnum.IN_PROGRESS.label;
        case FoodPartnerOrderStatusEnum.DELIVERED.value:
            return FoodPartnerOrderStatusEnum.DELIVERED.label;
        case FoodPartnerOrderStatusEnum.COMPLETED.value:
            return FoodPartnerOrderStatusEnum.COMPLETED.label;
        case FoodPartnerOrderStatusEnum.CANCELLED.value:
            return FoodPartnerOrderStatusEnum.CANCELLED.label;
        default:
            return FoodPartnerOrderStatusEnum.UNKNOWN.label;
    }
};

export const getFoodPartnerOrderStatusOptions = [
    { value: FoodPartnerOrderStatusEnum.CREATED.value, label: FoodPartnerOrderStatusEnum.CREATED.label },
    { value: FoodPartnerOrderStatusEnum.IN_PROGRESS.value, label: FoodPartnerOrderStatusEnum.IN_PROGRESS.label },
    { value: FoodPartnerOrderStatusEnum.DELIVERED.value, label: FoodPartnerOrderStatusEnum.DELIVERED.label },
    { value: FoodPartnerOrderStatusEnum.COMPLETED.value, label: FoodPartnerOrderStatusEnum.COMPLETED.label },
    { value: FoodPartnerOrderStatusEnum.CANCELLED.value, label: FoodPartnerOrderStatusEnum.CANCELLED.label },
];

export const getFoodPartnerOrderEmailStatusIcon = (emailStatus) => {
    switch (emailStatus) {
        case FoodPartnerOrderEmailStatusEnum.UNSENT.value:
            return (
                <Icon title={ FoodPartnerOrderEmailStatusEnum.UNSENT.label }>
                    <ClearIcon color="error"/>;
                </Icon>
            );
        case FoodPartnerOrderEmailStatusEnum.IN_PROGRESS.value:
            return (
                <Icon title={ FoodPartnerOrderEmailStatusEnum.IN_PROGRESS.label }>
                    <HourglassEmptyIcon color="warning"/>
                </Icon>
            );
        case FoodPartnerOrderEmailStatusEnum.SENT.value:
            return (
                <Icon title={ FoodPartnerOrderEmailStatusEnum.SENT.label }>
                    <CheckIcon color="success"/>;
                </Icon>
            );
        default:
            return (
                <Icon title={ FoodPartnerOrderEmailStatusEnum.UNSENT.label }>
                    <ClearIcon color="error"/>;
                </Icon>
            );
    }
};
