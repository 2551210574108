import React from 'react';
import { useSelector } from 'react-redux';

import { Grid } from '@mui/material';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';

import { SubmitButton } from '../../../../common/button';
import { SelectFieldFormik } from '../../../../common/select_field';
import { getGenerateWarehouseDocumentTypeRequestOptions, getWarehouseName } from '../../../../common/utils/warehouse_utils';
import { GenerateWarehouseDocumentTypeRequestEnum } from '../../../../../core/enums/inventory/warehouse_document';
import ThreeLevelSteps from '../../../common/warehouse_and_food_partner_order_form/three_level_steps';
import { getAllFoodPartnersState } from '../../../../../features/food_partner/get_all_food_partners';
import DatePickerFormik from '../../../../common/date_picker';
import { parseDateString } from '../../../../../core/utils/date_utils';
import { getAllWarehousesState } from '../../../../../features/inventory/get_all_warehouses';
import { yesNoOptions } from '../../../../common/form_utils';
import RadioGroupFormik from '../../../../common/radio_group';

const GeneralDataForm = ({ form, onSubmitGeneralData }) => {
    const getFoodPartners = useSelector(getAllFoodPartnersState);
    const getAllWarehouses = useSelector(getAllWarehousesState);

    const initialValues = {
        type: form?.type || '',
        foodPartnerId: form?.foodPartnerId || '',
        sourceWarehouseId: form?.sourceWarehouseId || '',
        confirmStocking: form?.confirmStocking || false,
        deliveryDate: form?.deliveryDate || ''
    };

    const schema = Yup.object().shape({
        type: Yup.string().required(),
        foodPartnerId: Yup.number().when('type', {
            is: (type) => type === GenerateWarehouseDocumentTypeRequestEnum.MM_DOCUMENTS.value,
            then: Yup.number().notRequired(),
            otherwise: Yup.number().required()
        }),
        sourceWarehouseId: Yup.number().when('type', {
            is: (type) => type === GenerateWarehouseDocumentTypeRequestEnum.MM_DOCUMENTS.value,
            then: Yup.number().required(),
            otherwise: Yup.number().notRequired()
        }),
        confirmStocking: Yup.boolean().when('type', {
            is: (type) => type === GenerateWarehouseDocumentTypeRequestEnum.MM_DOCUMENTS.value,
            then: Yup.boolean().required(),
            otherwise: Yup.boolean().notRequired()
        }),
        deliveryDate: Yup.date()
            .transform(parseDateString)
            .nullable()
            .required()
    });

    return (
        <Formik
            initialValues={ initialValues }
            validationSchema={ schema }
            validateOnChange={ false }
            validateOnBlur={ false }
            onSubmit={ onSubmitGeneralData }>
            { (formik) =>
                <Grid component={ Form } container rowGap={ 2 } paddingTop={ 2 }>
                    <ThreeLevelSteps firstStep/>
                    <Grid container item rowGap={ 2 }>
                        <Grid container item columnGap={ 6 } rowGap={ 2 }>
                            <Grid item xs={ 3 }>
                                <SelectFieldFormik
                                    name="type"
                                    label="Wariant dokumentu"
                                    options={ getGenerateWarehouseDocumentTypeRequestOptions }
                                    required
                                    onChange={ (value) => {
                                        value === GenerateWarehouseDocumentTypeRequestEnum.MM_DOCUMENTS.value && formik.setFieldValue('foodPartnerId', '');
                                        value !== GenerateWarehouseDocumentTypeRequestEnum.MM_DOCUMENTS.value && formik.setFieldValue('sourceWarehouseId', '');
                                        value !== GenerateWarehouseDocumentTypeRequestEnum.MM_DOCUMENTS.value && formik.setFieldValue('confirmDelivery', '');
                                    } }
                                />
                            </Grid>
                            {
                                (
                                    formik.values.type === GenerateWarehouseDocumentTypeRequestEnum.PZ_DOCUMENT.value
                                    || formik.values.type === GenerateWarehouseDocumentTypeRequestEnum.PZ_AND_MM_DOCUMENTS.value
                                    || formik.values.type === GenerateWarehouseDocumentTypeRequestEnum.PW_DOCUMENT.value
                                ) &&
                                <Grid item xs={ 6 }>
                                    <SelectFieldFormik
                                        name="foodPartnerId"
                                        label="Dostawca"
                                        options={ getFoodPartners?.data?.map(getFoodPartner => ({ value: getFoodPartner?.id, label: getFoodPartner?.name })) }
                                        required
                                    />
                                </Grid>
                            }
                            {
                                formik.values.type === GenerateWarehouseDocumentTypeRequestEnum.MM_DOCUMENTS.value &&
                                <Grid item xs={ 6 }>
                                    <SelectFieldFormik
                                        name="sourceWarehouseId"
                                        label="Magazyn źródłowy"
                                        options={ getAllWarehouses?.data?.map(warehouse => ({ value: warehouse?.internalId, label: getWarehouseName(warehouse) })) }
                                        required
                                    />
                                </Grid>
                            }
                        </Grid>
                        <Grid container item columnGap={ 6 } rowGap={ 2 }>
                            <Grid item>
                                <DatePickerFormik
                                    name="deliveryDate"
                                    label="Data dostawy"
                                    required
                                    InputLabelProps={ { shrink: true } }
                                />
                            </Grid>
                            {
                                formik.values.type === GenerateWarehouseDocumentTypeRequestEnum.MM_DOCUMENTS.value &&
                                <Grid item xs={ 6 }>
                                    <RadioGroupFormik
                                        name="confirmStocking"
                                        label="Potwierdź zatowarowanie"
                                        options={ yesNoOptions }
                                    />
                                </Grid>
                            }
                        </Grid>
                    </Grid>
                    <Grid container item mt={ 2 } justifyContent="flex-end">
                        <SubmitButton>Dalej</SubmitButton>
                    </Grid>
                </Grid>
            }
        </Formik>
    );
};

export default GeneralDataForm;
