import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Form, Formik } from 'formik';
import { Grid } from '@mui/material';
import * as Yup from 'yup';

import useNotification from '../../../../../core/hooks/use_notification';
import TextFieldFormik from '../../../../common/text_field';
import { getCouponTranslationState } from '../../../../../features/coupon/get_coupon_translation';
import { SubmitButton } from '../../../../common/button';
import { updateCouponTranslationAction, updateCouponTranslationState } from '../../../../../features/coupon/update_coupon_translation';
import { updateCouponTranslationNotificationData } from '../../../../../core/utils/notification_utils';

const CouponTranslationForm = ({ disableForm, couponId, languageCode }) => {
    const dispatch = useDispatch();
    const { showNotification } = useNotification();

    const getCouponTranslation = useSelector(getCouponTranslationState);
    const updateCouponTranslation = useSelector(updateCouponTranslationState);

    const initialValues = {
        name: getCouponTranslation?.data?.name || '',
        description: getCouponTranslation?.data?.description || ''
    };

    const schema = Yup.object().shape({
        name: Yup.string().required(),
        description: Yup.string().required()
    });

    const onSubmit = (values) => {
        if (disableForm) return;

        const form = {
            ...values,
            languageCode
        };

        dispatch(updateCouponTranslationAction({ form, couponId }))
            .then(response => showNotification((updateCouponTranslationNotificationData(response))));
    };

    return (
        <Formik
            initialValues={ initialValues }
            validationSchema={ schema }
            validateOnChange={ false }
            validateOnBlur={ false }
            onSubmit={ onSubmit }>
            { () =>
                <Grid component={ Form } container rowGap={ 4 }>
                    <Grid container item>
                        <TextFieldFormik name="name" label="Nazwa" required disabled={ disableForm }/>
                    </Grid>
                    <Grid container item>
                        <TextFieldFormik name="description" label="Opis" required disabled={ disableForm }/>
                    </Grid>
                    {
                        !disableForm &&
                        <Grid container item mt={ 2 } justifyContent="flex-end">
                            <SubmitButton isLoading={ updateCouponTranslation?.loading }>Zapisz</SubmitButton>
                        </Grid>
                    }
                </Grid>
            }
        </Formik>
    );
};

export default CouponTranslationForm;
