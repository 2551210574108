import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import webClient from '../../core/web_client';
import { COUPONS_PATH } from '../../core/constants';
import { extractErrorData, extractResponseData } from '../../core/utils/api_utils';
import { DEFAULT_STATE, STATE__API__FAIL, STATE__API__STARTED, STATE__API__SUCCESS } from '../../core/utils/store_utils';
import { invalidateToken } from '../authorization/get_token';

export const getCouponTranslationAction = createAsyncThunk(
    'coupon/getCouponTranslationAction',
    async (data, { fulfillWithValue, rejectWithValue }) => {
        try {
            const response = await webClient.get(`${ COUPONS_PATH }/${ data.couponId }/translation?languageCode=${ data.languageCode || 'PL'  }`);
            return fulfillWithValue(extractResponseData(response));
        } catch (error) {
            return rejectWithValue(extractErrorData(error));
        }
    }
);

const getCouponTranslationSlice = createSlice({
    name: 'getCouponTranslationSlice',
    initialState: DEFAULT_STATE,
    reducers: {},
    extraReducers: builder => {
        builder.addCase(getCouponTranslationAction.pending, () => STATE__API__STARTED);
        builder.addCase(getCouponTranslationAction.fulfilled, (_, { payload }) => STATE__API__SUCCESS(payload));
        builder.addCase(getCouponTranslationAction.rejected, (_, { payload }) => STATE__API__FAIL(payload));
        builder.addCase(invalidateToken, () => DEFAULT_STATE);
    }
});

export const getCouponTranslationState = (state) => state.coupon.getCouponTranslation;
export default getCouponTranslationSlice.reducer;
