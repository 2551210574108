import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { Box, Grid, LinearProgress, Tab, Tabs } from '@mui/material';
import { tabsClasses } from '@mui/material/Tabs';

import { Button } from '../../../../../common/button';
import { ROUTE_EXPIRATION_PROMOTIONS } from '../../../../../../core/constants';
import useNotification from '../../../../../../core/hooks/use_notification';
import { getAutomaticExpirationPromotionSettingAction, getAutomaticExpirationPromotionSettingState, invalidateAutomaticExpirationPromotionSetting } from '../../../../../../features/price/get_automatic_expiration_promotion_setting';
import { getAutomaticExpirationPromotionSettingNotificationData } from '../../../../../../core/utils/notification_utils';
import AutomaticExpirationPromotionSettingForm from './automatic_expiration_promotion_setting_form';

const AutomaticExpirationPromotionSettingFormsPage = () => {
    const dispatch = useDispatch();
    const { showErrorMessage } = useNotification();
    const navigate = useNavigate();
    const { type } = useParams();
    const location = useLocation();

    const getAutomaticExpirationPromotionSetting = useSelector(getAutomaticExpirationPromotionSettingState);

    const [tab, setTab] = useState(0);

    useEffect(() => {
        dispatch(getAutomaticExpirationPromotionSettingAction(type))
            .then(response => showErrorMessage(getAutomaticExpirationPromotionSettingNotificationData(response)));

        return () => {
            dispatch(invalidateAutomaticExpirationPromotionSetting());
        };
    }, [dispatch, type, showErrorMessage]);

    const renderView = () => {
        if (getAutomaticExpirationPromotionSetting?.loading) {
            return (
                <Box mt={ 6 }>
                    <LinearProgress/>
                </Box>
            );
        }

        if (getAutomaticExpirationPromotionSetting?.data) {
            if (tab === 0) {
                return (
                    <AutomaticExpirationPromotionSettingForm
                        type={ type }/>
                );
            }
        }

        return <></>;
    };

    return (
        <Grid mb={ 6 }>
            <Grid container mb={ 4 } justifyContent="space-between" rowGap={ 2 }>
                <Grid container item>
                    <Button onClick={ () => navigate(ROUTE_EXPIRATION_PROMOTIONS, { state: { tab: location?.state?.tab } }) }>
                        Wróć
                    </Button>
                </Grid>
                <Grid container item>
                    <Tabs
                        value={ tab }
                        onChange={ (_, value) => setTab(value) }
                        variant="scrollable"
                        scrollButtons
                        sx={ {
                            [`& .${ tabsClasses.scrollButtons }`]: {
                                '&.Mui-disabled': { opacity: 0.3 },
                            },
                        } }
                    >
                        <Tab label="Informacje ogólne" sx={ { justifyContent: 'flexEnd' } }/>
                    </Tabs>
                </Grid>
            </Grid>
            {
                renderView()
            }
        </Grid>
    );
};

export default AutomaticExpirationPromotionSettingFormsPage;
