import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Grid } from '@mui/material';

import { FilesInput, UploadedFiles } from '../../../common/file_input';
import { getFoodPartnerOrderAttachmentAction, getFoodPartnerOrderAttachmentState } from '../../../../features/inventory/get_food_partner_order_attachment';
import { getFoodPartnerOrderAttachmentNotificationData } from '../../../../core/utils/notification_utils';
import useNotification from '../../../../core/hooks/use_notification';

const UploadFiles = ({ formik, downloadOption, addAndRemoveOptions }) => {
    const dispatch = useDispatch();
    const { showErrorMessage } = useNotification();

    const generateFoodPartnerOrderAttachment = useSelector(getFoodPartnerOrderAttachmentState);

    const onAttachFile = (fileArray) => {
        const newFiles = [...formik?.values?.attachments] || [];
        fileArray.forEach(file => newFiles.push(file));

        formik.setFieldValue('attachments', newFiles);
    };

    const onRemoveFile = (id) => {
        const attachmentIdsToDelete = formik?.values?.attachmentIdsToDelete ? [...formik?.values?.attachmentIdsToDelete] : [];
        const newFiles = [...formik?.values?.attachments];
        const attachment = newFiles.splice(id, 1)[0];

        formik.setFieldValue('attachments', newFiles);
        attachment?.id && attachmentIdsToDelete.push(attachment?.id);
        formik.setFieldValue('attachmentIdsToDelete', attachmentIdsToDelete);
    };

    const ondDownloadClick = (fileUrl) => {
        dispatch(getFoodPartnerOrderAttachmentAction(fileUrl))
            .then(response => showErrorMessage(getFoodPartnerOrderAttachmentNotificationData(response)));
    };

    return (
        <Grid container mt={ 2 } rowGap={ 4 }>
            {
                addAndRemoveOptions &&
                <Grid container item>
                    <FilesInput
                        label="Załączniki do maila"
                        text="Dodaj lub przeciągnij plik"
                        onAttach={ onAttachFile }
                        maxFiles={ 3 }
                        acceptedFileExtensions={ ['xls', 'xlsx', 'pdf', 'doc', 'docx', 'csv', 'txt'] }
                        maxTotalFilesSizeInMB={ 15 }
                        uploadedFiles={ formik?.values?.attachments }
                    />
                </Grid>
            }
            <Grid container item>
                <UploadedFiles
                    uploadedFiles={ formik?.values?.attachments }
                    onRemove={ onRemoveFile }
                    label="Załączone pliki"
                    downloadOption={ downloadOption }
                    ondDownloadClick={ ondDownloadClick }
                    isLoading={ generateFoodPartnerOrderAttachment?.loading }
                    removeOption={ addAndRemoveOptions }
                />
            </Grid>
        </Grid>
    );
};

export default UploadFiles;
