import React from 'react';
import { useSelector } from 'react-redux';

import { Grid } from '@mui/material';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';

import { SubmitButton } from '../../../../../common/button';
import { SelectFieldFormik } from '../../../../../common/select_field';
import { GenerateWarehouseDocumentTypeRequestEnum } from '../../../../../../core/enums/inventory/warehouse_document';
import { getAllFoodPartnersState } from '../../../../../../features/food_partner/get_all_food_partners';
import { DateTimePickerFormik } from '../../../../../common/date_picker';
import { parseDateString } from '../../../../../../core/utils/date_utils';
import ThreeLevelSteps from '../../../../common/warehouse_and_food_partner_order_form/three_level_steps';
import { yesNoOptions } from '../../../../../common/form_utils';
import RadioGroupFormik from '../../../../../common/radio_group';

const GeneralDataForm = ({ form, onSubmitGeneralData }) => {
    const getFoodPartners = useSelector(getAllFoodPartnersState);

    const initialValues = {
        foodPartnerId: form?.foodPartnerId || '',
        orderDate: form?.orderDate || '',
        locationDivision: form?.locationDivision || false
    };

    const schema = Yup.object().shape({
        foodPartnerId: Yup.number().when('type', {
            is: (type) => type === GenerateWarehouseDocumentTypeRequestEnum.MM_DOCUMENTS.value,
            then: Yup.number().notRequired(),
            otherwise: Yup.number().required()
        }),
        orderDate: Yup.date()
            .transform(parseDateString)
            .nullable()
            .required(),
        locationDivision: Yup.boolean()
            .required()
    });

    return (
        <Formik
            initialValues={ initialValues }
            validationSchema={ schema }
            validateOnChange={ false }
            validateOnBlur={ false }
            onSubmit={ onSubmitGeneralData }>
            { () =>
                <Grid component={ Form } container rowGap={ 2 } paddingTop={ 2 }>
                    <ThreeLevelSteps firstStep/>
                    <Grid container item rowGap={ 2 }>
                        <Grid container item gap={ 6 }>
                            <Grid item xs={ 6 }>
                                <SelectFieldFormik
                                    name="foodPartnerId"
                                    label="Dostawca"
                                    options={ getFoodPartners?.data?.map(getFoodPartner => ({ value: getFoodPartner?.id, label: getFoodPartner?.name })) }
                                    required
                                />
                            </Grid>
                        </Grid>
                        <Grid container item>
                            <Grid item>
                                <DateTimePickerFormik
                                    name="orderDate"
                                    label="Data zamówienia"
                                    required
                                    InputLabelProps={ { shrink: true } }
                                />
                            </Grid>
                        </Grid>
                        <Grid container item>
                            <Grid item>
                                <RadioGroupFormik
                                    name="locationDivision"
                                    label="Podział na lokalizacje"
                                    options={ yesNoOptions }
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container item mt={ 2 } justifyContent="flex-end">
                        <SubmitButton>Dalej</SubmitButton>
                    </Grid>
                </Grid>
            }
        </Formik>
    );
};

export default GeneralDataForm;
