import { deepFreeze } from '../../utils/misc_utils';

export const FoodPartnerSuccessMessageEnum = deepFreeze({
    CREATE_FOOD_PARTNER: 'Pomyślnie utworzono dostawcę',
    UPDATE_FOOD_PARTNER: 'Pomyslnie zaktualizowano dostawcę',
    DELETE_FOOD_PARTNER: 'Pomyślnie usunięto dostawcę',
    CREATE_CONTACT_PERSON: 'Pomyślnie dodano osobę kontaktową',
    UPDATE_CONTACT_PERSON: 'Pomyślnie zaktualizowano osobę kontaktową',
    DELETE_CONTACT_PERSON: 'Pomyślnie usunięto osobę kontaktową',
    CREATE_DOCUMENT: 'Pomyślnie dodano dokument',
    DELETE_DOCUMENT: 'Pomyślnie usunięto dokument'
});

export const FoodPartnerErrorMessageEnum = deepFreeze({
    GET_FOOD_PARTNERS: 'Pobranie dostawców nie powiodło się',
    GET_ALL_FOOD_PARTNERS: 'Pobranie dostawców nie powiodło się',
    GET_FOOD_PARTNER: 'Pobranie dostawcy nie powiodło się',
    CREATE_FOOD_PARTNER: 'Utworzenie dostawcy nie powiodło się',
    UPDATE_FOOD_PARTNER: 'Aktualizacja dostawcy nie powiodła się',
    DELETE_FOOD_PARTNER: 'Nie udało się usunąć dostawcy',
    GET_CONTACT_PERSONS: 'Pobranie osób kontaktowych nie powiodło się',
    GET_CONTACT_PERSON: 'Pobranie osoby kontaktowej nie powiodło się',
    CREATE_CONTACT_PERSON: 'Dodanie osoby kontaktowej nie powiodło się',
    UPDATE_CONTACT_PERSON: 'Aktualizacja osoby kontaktowej nie powiodła się',
    DELETE_CONTACT_PERSON: 'Usunięcie osoby kontaktowej nie powiodło się',
    GET_DOCUMENTS: 'Pobranie dokumentów nie powiodło się',
    CREATE_DOCUMENT: 'Dodanie dokumentu nie powiodło się',
    DELETE_DOCUMENT: 'Usunięcie dokumentu nie powiodło się',
    GET_FOOD_PARTNERS_FOR_RESERVATION: 'Pobranie dostawców wspierających rezerwacje nie powiodło się'
});

const FoodPartnerServerErrorMessageEnum = deepFreeze({
    DUPLICATED_NIP: {
        message: 'Podany numer NIP już istnieje',
        errorCode: '400.2'
    },
    INVALID_NIP: {
        message: 'Niepoprawny format numeru NIP. Poprawny format to: 0000000000',
        errorCode: '400.3'
    },
});

export const getFoodPartnerErrorMessage = (message, defaultErrorMessage) => {
    for (let key in FoodPartnerServerErrorMessageEnum) {
        if (FoodPartnerServerErrorMessageEnum.hasOwnProperty(key)) {
            if (message?.substring(0, message?.indexOf(' ')) === FoodPartnerServerErrorMessageEnum[key].errorCode) {
                return FoodPartnerServerErrorMessageEnum[key].message;
            }
        }
    }

    return defaultErrorMessage;
};
