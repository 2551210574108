export const isNullOrUndefined = (input) => {
    return !!(input === undefined || input === null);
};

export const isEmptyArray = (array) => {
    return isNullOrUndefined(array) || array.length === 0;
};

export const stringsEqualTrimIgnoreCase = (string1, string2) => {
    if (isNullOrUndefined(string1) || isNullOrUndefined(string2)) {
        return false;
    }

    return string1.trim()
        .toLowerCase() === string2.trim()
        .toLowerCase();
};

export const deepFreeze = (object) => {
    if (isNullOrUndefined(object)) {
        return object;
    }

    Object.freeze(object);

    Object
        .getOwnPropertyNames(object)
        .forEach((prop) => {
            if (object.hasOwnProperty(prop)
                && object[prop] !== null
                && (typeof object[prop] === 'object' || typeof object[prop] === 'function')
                && !Object.isFrozen(object[prop])) {
                deepFreeze(object[prop]);
            }
        });

    return object;
};

export const isEmptyObject = (object) => {
    return isNullOrUndefined(object) || !!(Object.keys(object).length === 0 && object.constructor === Object);
};
