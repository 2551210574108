import { deepFreeze } from '../../utils/misc_utils';

export const WarehouseTypeEnum = deepFreeze({
    UNKNOWN: {
        value: 'UNKNOWN',
        label: 'Błąd'
    },
    POD: {
        value: 'POD',
        label: 'Pod'
    },
    INVENTORY: {
        value: 'INVENTORY',
        label: 'Inwentaryzacji'
    },
    MAIN: {
        value: 'MAIN',
        label: 'Główny'
    }
});