import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Box, Grid, LinearProgress, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import BlockIcon from '@mui/icons-material/Block';
import DeleteIcon from '@mui/icons-material/Delete';
import moment from 'moment/moment';

import { getEmployeesNotificationData, updateEmployeeNotificationData } from '../../../../core/utils/notification_utils';
import useNotification from '../../../../core/hooks/use_notification';
import { getEmployeesAction, getEmployeesState } from '../../../../features/benefit/get_employees';
import { MISC_DATE_ISO_FORMAT, MISC_DATE_POLISH_FORMAT } from '../../../../core/constants';
import { CustomIconButton } from '../../../common/button';
import { ApiRequestStatusEnum } from '../../../../core/enums/common/api';
import { updateEmployeeAction, updateEmployeeState } from '../../../../features/benefit/update_employee';
import PaginationControls from '../../../common/pagination_controls';
import deepmerge from 'deepmerge';
import { getDefaultEmployeePaginationQueryValues } from '../../../../core/utils/api_utils';

const initialQueryParams = {
    ...getDefaultEmployeePaginationQueryValues
};

const EmployeesList = ({ employerId }) => {
    const dispatch = useDispatch();
    const { showErrorMessage, showNotification } = useNotification();

    const [queryParams, setQueryParams] = useState(initialQueryParams);

    const getEmployees = useSelector(getEmployeesState);
    const updateEmployee = useSelector(updateEmployeeState);

    const refreshList = useCallback(() => {
        dispatch(getEmployeesAction({ ...queryParams, employerId}))
            .then(response => showErrorMessage(getEmployeesNotificationData(response)));
    }, [dispatch, employerId, queryParams, showErrorMessage]);

    useEffect(() => {
        refreshList();
    }, [refreshList]);

    const onNoConfirmEmployeeClick = (employeeId) => {
        dispatch(updateEmployeeAction({ id: employeeId, form: { command: 'NOT_CONFIRM', employerId } }))
            .then(response => {
                showNotification(updateEmployeeNotificationData(response));
                response?.meta?.requestStatus === ApiRequestStatusEnum.FULFILLED && refreshList();
            });
    };

    const onBlockEmployeeClick = (employeeId) => {
        dispatch(updateEmployeeAction({ id: employeeId, form: { command: 'BLOCK', employerId } }))
            .then(response => {
                showNotification(updateEmployeeNotificationData(response));
                response?.meta?.requestStatus === ApiRequestStatusEnum.FULFILLED && refreshList();
            });
    };

    return (
        <Grid rowGap={ 4 }>
            {
                getEmployees?.loading &&
                <LinearProgress/>
            }
            {
                getEmployees?.data?.content &&
                <Grid container>
                    <TableContainer>
                        <Table stickyHeader>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Email</TableCell>
                                    <TableCell>Status</TableCell>
                                    <TableCell align="center">Data utworzenia</TableCell>
                                    <TableCell align="center">Cofnij przypisanie</TableCell>
                                    <TableCell align="center">Zablokuj</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    getEmployees?.data?.content
                                        ?.map(employee => (
                                            <TableRow
                                                key={ employee?.employeeId }
                                                hover
                                            >
                                                <TableCell>{ employee?.email }</TableCell>
                                                <TableCell>{ employee?.status }</TableCell>
                                                <TableCell align="center">
                                                    {
                                                        moment(employee?.createDate, MISC_DATE_ISO_FORMAT)
                                                            .format(MISC_DATE_POLISH_FORMAT)
                                                    }
                                                </TableCell>
                                                <TableCell align="center">
                                                    <CustomIconButton
                                                        title={ 'Cofnij przypisanie' }
                                                        isLoading={ updateEmployee?.loading }
                                                        onClick={ () => onNoConfirmEmployeeClick(employee?.employeeId) }
                                                        icon={ <DeleteIcon/> }/>
                                                </TableCell>
                                                <TableCell align="center">
                                                    {
                                                        employee?.status !== 'BLOCKED' &&
                                                        <CustomIconButton
                                                            title={ 'Zablokuj' }
                                                            isLoading={ updateEmployee?.loading }
                                                            onClick={ () => onBlockEmployeeClick(employee?.employeeId) }
                                                            icon={ <BlockIcon/> }/>
                                                    }
                                                </TableCell>
                                            </TableRow>
                                        ))
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <Box mt={ 2 }>
                        <PaginationControls
                            currentPage={ getEmployees?.data?.pageable?.pageNumber }
                            totalPages={ getEmployees?.data?.totalPages }
                            setPage={ page => setQueryParams(prevState => deepmerge(prevState, { page })) }
                        />
                    </Box>
                </Grid>
            }
        </Grid>
    );
};

export default EmployeesList;
