import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { Grid, Tab, Tabs } from '@mui/material';
import { tabsClasses } from '@mui/material/Tabs';

import ArchivedPromotionCampaignsList from './archived_promotion_campaigns_list';
import ActivePromotionCampaignsList from './active_promotion_campaigns_list';

const PromotionCampaignsPage = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const [tab, setTab] = useState(location?.state?.tab || 0);

    const onTabChange = (tabValue) => {
        navigate(location.pathname, {});
        setTab(tabValue);
    };

    const renderView = () => {
        if (tab === 0) {
            return <ActivePromotionCampaignsList tab={ tab }/>;
        }
        if (tab === 1) {
            return <ArchivedPromotionCampaignsList tab={ tab }/>;
        }

        return <></>;
    };

    return (
        <Grid mb={ 6 }>
            <Grid container mb={ 4 }>
                <Tabs
                    value={ tab }
                    onChange={ (_, value) => onTabChange(value) }
                    variant="scrollable"
                    scrollButtons
                    sx={ {
                        [`& .${ tabsClasses.scrollButtons }`]: {
                            '&.Mui-disabled': { opacity: 0.3 },
                        },
                    } }
                >
                    <Tab label="Aktywne"/>
                    <Tab label="Archiwalne"/>
                </Tabs>
            </Grid>
            {
                renderView()
            }
        </Grid>
    );
};

export default PromotionCampaignsPage;
