import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import webClient from '../../core/web_client';
import { PROMOTION_CAMPAIGN_PATH } from '../../core/constants';
import { extractErrorData, extractResponseData } from '../../core/utils/api_utils';
import { DEFAULT_STATE, STATE__API__FAIL, STATE__API__STARTED, STATE__API__SUCCESS } from '../../core/utils/store_utils';
import { invalidateToken } from '../authorization/get_token';

export const getPromotionCampaignAdvertisementAction = createAsyncThunk(
    'price/getPromotionCampaignAdvertisementAction',
    async (promotionCampaignId, { fulfillWithValue, rejectWithValue }) => {
        try {
            const response = await webClient.get(`${ PROMOTION_CAMPAIGN_PATH }/${ promotionCampaignId }/advertisement`);
            return fulfillWithValue(extractResponseData(response));
        } catch (error) {
            return rejectWithValue(extractErrorData(error));
        }
    }
);

const getPromotionCampaignAdvertisementSlice = createSlice({
    name: 'getPromotionCampaignAdvertisementsSlice',
    initialState: DEFAULT_STATE,
    extraReducers: builder => {
        builder.addCase(getPromotionCampaignAdvertisementAction.pending, () => STATE__API__STARTED);
        builder.addCase(getPromotionCampaignAdvertisementAction.fulfilled, (_, { payload }) => STATE__API__SUCCESS(payload));
        builder.addCase(getPromotionCampaignAdvertisementAction.rejected, (_, { payload }) => STATE__API__FAIL(payload));
        builder.addCase(invalidateToken, () => DEFAULT_STATE);
    }
});

export const getPromotionCampaignAdvertisementState = (state) => state.price.getPromotionCampaignAdvertisement;
export default getPromotionCampaignAdvertisementSlice.reducer;