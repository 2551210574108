import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useNotification from '../../../../../core/hooks/use_notification';
import { getDocumentsAction, getDocumentsState } from '../../../../../features/food_partner/get_documents';
import { getDocumentsNotificationData } from '../../../../../core/utils/notification_utils';
import { Box, Grid, LinearProgress, Typography } from '@mui/material';
import DocumentsList from './documents_list';
import DocumentDialog from './document_dialog';

const DocumentsForm = ({ foodPartnerId }) => {
    const dispatch = useDispatch();
    const { showErrorMessage } = useNotification();

    const getDocuments = useSelector(getDocumentsState);

    const refreshList = useCallback(() => {
        dispatch(getDocumentsAction(foodPartnerId))
            .then(response => showErrorMessage(getDocumentsNotificationData(response)));
    }, [dispatch, foodPartnerId, showErrorMessage]);

    useEffect(() => {
        refreshList();
    }, [dispatch, refreshList, showErrorMessage]);

    return (
        <Grid>
            <Box mb={ 4 }>
                <Typography variant="h1">Dokumenty</Typography>
            </Box>
            <Box mb={ 2 }>
                <DocumentDialog foodPartnerId={ foodPartnerId } refreshList={ refreshList }/>
            </Box>
            {
                getDocuments?.loading &&
                <LinearProgress/>
            }
            {
                getDocuments?.data &&
                <DocumentsList foodPartnerId={ foodPartnerId } refreshList={ refreshList }/>
            }
        </Grid>
    );
}

export default DocumentsForm;
