import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import webClient from '../../core/web_client';
import { DELIVERY_NOTIFICATIONS_PATH } from '../../core/constants';
import { extractErrorData, extractResponseData } from '../../core/utils/api_utils';
import { DEFAULT_STATE, STATE__API__FAIL, STATE__API__STARTED, STATE__API__SUCCESS } from '../../core/utils/store_utils';
import { invalidateToken } from '../authorization/get_token';

export const deleteDeliveryNotificationAction = createAsyncThunk(
    'communication/deleteDeliveryNotificationAction',
    async (id, { fulfillWithValue, rejectWithValue }) => {
        try {
            const response = await webClient.delete(`${ DELIVERY_NOTIFICATIONS_PATH }/${ id }`);
            return fulfillWithValue(extractResponseData(response));
        } catch (error) {
            return rejectWithValue(extractErrorData(error));
        }
    }
);

const deleteDeliveryNotificationSlice = createSlice({
    name: 'deleteDeliveryNotificationSlice',
    initialState: DEFAULT_STATE,
    reducers: {},
    extraReducers: builder => {
        builder.addCase(deleteDeliveryNotificationAction.pending, () => STATE__API__STARTED);
        builder.addCase(deleteDeliveryNotificationAction.fulfilled, (_, { payload }) => STATE__API__SUCCESS(payload));
        builder.addCase(deleteDeliveryNotificationAction.rejected, (_, { payload }) => STATE__API__FAIL(payload));
        builder.addCase(invalidateToken, () => DEFAULT_STATE);
    }
});

export const deleteDeliveryNotificationState = (state) => state.communication.deleteDeliveryNotification;
export default deleteDeliveryNotificationSlice.reducer;
