import React, { useState } from 'react';
import { Button, SubmitButton } from '../../../../common/button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useDispatch, useSelector } from 'react-redux';
import { Grid } from '@mui/material';
import { createDocumentNotificationData } from '../../../../../core/utils/notification_utils';
import * as Yup from 'yup';
import { Form, Formik } from 'formik';
import TextFieldFormik from '../../../../common/text_field';
import { ApiRequestStatusEnum } from '../../../../../core/enums/common/api';
import { createDocumentAction, createDocumentState } from '../../../../../features/food_partner/create_document';
import FileInput from '../../../../common/file_input';
import { isNullOrUndefined } from '../../../../../core/utils/misc_utils';
import _ from 'lodash';

export default function DocumentDialog({ foodPartnerId, refreshList }) {
    const dispatch = useDispatch();

    const [open, setOpen] = useState(false);

    const createDocument = useSelector(createDocumentState);

    const initialValues = {
        displayName: '',
        document: null
    }

    const schema = Yup.object().shape({
        displayName: Yup.string().required(),
        document: Yup.mixed().required()
    });

    const onOpen = () => {
        setOpen(true);
    };

    const onSubmit = (values) => {
        const form = {
            ..._.omit(values, [
                'document'
            ])
        };
        const document = values.document;

        dispatch(createDocumentAction({ form, document, foodPartnerId }))
            .then(response => {
                createDocumentNotificationData((response));
                response?.meta?.requestStatus === ApiRequestStatusEnum.FULFILLED && onClose();
                response?.meta?.requestStatus === ApiRequestStatusEnum.FULFILLED && refreshList();
            });
    };

    const onClose = () => {
        setOpen(false);
    };

    const onAttach = (file, formik) => {
        formik.setFieldValue('document', file);
    };

    const onRemove = (formik) => {
        formik.setFieldValue('document', null);
    };

    return (
        <>
            <Button onClick={ onOpen } cursor="pointer">
                Dodaj dokument
            </Button>

            <Dialog open={ open } onClose={ onClose } fullWidth={ true } maxWidth={ 'sm' }>
                <DialogTitle>Dokument</DialogTitle>
                <DialogContent>
                    <Formik
                        initialValues={ initialValues }
                        validationSchema={ schema }
                        validateOnChange={ false }
                        validateOnBlur={ false }
                        onSubmit={ onSubmit }>
                        { (formik) =>
                            <Grid component={ Form } container rowGap={ 2 } paddingTop={ 2 }>
                                <Grid container item direction="column" rowGap={ 2 }>
                                    <Grid container item>
                                        <TextFieldFormik name="displayName" label="Nazwa dokumentu" required/>
                                    </Grid>
                                    <Grid container item>
                                        <FileInput
                                            label="Załącznik"
                                            text="Dodaj lub przeciągnij plik"
                                            errorMessage="Niepoprawny plik."
                                            validate={ document => ['image/png', 'image/jpeg', 'application/pdf',
                                                    'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                                                    'application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'].includes(document.type) }
                                            isAttached={ !isNullOrUndefined(formik.values.document) }
                                            onAttach={ (file) => onAttach(file, formik) }
                                            onRemove={ () => onRemove(formik) }
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container item mt={ 2 } justifyContent="flex-end">
                                    <DialogActions sx={ { gap: 2 } }>
                                        <SubmitButton isLoading={ createDocument?.loading }>Zapisz</SubmitButton>
                                        <Button onClick={ onClose }>Zamknij</Button>
                                    </DialogActions>
                                </Grid>
                            </Grid>
                        }
                    </Formik>
                </DialogContent>
            </Dialog>
        </>
    );
}
