import React from 'react';

import { ErrorMessage, Field, getIn } from 'formik';
import { Autocomplete, Checkbox, FormControl, FormHelperText, ListItemText, MenuItem, TextField } from '@mui/material';

const AutocompleteMultipleSearchOnType = ({ options, value, setValue, label, onInputChange, required, loading }) => {
    const randomId = Date.now().toString();

    return (
        <FormControl fullWidth required={ required }>
            <Autocomplete
                id={ randomId }
                loading={ loading }
                disableCloseOnSelect
                multiple
                options={ options }
                filterOptions={ (x) => x }
                value={ value }
                isOptionEqualToValue={ (option, newValue) => option.value === newValue.value }
                onInputChange={ (_, newInputValue) => newInputValue && onInputChange(newInputValue) }
                onChange={ (_, newValue) => setValue(newValue) }
                renderInput={ (params) =>
                    <TextField { ...params }
                               label={ label }
                               inputProps={ {
                                   ...params.inputProps,
                                   required: required && value.length === 0
                               } }
                               required={ required }/>
                }
                renderOption={ (props, option) => (
                    <MenuItem { ...props } key={ option?.key } value={ option }>
                        <Checkbox checked={ value.find(v => v?.value === option?.value) !== undefined }/>
                        <ListItemText primary={ option?.label } secondary={ option?.secondaryLabel } secondaryTypographyProps={ { fontWeight: 'bold', color: '#000000' } }/>
                    </MenuItem>
                ) }
            />
        </FormControl>
    );
};

export const AutocompleteMultipleSearchOnTypeFormik = ({ options, name, label, onInputChange, required, disabled, loading }) => {
    const randomId = Date.now().toString();

    return (
        <Field name={ name }>
            { ({ form: { errors, setFieldValue }, field: { value } }) => (
                <FormControl fullWidth disabled={ disabled } required={ required }>
                    <Autocomplete
                        id={ randomId }
                        loading={ loading }
                        disableCloseOnSelect
                        multiple
                        options={ options }
                        filterOptions={ (x) => x }
                        value={ value }
                        disabled={ disabled }
                        isOptionEqualToValue={ (option, newValue) => option.value === newValue.value }
                        onInputChange={ (_, newInputValue) => newInputValue && onInputChange(newInputValue) }
                        onChange={ (_, newValue) => setFieldValue(name, newValue) }
                        renderInput={ (params) =>
                            <TextField { ...params }
                                       label={ label }
                                       inputProps={ {
                                           ...params.inputProps,
                                           required: required && value.length === 0
                                       } }
                                       error={ !!getIn(errors, name) }
                                       required={ required }/>
                        }
                        renderOption={ (props, option) => (
                            <MenuItem { ...props } key={ option?.key } value={ option }>
                                <Checkbox checked={ value.find(v => v?.value === option?.value) !== undefined }/>
                                <ListItemText primary={ option?.label }/>
                            </MenuItem>
                        ) }
                    />
                    <FormHelperText error>
                        <ErrorMessage name={ name }/>
                    </FormHelperText>
                </FormControl>
            ) }
        </Field>
    );
};

export const AutocompleteMultipleFormik = ({ options, name, label, required, disabled, groupBy }) => {
    const randomId = Date.now().toString();

    return (
        <Field name={ name }>
            { ({ form: { errors, setFieldValue }, field: { value } }) => (
                <FormControl fullWidth disabled={ disabled } required={ required }>
                    <Autocomplete
                        id={ randomId }
                        disableCloseOnSelect
                        multiple
                        options={ options }
                        value={ value }
                        disabled={ disabled }
                        groupBy={ (option) => groupBy && groupBy(option) }
                        isOptionEqualToValue={ (option, newValue) => option.value === newValue.value }
                        onChange={ (_, newValue) => setFieldValue(name, newValue) }
                        renderInput={ (params) =>
                            <TextField { ...params }
                                       label={ label }
                                       inputProps={ {
                                           ...params.inputProps,
                                           required: required && value.length === 0
                                       } }
                                       error={ !!getIn(errors, name) }
                                       required={ required }/>
                        }
                        renderOption={ (props, option) => (
                            <MenuItem { ...props } key={ option?.key } value={ option }>
                                <Checkbox checked={ value.find(v => v?.value === option?.value) !== undefined }/>
                                <ListItemText primary={ option?.label }/>
                            </MenuItem>
                        ) }
                    />
                    <FormHelperText error>
                        <ErrorMessage name={ name }/>
                    </FormHelperText>
                </FormControl>
            ) }
        </Field>
    );
};

export const AutocompleteFormik = ({ options, name, label, required, disabled, onChange }) => {
    const randomId = Date.now().toString();

    return (
        <Field name={ name }>
            { ({ form: { errors, setFieldValue }, field: { value } }) => (
                <FormControl fullWidth disabled={ disabled } required={ required }>
                    <Autocomplete
                        id={ randomId }
                        options={ options }
                        value={ value || null }
                        disabled={ disabled }
                        isOptionEqualToValue={ (option, newValue) => option.value === newValue.value }
                        onChange={ (_, newValue) => {
                            setFieldValue(name, newValue);
                            onChange && onChange(newValue);
                        } }
                        renderInput={ (params) =>
                            <TextField { ...params }
                                       label={ label }
                                       inputProps={ {
                                           ...params.inputProps,
                                           required: required && !value
                                       } }
                                       error={ !!getIn(errors, name) }
                                       required={ required }/>
                        }
                        renderOption={ (props, option) => (
                            <MenuItem { ...props } key={ option?.key } value={ option }>
                                <ListItemText primary={ option?.label }/>
                            </MenuItem>
                        ) }
                    />
                    <FormHelperText error>
                        <ErrorMessage name={ name }/>
                    </FormHelperText>
                </FormControl>
            ) }
        </Field>
    );
};

export default AutocompleteMultipleSearchOnType;
