import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';

import { CssBaseline } from '@mui/material';
import { SnackbarProvider } from 'notistack';
import { ThemeProvider } from '@mui/material/styles';
import createCache from '@emotion/cache';
import { CacheProvider } from '@emotion/react';
import { TssCacheProvider } from 'tss-react';
import 'moment/locale/pl';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers';

import App from './main/js/core/routing';
import { store } from './main/js/core/store';
import theme from './main/js/view/theme';
import './yup_locale';

const muiCache = createCache({
    'key': 'mui',
    'prepend': true
});

const tssCache = createCache({
    'key': 'tss'
});

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
    <Provider store={ store }>
        <ThemeProvider theme={ theme }>
            <CacheProvider value={ muiCache }>
                <TssCacheProvider value={ tssCache }>
                    <CssBaseline/>
                    <SnackbarProvider maxSnack={ 5 }>
                        <LocalizationProvider dateAdapter={ AdapterMoment }>
                            <App/>
                        </LocalizationProvider>
                    </SnackbarProvider>
                </TssCacheProvider>
            </CacheProvider>
        </ThemeProvider>
    </Provider>
);
