import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import moment, { isMoment } from 'moment/moment';
import * as Yup from 'yup';
import _ from 'lodash';
import { Form, Formik } from 'formik';
import { Grid } from '@mui/material';

import useNotification from '../../../../../core/hooks/use_notification';
import { getAllPodsState } from '../../../../../features/pod/get_all_pods';
import { getConstantsState } from '../../../../../features/product/get_constants';
import { getAllProductsState } from '../../../../../features/product/get_all_products';
import { parseDateString } from '../../../../../core/utils/date_utils';
import { ApiRequestStatusEnum } from '../../../../../core/enums/common/api';
import { createPromotionCampaignNotificationData, updatePromotionCampaignNotificationData } from '../../../../../core/utils/notification_utils';
import { GET_ROUTE_PROMOTION_CAMPAIGNS_FORMS, MISC_DATE_ISO_FORMAT, MISC_DATE_ISO_TIME_ONLY_FORMAT } from '../../../../../core/constants';
import TextFieldFormik from '../../../../common/text_field';
import RadioGroupFormik from '../../../../common/radio_group';
import { DateTimePickerFormik } from '../../../../common/date_picker';
import { yesNoOptions } from '../../../../common/form_utils';
import { AutocompleteMultipleFormik } from '../../../../common/autocomplete';
import { SelectFieldFormik, SelectFieldMultipleFormik } from '../../../../common/select_field';
import { SubmitButton } from '../../../../common/button';
import { getPromotionCampaignState } from '../../../../../features/price/get_promotion_campaign';
import TimePickerFormik from '../../../../common/time_picker';
import { getDayOfWeekOptions } from '../../../../common/utils/day_of_week_utils';
import { getPromotionCampaignTypeOptions } from '../../../../common/utils/promotion_campaign_utils';
import { PromotionCampaignTypeEnum } from '../../../../../core/enums/price/promotion_campaign';
import { getAllFoodPartnersState } from '../../../../../features/food_partner/get_all_food_partners';
import { createPromotionCampaignAction, createPromotionCampaignState } from '../../../../../features/price/create_promotion_campaign';
import { FormRouteEnum } from '../../../../../core/enums/common/route';
import { updatePromotionCampaignAction, updatePromotionCampaignState } from '../../../../../features/price/update_promotion_campaign';
import { updatePromotionCampaignNotificationAction } from '../../../../../features/price/update_promotion_campaign_notification';
import { updatePromotionCampaignAdvertisementAction } from '../../../../../features/price/update_promotion_campaign_advertisement';
import { getAdvertisementFileAsRaw } from '../../../../../features/price';

const PromotionCampaignForm = ({ createNewForm, promotionCampaignId, refreshData,
                                   duplicateMode, promotionCampaignAdvertisementToDuplicate, promotionCampaignNotificationToDuplicate, disableForm }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { showNotification } = useNotification();
    const location = useLocation();

    const getPromotionCampaign = useSelector(getPromotionCampaignState);
    const getAllPods = useSelector(getAllPodsState);
    const getConstants = useSelector(getConstantsState);
    const getAllProducts = useSelector(getAllProductsState);
    const getAllFoodPartners = useSelector(getAllFoodPartnersState);
    const createPromotionCampaign = useSelector(createPromotionCampaignState);
    const updatePromotionCampaign = useSelector(updatePromotionCampaignState);

    const mapProductOptions = useCallback((data) => {
        return data?.map(product => ({
            key: product?.id + Math.random(),
            value: product?.id,
            label: product?.name
        }));
    }, []);

    const initialValues = {
        workingName: getPromotionCampaign?.data?.workingName || '',
        discount: getPromotionCampaign?.data?.discount || '',
        repeatable: getPromotionCampaign?.data?.repeatable || false,
        timeFrom: getPromotionCampaign?.data?.timeFrom ? moment(getPromotionCampaign?.data?.timeFrom) : '',
        timeTo: getPromotionCampaign?.data?.timeTo ? moment(getPromotionCampaign?.data?.timeTo) : '',
        repeatableDay: getPromotionCampaign?.data?.repeatableDay || '',
        repeatableDayTimeFrom: getPromotionCampaign?.data?.repeatableDayTimeFrom
            ? moment(getPromotionCampaign?.data?.repeatableDayTimeFrom, MISC_DATE_ISO_TIME_ONLY_FORMAT)
            : '',
        repeatableDayTimeTo: getPromotionCampaign?.data?.repeatableDayTimeTo
            ? moment(getPromotionCampaign?.data?.repeatableDayTimeTo, MISC_DATE_ISO_TIME_ONLY_FORMAT)
            : '',
        allPods: getPromotionCampaign?.data?.allPods || false,
        podIds: getPromotionCampaign?.data?.podIds || [],
        type: getPromotionCampaign?.data?.type || '',
        allProducts: getPromotionCampaign?.data?.allProducts || false,
        products: mapProductOptions(getAllProducts?.data?.filter(product => getPromotionCampaign?.data?.productIds?.includes(product?.id))) || [],
        constantValueIds: getPromotionCampaign?.data?.constantValueIds || [],
        foodPartnerIds: getPromotionCampaign?.data?.foodPartnerIds || [],
        activateExpirationPromotion: getPromotionCampaign?.data?.activateExpirationPromotion || false
    };

    const schema = Yup.object().shape({
        workingName: Yup.string().required(),
        discount: Yup.number()
            .required()
            .min(0.01)
            .max(100.00),
        repeatable: Yup.bool().required(),
        timeFrom: Yup.date().when('repeatable', {
            is: false,
            then: Yup.date()
                .transform(parseDateString)
                .nullable()
                .required('Data od wymagana'),
            otherwise: Yup.date()
                .transform(parseDateString)
                .nullable()
                .notRequired()
        }),
        timeTo: Yup.date().when('repeatable', {
            is: false,
            then: Yup.date()
                .transform(parseDateString)
                .nullable()
                .required('Data do wymagana'),
            otherwise: Yup.date()
                .transform(parseDateString)
                .nullable()
                .notRequired()
        }),
        repeatableDay: Yup.string().when('repeatable', {
            is: true,
            then: Yup.string().required(),
            otherwise: Yup.string().notRequired()
        }),
        repeatableDayTimeFrom: Yup.date().when('repeatable', {
            is: true,
            then: Yup.date()
                .transform(parseDateString)
                .nullable()
                .required('Czas od wymagany'),
            otherwise: Yup.date()
                .transform(parseDateString)
                .nullable()
                .notRequired()
        }),
        repeatableDayTimeTo: Yup.date().when('repeatable', {
            is: true,
            then: Yup.date()
                .transform(parseDateString)
                .nullable()
                .required('Czas do wymagany'),
            otherwise: Yup.date()
                .transform(parseDateString)
                .nullable()
                .notRequired()
        }),
        allPods: Yup.bool().required(),
        podIds: Yup.array().when('allPods', {
            is: false,
            then: Yup.array().min(1),
            otherwise: Yup.array().notRequired()
        }),
        type: Yup.string().required(),
        allProducts: Yup.bool().when('type', {
            is: (type) => PromotionCampaignTypeEnum.PRODUCTS.value === type,
            then: Yup.bool().required(),
            otherwise: Yup.bool().notRequired()
        }),
        products: Yup.array().when('allProducts', {
            is: () => false,
            then: Yup.array().min(1),
            otherwise: Yup.array().notRequired()
        }),
        constantValueIds: Yup.array().when('type', {
            is: (type) => [PromotionCampaignTypeEnum.FOOD_TYPE.value, PromotionCampaignTypeEnum.ATTRIBUTE.value].includes(type),
            then: Yup.array().min(1),
            otherwise: Yup.array().notRequired()
        }),
        foodPartnerIds: Yup.array().when('type', {
            is: (type) => PromotionCampaignTypeEnum.FOOD_PARTNER.value === type,
            then: Yup.array().min(1),
            otherwise: Yup.array().notRequired()
        }),
        activateExpirationPromotion: Yup.bool().required()
    });

    const onPromotionCampaignTypeChange = (type, formik) => {
        formik.setFieldValue('allProducts', false);
        formik.setFieldValue('products', []);
        formik.setFieldValue('constantValueIds', []);
        formik.setFieldValue('foodPartnerIds', []);
    };

    const onSubmit = (values) => {
        if (disableForm) return;

        const productIds = _.pick(values, ['products'])?.products?.map(product => product?.value) || [];
        const timeFrom = isMoment(_.pick(values, ['timeFrom'])?.timeFrom) ? _.pick(values, ['timeFrom'])?.timeFrom?.format(MISC_DATE_ISO_FORMAT) : '';
        const timeTo = isMoment(_.pick(values, ['timeTo'])?.timeTo) ? _.pick(values, ['timeTo']).timeTo.format(MISC_DATE_ISO_FORMAT) : '';
        const repeatableDay = !!_.pick(values, ['repeatableDay'])?.repeatableDay ? _.pick(values, ['repeatableDay'])?.repeatableDay : null;
        const repeatableDayTimeFrom = isMoment(_.pick(values, ['repeatableDayTimeFrom'])?.repeatableDayTimeFrom) ? _.pick(values, ['repeatableDayTimeFrom'])?.repeatableDayTimeFrom?.format(MISC_DATE_ISO_TIME_ONLY_FORMAT) : '';
        const repeatableDayTimeTo = isMoment(_.pick(values, ['repeatableDayTimeTo'])?.repeatableDayTimeTo) ? _.pick(values, ['repeatableDayTimeTo'])?.repeatableDayTimeTo?.format(MISC_DATE_ISO_TIME_ONLY_FORMAT) : '';

        const form = {
            ..._.omit(values, ['products', 'timeFrom', 'timeTo', 'repeatableDay', 'repeatableDayTimeFrom', 'repeatableDayTimeTo']),
            productIds,
            timeFrom,
            timeTo,
            repeatableDay,
            repeatableDayTimeFrom,
            repeatableDayTimeTo
        };

        if (createNewForm) {
            dispatch(createPromotionCampaignAction(form))
                .then(response => {
                    showNotification(createPromotionCampaignNotificationData(response));
                    if (duplicateMode) {
                        if (promotionCampaignNotificationToDuplicate && promotionCampaignNotificationToDuplicate.hasNotification) {
                            const notificationTime = !values.repeatable ? promotionCampaignNotificationToDuplicate.notificationTime ? promotionCampaignNotificationToDuplicate.notificationTime : _.pick(values, ['timeFrom'])?.timeFrom?.format(MISC_DATE_ISO_FORMAT) : null;
                            const repeatableNotificationTime = values.repeatable ? promotionCampaignNotificationToDuplicate.repeatableNotificationTime ? promotionCampaignNotificationToDuplicate.repeatableNotificationTime : _.pick(values, ['repeatableDayTimeFrom'])?.repeatableDayTimeFrom?.format(MISC_DATE_ISO_TIME_ONLY_FORMAT) : null;

                            const form = {
                                ..._.omit(promotionCampaignNotificationToDuplicate, 'hasNotification', 'notificationTime', 'repeatableNotificationTime'),
                                notificationTime,
                                repeatableNotificationTime
                            }
                            dispatch(updatePromotionCampaignNotificationAction({ form, promotionCampaignId: response?.payload }));
                        }
                        if (promotionCampaignAdvertisementToDuplicate && promotionCampaignAdvertisementToDuplicate.hasAdvertisement) {
                            getAdvertisementFileAsRaw(promotionCampaignAdvertisementToDuplicate.fileUrl).then(file => {
                                dispatch(updatePromotionCampaignAdvertisementAction({ picture: file, promotionCampaignId: response?.payload }));
                            });
                        }
                    }
                    response?.meta?.requestStatus === ApiRequestStatusEnum.FULFILLED && navigate(GET_ROUTE_PROMOTION_CAMPAIGNS_FORMS(response?.payload), { state: { action: FormRouteEnum.EDIT, queryParams: location?.state?.queryParams, tab: location?.state?.tab } });
                });
        } else {
            dispatch(updatePromotionCampaignAction({ form, promotionCampaignId }))
                .then(response => {
                    showNotification(updatePromotionCampaignNotificationData(response));
                    response?.meta?.requestStatus === ApiRequestStatusEnum.FULFILLED && refreshData();
                });
        }
    };

    return (
        <Formik
            initialValues={ initialValues }
            validationSchema={ schema }
            validateOnChange={ false }
            validateOnBlur={ false }
            onSubmit={ onSubmit }>
            { (formik) =>
                <Grid component={ Form } container spacing={ 2 }>
                    <Grid container item md={ 12 } lg={ 6 } gap={ 2 } direction="column">
                        <Grid container item>
                            <TextFieldFormik name="workingName" label="Nazwa robocza" required disabled={ disableForm }/>
                        </Grid>
                        <Grid container item>
                            <Grid item xs={ 6 }>
                                <TextFieldFormik name="discount" label="Obniżka" type="number" required disabled={ disableForm } inputProps={ { min: '0.01', max: '100.00', step: '0.01' } }/>
                            </Grid>
                        </Grid>
                        <Grid container item>
                            <RadioGroupFormik
                                name="repeatable"
                                label="Powtarzalna"
                                options={ yesNoOptions }
                                disabled={ disableForm }
                                onChange={ (value) => {
                                    if (value) {
                                        formik.setFieldValue('timeFrom', '');
                                        formik.setFieldValue('timeTo', '');
                                    } else {
                                        formik.setFieldValue('repeatableDay', '');
                                        formik.setFieldValue('repeatableDayTimeFrom', '');
                                        formik.setFieldValue('repeatableDayTimeTo', '');
                                    }
                                } }
                            />
                        </Grid>
                        {
                            formik.values.repeatable &&
                            <>
                                <Grid container item>
                                    <Grid item xs={ 6 }>
                                        <SelectFieldFormik
                                            name="repeatableDay"
                                            label="Dzień promocji"
                                            options={ getDayOfWeekOptions }
                                            required
                                            disabled={ disableForm }
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container item>
                                    <Grid container gap={ 2 }>
                                        <Grid item xs={ 6 }>
                                            <TimePickerFormik
                                                name="repeatableDayTimeFrom"
                                                required
                                                label="Godzina od"
                                                InputLabelProps={ { shrink: true } }
                                                views={ ['hours', 'minutes'] }
                                                disabled={ disableForm }/>
                                        </Grid>
                                        <Grid item xs={ 6 }>
                                            <TimePickerFormik
                                                name="repeatableDayTimeTo"
                                                required
                                                label="Godzina do"
                                                InputLabelProps={ { shrink: true } }
                                                views={ ['hours', 'minutes'] }
                                                disabled={ disableForm }/>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </>
                        }
                        {
                            !formik.values.repeatable &&
                            <>
                                <Grid container item>
                                    <Grid item xs={ 6 }>
                                        <DateTimePickerFormik
                                            name="timeFrom"
                                            label="Data od"
                                            required
                                            disabled={ disableForm }
                                            InputLabelProps={ { shrink: true } }
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container item>
                                    <Grid item xs={ 6 }>
                                        <DateTimePickerFormik
                                            name="timeTo"
                                            label="Data do"
                                            required
                                            disabled={ disableForm }
                                            InputLabelProps={ { shrink: true } }
                                        />
                                    </Grid>
                                </Grid>
                            </>
                        }
                    </Grid>
                    <Grid container item lg={ 6 } gap={ 2 } direction="column">
                        <Grid container item>
                            <RadioGroupFormik
                                name="allPods"
                                label="Wszystkie pody"
                                options={ yesNoOptions }
                                disabled={ disableForm }
                                onChange={ (value) => value === true && formik.setFieldValue('podIds', []) }
                            />
                        </Grid>
                        {
                            !formik.values.allPods &&
                            <Grid container item>
                                <SelectFieldMultipleFormik
                                    name="podIds"
                                    label="Wybierz pody - wiele"
                                    options={ getAllPods?.data?.map(pod => ({ value: pod?.id, label: pod?.indoorPartner?.name + ' (' + pod?.description + ') - ' + (pod?.device?.serviceId || 'brak urządzenia') })) }
                                    disabled={ disableForm }
                                    required
                                />
                            </Grid>
                        }
                        <Grid container item>
                            <Grid item xs={ 6 }>
                                <SelectFieldFormik
                                    name="type"
                                    label="Typ promocji"
                                    options={ getPromotionCampaignTypeOptions }
                                    required
                                    disabled={ disableForm }
                                    onChange={ (value) => onPromotionCampaignTypeChange(value, formik) }
                                />
                            </Grid>
                        </Grid>
                        {
                            PromotionCampaignTypeEnum.PRODUCTS.value === formik.values.type &&
                            <Grid container item>
                                <RadioGroupFormik
                                    name="allProducts"
                                    label="Wszystkie produkty"
                                    options={ yesNoOptions }
                                    disabled={ disableForm }
                                    onChange={ () => formik.setFieldValue('products', []) }
                                />
                            </Grid>
                        }
                        {
                            PromotionCampaignTypeEnum.PRODUCTS.value === formik.values.type && !formik.values.allProducts &&
                            <Grid container item>
                                <AutocompleteMultipleFormik
                                    name="products"
                                    label="Wybierz produkty - wiele"
                                    options={ mapProductOptions(getAllProducts?.data) }
                                    disabled={ disableForm }
                                    required
                                />
                            </Grid>
                        }
                        {
                            [PromotionCampaignTypeEnum.FOOD_TYPE.value, PromotionCampaignTypeEnum.ATTRIBUTE.value].includes(formik.values.type) &&
                            <Grid container item>
                                <SelectFieldMultipleFormik
                                    name="constantValueIds"
                                    label="Zmienne - wiele"
                                    options={ getConstants?.data?.productConstants?.[formik.values.type]?.map(productConstant => ({ value: productConstant?.id, label: productConstant?.body })) }
                                    required
                                    disabled={ disableForm }
                                />
                            </Grid>
                        }
                        {
                            PromotionCampaignTypeEnum.FOOD_PARTNER.value === formik.values.type &&
                            <Grid container item>
                                <SelectFieldMultipleFormik
                                    name="foodPartnerIds"
                                    label="Dostawcy"
                                    options={ getAllFoodPartners?.data?.map(getFoodPartner => ({ value: getFoodPartner?.id, label: getFoodPartner?.name })) }
                                    required
                                    disabled={ disableForm }
                                />
                            </Grid>
                        }
                        <Grid container item>
                            <RadioGroupFormik
                                name="activateExpirationPromotion"
                                label="Aktywować promocję na krótką datę przydatności"
                                options={ yesNoOptions }
                                disabled={ disableForm }
                            />
                        </Grid>
                    </Grid>
                    {
                        !disableForm &&
                        <Grid container item mt={ 2 } justifyContent="flex-end">
                            <SubmitButton isLoading={ createNewForm ? createPromotionCampaign?.loading : updatePromotionCampaign?.loading }>Zapisz</SubmitButton>
                        </Grid>
                    }
                </Grid>
            }
        </Formik>
    );
};

export default PromotionCampaignForm;
