import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import * as Yup from 'yup';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import { Form, Formik } from 'formik';
import { Grid, IconButton } from '@mui/material';
import DialogActions from '@mui/material/DialogActions';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

import useNotification from '../../../../core/hooks/use_notification';
import { Button, SubmitButton } from '../../../common/button';
import { updateEmployeeAction, updateEmployeeState } from '../../../../features/benefit/update_employee';
import { SelectFieldFormik } from '../../../common/select_field';
import { getAllEmployersState } from '../../../../features/benefit/get_all_employers';
import { updateEmployeeNotificationData } from '../../../../core/utils/notification_utils';
import { ApiRequestStatusEnum } from '../../../../core/enums/common/api';

const ConfirmEmployeeDialog = ({ employeeId, refreshList }) => {
    const { showNotification } = useNotification();
    const dispatch = useDispatch();

    const updateEmployee = useSelector(updateEmployeeState);
    const getAllEmployers = useSelector(getAllEmployersState);

    const [open, setOpen] = useState(false);

    const initialValues = {
        employerId: ''
    };

    const schema = Yup.object().shape({
        employerId: Yup.number().required()
    });

    const onClose = () => setOpen(false);

    const onSubmit = (values) => {
        dispatch(updateEmployeeAction({ id: employeeId, form: { command: 'CONFIRM', employerId: values?.employerId } }))
            .then(response => {
                showNotification(updateEmployeeNotificationData(response));
                if (response?.meta?.requestStatus === ApiRequestStatusEnum.FULFILLED) {
                    refreshList();
                    onClose();
                }
            });
    };

    return (
        <>
            <IconButton title="Zatwierdź" onClick={ () => setOpen(true) }>
                <CheckCircleIcon/>
            </IconButton>
            <Dialog open={ open } onClose={ onClose } fullWidth maxWidth={ 'xs' }>
                <DialogTitle>Zatwierdź pracownika</DialogTitle>
                <DialogContent sx={ { paddingTop: '6px !important' } }>
                    <Formik
                        initialValues={ initialValues }
                        validationSchema={ schema }
                        validateOnChange={ false }
                        validateOnBlur={ false }
                        onSubmit={ onSubmit }>
                        { () =>
                            <Grid component={ Form } container rowGap={ 4 }>
                                <Grid container item>
                                    <SelectFieldFormik
                                        name="employerId"
                                        label="Pracodawca"
                                        options={ getAllEmployers?.data?.map(employer => ({ value: employer?.id, label: employer?.name })) }
                                        required
                                    />
                                </Grid>
                                <Grid container item mt={ 2 } justifyContent="flex-end">
                                    <DialogActions sx={ { gap: 2 } }>
                                        <SubmitButton isLoading={ updateEmployee?.loading }>Zapisz</SubmitButton>
                                        <Button onClick={ onClose }>Zamknij</Button>
                                    </DialogActions>
                                </Grid>
                            </Grid>
                        }
                    </Formik>
                </DialogContent>
            </Dialog>
        </>
    );
};

export default ConfirmEmployeeDialog;
