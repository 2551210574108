import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { tabsClasses } from '@mui/material/Tabs';
import { Box, Grid, LinearProgress, Tab, Tabs } from '@mui/material';

import useNotification from '../../../../core/hooks/use_notification';
import { getLanguagesState } from '../../../../features/product/get_languages';
import { getAutomaticCouponSettingNotificationData, getAutomaticCouponSettingTranslationNotificationData, getPodsNotificationData } from '../../../../core/utils/notification_utils';
import { getAutomaticCouponSettingAction, getAutomaticCouponSettingState, invalidateAutomaticCouponSetting } from '../../../../features/coupon/get_automatic_coupon_setting';
import { Button } from '../../../common/button';
import { ROUTE_COUPONS } from '../../../../core/constants';
import AutomaticCouponSettingForm from './forms/automatic_coupon_setting_form';
import AutomaticCouponSettingTranslationForm from './forms/automatic_coupon_setting_translation_form';
import { getAllPodsAction, getAllPodsState } from '../../../../features/pod/get_all_pods';
import { getAutomaticCouponSettingTranslationAction, getAutomaticCouponSettingTranslationState } from '../../../../features/coupon/get_automatic_coupon_setting_translation';

const AutomaticCouponSettingFormsPage = () => {
    const { showErrorMessage } = useNotification();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { automaticCouponType } = useParams();

    const getAutomaticCouponSetting = useSelector(getAutomaticCouponSettingState);
    const getAutomaticCouponSettingTranslation = useSelector(getAutomaticCouponSettingTranslationState);
    const getLanguages = useSelector(getLanguagesState);
    const getAllPods = useSelector(getAllPodsState);

    const [languageCode, setLanguageCode] = useState('PL');
    const [tab, setTab] = useState(0);

    useEffect(() => {
        dispatch(getAutomaticCouponSettingAction(automaticCouponType))
            .then(response => showErrorMessage(getAutomaticCouponSettingNotificationData(response)));
        dispatch(getAllPodsAction())
            .then(response => showErrorMessage(getPodsNotificationData(response)));

        return () => {
            dispatch(invalidateAutomaticCouponSetting());
        };
    }, [dispatch, automaticCouponType, showErrorMessage]);

    useEffect(() => {
        dispatch(getAutomaticCouponSettingTranslationAction({ automaticCouponType, languageCode }))
            .then(response => showErrorMessage(getAutomaticCouponSettingTranslationNotificationData(response)));
    }, [dispatch, automaticCouponType, languageCode, showErrorMessage]);

    const renderView = () => {
        if (getAutomaticCouponSetting?.loading || getAllPods?.loading || getAutomaticCouponSettingTranslation?.loading) {
            return (
                <Box mt={ 6 }>
                    <LinearProgress/>
                </Box>
            );
        }

        if (getAutomaticCouponSetting?.data && getAllPods?.data) {
            if (tab === 0) {
                return (
                    <AutomaticCouponSettingForm
                        automaticCouponType={ automaticCouponType }/>
                );
            } else if (tab !== 0 && getAutomaticCouponSettingTranslation?.data) {
                return (
                    <AutomaticCouponSettingTranslationForm
                        automaticCouponType={ automaticCouponType }
                        languageCode={ languageCode }/>
                );
            }
        }

        return <></>;
    };

    return (
        <Grid mb={ 6 }>
            <Grid container mb={ 4 } justifyContent="space-between" rowGap={ 2 }>
                <Grid container item>
                    <Button onClick={ () => navigate(ROUTE_COUPONS) }>
                        Wróć
                    </Button>
                </Grid>
                <Grid container item>
                    <Tabs
                        value={ tab }
                        onChange={ (_, value) => {
                            setTab(value);
                            setLanguageCode(getLanguages?.data[value - 1]?.languageCode || 'PL');
                        } }
                        variant="scrollable"
                        scrollButtons
                        sx={ {
                            [`& .${ tabsClasses.scrollButtons }`]: {
                                '&.Mui-disabled': { opacity: 0.3 },
                            },
                        } }
                    >
                        <Tab label="Informacje ogólne" sx={ { justifyContent: 'flexEnd' } }/>
                        {
                            getLanguages?.data?.map(language => {
                                return <Tab key={ language?.languageCode } label={ language?.languageName } disabled={ !getAutomaticCouponSetting?.data?.discount }/>;
                            })
                        }
                    </Tabs>
                </Grid>
            </Grid>
            {
                renderView()
            }
        </Grid>
    );
};

export default AutomaticCouponSettingFormsPage;
