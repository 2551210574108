import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Grid } from '@mui/material';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';

import { getProductAction, getProductState } from '../../../../../features/product/get_product';
import useNotification from '../../../../../core/hooks/use_notification';
import TextFieldFormik from '../../../../common/text_field';
import { SubmitButton } from '../../../../common/button';
import { getProductNotificationData, updateProductDetailsNotificationData } from '../../../../../core/utils/notification_utils';
import { updateProductDetailsAction, updateProductDetailsState } from '../../../../../features/product/update_product_details';

const ProductDetailsForm = ({ languageCode }) => {
    const dispatch = useDispatch();
    const { showNotification, showErrorMessage } = useNotification();

    const getProduct = useSelector(getProductState);
    const updateProductDetails = useSelector(updateProductDetailsState);

    const initialValues = {
        name: getProduct?.data?.name || '',
        description: getProduct?.data?.description || '',
        productComposition: getProduct?.data?.productComposition || '',
        preparation: getProduct?.data?.preparation || ''
    };

    const schema = Yup.object().shape({
        name: Yup.string().required(),
        productComposition: Yup.string().required(),
    });

    const onSubmit = (values) => {
        const form = { ...values, languageCode };
        const id = getProduct?.data?.id;
        dispatch(updateProductDetailsAction({ form, id }))
            .then(response => showNotification(updateProductDetailsNotificationData(response)))
            .then(() => {
                dispatch(getProductAction({ id, languageCode }))
                    .then(response => showErrorMessage(getProductNotificationData(response)));
            });
    };

    return (
        <Formik
            initialValues={ initialValues }
            validationSchema={ schema }
            validateOnChange={ false }
            validateOnBlur={ false }
            onSubmit={ onSubmit }>
            <Grid component={ Form } container rowGap={ 2 }>
                <Grid container item>
                    <TextFieldFormik name="name" label="Nazwa" required/>
                </Grid>
                <Grid container item>
                    <TextFieldFormik name="description" label="Opis" multiline rows={ 2 }/>
                </Grid>
                <Grid container item>
                    <TextFieldFormik name="productComposition" label="Składniki" multiline rows={ 3 } required/>
                </Grid>
                <Grid container item>
                    <TextFieldFormik name="preparation" label="Sposób przygotowania" multiline rows={ 3 }/>
                </Grid>
                <Grid container item mt={ 2 } justifyContent="flex-end">
                    <SubmitButton isLoading={ updateProductDetails?.loading }>Zapisz</SubmitButton>
                </Grid>
            </Grid>
        </Formik>
    );
};

export default ProductDetailsForm;
