import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Box, Grid, LinearProgress, Typography } from '@mui/material';
import deepmerge from 'deepmerge';

import { getDefaultPodsPaginationQueryValues } from '../../../core/utils/api_utils';
import { getPodsNotificationData } from '../../../core/utils/notification_utils';
import { getPodsAction, getPodsState } from '../../../features/pod/get_pods';
import useNotification from '../../../core/hooks/use_notification';
import PaginationControls from '../../common/pagination_controls';
import PodsList from '../common/pods_list';
import { GET_ROUTE_POD_ADVERTISEMENTS } from '../../../core/constants';
import AdvertisementsFormDialog from './advertisement_forms/advertisements_form_dialog';
import { getAllPodsAction, getAllPodsState } from '../../../features/pod/get_all_pods';

const initialQueryParams = {
    ...getDefaultPodsPaginationQueryValues
};

const AdvertisementsPage = () => {
    const dispatch = useDispatch();
    const [queryParams, setQueryParams] = useState(initialQueryParams);
    const { showErrorMessage } = useNotification();

    const getPods = useSelector(getPodsState);
    const getAllPods = useSelector(getAllPodsState);

    useEffect(() => {
        dispatch(getPodsAction(queryParams))
            .then(response => showErrorMessage(getPodsNotificationData(response)));
        dispatch(getAllPodsAction())
            .then(response => showErrorMessage(getPodsNotificationData(response)));
    }, [dispatch, queryParams, showErrorMessage]);

    return (
        <Grid mb={ 6 }>
            <Box mb={ 4 }>
                <Typography variant="h1">Wybierz poda</Typography>
            </Box>
            {
                (getPods?.loading || getAllPods?.loading) &&
                <LinearProgress/>
            }
            {
                (getPods?.data?.content && getAllPods?.data) &&
                <Box>
                    <Box mb={ 4 }>
                        <AdvertisementsFormDialog/>
                    </Box>
                    <PodsList
                        route={ GET_ROUTE_POD_ADVERTISEMENTS }/>
                    <Box mt={ 2 }>
                        <PaginationControls
                            currentPage={ getPods?.data?.pageable?.pageNumber }
                            totalPages={ getPods?.data?.totalPages }
                            setPage={ page => setQueryParams(prevState => deepmerge(prevState, { page })) }
                        />
                    </Box>
                </Box>
            }
        </Grid>
    );
};

export default AdvertisementsPage;
