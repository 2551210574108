export const DEFAULT_STATE = {
    loading: false,
    data: null,
    error: null
};

export const STATE__API__STARTED = {
    loading: true,
    data: null,
    error: null
};

export const STATE__API__SUCCESS = (data) => {
    return {
        loading: false,
        data,
        error: null
    };
};

export const STATE__API__FAIL = (error) => {
    return {
        loading: false,
        data: null,
        error
    };
};