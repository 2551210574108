import { combineReducers } from '@reduxjs/toolkit';

import getFoodPartners from './get_food_partners';
import getAllFoodPartners from './get_all_food_partners';
import getFoodPartner from './get_food_partner';
import getFoodPartnersForReservation from './get_food_partners_for_reservation';
import createFoodPartner from './create_food_partner';
import updateFoodPartner from './update_food_partner';
import deleteFoodPartner from './delete_food_partner';
import getContactPersons from './get_contact_persons';
import getContactPerson from './get_contact_person';
import createContactPerson from './create_contact_person';
import updateContactPerson from './update_contact_person';
import deleteContactPerson from './delete_contact_person';
import getDocuments from './get_documents';
import createDocument from './create_document';
import deleteDocument from './delete_document';


export default combineReducers({
    getFoodPartners, getAllFoodPartners, getFoodPartner, getFoodPartnersForReservation, createFoodPartner,
    updateFoodPartner, deleteFoodPartner, getContactPersons, getContactPerson, createContactPerson, updateContactPerson, deleteContactPerson,
    getDocuments, createDocument, deleteDocument
});
