import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import * as Yup from 'yup';
import { ErrorMessage, FieldArray, Form, Formik } from 'formik';
import DialogActions from '@mui/material/DialogActions';
import { FormHelperText, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from '@mui/material';
import { isNumber } from 'lodash';

import { getAllPodsState } from '../../../../../../features/pod/get_all_pods';
import TextFieldFormik from '../../../../../common/text_field';
import { Button, SubmitButton } from '../../../../../common/button';
import { createProductAvailabilityAction, createProductAvailabilityState } from '../../../../../../features/reservation/create_product_availability';
import useNotification from '../../../../../../core/hooks/use_notification';
import { createProductAvailabilityNotificationData } from '../../../../../../core/utils/notification_utils';
import { ApiRequestStatusEnum } from '../../../../../../core/enums/common/api';

const ProductAvailabilityPodsForm = ({ data, setView, refreshCalendar, onClose }) => {
    const dispatch = useDispatch();
    const { showNotification } = useNotification();

    const getAllPods = useSelector(getAllPodsState);
    const createProductAvailability = useSelector(createProductAvailabilityState);

    const initialValues = {
        pods: getAllPods?.data?.map(pod => (
            {
                id: pod?.id,
                quantityLimit: ''
            }
        ))
    };

    const schema = Yup.object().shape({
        pods: Yup.array().of(
            Yup.object().shape(
                {
                    id: Yup.string().required(),
                    quantityLimit: Yup.number().notRequired()
                }
            )
        )
    });

    const onChangeQuantity = (quantity, formik) => {
        const pods = formik?.values?.pods?.map(item => ({ ...item, quantityLimit: parseFloat(quantity) || quantity }));
        formik.setFieldValue('pods', pods);
    };

    const onSubmit = (values, { setErrors }) => {
        const errors = validate(values);

        if (errors.pods) {
            setErrors(errors);

            return;
        }

        const podsQuantityLimits = values?.pods
            ?.filter((podQuantityLimit) => !!podQuantityLimit.quantityLimit)
            ?.reduce((accumulator, current) => {
                accumulator[current.id] = current.quantityLimit;
                return accumulator;
            }, {});

        const formToSend = {
            ...data?.form,
            podsQuantityLimits
        };

        dispatch(createProductAvailabilityAction(formToSend))
            .then(response => {
                showNotification(createProductAvailabilityNotificationData(response));
                if (response?.meta?.requestStatus === ApiRequestStatusEnum.FULFILLED) {
                    onClose();
                    refreshCalendar && refreshCalendar();
                }
            });
    };

    const validate = (values) => {
        const errors = {
            pods: ''
        };

        const podsQuantity = values?.pods
            ?.map(pod => pod?.quantityLimit)
            ?.reduce((accumulator, current) => {
                return isNumber(current)
                    ? accumulator + current
                    : accumulator;
            }, 0);
        const generalQuantityLimit = data?.form?.generalQuantityLimit;


        if (podsQuantity > generalQuantityLimit) {
            errors.pods = `Przydzielono podom ${ podsQuantity } szt., gdy globalny limit wynosi: ${ generalQuantityLimit } szt.`;
        }

        return errors;
    };

    return (
        <Formik
            initialValues={ initialValues }
            validationSchema={ schema }
            validateOnChange={ false }
            validateOnBlur={ false }
            onSubmit={ onSubmit }>
            { (formik) =>
                <Grid component={ Form } container item rowGap={ 2 } alignContent="space-between">
                    <Grid container item>
                        <TextField
                            name="quantity"
                            label="Ilość"
                            type="number"
                            onChange={ event => onChangeQuantity(event.target.value, formik) }
                        />
                    </Grid>
                    <Grid container item>
                        <TableContainer>
                            <Table stickyHeader>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Pod</TableCell>
                                        <TableCell align="center">Limit rezerwacji</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <FieldArray name="pods">
                                        { () => (
                                            getAllPods?.data?.map((pod, index) => (
                                                <TableRow
                                                    key={ pod?.id }
                                                    hover
                                                >
                                                    <TableCell>
                                                        {
                                                            pod?.indoorPartner?.name + ' (' + pod?.description + ') - ' + (pod?.device?.serviceId || 'brak urządzenia')
                                                        }
                                                    </TableCell>
                                                    <TableCell>
                                                        <TextFieldFormik
                                                            name={ `pods.${ index }.quantityLimit` }
                                                            type="number"
                                                            inputProps={ { min: '1', step: '1' } }
                                                        />
                                                    </TableCell>
                                                </TableRow>
                                            ))
                                        ) }
                                    </FieldArray>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                    <Grid container item mt={ 2 } justifyContent="flex-end">
                        <Grid item>
                            <Grid container item>
                                <FormHelperText error>
                                    <ErrorMessage name="pods"/>
                                </FormHelperText>
                            </Grid>
                            <Grid item>
                                <DialogActions sx={ { gap: 2 } }>
                                    <Button onClick={ () => setView(0) }>Wróć</Button>
                                    <SubmitButton isLoading={ createProductAvailability?.loading }>Zapisz </SubmitButton>
                                </DialogActions>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            }
        </Formik>
    );
};

export default ProductAvailabilityPodsForm;
