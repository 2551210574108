import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { DEFAULT_STATE, STATE__API__FAIL, STATE__API__STARTED, STATE__API__SUCCESS } from '../../core/utils/store_utils';
import { invalidateToken } from '../authorization/get_token';
import { webClientFileDownload } from '../../core/web_client';
import { exportFile, extractErrorData, extractResponseData } from '../../core/utils/api_utils';
import { WAREHOUSE_DOCUMENTS_PATH } from '../../core/constants';

export const generateWarehouseDocumentLabelsAction = createAsyncThunk(
    'inventory/generateWarehouseDocumentLabelsAction',
    async (data, { fulfillWithValue, rejectWithValue }) => {
        try {
            const response = await webClientFileDownload.post(`${ WAREHOUSE_DOCUMENTS_PATH }/${ data.id }/labels`);
            const fileName = response.headers['content-disposition']?.split('filename=')[1]?.replaceAll("\"", "");
            exportFile(extractResponseData(response), fileName ? fileName : 'naklejki.zip');
            return fulfillWithValue();
        } catch (error) {
            return rejectWithValue(extractErrorData(error));
        }
    }
);

const generateWarehouseDocumentLabelsSlice = createSlice({
    name: 'generateWarehouseDocumentLabelsSlice',
    initialState: DEFAULT_STATE,
    reducers: {},
    extraReducers: builder => {
        builder.addCase(generateWarehouseDocumentLabelsAction.pending, () => STATE__API__STARTED);
        builder.addCase(generateWarehouseDocumentLabelsAction.fulfilled, (_, { payload }) => STATE__API__SUCCESS(payload));
        builder.addCase(generateWarehouseDocumentLabelsAction.rejected, (_, { payload }) => STATE__API__FAIL(payload));
        builder.addCase(invalidateToken, () => DEFAULT_STATE);
    }
});

export const generateWarehouseDocumentLabelsState = (state) => state.inventory.generateWarehouseDocumentLabels;
export default generateWarehouseDocumentLabelsSlice.reducer;
