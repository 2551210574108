import { useCallback } from 'react';

import { useSnackbar } from 'notistack';

import { ApiRequestStatusEnum } from '../enums/common/api';

const useNotification = () => {
    const { enqueueSnackbar } = useSnackbar();

    const showNotification = useCallback((data) => {
        if (data.status === ApiRequestStatusEnum.FULFILLED) {
            enqueueSnackbar(data.message, { variant: 'success' });
        } else if (data.status === ApiRequestStatusEnum.REJECTED) {
            enqueueSnackbar(data.message, { variant: 'error' });
        }
    },[enqueueSnackbar]);

    const showErrorMessage = useCallback((data) => {
        if (data.status === ApiRequestStatusEnum.REJECTED) {
            enqueueSnackbar(data.message, { variant: 'error' });
        }
    }, [enqueueSnackbar]);

    return { showNotification, showErrorMessage };
};

export default useNotification;