import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import SearchIcon from '@mui/icons-material/Search';
import moment from 'moment';

import { getCouponsState } from '../../../features/coupon/get_coupons';
import { GET_ROUTE_COUPON_FORMS, MISC_DATE_ISO_FORMAT, MISC_DATE_POLISH_FORMAT, ROUTE_COUPON_FORMS_NEW } from '../../../core/constants';
import { DeleteButton, DuplicateButton, SubmitButton } from '../../common/button';
import { updateCouponStatusAction, updateCouponStatusState } from '../../../features/coupon/update_coupon_status';
import { updateCouponStatusNotificationData } from '../../../core/utils/notification_utils';
import useNotification from '../../../core/hooks/use_notification';
import { ApiRequestStatusEnum } from '../../../core/enums/common/api';
import { CouponStatusEnum } from '../../../core/enums/price/coupon';
import { FormRouteEnum } from '../../../core/enums/common/route';
import { getButtonText, getCouponStatus, getUpdateCouponStatus } from '../../common/utils/coupon_utils';

const CouponsList = ({ refreshCouponList, tab }) => {
    const { showNotification } = useNotification();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const getCoupons = useSelector(getCouponsState);
    const updateCouponStatus = useSelector(updateCouponStatusState);

    const updateOptions = tab === 0;

    const onUpdateCouponStatus = (coupon, status = null) => {
        const couponStatus = status || getUpdateCouponStatus(coupon?.couponStatus);

        dispatch(updateCouponStatusAction({ couponId: coupon?.couponId, status: couponStatus }))
            .then(response => {
                showNotification(updateCouponStatusNotificationData(response, couponStatus));
                response?.meta?.requestStatus === ApiRequestStatusEnum.FULFILLED && refreshCouponList();
            });
    };

    return (
        <TableContainer>
            <Table stickyHeader>
                <TableHead>
                    <TableRow>
                        <TableCell>Nazwa robocza</TableCell>
                        <TableCell align="center">Data obowiązywania</TableCell>
                        <TableCell align="center">Wszystkie pody</TableCell>
                        <TableCell align="center">Wszyscy klienci</TableCell>
                        <TableCell align="center">Status</TableCell>
                        <TableCell align="center">
                            {
                                updateOptions
                                    ? 'Podgląd/edycja'
                                    : 'Podgląd'
                            }
                        </TableCell>
                        {
                            updateOptions && <TableCell align="center">Zmiana statusu</TableCell>
                        }
                        <TableCell align="center">Duplikuj</TableCell>
                        {
                            updateOptions && <TableCell align="center">Usuń</TableCell>
                        }
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        getCoupons?.data?.content?.map(coupon => (
                            <TableRow
                                key={ coupon?.couponId }
                                hover
                            >
                                <TableCell>{ coupon?.workingName }</TableCell>
                                <TableCell align="center">
                                    <Grid container>
                                        <Grid container item justifyContent="center">
                                            Od:&nbsp;
                                            {
                                                moment(coupon?.timeFrom, MISC_DATE_ISO_FORMAT)
                                                    .format(MISC_DATE_POLISH_FORMAT)
                                            }
                                        </Grid>
                                        <Grid container item justifyContent="center">
                                            Do:&nbsp;
                                            {
                                                coupon?.withoutEndDate
                                                    ? 'Brak daty końcowej'
                                                    : moment(coupon?.timeTo, MISC_DATE_ISO_FORMAT)
                                                        .format(MISC_DATE_POLISH_FORMAT)
                                            }
                                        </Grid>
                                    </Grid>
                                </TableCell>
                                <TableCell align="center">
                                    {
                                        coupon?.allPods ? 'Tak' : 'Nie'
                                    }
                                </TableCell>
                                <TableCell align="center">
                                    {
                                        coupon?.allCustomers ? 'Tak' : 'Nie'
                                    }
                                </TableCell>
                                <TableCell align="center">
                                    {
                                        getCouponStatus(coupon?.couponStatus)
                                    }
                                </TableCell>
                                <TableCell align="center">
                                    {
                                        updateOptions
                                            ? <EditIcon onClick={ () => navigate(GET_ROUTE_COUPON_FORMS(coupon?.couponId), { state: { action: FormRouteEnum.EDIT } }) } cursor="pointer"/>
                                            : <SearchIcon onClick={ () => navigate(GET_ROUTE_COUPON_FORMS(coupon?.couponId), { state: { action: FormRouteEnum.VIEW } }) } cursor="pointer"/>
                                    }

                                </TableCell>
                                {
                                    updateOptions &&
                                    <TableCell align="center">
                                        <SubmitButton onClick={ () => onUpdateCouponStatus(coupon) } isLoading={ updateCouponStatus?.loading } disabled={ coupon?.couponStatus === CouponStatusEnum.DRAFT }>
                                            {
                                                getButtonText(coupon?.couponStatus)
                                            }
                                        </SubmitButton>
                                    </TableCell>
                                }
                                <TableCell align="center">
                                    <DuplicateButton onClick={ () => navigate(ROUTE_COUPON_FORMS_NEW, { state: { action : FormRouteEnum.NEW, couponIdToDuplicate : coupon?.couponId }}) }/>
                                </TableCell>
                                {
                                    updateOptions &&
                                    <TableCell align="center">
                                        <DeleteButton onDeleteClick={ () => onUpdateCouponStatus(coupon, CouponStatusEnum.DELETED) } isLoading={ updateCouponStatus?.loading }/>
                                    </TableCell>
                                }
                            </TableRow>
                        ))
                    }
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default CouponsList;
