import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { Box, Grid, LinearProgress, Tab, Tabs, Typography } from '@mui/material';
import { tabsClasses } from '@mui/material/Tabs';

import { Button } from '../../../common/button';
import { ROUTE_PRODUCTS } from '../../../../core/constants';
import { getProductAction, getProductState, invalidateProduct } from '../../../../features/product/get_product';
import { getLanguagesState } from '../../../../features/product/get_languages';
import ProductForm from './forms/product_form';
import ProductDetailsForm from './forms/product_details_form';
import { getConstantsAction, getConstantsState, invalidateConstants } from '../../../../features/product/get_constants';
import useNotification from '../../../../core/hooks/use_notification';
import { getAllFoodPartnersNotificationData, getAllProducersNotificationData, getConstantsNotificationData, getProductNotificationData } from '../../../../core/utils/notification_utils';
import QRCodeDownload from '../../../common/qr_code_download';
import { getAllFoodPartnersAction } from '../../../../features/food_partner/get_all_food_partners';
import { getAllProducersAction } from "../../../../features/product/get_all_producers";

const ProductFormsPage = () => {
    const { showErrorMessage } = useNotification();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { productId } = useParams();
    const location = useLocation();

    const getLanguages = useSelector(getLanguagesState);
    const getProduct = useSelector(getProductState);
    const getConstants = useSelector(getConstantsState);

    const [languageCode, setLanguageCode] = useState('PL');
    const [tab, setTab] = useState(0);

    useEffect(() => {
        dispatch(getConstantsAction())
            .then(response => showErrorMessage(getConstantsNotificationData(response)));

        return () => {
            dispatch(invalidateConstants());
        };
    }, [dispatch, showErrorMessage]);

    useEffect(() => {
        dispatch(getProductAction({ id: productId, languageCode }))
            .then(response => showErrorMessage(getProductNotificationData(response)));

        return () => {
            dispatch(invalidateProduct());
        };
    }, [dispatch, productId, showErrorMessage, languageCode]);

    useEffect(() => {
        dispatch(getAllFoodPartnersAction())
            .then(response => showErrorMessage(getAllFoodPartnersNotificationData(response)));
        dispatch(getAllProducersAction())
            .then(response => showErrorMessage(getAllProducersNotificationData(response)));
    }, [dispatch, showErrorMessage]);

    const renderView = () => {
        if (getProduct?.loading || getConstants?.loading) {
            return (
                <Box mt={ 6 }>
                    <LinearProgress/>
                </Box>
            );
        }

        if (getProduct?.data && getConstants?.data) {
            if (tab === 0) {
                return (
                    <Grid container rowGap={ 6 }>
                        <Grid container item justifyContent="space-between">
                            <Grid item>
                                <Typography variant="h6" textAlign="center" mb={ 2 }>
                                    { getProduct?.data?.name }
                                </Typography>
                            </Grid>
                            <Grid item alignContent="end">
                                <Typography variant="h6" textAlign="center" mb={ 2 }>QR kod</Typography>
                                <QRCodeDownload
                                    productId={ getProduct?.data?.id }
                                    productName={ getProduct?.data?.name }
                                />
                            </Grid>
                        </Grid>
                        <ProductForm languageCode={ languageCode }/>
                    </Grid>
                );
            } else {
                return (
                    <ProductDetailsForm languageCode={ languageCode }/>
                );
            }
        }

        return <></>;
    };

    return (
        <Grid mb={ 6 }>
            <Grid container mb={ 4 } justifyContent="space-between" rowGap={ 2 }>
                <Grid container item>
                    <Button onClick={ () => navigate(ROUTE_PRODUCTS, { state: { queryParams: location?.state?.queryParams } }) }>
                        Wróć
                    </Button>
                </Grid>
                <Grid container item>
                    <Tabs
                        value={ tab }
                        onChange={ (_, value) => {
                            setTab(value);
                            setLanguageCode(getLanguages?.data[value - 1]?.languageCode || 'PL');
                        } }
                        variant="scrollable"
                        scrollButtons
                        sx={ {
                            [`& .${ tabsClasses.scrollButtons }`]: {
                                '&.Mui-disabled': { opacity: 0.3 },
                            },
                        } }
                    >
                        <Tab label="Informacje ogólne" sx={ { justifyContent: 'flexEnd' } }/>
                        {
                            getLanguages?.data?.map(language => {
                                return <Tab key={ language?.languageCode } label={ language?.languageName } disabled={ !getProduct?.data?.type }/>;
                            })
                        }
                    </Tabs>
                </Grid>
            </Grid>
            {
                renderView()
            }
        </Grid>
    );
};

export default ProductFormsPage;
