import React, { useCallback, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { Grid, LinearProgress, Stack } from '@mui/material';

import useNotification from '../../../../core/hooks/use_notification';
import { getPodAction, getPodState } from '../../../../features/pod/get_pod';
import { ROUTE_SALES } from '../../../../core/constants';
import { getBusinessClassificationsNotificationData, getBusinessDataNotificationData, getBusinessDecisionsNotificationData, getPodNotificationData } from '../../../../core/utils/notification_utils';
import { getBusinessDataAction, getBusinessDataState } from '../../../../features/store/get_business_data';
import BusinessDataList from './data_list';
import Item from '../../../common/item';
import { getBusinessClassificationsAction } from '../../../../features/store/get_business_classifications';
import { getBusinessDecisionsAction } from '../../../../features/store/get_business_decisions';
import { Button } from '../../../common/button';

const PodSalesPage = () => {
    const dispatch = useDispatch();
    const { showErrorMessage } = useNotification();
    const navigate = useNavigate();
    const { podId } = useParams();

    const getPod = useSelector(getPodState);
    const getBusinessData = useSelector(getBusinessDataState);

    const refreshList = useCallback(() => {
        dispatch(getBusinessDataAction({podId}))
            .then(response => showErrorMessage(getBusinessDataNotificationData(response)));
    }, [dispatch, podId, showErrorMessage]);

    useEffect(() => {
        dispatch(getPodAction({ id: podId }))
            .then(response => showErrorMessage(getPodNotificationData(response)));
        dispatch(getBusinessClassificationsAction())
            .then(response => showErrorMessage(getBusinessClassificationsNotificationData(response)));
        dispatch(getBusinessDecisionsAction())
            .then(response => showErrorMessage(getBusinessDecisionsNotificationData(response)));
        refreshList();
    }, [dispatch, podId, refreshList, showErrorMessage]);
    
    return (
        <Grid mb={ 6 }>
            <Grid container mb={ 4 } justifyContent="space-between">
                <Grid item>
                    <Button onClick={ () => navigate(ROUTE_SALES) }>
                        Wróć
                    </Button>
                </Grid>
            </Grid>
            <Item>
                <Stack direction={ 'row' } spacing={ 2 }>
                    <span><b>{ getPod?.data?.name }</b></span>
                    <span>{ getPod?.data?.indoorPartner?.name }</span>
                    <span>{ getPod?.data?.address }</span>
                    <span>({ getPod?.data?.locationType })</span>
                </Stack>
            </Item>
            <br/>
            {
                getBusinessData?.loading &&
                <LinearProgress/>
            }
            {
                getBusinessData?.data &&
                <div>
                    <BusinessDataList
                        pod={ getPod?.data }
                        refreshList={ refreshList }
                    />
                </div>
            }
        </Grid>
    );
};

export default PodSalesPage;
