import { deepFreeze } from '../../utils/misc_utils';

export const GenerateWarehouseDocumentTypeRequestEnum = deepFreeze({
    PZ_DOCUMENT: {
        value: 'PZ_DOCUMENT',
        label: 'PZ'
    },
    PZ_AND_MM_DOCUMENTS: {
        value: 'PZ_AND_MM_DOCUMENTS',
        label: 'PZ + MM'
    },
    MM_DOCUMENTS: {
        value: 'MM_DOCUMENTS',
        label: 'MM'
    },
    PW_DOCUMENT: {
        value: 'PW_DOCUMENT',
        label: 'PW'
    }
});

export const WarehouseDocumentTypeEnum = deepFreeze({
    PZ_DOCUMENT: {
        value: 'PZ',
        label: 'PZ'
    },
    PW_DOCUMENT: {
        value: 'PW',
        label: 'PW'
    },
    MP_DOCUMENT: {
        value: 'MP',
        label: 'MP'
    },
    MW_DOCUMENT: {
        value: 'MW',
        label: 'MW'
    },
    RW_DOCUMENT: {
        value: 'RW',
        label: 'RW'
    }
});