import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Box, Grid, LinearProgress, Typography } from '@mui/material';

import useNotification from '../../../../core/hooks/use_notification';
import { getAllWarehousesNotificationData, getWarehouseDocumentsNotificationData } from '../../../../core/utils/notification_utils';
import { Button } from '../../../common/button';
import { ROUTE_WAREHOUSE_DOCUMENT_FORM } from '../../../../core/constants';
import WarehouseDocumentFilters from "./warehouse_documents_filters";
import { getWarehouseDocumentsAction, getWarehouseDocumentsState } from "../../../../features/inventory/get_warehouse_documents";
import PaginationControls from "../../../common/pagination_controls";
import deepmerge from "deepmerge";
import { getDefaultPaginationQueryValues } from "../../../../core/utils/api_utils";
import WarehouseDocumentsList from "./warehouse_documents_list";
import { getAllWarehousesAction, getAllWarehousesState } from "../../../../features/inventory/get_all_warehouses";

const initialQueryParams = {
    ...getDefaultPaginationQueryValues,
    query: '',
    type: [],
    warehouseId: []
};

const WarehouseDocumentsPage = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { showErrorMessage } = useNotification();

    const getWarehouseDocuments = useSelector(getWarehouseDocumentsState);
    const getAllWarehouses = useSelector(getAllWarehousesState);

    const [queryParams, setQueryParams] = useState(initialQueryParams);

    const refreshList = useCallback(() => {
        dispatch(getWarehouseDocumentsAction(queryParams))
            .then(response => showErrorMessage(getWarehouseDocumentsNotificationData(response)));
    }, [dispatch, queryParams, showErrorMessage]);

    useEffect(() => {
        dispatch(getAllWarehousesAction())
            .then(response => showErrorMessage(getAllWarehousesNotificationData(response)));
    }, [dispatch, showErrorMessage]);

    useEffect(() => {
        refreshList();
    }, [refreshList]);

    return (
        <Grid mb={ 6 }>
            <Grid container mb={ 4 } rowGap={ 4 } justifyContent="space-between">
                <Grid container>
                    <Typography variant="h1">Dokumenty magazynowe</Typography>
                </Grid>
                <Grid container>
                    {
                        getAllWarehouses?.loading
                            ? <></>
                            : getAllWarehouses?.data &&
                            <WarehouseDocumentFilters
                                onSearchInputChange={ query => setQueryParams(prevState => ({ ...prevState, query })) }
                                onSelectWarehousesChange={ warehouseId => setQueryParams(prevState => ({ ...prevState, warehouseId })) }
                                onSelectTypesChange={ type => setQueryParams(prevState => ({ ...prevState, type })) }
                            />
                    }
                </Grid>
                <Grid container sx={ { gap: 2 } }>
                    <Button onClick={ () => navigate(ROUTE_WAREHOUSE_DOCUMENT_FORM) }>
                        Dodaj dokument magazynowy
                    </Button>
                </Grid>
            </Grid>
            {
                getWarehouseDocuments?.loading &&
                <LinearProgress/>
            }
            {
                getWarehouseDocuments?.data?.content &&
                <>
                    <WarehouseDocumentsList
                        refreshList={ refreshList }/>
                    <Box mt={ 2 }>
                        <PaginationControls
                            currentPage={ getWarehouseDocuments?.data?.pageable?.pageNumber }
                            totalPages={ getWarehouseDocuments?.data?.totalPages }
                            setPage={ page => setQueryParams(prevState => deepmerge(prevState, { page })) }
                        />
                    </Box>
                </>
            }
        </Grid>
    );
};

export default WarehouseDocumentsPage;
