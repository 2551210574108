import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import webClient from '../../core/web_client';
import { PRODUCT_PROMOTIONS_PATH } from '../../core/constants';
import { buildQueryString, extractErrorData, extractResponseData } from '../../core/utils/api_utils';
import { DEFAULT_STATE, STATE__API__FAIL, STATE__API__STARTED, STATE__API__SUCCESS } from '../../core/utils/store_utils';
import { invalidateToken } from '../authorization/get_token';

export const getProductPromotionsAction = createAsyncThunk(
    'price/getProductPromotionsAction',
    async (data, { fulfillWithValue, rejectWithValue }) => {
        try {
            const response = await webClient.get(`${ PRODUCT_PROMOTIONS_PATH }/product/${ data.productId }${ buildQueryString(data.queryParams) }`);
            return fulfillWithValue(extractResponseData(response));
        } catch (error) {
            return rejectWithValue(extractErrorData(error));
        }
    }
);

const getProductPromotionsSlice = createSlice({
    name: 'getProductPromotionsSlice',
    initialState: DEFAULT_STATE,
    reducers: {
        invalidateProductPromotions() {
            return DEFAULT_STATE;
        }
    },
    extraReducers: builder => {
        builder.addCase(getProductPromotionsAction.pending, () => STATE__API__STARTED);
        builder.addCase(getProductPromotionsAction.fulfilled, (_, { payload }) => STATE__API__SUCCESS(payload));
        builder.addCase(getProductPromotionsAction.rejected, (_, { payload }) => STATE__API__FAIL(payload));
        builder.addCase(invalidateToken, () => DEFAULT_STATE);
    }
});

export const { invalidateProductPromotions } = getProductPromotionsSlice.actions;
export const getProductPromotionsState = (state) => state.price.getProductPromotions;
export default getProductPromotionsSlice.reducer;