import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import * as Yup from 'yup';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import { Form, Formik } from 'formik';
import { Box, Grid, IconButton, LinearProgress, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import DialogActions from '@mui/material/DialogActions';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import moment from 'moment';

import useNotification from '../../../../../../core/hooks/use_notification';
import { Button, SubmitButton } from '../../../../../common/button';
import { getProductsAvailabilitySingleDayNotificationData, importProductAvailabilityNotificationData, } from '../../../../../../core/utils/notification_utils';
import { ApiRequestStatusEnum } from '../../../../../../core/enums/common/api';
import { MISC_DATE_ISO_DATE_ONLY_FORMAT } from '../../../../../../core/constants';
import { getProductsAvailabilitySingleDayAction, getProductsAvailabilitySingleDayState } from '../../../../../../features/reservation/get_products_availability_single_day';
import { importProductAvailabilityAction, importProductAvailabilityState } from '../../../../../../features/reservation/import_product_availability';
import DatePickerFormik from '../../../../../common/date_picker';
import { parseDateString } from '../../../../../../core/utils/date_utils';

const initialQueryParams = {
    languageCode: 'PL'
};

const ImportProductAvailabilityFormDialog = ({ refreshCalendar, targetDate }) => {
    const { showNotification, showErrorMessage } = useNotification();
    const dispatch = useDispatch();

    const getProductsAvailabilitySingleDay = useSelector(getProductsAvailabilitySingleDayState);
    const importProductAvailability = useSelector(importProductAvailabilityState);

    const [open, setOpen] = useState(false);

    const onClose = () => setOpen(false);

    const onOpen = () => {
        onChangeDate(initialValues.sourceDate);
        setOpen(true);
    };

    const initialValues = {
        sourceDate: moment(targetDate).subtract(1, 'days')
    };

    const schema = Yup.object().shape({
        sourceDate: Yup.date()
            .transform(parseDateString)
            .nullable()
            .required()
    });

    const onSubmit = (values) => {
        const queryParams = {
            ...initialQueryParams,
            sourceDate: values?.sourceDate?.format(MISC_DATE_ISO_DATE_ONLY_FORMAT),
            targetDate
        };

        dispatch(importProductAvailabilityAction(queryParams))
            .then(response => {
                showNotification(importProductAvailabilityNotificationData(response));
                if (response?.meta?.requestStatus === ApiRequestStatusEnum.FULFILLED) {
                    onClose();
                    refreshCalendar && refreshCalendar();
                }
            });
    };

    const onChangeDate = (date) => {
        const queryParams = {
            date: date?.format(MISC_DATE_ISO_DATE_ONLY_FORMAT)
        };

        dispatch(getProductsAvailabilitySingleDayAction(queryParams))
            .then(response => {
                showErrorMessage(getProductsAvailabilitySingleDayNotificationData(response));
            });
    };

    return (
        <>
            <IconButton onClick={ onOpen }>
                <FileDownloadIcon/>
            </IconButton>
            <Dialog open={ open } onClose={ onClose } fullWidth maxWidth={ 'lg' }>
                <DialogTitle>
                    Zaimportuj listę produktów ({ targetDate })
                </DialogTitle>
                <DialogContent sx={ { paddingTop: '6px !important' } }>
                    <Formik
                        initialValues={ initialValues }
                        validationSchema={ schema }
                        validateOnChange={ false }
                        validateOnBlur={ false }
                        onSubmit={ onSubmit }>
                        { () => {
                            return (
                                <>
                                    <Grid component={ Form } container item rowGap={ 2 } alignContent="space-between">
                                        <Grid container mt={ 2 }>
                                            <Grid item xs={ 8 }>
                                                <DatePickerFormik
                                                    name="sourceDate"
                                                    required
                                                    fullWidth
                                                    onChange={ value => onChangeDate(value) }
                                                />
                                            </Grid>
                                            <Grid item xs={ 4 }>
                                                <DialogActions sx={ { gap: 2 } }>
                                                    <Button onClick={ onClose }>Zamknij</Button>
                                                    <SubmitButton isLoading={ importProductAvailability?.loading }>Wczytaj listę produktów</SubmitButton>
                                                </DialogActions>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Paper elevation={ 0 } style={ { maxHeight: 500, minHeight: 500, overflow: 'auto', marginTop: 20 } }>
                                        {
                                            getProductsAvailabilitySingleDay?.loading &&
                                            <LinearProgress/>
                                        }
                                        {
                                            getProductsAvailabilitySingleDay?.data &&
                                            <Box>
                                                <TableContainer>
                                                    <Table stickyHeader>
                                                        <TableHead>
                                                            <TableRow>
                                                                <TableCell>
                                                                    Produkt
                                                                </TableCell>
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                            {
                                                                getProductsAvailabilitySingleDay?.data?.map(product => (
                                                                    <TableRow key={ product?.productId }>
                                                                        <TableCell>{ product?.productName }</TableCell>
                                                                    </TableRow>
                                                                ))
                                                            }
                                                        </TableBody>
                                                    </Table>
                                                </TableContainer>
                                            </Box>
                                        }
                                    </Paper>
                                </>
                            );
                        } }
                    </Formik>
                </DialogContent>
            </Dialog>
        </>
    );
};

export default ImportProductAvailabilityFormDialog;
