import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import webClient from '../../core/web_client';
import { PRODUCT_PROMOTIONS_PATH } from '../../core/constants';
import { extractErrorData, extractResponseData } from '../../core/utils/api_utils';
import { DEFAULT_STATE, STATE__API__FAIL, STATE__API__STARTED, STATE__API__SUCCESS } from '../../core/utils/store_utils';
import { invalidateToken } from '../authorization/get_token';

export const deleteProductPromotionAction = createAsyncThunk(
    'price/deleteProductPromotionAction',
    async (id, { fulfillWithValue, rejectWithValue }) => {
        try {
            const response = await webClient.delete(`${ PRODUCT_PROMOTIONS_PATH }/${ id }`);
            return fulfillWithValue(extractResponseData(response));
        } catch (error) {
            return rejectWithValue(extractErrorData(error));
        }
    }
);

const deleteProductPromotionSlice = createSlice({
    name: 'deleteProductPromotionSlice',
    initialState: DEFAULT_STATE,
    reducers: {},
    extraReducers: builder => {
        builder.addCase(deleteProductPromotionAction.pending, () => STATE__API__STARTED);
        builder.addCase(deleteProductPromotionAction.fulfilled, (_, { payload }) => STATE__API__SUCCESS(payload));
        builder.addCase(deleteProductPromotionAction.rejected, (_, { payload }) => STATE__API__FAIL(payload));
        builder.addCase(invalidateToken, () => DEFAULT_STATE);
    }
});

export const deleteProductPromotionState = (state) => state.price.deleteProductPromotion;
export default deleteProductPromotionSlice.reducer;
