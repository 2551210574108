import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import webClient, { webClientFileDownload } from '../../core/web_client';
import { exportFile, extractErrorData, extractErrorDataFromFile, extractResponseData } from '../../core/utils/api_utils';
import { DEFAULT_STATE, STATE__API__FAIL, STATE__API__STARTED, STATE__API__SUCCESS } from '../../core/utils/store_utils';
import { invalidateToken } from '../authorization/get_token';
import { FOOD_PARTNER_ORDER_PATH, MISC_REST_CONTENT_TYPE_APPLICATION_JSON, MISC_REST_CONTENT_TYPE_MULTIPART } from '../../core/constants';

export const updateFoodPartnerOrderAction = createAsyncThunk(
    'inventory/updateFoodPartnerOrderAction',
    async (data, { fulfillWithValue, rejectWithValue }) => {
        let body = new FormData();
        const formJson = JSON.stringify(data.form);
        const formBlob = new Blob([formJson], {
            type: MISC_REST_CONTENT_TYPE_APPLICATION_JSON
        });

        body.append('form', formBlob);

        for (const attachment of data?.attachments) {
            body.append('attachments', attachment);
        }

        if (data.form.generateLabels) {
            try {
                const response = await webClientFileDownload.put(`${ FOOD_PARTNER_ORDER_PATH }/${ data.id }/save`, body, {
                    headers: {
                        'content-type': MISC_REST_CONTENT_TYPE_MULTIPART
                    }
                });

                if (response?.status === 200) {
                    const fileName = response.headers['content-disposition']?.split('filename=')[1]?.replaceAll("\"", '');
                    exportFile(extractResponseData(response), fileName ? fileName : 'naklejki.zip');
                    return fulfillWithValue();
                } else {
                    return fulfillWithValue('generateLabelsFailed');
                }
            } catch (error) {
                const response = await extractErrorDataFromFile(extractResponseData(error?.response));
                return rejectWithValue(response);
            }
        } else {
            try {
                const response = await webClient.put(`${ FOOD_PARTNER_ORDER_PATH }/${ data.id }/save`, body, {
                    headers: {
                        'content-type': MISC_REST_CONTENT_TYPE_MULTIPART
                    }
                });

                return fulfillWithValue(extractResponseData(response));
            } catch (error) {
                return rejectWithValue(extractErrorData(error));
            }
        }
    }
);

const updateFoodPartnerOrderSlice = createSlice({
    name: 'updateFoodPartnerOrderSlice',
    initialState: DEFAULT_STATE,
    reducers: {},
    extraReducers: builder => {
        builder.addCase(updateFoodPartnerOrderAction.pending, () => STATE__API__STARTED);
        builder.addCase(updateFoodPartnerOrderAction.fulfilled, (_, { payload }) => STATE__API__SUCCESS(payload));
        builder.addCase(updateFoodPartnerOrderAction.rejected, (_, { payload }) => STATE__API__FAIL(payload));
        builder.addCase(invalidateToken, () => DEFAULT_STATE);
    }
});

export const updateFoodPartnerOrderState = (state) => state.inventory.updateFoodPartnerOrder;
export default updateFoodPartnerOrderSlice.reducer;
