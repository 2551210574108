import React, { useState } from 'react';

import { Grid } from '@mui/material';
import { useSelector } from 'react-redux';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import SelectField, { SelectFieldMultiple } from '../../../../common/select_field';
import { getAllPodsState } from '../../../../../features/pod/get_all_pods';

const showArchiveOptions = [
    {
        value: true,
        label: 'Archiwalne'
    },
    {
        value: false,
        label: 'Aktualne'
    }
];

const ReservationFilters = ({ from, onChangeTimeFrom, to, onChangeTimeTo, showArchive, onSelectShowArchiveChange, onSelectPodsChange }) => {
    const getAllPods = useSelector(getAllPodsState);

    const [podIds, setPodIds] = useState([]);

    return (
        <Grid container mb={ 4 } gap={ 4 }>
            <Grid container gap={ 4 }>
                <Grid item>
                    <DatePicker
                        displayWeekNumber
                        value={ from }
                        label="Data od"
                        onChange={ newDate => onChangeTimeFrom(newDate) }
                    />
                </Grid>
                <Grid item>
                    <DatePicker
                        displayWeekNumber
                        value={ to }
                        label="Data do"
                        onChange={ newDate => onChangeTimeTo(newDate) }
                    />
                </Grid>
                <Grid item>
                    <SelectField
                        label={ 'Pokaż' }
                        value={ showArchive }
                        onChange={ (event) => onSelectShowArchiveChange(event.target.value) }
                        options={ showArchiveOptions }
                    />
                </Grid>
            </Grid>
            <Grid container>
                <Grid item xs={ 9 }>
                    <SelectFieldMultiple
                        label="Pokaż dla podów"
                        options={ getAllPods?.data?.map(pod => ({ value: pod?.id, label: pod?.indoorPartner?.name + ' (' + pod?.description + ') - ' + (pod?.device?.serviceId || 'brak urządzenia') })) }
                        required
                        value={ podIds }
                        onClose={ () => onSelectPodsChange(podIds) }
                        onChange={ event => setPodIds(event.target.value) }
                    />
                </Grid>
            </Grid>
        </Grid>
    );
};

export default ReservationFilters;