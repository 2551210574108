import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { DEFAULT_STATE, STATE__API__FAIL, STATE__API__STARTED, STATE__API__SUCCESS } from '../../core/utils/store_utils';
import { invalidateToken } from '../authorization/get_token';
import { webClientFileDownload } from '../../core/web_client';
import { exportFile, extractErrorData, extractResponseData } from '../../core/utils/api_utils';

export const getFoodPartnerOrderAttachmentAction = createAsyncThunk(
    'inventory/getFoodPartnerOrderAttachmentAction',
    async (fileUrl, { fulfillWithValue, rejectWithValue }) => {
        try {
            const response = await webClientFileDownload.get(fileUrl);
            const fileName = response.headers['content-disposition']?.split('filename=')[1]?.replaceAll("\"", '');
            exportFile(extractResponseData(response), fileName ? fileName : 'załącznik');
            return fulfillWithValue();
        } catch (error) {
            return rejectWithValue(extractErrorData(error));
        }
    }
);

const getFoodPartnerOrderAttachmentSlice = createSlice({
    name: 'getFoodPartnerOrderAttachmentSlice',
    initialState: DEFAULT_STATE,
    reducers: {},
    extraReducers: builder => {
        builder.addCase(getFoodPartnerOrderAttachmentAction.pending, () => STATE__API__STARTED);
        builder.addCase(getFoodPartnerOrderAttachmentAction.fulfilled, (_, { payload }) => STATE__API__SUCCESS(payload));
        builder.addCase(getFoodPartnerOrderAttachmentAction.rejected, (_, { payload }) => STATE__API__FAIL(payload));
        builder.addCase(invalidateToken, () => DEFAULT_STATE);
    }
});

export const getFoodPartnerOrderAttachmentState = (state) => state.inventory.getFoodPartnerOrderAttachment;
export default getFoodPartnerOrderAttachmentSlice.reducer;
