import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Box, Grid, LinearProgress, Tab, Tabs } from '@mui/material';
import { tabsClasses } from '@mui/material/Tabs';
import deepmerge from 'deepmerge';
import _ from 'lodash';

import { getCouponsAction, getCouponsState } from '../../../features/coupon/get_coupons';
import { getAutomaticCouponSettingsNotificationData, getCouponsNotificationData } from '../../../core/utils/notification_utils';
import useNotification from '../../../core/hooks/use_notification';
import PaginationControls from '../../common/pagination_controls';
import { getDefaultPaginationQueryValues } from '../../../core/utils/api_utils';
import CouponsList from './coupons_list';
import { Button } from '../../common/button';
import { FormRouteEnum } from '../../../core/enums/common/route';
import { ROUTE_COUPON_FORMS_NEW } from '../../../core/constants';
import { getAutomaticCouponSettingsAction, getAutomaticCouponSettingsState } from '../../../features/coupon/get_automatic_coupon_settings';
import AutomaticCouponSettingList from './automatic_coupon_setting_list';
import SelectField from '../../common/select_field';

const initialQueryParams = {
    ...getDefaultPaginationQueryValues,
    sort: ['workingName', 'asc'],
    showArchived: false,
    filter: 'all'
};

const filterOptions = [
    {
        value: 'all',
        label: 'Wszystkie'
    },
    {
        value: 'reusable',
        label: 'Wielokrotnego użytku'
    },
    {
        value: 'nonReusable',
        label: 'Jednorazowe'
    }
];

const CouponsPage = () => {
    const { showErrorMessage } = useNotification();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const getCoupons = useSelector(getCouponsState);
    const getAutomaticCouponSettings = useSelector(getAutomaticCouponSettingsState);

    const [tab, setTab] = useState(0);
    const [queryParams, setQueryParams] = useState(initialQueryParams);

    const refreshCouponList = useCallback(() => {
        dispatch(getCouponsAction(tab === 0 ? queryParams : _.omit(queryParams, ['filter'])))
            .then(response => showErrorMessage(getCouponsNotificationData(response)));
    }, [dispatch, queryParams, showErrorMessage, tab]);

    useEffect(() => {
        refreshCouponList();
    }, [dispatch, queryParams, refreshCouponList, showErrorMessage]);

    const refreshAutomaticCouponSettingList = () => {
        dispatch(getAutomaticCouponSettingsAction())
            .then(response => showErrorMessage(getAutomaticCouponSettingsNotificationData(response)));
    };

    const onTabChange = (tabValue) => {
        setQueryParams(prevState => deepmerge(prevState, { filter: filterOptions[0].value }));
        setTab(tabValue);
        if (tabValue !== 2) setQueryParams(prevState => deepmerge(prevState, { showArchived: tabValue !== 0 }));
        if (tabValue === 2) refreshAutomaticCouponSettingList();
    };

    const onSelectFilterChange = (event) => {
        tab === 0 && setQueryParams(prevState => deepmerge(prevState, { filter: event.target.value }));
    };

    const renderView = () => {
        if (getCoupons?.loading || getAutomaticCouponSettings?.loading) {
            return (
                <Box mt={ 6 }>
                    <LinearProgress/>
                </Box>
            );
        }

        if (getCoupons?.data?.content && tab !== 2) {
            return (
                <Grid container>
                    <CouponsList
                        refreshCouponList={ refreshCouponList }
                        tab={ tab }
                    />
                    <Box mt={ 2 }>
                        <PaginationControls
                            currentPage={ getCoupons?.data?.pageable?.pageNumber }
                            totalPages={ getCoupons?.data?.totalPages }
                            setPage={ page => setQueryParams(prevState => deepmerge(prevState, { page })) }
                        />
                    </Box>
                </Grid>
            );
        } else if (getAutomaticCouponSettings?.data && tab === 2) {
            return (
                <AutomaticCouponSettingList
                    refreshAutomaticCouponSettingList={ refreshAutomaticCouponSettingList }/>
            );
        }

        return <></>;
    };

    return (
        <Grid mb={ 6 }>
            <Grid container mb={ 4 } rowGap={ 4 } justifyContent="space-between">
                <Grid container item>
                    <Tabs
                        value={ tab }
                        onChange={ (_, value) => onTabChange(value) }
                        variant="scrollable"
                        scrollButtons
                        sx={ {
                            [`& .${ tabsClasses.scrollButtons }`]: {
                                '&.Mui-disabled': { opacity: 0.3 },
                            },
                        } }
                    >
                        <Tab label="Kupony aktywne"/>
                        <Tab label="Kupony archiwalne"/>
                        <Tab label="Ustawienia automatycznych kuponów"/>
                    </Tabs>
                </Grid>
                {
                    tab === 0 &&
                    <Grid item>
                        <SelectField
                            label={ 'Pokaż' }
                            value={ _.pick(queryParams, ['filter']).filter }
                            onChange={ onSelectFilterChange }
                            options={ filterOptions }
                        />
                    </Grid>
                }
                {
                    tab !== 2 &&
                    <Grid container item>
                        <Button onClick={ () => navigate(ROUTE_COUPON_FORMS_NEW, { state: { action: FormRouteEnum.NEW } }) }>
                            Dodaj kupon
                        </Button>
                    </Grid>
                }
            </Grid>
            {
                renderView()
            }
        </Grid>
    );
};

export default CouponsPage;
