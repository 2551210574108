import { createTheme } from '@mui/material/styles';
import { plPL } from '@mui/material/locale';

const theme = createTheme({
    typography: {
        h1: {
            fontSize: '1.7em',
            fontWeight: 'bold'
        },
        h2: {
            fontSize: '1.5em',
            fontWeight: 'bold'
        },
        h3: {
            fontSize: '1.4em',
            fontWeight: 'bold'
        },
        h4: {
            fontSize: '1.3em',
            fontWeight: 'bold'
        },
        h5: {
            fontSize: '1.2em',
            fontWeight: 'bold'
        },
        h6: {
            fontSize: '1.1em',
            fontWeight: 'bold'
        }
    },

    palette: {
        primary: {
            main: '#474747'
        },
        secondary: {
            main: '#aaaaaa',
            light: '#e8e8e8'
        },
        text: {
            primary: '#505050',
            secondary: '#b3b3b3'
        },
        error: {
            main: '#b30000'
        },
        success: {
            main: '#18860b'
        },
        background: {
            default: '#fafafa'
        }
    },

    overrides: {
        MuiTableRow: {
            root: {
                textDecoration: 'none'
            }
        },

        MuiInput: {
            root: {
                fontSize: '14px'
            }
        },

        MuiInputBase: {
            input: {
                paddingLeft: '4px',
                paddingRight: '4px',
            }
        },

        MuiButtonBase: {
            root: {
                height: 'fit-content',
                minHeight: '38px'
            }
        }
    },

    props: {
        MuiTableCell: {
            component: 'div'
        },
    }

}, plPL);

export default theme;
