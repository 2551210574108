import { combineReducers } from '@reduxjs/toolkit';

import getProductBatches from './get_product_batches';
import updateProducts from './update_products';
import getInventoryExport from './get_inventory_export';
import getCounterParties from './get_counterparties';
import createWarehouseDocument from './create_warehouse_document';
import createAndSendWarehouseDocument from './create_and_send_warehouse_document';
import getWarehouseProductBatches from './get_warehouse_product_batches';
import getGenerateWarehouseDocumentRequests from './get_generate_warehouse_document_requests';
import getFoodPartnerOrders from './get_food_partner_orders';
import updateFoodPartnerOrderStatus from './update_food_partner_order_status';
import sendFoodPartnerOrder from './send_food_partner_order';
import createFoodPartnerOrder from './create_food_partner_order';
import createAndSendFoodPartnerOrder from './create_and_send_food_partner_order';
import getFoodPartnerOrder from './get_food_partner_order';
import updateFoodPartnerOrder from './update_food_partner_order';
import updateAndSendFoodPartnerOrder from './update_and_send_food_partner_order';
import getAllWarehouses from './get_all_warehouses';
import generateFoodPartnerOrderLabels from './generate_food_partner_order_labels';
import getFoodPartnerOrderHistory from './get_food_partner_order_history';
import getFoodPartnerOrderAttachment from './get_food_partner_order_attachment';
import getWarehouseDocument from './get_warehouse_document';
import getWarehouseDocuments from './get_warehouse_documents';
import generateWarehouseDocumentLabels from './generate_warehouse_document_labels';

export default combineReducers({
    getProductBatches, updateProducts, getInventoryExport, getCounterParties,
    createWarehouseDocument, createAndSendWarehouseDocument,
    getWarehouseDocument, getWarehouseDocuments, generateWarehouseDocumentLabels,
    getWarehouseProductBatches, getGenerateWarehouseDocumentRequests,
    getFoodPartnerOrders, updateFoodPartnerOrderStatus, sendFoodPartnerOrder, createFoodPartnerOrder, createAndSendFoodPartnerOrder,
    getFoodPartnerOrder, updateFoodPartnerOrder, updateAndSendFoodPartnerOrder, getAllWarehouses, generateFoodPartnerOrderLabels,
    getFoodPartnerOrderHistory, getFoodPartnerOrderAttachment,

});
