import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { DEFAULT_STATE, STATE__API__FAIL, STATE__API__STARTED, STATE__API__SUCCESS } from '../../core/utils/store_utils';
import { invalidateToken } from '../authorization/get_token';
import webClient from '../../core/web_client';
import { extractErrorData, extractResponseData } from '../../core/utils/api_utils';
import { WAREHOUSE_DOCUMENTS_PATH } from '../../core/constants';

export const getWarehouseDocumentAction = createAsyncThunk(
    'inventory/getWarehouseDocumentAction',
    async (id, { fulfillWithValue, rejectWithValue }) => {
        try {
            const response = await webClient.get(`${ WAREHOUSE_DOCUMENTS_PATH }/${ id }`);
            return fulfillWithValue(extractResponseData(response));
        } catch (error) {
            return rejectWithValue(extractErrorData(error));
        }
    }
);

const getWarehouseDocumentSlice = createSlice({
    name: 'getWarehouseDocumentSlice',
    initialState: DEFAULT_STATE,
    reducers: {},
    extraReducers: builder => {
        builder.addCase(getWarehouseDocumentAction.pending, () => STATE__API__STARTED);
        builder.addCase(getWarehouseDocumentAction.fulfilled, (_, { payload }) => STATE__API__SUCCESS(payload));
        builder.addCase(getWarehouseDocumentAction.rejected, (_, { payload }) => STATE__API__FAIL(payload));
        builder.addCase(invalidateToken, () => DEFAULT_STATE);
    }
});

export const getWarehouseDocumentState = (state) => state.inventory.getWarehouseDocument;
export default getWarehouseDocumentSlice.reducer;
