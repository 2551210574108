import { AccessTypeEnum, LocationTypeEnum, PodPurposeEnum } from '../../../core/enums/pod/pod';

export const getAccessTypeOptions = [
    { value: AccessTypeEnum.PUBLIC.value, label: AccessTypeEnum.PUBLIC.label },
    { value: AccessTypeEnum.PRIVATE.value, label: AccessTypeEnum.PRIVATE.label },
    { value: AccessTypeEnum.HIDDEN.value, label: AccessTypeEnum.HIDDEN.label }
];

export const getPodPurposeOptions = [
    { value: PodPurposeEnum.SALE.value, label: PodPurposeEnum.SALE.label },
    { value: PodPurposeEnum.DELIVERY.value, label: PodPurposeEnum.DELIVERY.label }
];

export const getLocationTypeOptions = [
    { value: LocationTypeEnum.AIRPORT.value, label: LocationTypeEnum.AIRPORT.label },
    { value: LocationTypeEnum.HOSPITAL.value, label: LocationTypeEnum.HOSPITAL.label },
    { value: LocationTypeEnum.MANUFACTURE.value, label: LocationTypeEnum.MANUFACTURE.label },
    { value: LocationTypeEnum.OFFICE.value, label: LocationTypeEnum.OFFICE.label },
    { value: LocationTypeEnum.SCHOOL.value, label: LocationTypeEnum.SCHOOL.label },
    { value: LocationTypeEnum.UNIVERSITY.value, label: LocationTypeEnum.UNIVERSITY.label },
];
