import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Accordion, AccordionDetails, AccordionSummary, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { Edit as EditIcon } from '@mui/icons-material';

import useNotification from '../../../core/hooks/use_notification';
import { deleteDeliveryNotificationNotificationData } from '../../../core/utils/notification_utils';
import { ApiRequestStatusEnum } from '../../../core/enums/common/api';
import { DeleteButton, DuplicateButton } from '../../common/button';
import { getDeliveryNotificationsState } from '../../../features/communication/get_delivery_notifications';
import { deleteDeliveryNotificationAction, deleteDeliveryNotificationState } from '../../../features/communication/delete_delivery_notification';
import { GET_ROUTE_ROUTE_DELIVERY_NOTIFICATIONS_EDIT, ROUTE_DELIVERY_NOTIFICATIONS_NEW } from '../../../core/constants';
import { FormRouteEnum } from '../../../core/enums/common/route';

const DeliveryNotificationsList = ({ refreshList }) => {
    const dispatch = useDispatch();
    const { showNotification } = useNotification();
    const navigate = useNavigate();

    const getDeliveryNotifications = useSelector(getDeliveryNotificationsState);
    const deleteDeliveryNotification = useSelector(deleteDeliveryNotificationState);

    const onDeleteDeliveryNotification = (deliveryNotificationId) => {
        dispatch(deleteDeliveryNotificationAction(deliveryNotificationId))
            .then(response => {
                showNotification(deleteDeliveryNotificationNotificationData(response));
                response?.meta?.requestStatus === ApiRequestStatusEnum.FULFILLED && refreshList();
            });
    };

    return (
        <TableContainer>
            <Table stickyHeader>
                <TableHead>
                    <TableRow>
                        <TableCell>Nazwa robocza</TableCell>
                        <TableCell align="center">Treść</TableCell>
                        <TableCell align="center">Podgląd/edycja</TableCell>
                        <TableCell align="center">Duplikuj</TableCell>
                        <TableCell align="center">Usuń</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        getDeliveryNotifications?.data?.content?.map(deliveryNotification => (
                            <TableRow
                                key={ deliveryNotification?.id }
                                hover
                            >
                                <TableCell>{ deliveryNotification?.workingName }</TableCell>
                                <TableCell>
                                    {
                                        deliveryNotification?.translatedMessages.map(message => (
                                            <Accordion key={ message.languageCode }>
                                                <AccordionSummary>
                                                    <Typography><b>{ message.languageCode }</b> - { message.title }</Typography>
                                                </AccordionSummary>
                                                <AccordionDetails>
                                                    <Typography>{ message.body }</Typography>
                                                </AccordionDetails>
                                            </Accordion>
                                        ))
                                    }
                                </TableCell>
                                <TableCell align="center">
                                    <EditIcon onClick={ () => navigate(GET_ROUTE_ROUTE_DELIVERY_NOTIFICATIONS_EDIT(deliveryNotification?.id), { state: { action: FormRouteEnum.EDIT } }) } cursor="pointer"/>
                                </TableCell>
                                <TableCell align="center">
                                    <DuplicateButton onClick={ () => navigate(ROUTE_DELIVERY_NOTIFICATIONS_NEW, { state: { action: FormRouteEnum.NEW, deliveryNotificationIdToDuplicate: deliveryNotification?.id } }) }/>
                                </TableCell>
                                <TableCell align="center">
                                    <DeleteButton
                                        onDeleteClick={ () => onDeleteDeliveryNotification(deliveryNotification?.id) }
                                        isLoading={ deleteDeliveryNotification?.loading }/>
                                </TableCell>
                            </TableRow>
                        ))
                    }
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default DeliveryNotificationsList;
