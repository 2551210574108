import { deepFreeze } from '../../utils/misc_utils';

export const CouponStatusEnum = deepFreeze({
    ACTIVE: 'ACTIVE',
    DRAFT: 'DRAFT',
    READY_TO_ACTIVE: 'READY_TO_ACTIVE',
    DEACTIVATED: 'DEACTIVATED',
    EXPIRED: 'EXPIRED',
    DELETED: 'DELETED',
    UNKNOWN: 'UNKNOWN'
});

export const CouponTypeEnum = deepFreeze({
    ORDER: 'ORDER',
    MULTI_PACK: 'MULTI_PACK',
    SET_PRODUCT: 'SET_PRODUCT',
    SET_FOOD_TYPE: 'SET_FOOD_TYPE',
    PRODUCT: 'PRODUCT',
    PRODUCT_BATCH: 'PRODUCT_BATCH',
    FOOD_TYPE: 'FOOD_TYPE',
    TYPE_CUISINE: 'TYPE_CUISINE',
    DIET: 'DIET',
    ALLERGEN: 'ALLERGEN',
    ATTRIBUTE: 'ATTRIBUTE',
    UNKNOWN: 'UNKNOWN'
});
