import { CouponStatusEnum } from '../../../core/enums/price/coupon';

export const getCouponStatus = (status) => {
    switch (status) {
        case CouponStatusEnum.ACTIVE:
            return 'Aktywny';
        case CouponStatusEnum.DRAFT:
            return 'Roboczy';
        case CouponStatusEnum.READY_TO_ACTIVE:
            return 'Gotowy do aktywacji';
        case CouponStatusEnum.DEACTIVATED:
            return 'Nieaktywny';
        case CouponStatusEnum.EXPIRED:
            return 'Wygasły';
        case CouponStatusEnum.DELETED:
            return 'Usunięty';
        default:
            return CouponStatusEnum.UNKNOWN;
    }
};

export const getButtonText = (status) => {
    switch (status) {
        case CouponStatusEnum.ACTIVE:
            return 'Dezaktywuj';
        case CouponStatusEnum.READY_TO_ACTIVE:
        case CouponStatusEnum.DEACTIVATED:
            return 'Aktywuj';
        case CouponStatusEnum.DRAFT:
            return 'Uzupełnij kupon';
        default:
            return CouponStatusEnum.UNKNOWN;
    }
};

export const getUpdateCouponStatus = (status) => {
    switch (status) {
        case CouponStatusEnum.ACTIVE:
            return CouponStatusEnum.DEACTIVATED;
        case CouponStatusEnum.READY_TO_ACTIVE:
        case CouponStatusEnum.DEACTIVATED:
            return CouponStatusEnum.ACTIVE;
        default:
            return CouponStatusEnum.UNKNOWN;
    }
};
