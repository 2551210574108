import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import useNotification from '../../../../core/hooks/use_notification';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Grid, LinearProgress } from '@mui/material';
import { Button, SubmitButton } from '../../../common/button';
import { getProducerAction, getProducerState } from "../../../../features/product/get_producer";
import { createProducerNotificationData, getProducerNotificationData, updateProducerNotificationData } from "../../../../core/utils/notification_utils";
import { createProducerAction, createProducerState } from "../../../../features/product/create_producer";
import { updateProducerAction, updateProducerState } from "../../../../features/product/update_producer";
import * as Yup from "yup";
import { ApiRequestStatusEnum } from "../../../../core/enums/common/api";
import { GET_ROUTE_PRODUCER_FORMS, ROUTE_PRODUCERS } from "../../../../core/constants";
import { Form, Formik } from "formik";
import TextFieldFormik from "../../../common/text_field";

const ProducerPage = () => {
    const { showErrorMessage, showNotification } = useNotification();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { producerId } = useParams();

    const getProducer = useSelector(getProducerState);
    const createProducer = useSelector(createProducerState);
    const updateProducer = useSelector(updateProducerState);

    const initialValues = {
        name: (producerId && getProducer?.data?.name) || '',
    }

    const schema = Yup.object().shape({
        name: Yup.string().required()
    });

    useEffect(() => {
        producerId && dispatch(getProducerAction(producerId))
            .then(response => showErrorMessage((getProducerNotificationData(response))));
    }, [producerId, dispatch, showErrorMessage]);

    const onSubmit = (values) => {
        const form = { ...values };

        if (!producerId) {
            dispatch(createProducerAction({ form }))
                .then(response => {
                    showNotification(createProducerNotificationData(response));
                    response?.meta?.requestStatus === ApiRequestStatusEnum.FULFILLED && navigate(GET_ROUTE_PRODUCER_FORMS(response?.payload.id));
                });
        } else {
            dispatch(updateProducerAction({ form, id: producerId }))
                .then(response => {
                    showNotification(updateProducerNotificationData(response));
                });
        }
    }

    const renderView = () => {
        if (getProducer?.loading) {
            return (
                <Box mt={ 6 }>
                    <LinearProgress/>
                </Box>
            );
        }

        return (
            <Formik
                initialValues={ initialValues }
                validationSchema={ schema }
                validateOnChange={ false }
                validateOnBlur={ false }
                onSubmit={ onSubmit }>
                { () =>
                    <Grid component={ Form } container rowGap={ 2 }>
                        <Grid container item>
                            <TextFieldFormik name="name" label="Nazwa" required/>
                        </Grid>
                        <Grid container item mt={ 2 } justifyContent="flex-end">
                            <SubmitButton isLoading={ !producerId ? createProducer?.loading : updateProducer?.loading }>Zapisz</SubmitButton>
                        </Grid>
                    </Grid>
                }
            </Formik>
        );
    }

    return (
        <Grid mb={ 6 }>
            <Grid container mb={ 4 } justifyContent="space-between" rowGap={ 2 }>
                <Grid container item>
                    <Button onClick={ () => navigate(ROUTE_PRODUCERS) }>
                        Wróć
                    </Button>
                </Grid>
            </Grid>
            {
                renderView()
            }
        </Grid>
    );
}

export default ProducerPage;
