import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import moment from 'moment/moment';

import { getProductPromotionsState } from '../../../../../features/price/get_product_promotions';
import { MISC_DATE_ISO_FORMAT, MISC_DATE_POLISH_FORMAT } from '../../../../../core/constants';
import { DeleteButton } from '../../../../common/button';
import { deleteProductPromotionAction, deleteProductPromotionState } from '../../../../../features/price/delete_product_promotion';
import { deletePromotionNotificationData } from '../../../../../core/utils/notification_utils';
import useNotification from '../../../../../core/hooks/use_notification';
import { ApiRequestStatusEnum } from '../../../../../core/enums/common/api';
import { getAllPodsState } from '../../../../../features/pod/get_all_pods';
import { Check as CheckIcon, Clear as ClearIcon } from '@mui/icons-material';

const ProductPromotionsList = ({ refreshList }) => {
    const dispatch = useDispatch();
    const { showNotification } = useNotification();

    const getProductPromotions = useSelector(getProductPromotionsState);
    const getAllPods = useSelector(getAllPodsState);
    const deleteProductPromotion = useSelector(deleteProductPromotionState);

    const onDeleteProductPromotion = (productPromotionId) => {
        dispatch(deleteProductPromotionAction(productPromotionId))
            .then(response => {
                showNotification(deletePromotionNotificationData(response));
                response?.meta?.requestStatus === ApiRequestStatusEnum.FULFILLED && refreshList();
            });
    };

    return (
        <TableContainer>
            <Table stickyHeader>
                <TableHead>
                    <TableRow>
                        <TableCell align="center">Pod</TableCell>
                        <TableCell align="center">Akcja promocyjna</TableCell>
                        <TableCell align="center">Cena bazowa</TableCell>
                        <TableCell align="center">Cena promocyjna</TableCell>
                        <TableCell align="center">Promocja od</TableCell>
                        <TableCell align="center">Promocja do</TableCell>
                        <TableCell align="center">Aktywacja promocji na krótką datę przydatności</TableCell>
                        <TableCell align="center">Usuń</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        getProductPromotions?.data?.content?.map((productPromotion, index) => (
                            <TableRow
                                key={ index }
                                hover
                            >
                                <TableCell align="center">
                                    {
                                        getAllPods?.data?.map(pod => ({ id: pod?.id, label: pod?.indoorPartner?.name + ' (' + pod?.description + ') - ' + (pod?.device?.serviceId || 'brak urządzenia') }))
                                            .find(pod => pod?.id === productPromotion?.podId)?.label
                                    }
                                </TableCell>
                                <TableCell align="center">
                                    {
                                        productPromotion?.isPromotionCampaign
                                            ? <CheckIcon color="success"/>
                                            : <ClearIcon color="error"/>
                                    }
                                </TableCell>
                                <TableCell align="center">{ productPromotion?.basicGrossPrice?.toFixed(2) }</TableCell>
                                <TableCell align="center">{ productPromotion?.promotionGrossPrice?.toFixed(2) }</TableCell>
                                <TableCell align="center">
                                    {
                                        moment(productPromotion?.timeFrom, MISC_DATE_ISO_FORMAT)
                                            .format(MISC_DATE_POLISH_FORMAT)
                                    }
                                </TableCell>
                                <TableCell align="center">
                                    {
                                        moment(productPromotion?.timeTo, MISC_DATE_ISO_FORMAT)
                                            .format(MISC_DATE_POLISH_FORMAT)
                                    }
                                </TableCell>
                                <TableCell align="center">
                                    {
                                        !productPromotion?.excludeExpirationPromotion
                                            ? <CheckIcon color="success"/>
                                            : <ClearIcon color="error"/>
                                    }
                                </TableCell>
                                <TableCell align="center">
                                    {
                                        !productPromotion?.isPromotionCampaign &&
                                        <DeleteButton
                                            onDeleteClick={ () => onDeleteProductPromotion(productPromotion?.productPromotionId) }
                                            isLoading={ deleteProductPromotion?.loading }/>
                                    }
                                </TableCell>
                            </TableRow>
                        ))
                    }
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default ProductPromotionsList;
