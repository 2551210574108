import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { DEFAULT_STATE, STATE__API__FAIL, STATE__API__STARTED, STATE__API__SUCCESS } from '../../core/utils/store_utils';
import { invalidateToken } from '../authorization/get_token';
import webClient from '../../core/web_client';
import { extractErrorData, extractResponseData } from '../../core/utils/api_utils';
import { FOOD_PARTNERS_PATH } from '../../core/constants';

export const deleteDocumentAction = createAsyncThunk(
    'foodPartner/deleteDocumentAction',
    async (data, { fulfillWithValue,rejectWithValue }) => {
        try {
            const response = await webClient.delete(`${ FOOD_PARTNERS_PATH }/${ data.foodPartnerId }/documents/${ data.documentId }`);
            return fulfillWithValue(extractResponseData(response));
        } catch (error) {
            return rejectWithValue(extractErrorData(error));
        }
    }
);

const deleteDocumentSlice = createSlice({
    name: 'deleteDocumentSlice',
    initialState: DEFAULT_STATE,
    reducers: {},
    extraReducers: builder => {
        builder.addCase(deleteDocumentAction.pending, () => STATE__API__STARTED);
        builder.addCase(deleteDocumentAction.fulfilled, (_, { payload }) => STATE__API__SUCCESS(payload));
        builder.addCase(deleteDocumentAction.rejected, (_, { payload }) => STATE__API__FAIL(payload));
        builder.addCase(invalidateToken, () => DEFAULT_STATE);
    }
});

export const deleteDocumentState = (state) => state.foodPartner.deleteDocument;
export default deleteDocumentSlice.reducer;
