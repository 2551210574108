import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { DEFAULT_STATE, STATE__API__FAIL, STATE__API__STARTED, STATE__API__SUCCESS } from '../../core/utils/store_utils';
import { invalidateToken } from '../authorization/get_token';
import webClient from '../../core/web_client';
import { extractErrorData, extractResponseData } from '../../core/utils/api_utils';
import { FOOD_PARTNER_ORDER_PATH } from '../../core/constants';

export const getFoodPartnerOrderAction = createAsyncThunk(
    'inventory/getFoodPartnerOrderAction',
    async (id, { fulfillWithValue, rejectWithValue }) => {
        try {
            const response = await webClient.get(`${ FOOD_PARTNER_ORDER_PATH }/${ id }`);
            return fulfillWithValue(extractResponseData(response));
        } catch (error) {
            return rejectWithValue(extractErrorData(error));
        }
    }
);

const getFoodPartnerOrderSlice = createSlice({
    name: 'getFoodPartnerOrderSlice',
    initialState: DEFAULT_STATE,
    reducers: {},
    extraReducers: builder => {
        builder.addCase(getFoodPartnerOrderAction.pending, () => STATE__API__STARTED);
        builder.addCase(getFoodPartnerOrderAction.fulfilled, (_, { payload }) => STATE__API__SUCCESS(payload));
        builder.addCase(getFoodPartnerOrderAction.rejected, (_, { payload }) => STATE__API__FAIL(payload));
        builder.addCase(invalidateToken, () => DEFAULT_STATE);
    }
});

export const getFoodPartnerOrderState = (state) => state.inventory.getFoodPartnerOrder;
export default getFoodPartnerOrderSlice.reducer;
