import { useCallback, useEffect, useState } from 'react';

const useWindowDimensions = () => {
    const getWindowDimensions = () => {
        const { innerWidth: width, innerHeight: height } = window;
        return {
            width,
            height
        };
    };

    const handleResize = useCallback(() => {
        setWindowDimensions(getWindowDimensions());
    }, []);

    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, [handleResize]);

    return windowDimensions;
};

export default useWindowDimensions;
