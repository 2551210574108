import { combineReducers, createAsyncThunk } from '@reduxjs/toolkit';

import getProducts from './get_products';
import getAllProducts from './get_all_products';
import getProduct from './get_product';
import getProductsForPromotion from './get_products_for_promotion';
import getProductsForReservation from './get_products_for_reservation';
import getProductsForDocument from './get_products_for_document';
import getLanguages from './get_languages';
import getConstants from './get_constants';
import updateProduct from './update_product';
import updateProductDetails from './update_product_details';
import getProducers from './get_producers';
import getAllProducers from './get_all_producers';
import getProducer from './get_producer';
import createProducer from './create_producer';
import updateProducer from './update_producer';
import deleteProducer from './delete_producer';
import { webClientFileDownload } from '../../core/web_client';
import { exportFile, extractErrorDataFromFile, extractResponseData } from '../../core/utils/api_utils';
import { PRODUCTS_PATH } from '../../core/constants';

export const getProductLabel = createAsyncThunk(
    'product/getProductLabel',
    async (data, { fulfillWithValue, rejectWithValue }) => {
        try {
            const response = await webClientFileDownload.get(`${ PRODUCTS_PATH }/${ data.id }/label?productBatchId=${ data.productBatchId }`);
            const fileName = response.headers['content-disposition']?.split('filename=')[1]?.replaceAll("\"", "");
            exportFile(extractResponseData(response), fileName ? fileName : data.title + '.pdf');
            return fulfillWithValue();
        } catch (error) {
            const response = await extractErrorDataFromFile(extractResponseData(error?.response));
            return rejectWithValue(response);
        }
    }
);

export default combineReducers({ getProducts, getAllProducts, getProduct,
    getProductsForReservation, getProductsForPromotion, getProductsForDocument,
    getLanguages, getConstants, updateProduct, updateProductDetails,
    getProducers, getAllProducers, getProducer, createProducer, updateProducer, deleteProducer});
