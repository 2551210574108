import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { Grid, TextField } from '@mui/material';
import _ from 'lodash';

import SelectField from '../../common/select_field';
import { getAllFoodPartnersState } from '../../../features/food_partner/get_all_food_partners';
import { getConstantsState } from '../../../features/product/get_constants';
import { yesNoOptions } from '../../common/form_utils';
import deepmerge from 'deepmerge';

const searchCodeOptions = [
    {
        value: 'all',
        label: 'Wszystkie'
    },
    {
        value: 'updated',
        label: 'Uzupełnione'
    },
    {
        value: 'created',
        label: 'Nie uzupełnione'
    },
    {
        value: 'EN',
        label: 'Nie uzupełnione EN'
    }
];

const ProductFilters = ({ queryParams, setQueryParams }) => {
    const getConstants = useSelector(getConstantsState);
    const getAllFoodPartners = useSelector(getAllFoodPartnersState);

    const [searchCode, setSearchCode] = useState(queryParams?.searchCode || searchCodeOptions[0].value);
    const [foodPartnerId, setFoodPartnerId] = useState(queryParams?.foodPartnerId || 'none');
    const [attributeId, setAttributeId] = useState(queryParams?.attributeId || 'none');
    const [recommended, setRecommended] = useState(queryParams?.recommended || 'none');
    const [defaultPicture, setDefaultPicture] = useState(queryParams?.defaultPicture || 'none');

    const onSearchInputChangeDebounced = _.debounce((query) => setQueryParams(prevState => deepmerge(prevState, { query })), 600);

    const onSelectSearchCodeChange = (event) => {
        setSearchCode(event.target.value);
        setQueryParams(prevState => deepmerge(prevState, { searchCode: event.target.value }));
    };

    const onSelectFoodPartnerChange = (event) => {
        setFoodPartnerId(event.target.value);
        setQueryParams(prevState => deepmerge(prevState, { foodPartnerId: event.target.value }));
    };

    const onSelectAttributeChange = (event) => {
        setAttributeId(event.target.value);
        setQueryParams(prevState => deepmerge(prevState, { attributeId: event.target.value }));
    };

    const onSelectRecommendedChange = (event) => {
        setRecommended(event.target.value);
        setQueryParams(prevState => deepmerge(prevState, { recommended: event.target.value }));
    };

    const onSelectDefaultPictureChange = (event) => {
        setDefaultPicture(event.target.value);
        setQueryParams(prevState => deepmerge(prevState, { defaultPicture: event.target.value }));
    };

    return (
        <Grid container gap={ 2 }>
            <Grid container gap={ 2 }>
                <Grid item flexBasis="45%">
                    <TextField
                        label="Szukaj po nazwie produktu"
                        fullWidth
                        onChange={ event => onSearchInputChangeDebounced(event.target.value) }
                        placeholder={ queryParams?.query }
                        InputLabelProps={ queryParams?.query ? { shrink: true } : undefined }
                    />
                </Grid>
                <Grid item>
                    <SelectField
                        label={ 'Pokaż' }
                        value={ searchCode }
                        onChange={ onSelectSearchCodeChange }
                        options={ searchCodeOptions }
                    />
                </Grid>
                <Grid item>
                    <SelectField
                        label={ 'Tag' }
                        value={ attributeId }
                        onChange={ onSelectAttributeChange }
                        options={ [{ value: 'none', label: 'Nie wybrano' }, ...getConstants?.data?.productConstants?.ATTRIBUTE?.map(productConstant => ({ value: productConstant?.id, label: productConstant?.body }))] }
                    />
                </Grid>
                <Grid item>
                    <SelectField
                        label="Dostawca"
                        value={ foodPartnerId }
                        onChange={ onSelectFoodPartnerChange }
                        options={ [{ value: 'none', label: 'Nie wybrano' }, ...getAllFoodPartners?.data?.map(getFoodPartner => ({ value: getFoodPartner?.id, label: getFoodPartner?.name }))] }
                    />
                </Grid>
            </Grid>
            <Grid container gap={ 2 }>
                <Grid item xs={ 2 }>
                    <SelectField
                        label="Polecany produkt"
                        value={ recommended }
                        onChange={ onSelectRecommendedChange }
                        options={ [{ value: 'none', label: 'Nie wybrano' }, ...yesNoOptions] }
                    />
                </Grid>
                <Grid item xs={ 2 }>
                    <SelectField
                        label="Domyślne zdjęcie"
                        value={ defaultPicture }
                        onChange={ onSelectDefaultPictureChange }
                        options={ [{ value: 'none', label: 'Nie wybrano' }, ...yesNoOptions] }
                    />
                </Grid>
            </Grid>
        </Grid>
    );
};
export default ProductFilters;
