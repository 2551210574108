import { deepFreeze } from '../../utils/misc_utils';

export const EmployerStatusEnum = deepFreeze({
    ACTIVE: 'ACTIVE',
    INACTIVE: 'INACTIVE',
    UNKNOWN: 'UNKNOWN'
});

export const EmployerStatusFormEnum = deepFreeze({
    ACTIVATE: 'ACTIVATE',
    DEACTIVATE: 'DEACTIVATE'
});

export const EmployerReportType = deepFreeze({
    GENERAL: {
        value: 'GENERAL',
        message: 'Raport ogólny'
    },
    EMPLOYEE: {
        value: 'EMPLOYEE',
        message: 'Raport pracowniczy'
    },
})