import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { Grid, TextField } from '@mui/material';
import _ from 'lodash';
import deepmerge from 'deepmerge';

import SelectField from '../../../common/select_field';
import { getConstantsState } from '../../../../features/product/get_constants';
import { getAllFoodPartnersState } from '../../../../features/food_partner/get_all_food_partners';

const filterOptions = [
    {
        value: 'all',
        label: 'Wszystkie'
    },
    {
        value: 'withPromotion',
        label: 'Promocja dodana'
    },
    {
        value: 'withoutPromotion',
        label: 'Promocja nie dodana'
    }
];

const ProductFilters = ({ isProductPromotionList, queryParams, setQueryParams }) => {
    const getConstants = useSelector(getConstantsState);
    const getAllFoodPartners = useSelector(getAllFoodPartnersState);

    const [promotionFilter, setPromotionFilter] = useState(queryParams?.promotionFilter || filterOptions[0].value);
    const [foodTypeId, setFoodTypeId] = useState(queryParams?.foodTypeId || 'none');
    const [foodPartnerId, setFoodPartnerId] = useState(queryParams?.foodPartnerId || 'none');

    const onSearchInputChangeDebounced = _.debounce((value) => onSearchInputChange(value), 600);

    const onSearchInputChange = (query) => {
        setQueryParams(prevState => deepmerge(prevState, { query }));
    };

    const onSelectPromotionFilterChange = (event) => {
        setPromotionFilter(event.target.value);
        setQueryParams(prevState => deepmerge(prevState, { promotionFilter: event.target.value }));
    };

    const onSelectTypeChange = (event) => {
        setFoodTypeId(event.target.value);
        setQueryParams(prevState => deepmerge(prevState, { foodTypeId: event.target.value }));
    };

    const onSelectFoodPartnerChange = (event) => {
        setFoodPartnerId(event.target.value);
        setQueryParams(prevState => deepmerge(prevState, { foodPartnerId: event.target.value }));
    };

    return (
        <Grid container gap={ 2 }>
            <Grid item flexBasis="45%">
                <TextField
                    label="Szukaj po nazwie produktu"
                    fullWidth
                    placeholder={ queryParams?.query }
                    onChange={ event => onSearchInputChangeDebounced(event.target.value) }
                    InputLabelProps={ queryParams?.query ? { shrink: true } : undefined }
                />
            </Grid>
            {
                isProductPromotionList &&
                <Grid item>
                    <SelectField
                        label={ 'Pokaż' }
                        value={ promotionFilter }
                        onChange={ onSelectPromotionFilterChange }
                        options={ filterOptions }
                    />
                </Grid>
            }
            {
                (isProductPromotionList && getConstants?.data) &&
                <Grid item>
                    <SelectField
                        label="Typ produktu"
                        value={ foodTypeId }
                        onChange={ onSelectTypeChange }
                        options={ [{ value: 'none', label: 'Nie wybrano' }, ...getConstants?.data?.productConstants?.FOOD_TYPE?.map(productConstant => ({ value: productConstant?.id, label: productConstant?.body }))] }
                    />
                </Grid>
            }
            {
                (isProductPromotionList && getAllFoodPartners?.data) &&
                <Grid item>
                    <SelectField
                        label="Dostawca"
                        value={ foodPartnerId }
                        onChange={ onSelectFoodPartnerChange }
                        options={ [{ value: 'none', label: 'Nie wybrano' }, ...getAllFoodPartners?.data?.map(foodPartner => ({ value: foodPartner?.id, label: foodPartner?.name }))] }
                    />
                </Grid>
            }
        </Grid>
    );
};
export default ProductFilters;
