import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import webClient from '../../core/web_client';
import { CUSTOMER_PATH } from '../../core/constants';
import { extractErrorData, extractResponseData } from '../../core/utils/api_utils';
import { DEFAULT_STATE, STATE__API__FAIL, STATE__API__STARTED, STATE__API__SUCCESS } from '../../core/utils/store_utils';
import { invalidateToken } from '../authorization/get_token';

export const getCustomersByPhoneNumberAction = createAsyncThunk(
    'customer/getCustomersByPhoneNumberAction',
    async (phoneNumber, { fulfillWithValue, rejectWithValue }) => {
        try {
            const response = await webClient.get(`${ CUSTOMER_PATH }/phoneNumber?phoneNumber=${ phoneNumber }`);
            return fulfillWithValue(extractResponseData(response));
        } catch (error) {
            return rejectWithValue(extractErrorData(error));
        }
    }
);

const getCustomersByPhoneNumberSlice = createSlice({
    name: 'getCustomersByPhoneNumberSlice',
    initialState: DEFAULT_STATE,
    reducers: {
        invalidateCustomersByPhoneNumber() {
            return DEFAULT_STATE;
        }
    },
    extraReducers: builder => {
        builder.addCase(getCustomersByPhoneNumberAction.pending, () => STATE__API__STARTED);
        builder.addCase(getCustomersByPhoneNumberAction.fulfilled, (_, { payload }) => STATE__API__SUCCESS(payload));
        builder.addCase(getCustomersByPhoneNumberAction.rejected, (_, { payload }) => STATE__API__FAIL(payload));
        builder.addCase(invalidateToken, () => DEFAULT_STATE);
    }
});

export const { invalidateCustomersByPhoneNumber } = getCustomersByPhoneNumberSlice.actions;
export const getCustomersByPhoneNumberState = (state) => state.customer.getCustomersByPhoneNumber;
export default getCustomersByPhoneNumberSlice.reducer;