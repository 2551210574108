import React, { useEffect } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import { generateWarehouseDocumentLabelsNotificationData, getPodsNotificationData, getWarehouseDocumentNotificationData } from "../../../../../core/utils/notification_utils";
import { useDispatch, useSelector } from "react-redux";
import useNotification from "../../../../../core/hooks/use_notification";
import { getWarehouseDocumentAction, getWarehouseDocumentState } from "../../../../../features/inventory/get_warehouse_document";
import { Box, Grid, LinearProgress, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from "@mui/material";
import { Button } from "../../../../common/button";
import { MISC_DATE_ISO_FORMAT, MISC_DATE_POLISH_DATE_ONLY_FORMAT, ROUTE_WAREHOUSE_DOCUMENTS } from "../../../../../core/constants";
import { getAllPodsAction } from "../../../../../features/pod/get_all_pods";
import moment from "moment";
import { getAllWarehousesState } from "../../../../../features/inventory/get_all_warehouses";
import { generateWarehouseDocumentLabelsAction, generateWarehouseDocumentLabelsState } from "../../../../../features/inventory/generate_warehouse_document_labels";
import { getWarehouseName } from "../../../../common/utils/warehouse_utils";

const WarehouseDocumentDetailsPage = () => {
    const { showErrorMessage, showNotification } = useNotification();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { warehouseDocumentId } = useParams();

    const getWarehouseDocument = useSelector(getWarehouseDocumentState);
    const getAllWarehouses = useSelector(getAllWarehousesState);
    const generateWarehouseDocumentLabels = useSelector(generateWarehouseDocumentLabelsState);

    useEffect(() => {
        warehouseDocumentId && dispatch(getWarehouseDocumentAction(warehouseDocumentId))
            .then(response => showErrorMessage((getWarehouseDocumentNotificationData(response))));
    }, [warehouseDocumentId, dispatch, showErrorMessage]);

    useEffect(() => {
        dispatch(getAllPodsAction())
            .then(response => showErrorMessage(getPodsNotificationData(response)));
    }, [dispatch, showErrorMessage]);

    const onGenerateWarehouseDocumentLabels = () => {
        dispatch(generateWarehouseDocumentLabelsAction({ id: warehouseDocumentId }))
            .then(response => {
                showNotification(generateWarehouseDocumentLabelsNotificationData(response));
            });
    };


    const renderView = () => {
        if (getWarehouseDocument?.loading) {
            return (
                <Box mt={ 6 }>
                    <LinearProgress/>
                </Box>
            );
        }

        return (
            <Grid container sx={ { paddingTop: '40px !important' } }  item gap={ 2 }>
                <Grid item>
                    <Typography variant="h1">{ getWarehouseDocument?.data?.name }</Typography>
                </Grid>
                <Grid container gap={ 2 }>
                    <Grid item>
                        <TextField fullWidth value={ moment(getWarehouseDocument?.data?.issueDate, MISC_DATE_ISO_FORMAT)?.format(MISC_DATE_POLISH_DATE_ONLY_FORMAT) } label="Data utworzenia" disabled/>
                    </Grid>
                    <Grid item>
                        <TextField fullWidth value={ getWarehouseDocument?.data?.deliveryDate ?
                            moment(getWarehouseDocument?.data?.deliveryDate, MISC_DATE_ISO_FORMAT)?.format(MISC_DATE_POLISH_DATE_ONLY_FORMAT) : '-' } label="Data dostawy" disabled/>
                    </Grid>
                </Grid>
                {
                    getWarehouseDocument?.data?.counterpartyName &&
                    <Grid item xs={8}>
                        <TextField fullWidth value={getWarehouseDocument?.data?.counterpartyName} label="Kontrahent" disabled/>
                    </Grid>
                }
                <Grid item xs={ 8 }>
                    <TextField fullWidth
                               value={ getAllWarehouses?.data?.map(warehouse => ({ value: warehouse?.internalId, label: getWarehouseName(warehouse) }))
                                   .find(warehouse => warehouse?.value === getWarehouseDocument?.data?.warehouseId)?.label }
                               label={ getWarehouseDocument?.data?.documentType === 'MW' ? 'Magazyn źródłowy' : 'Magazyn' } disabled/>
                </Grid>
                {
                    getWarehouseDocument?.data?.documentType === 'MW' &&
                    <Grid item xs={ 8 }>
                        <TextField fullWidth
                                   value={ getAllWarehouses?.data?.map(warehouse => ({ value: warehouse?.internalId, label: getWarehouseName(warehouse) }))
                                       .find(warehouse => warehouse?.value === getWarehouseDocument?.data?.targetWarehouseId)?.label || ''}
                                   label="Magazyn docelowy" disabled/>
                    </Grid>
                }
                <Grid container sx={ { paddingTop: '40px !important' } }  item gap={ 2 }>
                    <Grid container>
                        <Typography variant="h2">Pozycje dokumentu</Typography>
                    </Grid>
                    <TableContainer>
                        <Table stickyHeader>
                            <TableHead>
                                <TableRow>
                                    <TableCell align="center">Produkt</TableCell>
                                    <TableCell align="center">Ilość</TableCell>
                                    <TableCell align="center">Partia</TableCell>
                                    <TableCell align="center">Data ważności</TableCell>
                                    <TableCell align="center">Cena netto</TableCell>
                                    <TableCell align="center">Cena brutto</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    getWarehouseDocument?.data?.positions?.map(item => (
                                        <TableRow
                                            key={ item?.warehouseBatchId }
                                            hover
                                        >
                                            <TableCell>{ item?.product?.name }</TableCell>
                                            <TableCell align="right">{ item?.quantity }</TableCell>
                                            <TableCell align="center">{ item?.productBatchId }</TableCell>
                                            <TableCell align="center">
                                                {
                                                    moment(item?.expiryDate, MISC_DATE_ISO_FORMAT)
                                                        .format(MISC_DATE_POLISH_DATE_ONLY_FORMAT)
                                                }
                                            </TableCell>
                                            <TableCell align="right">{ item?.netPrice?.toFixed(2) }</TableCell>
                                            <TableCell align="right">{ item?.grossPrice?.toFixed(2) }</TableCell>
                                        </TableRow>
                                    ))
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid>
        );
    }

    return (
        <Grid mb={ 6 }>
            <Grid container justifyContent="space-between" rowGap={ 2 }>
                <Grid item>
                    <Button onClick={ () => navigate(ROUTE_WAREHOUSE_DOCUMENTS) }>
                        Wróć
                    </Button>
                </Grid>
                <Grid justifyContent="flex-end">
                    {
                        <Button
                            isLoading={ generateWarehouseDocumentLabels?.loading }
                            onClick={ onGenerateWarehouseDocumentLabels }>
                            Pobierz naklejki
                        </Button>
                    }
                </Grid>
            </Grid>
            {
                renderView()
            }
        </Grid>
    );

}

export default WarehouseDocumentDetailsPage;