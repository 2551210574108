import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Grid, LinearProgress } from '@mui/material';

import useNotification from '../../../../../../core/hooks/use_notification';
import { getAllFoodPartnersAction, getAllFoodPartnersState } from '../../../../../../features/food_partner/get_all_food_partners';
import { getAllFoodPartnersNotificationData } from '../../../../../../core/utils/notification_utils';
import GeneralDataForm from './general_data_form';
import FormWithoutLocationDivision from './form_without_location_division';
import FormWithLocationDivision from './form_with_location_division';
import Summary from './summary';

const FoodPartnerOrderCreateForm = () => {
    const dispatch = useDispatch();
    const { showErrorMessage } = useNotification();

    const getFoodPartners = useSelector(getAllFoodPartnersState);

    const [view, setView] = useState(0);
    const [form, setForm] = useState({});
    const [data, setData] = useState({});

    useEffect(() => {
        dispatch(getAllFoodPartnersAction())
            .then(response => showErrorMessage(getAllFoodPartnersNotificationData(response)));

    }, [dispatch, showErrorMessage]);

    const onSubmitGeneralData = (values) => {
        const locationDivision = values?.locationDivision;
        setForm(values);

        if (locationDivision) {
            setView(2);
        } else {
            setView(1);
        }
    };

    const renderView = () => {
        if (getFoodPartners?.loading) {
            return (
                <Grid mt={ 6 } item width={ '100%' }>
                    <LinearProgress/>
                </Grid>
            );
        }

        if (getFoodPartners?.data) {
            if (view === 1) {
                return (
                    <FormWithoutLocationDivision
                        form={ form }
                        setView={ setView }
                        data={ data }
                        setData={ setData }/>
                );
            } else if (view === 2) {
                return (
                    <FormWithLocationDivision
                        form={ form }
                        setView={ setView }
                        data={ data }
                        setData={ setData }/>
                );
            } else if (view === 3) {
                return (
                    <Summary setView={ setView } data={ data }/>
                );
            } else {
                return (
                    <GeneralDataForm
                        form={ form }
                        onSubmitGeneralData={ onSubmitGeneralData }/>
                );
            }
        }

        return <></>;
    };

    return (
        <Grid container mb={ 6 } pl={ 15 } pr={ 15 }>
            {
                renderView()
            }
        </Grid>
    );
};

export default FoodPartnerOrderCreateForm;
