import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useNotification from '../../../../../core/hooks/use_notification';
import { Box, Grid, LinearProgress, Typography } from '@mui/material';
import { getContactPersonsNotificationData } from '../../../../../core/utils/notification_utils';
import { getContactPersonsAction, getContactPersonsState } from '../../../../../features/food_partner/get_contact_persons';
import ContactPersonDialog from './contact_person_dialog';
import ContactPersonsList from './contact_persons_list';

const ContactPersonsForm = ({ foodPartnerId }) => {
    const dispatch = useDispatch();
    const { showErrorMessage } = useNotification();

    const getContactPersons = useSelector(getContactPersonsState);

    const refreshList = useCallback(() => {
        dispatch(getContactPersonsAction(foodPartnerId))
            .then(response => showErrorMessage(getContactPersonsNotificationData(response)));
    }, [dispatch, foodPartnerId, showErrorMessage]);

    useEffect(() => {
        refreshList();
    }, [dispatch, refreshList, showErrorMessage]);

    return (
        <Grid>
            <Box mb={ 4 }>
                <Typography variant="h1">Osoby kontaktowe</Typography>
            </Box>
            <Box mb={ 2 }>
                <ContactPersonDialog foodPartnerId={ foodPartnerId } refreshList={ refreshList }/>
            </Box>
            {
                getContactPersons?.loading &&
                <LinearProgress/>
            }
            {
                getContactPersons?.data &&
                <ContactPersonsList foodPartnerId={ foodPartnerId } refreshList={ refreshList }/>
            }
        </Grid>
    );
}

export default ContactPersonsForm;
