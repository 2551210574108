import { deepFreeze } from '../../utils/misc_utils';

export const BenefitSuccessMessageEnum = deepFreeze({
    CREATE_REPORT: 'Pomyślnie uwtorzono raport',
    UPDATE_EMPLOYER: 'Pomyślnie zaktualizowano pracodawcę',
    UPDATE_EMPLOYER_STATUS: 'Pomyślnie zaktualizowano status pracodawcy',
    UPDATE_EMPLOYER_DISCOUNT: 'Pomyślnie zaktualizowano obniżkę pracodawcy',
    DELETE_EMPLOYER_DISCOUNT: 'Pomyślnie usunięto obniżkę pracodawcy',
    UPDATE_EMPLOYEE: 'Pomyślnie zaktualizowano pracownika',
});

export const BenefitErrorMessageEnum = deepFreeze({
    GET_EMPLOYERS: 'Pobranie pracodawców nie powiodło się',
    GET_ALL_EMPLOYER: 'Pobranie pracodawców nie powiodło się',
    GET_EMPLOYER: 'Pobranie pracodawcy nie powiodło się',
    GET_EMPLOYER_DISCOUNT: 'Pobranie obniżki pracodawcy nie powiodło się',
    UPDATE_EMPLOYER_DISCOUNT: 'Aktualizacja obnizki pracodawcy nie powiodła się',
    DELETE_EMPLOYER_DISCOUNT: 'Usunięcie obnizki pracodawcy nie powiodło się',
    CREATE_REPORT: 'Uwtorzenie raportu nie powiodło się',
    UPDATE_EMPLOYER: 'Aktualizacja pracodawcy nie powiodła się',
    UPDATE_EMPLOYER_STATUS: 'Aktualizacja statusu pracodawcy nie powiodła się',
    GET_EMPLOYEES: 'Pobranie pracowników nie powiodło się',
    GET_EMPLOYEES_TO_CONFIRM: 'Pobranie pracowników nie powiodło się',
    UPDATE_EMPLOYEE: 'Aktualizacja pracownika nie powiodła się',
});

const BenefitServerErrorMessageEnum = deepFreeze({});

export const getBenefitErrorMessage = (message, defaultErrorMessage) => {
    for (let key in BenefitServerErrorMessageEnum) {
        if (BenefitServerErrorMessageEnum.hasOwnProperty(key)) {
            if (message?.substring(0, message?.indexOf(' ')) === BenefitServerErrorMessageEnum[key].errorCode) {
                return BenefitServerErrorMessageEnum[key].message;
            }
        }
    }

    return defaultErrorMessage;
};
