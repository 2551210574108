import React, { useState } from 'react';

import { Grid, Tab, Tabs } from '@mui/material';
import { tabsClasses } from '@mui/material/Tabs';

import PodPriceAssignmentList from './pod_price_assignment_list';
import Settings from './settings';

const PricesPage = () => {
    const [tab, setTab] = useState(0);

    const renderView = () => {
        if (tab === 0) {
            return <PodPriceAssignmentList/>;
        }

        return <Settings/>;
    };

    return (
        <Grid mb={ 6 }>
            <Grid container mb={ 4 } rowGap={ 4 } justifyContent="space-between">
                <Grid container item>
                    <Tabs
                        value={ tab }
                        onChange={ (_, value) => setTab(value) }
                        variant="scrollable"
                        scrollButtons
                        sx={ {
                            [`& .${ tabsClasses.scrollButtons }`]: {
                                '&.Mui-disabled': { opacity: 0.3 },
                            },
                        } }
                    >
                        <Tab label="Przyporządkowanie cenników"/>
                        <Tab label="Ustawienia"/>
                    </Tabs>
                </Grid>
            </Grid>
            {
                renderView()
            }
        </Grid>
    );
};

export default PricesPage;