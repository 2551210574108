import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import webClient from '../../core/web_client';
import { extractErrorData, extractResponseData } from '../../core/utils/api_utils';
import { DEFAULT_STATE, STATE__API__FAIL, STATE__API__STARTED, STATE__API__SUCCESS } from '../../core/utils/store_utils';
import { invalidateToken } from '../authorization/get_token';
import { MISC_REST_CONTENT_TYPE_APPLICATION_JSON, MISC_REST_CONTENT_TYPE_MULTIPART, WAREHOUSE_DOCUMENTS_PATH } from '../../core/constants';

export const createAndSendWarehouseDocumentAction = createAsyncThunk(
    'inventory/createAndSendWarehouseDocumentAction',
    async (data, { fulfillWithValue, rejectWithValue }) => {
        try {
            let body = new FormData();
            const formJson = JSON.stringify(data.form);
            const formBlob = new Blob([formJson], {
                type: MISC_REST_CONTENT_TYPE_APPLICATION_JSON
            });

            body.append('form', formBlob);

            for (const attachment of data?.attachments) {
                body.append('attachments', attachment);
            }

            const response = await webClient.post(`${ WAREHOUSE_DOCUMENTS_PATH }/generate-and-send`, body, {
                headers: {
                    'content-type': MISC_REST_CONTENT_TYPE_MULTIPART
                }
            });
            return fulfillWithValue(extractResponseData(response));
        } catch (error) {
            return rejectWithValue(extractErrorData(error));
        }
    }
);

const createAndSendWarehouseDocumentSlice = createSlice({
    name: 'createAndSendWarehouseDocumentSlice',
    initialState: DEFAULT_STATE,
    reducers: {},
    extraReducers: builder => {
        builder.addCase(createAndSendWarehouseDocumentAction.pending, () => STATE__API__STARTED);
        builder.addCase(createAndSendWarehouseDocumentAction.fulfilled, (_, { payload }) => STATE__API__SUCCESS(payload));
        builder.addCase(createAndSendWarehouseDocumentAction.rejected, (_, { payload }) => STATE__API__FAIL(payload));
        builder.addCase(invalidateToken, () => DEFAULT_STATE);
    }
});

export const createAndSendWarehouseDocumentState = (state) => state.inventory.createAndSendWarehouseDocument;
export default createAndSendWarehouseDocumentSlice.reducer;
