import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Form, Formik } from 'formik';
import { Box, Grid } from '@mui/material';
import * as Yup from 'yup';

import TextFieldFormik from '../../common/text_field';
import { SubmitButton } from '../../common/button';
import { getTokenAction, getTokenState } from '../../../features/authorization/get_token';
import useNotification from '../../../core/hooks/use_notification';
import { getLanguagesNotificationData, getPodsNotificationData, getTokenNotificationData } from '../../../core/utils/notification_utils';
import { ApiRequestStatusEnum } from '../../../core/enums/common/api';
import { getLanguagesAction } from '../../../features/product/get_languages';
import { getAllPodsAction } from '../../../features/pod/get_all_pods';

const initialValues = { username: '', password: '' };

export const SignInPage = () => {
    const dispatch = useDispatch();
    const getToken = useSelector(getTokenState);
    const { showNotification, showErrorMessage } = useNotification();

    const onSubmit = (values) => {
        dispatch(getTokenAction({ username: values.username, password: values.password }))
            .then(response => {
                showNotification(getTokenNotificationData(response));

                if (response?.meta?.requestStatus === ApiRequestStatusEnum.FULFILLED) {
                    dispatch(getLanguagesAction())
                        .then(response => showErrorMessage(getLanguagesNotificationData(response)));
                    dispatch(getAllPodsAction())
                        .then(response => showErrorMessage(getPodsNotificationData(response)));
                }
            });
    };

    const schema = Yup.object().shape({
        username: Yup.string().required(),
        password: Yup.string().required(),
    });

    return (
        <Box display="flex" justifyContent="center" mt={ 20 }>
            <Formik
                initialValues={ initialValues }
                validationSchema={ schema }
                validateOnBlur={ false }
                validateOnChange={ false }
                onSubmit={ onSubmit }>
                <Grid container justifyContent="center">
                    <Grid item xs={ 3 }>
                        <Form>
                            <TextFieldFormik name="username" label="Login" required/>
                            <Box mt={ 2 }/>
                            <TextFieldFormik name="password" label="Hasło" type="password" required/>
                            <Box mt={ 2 }/>
                            <SubmitButton isLoading={ getToken?.loading }>Zaloguj</SubmitButton>
                        </Form>
                    </Grid>
                </Grid>
            </Formik>
        </Box>
    );
};

export default SignInPage;
