import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { Card, CardContent, Grid, Typography } from '@mui/material';
import { Form, Formik } from 'formik';
import moment from 'moment/moment';

import ThreeLevelSteps from '../../../common/warehouse_and_food_partner_order_form/three_level_steps';
import AdditionalOptions from './common/additional_options';
import AddProductsDialog from '../../../common/warehouse_and_food_partner_order_form/add_products_dialog';
import ProductsList from './common/products_list';
import { getFoodPartnerNotificationData } from '../../../../../core/utils/notification_utils';
import { MISC_DATE_ISO_DATE_ONLY_FORMAT } from '../../../../../core/constants';
import useNotification from '../../../../../core/hooks/use_notification';
import { getFoodPartnerAction } from '../../../../../features/food_partner/get_food_partner';
import Buttons from '../../../common/warehouse_and_food_partner_order_form/buttons';
import UploadFiles from '../../../common/warehouse_and_food_partner_order_form/upload_files';

const PZForm = ({ form, setView, data, setData }) => {
    const dispatch = useDispatch();
    const { showErrorMessage } = useNotification();

    useEffect(() => {
        dispatch(getFoodPartnerAction(form?.foodPartnerId))
            .then(response => showErrorMessage(getFoodPartnerNotificationData(response)));
    }, [dispatch, form, showErrorMessage]);

    const initialValues = {
        productsList: data?.formik?.productsList || [],
        attachments: data?.formik?.attachments || [],
    };

    const onReturn = () => {
        setData({});
        setView(0);
    };

    const onChangeSetDate = (newDate, allDates, formik) => {
        const productsList = formik?.values?.productsList[0]?.map(item => {
            if (!allDates && item?.expirationDate) {
                return item;
            }

            return ({ ...item, expirationDate: newDate });
        });

        formik.setFieldValue(`productsList.${ 0 }`, productsList);
    };

    const onCopyHistoricalDocument = (values, formik) => {
        const productsList = [];

        values?.forEach((product, index) => {
            productsList.push({
                position: index,
                name: product?.productName,
                productId: product?.productId,
                productBatchId: '',
                quantity: product?.quantity,
                expirationDate: product?.defaultDaysToExpiration ? moment(form?.deliveryDate).add(Math.max(product?.defaultDaysToExpiration - 1, 0), 'days') : '',
                price: product?.purchaseNetPrice || '',
                type: product?.type
            });
        });

        formik.setFieldValue(`productsList.${ 0 }`, productsList);
    };

    const onSubmit = (formik) => {
        const errors = validate(formik.values);

        if (errors.productsList.length ||
            errors.products.length) {
            formik.setErrors(errors);
            return;
        }

        const documents = [{
            documentProducts: [
                ...formik?.values?.productsList?.[0]?.map(item => ({
                    productId: item?.productId,
                    quantity: item?.quantity,
                    expirationDate: item?.expirationDate?.format(MISC_DATE_ISO_DATE_ONLY_FORMAT),
                    price: item?.price
                }))
            ]
        }];

        setData({
            form: { ...form, deliveryDate: form?.deliveryDate?.format(MISC_DATE_ISO_DATE_ONLY_FORMAT), documents, attachments: formik?.values?.attachments || [] },
            formik: formik?.values
        });
        setView(5);
    };

    const validate = (values) => {
        const errors = {
            productsList: [],
            products: []
        };

        if (values.productsList.length === 0) {
            errors.productsList[0] = 'Wybierz minimum 1 produkt';
        } else {
            values.productsList.forEach((products, index) => {
                if (products.length === 0) {
                    errors.productsList[index] = 'Wybierz minimum 1 produkt';
                } else {
                    products.forEach((item, itemIndex) => {
                        if (!item?.quantity) {
                            const oldValues = errors.products[index];
                            errors.products[index] = {
                                ...oldValues,
                                [itemIndex]: {
                                    ...errors.products?.[index]?.[itemIndex],
                                    quantity: 'Pole wymagane'
                                }
                            };
                        } else {
                            if (item?.quantity <= 0) {
                                const oldValues = errors.products[index];
                                errors.products[index] = {
                                    ...oldValues,
                                    [itemIndex]: {
                                        ...errors.products?.[index]?.[itemIndex],
                                        quantity: 'Proszę podać wartość całkowitą dodatnią'
                                    }
                                };
                            }
                        }

                        if (!item?.expirationDate) {
                            const oldValues = errors.products[index];
                            errors.products[index] = {
                                ...oldValues,
                                [itemIndex]: {
                                    ...errors.products?.[index]?.[itemIndex],
                                    expirationDate: 'Pole wymagane'
                                }
                            };
                        }

                        if (!item?.price) {
                            const oldValues = errors.products[index];
                            errors.products[index] = {
                                ...oldValues,
                                [itemIndex]: {
                                    ...errors.products?.[index]?.[itemIndex],
                                    price: 'Pole wymagane'
                                }
                            };
                        } else {
                            if (item?.price <= 0) {
                                const oldValues = errors.products[index];
                                errors.products[index] = {
                                    ...oldValues,
                                    [itemIndex]: {
                                        ...errors.products?.[index]?.[itemIndex],
                                        price: 'Proszę podać wartość dodatnią'
                                    }
                                };
                            }
                        }
                    });
                }
            });
        }

        return errors;
    };

    return (
        <Formik initialValues={ initialValues }>
            { (formik) =>
                <Grid component={ Form } container rowGap={ 2 } paddingTop={ 2 }>
                    <ThreeLevelSteps secondStep/>
                    <AdditionalOptions
                        onChangeSetDate={ (newDate, allDates) => onChangeSetDate(newDate, allDates, formik) }
                        form={ form }
                        onCopyHistoricalDocument={ (values) => onCopyHistoricalDocument(values, formik) }/>
                    <Card sx={ { width: '100%', backgroundColor: '#f5f5f5' } }>
                        <CardContent>
                            <Typography variant="h4" mb={ 5 }>
                                Magazyn Główny
                            </Typography>
                            <ProductsList
                                formik={ formik }
                                productsListIndex={ 0 }/>
                            <AddProductsDialog
                                foodPartnerId={ form?.foodPartnerId }
                                formik={ formik }
                                dateToCalculateExpirationDate={ form?.deliveryDate }
                                productsListIndex={ 0 }
                            />
                        </CardContent>
                    </Card>
                    <UploadFiles formik={ formik } addAndRemoveOptions/>
                    <Buttons
                        onReturn={ onReturn }
                        onSubmit={ () => onSubmit(formik) }
                    />
                </Grid>
            }
        </Formik>
    );
};

export default PZForm;
