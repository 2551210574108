import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';

import { getSuppliersState } from '../../../features/store/get_suppliers';
import { getButtonText, getSupplierStatusIcon, getUpdateSupplierStatus } from '../../common/utils/supplier_utils';
import { SubmitButton } from '../../common/button';
import { getNewSupplierKeyNotificationData, getSupplierKeyNotificationData, updateSupplierStatusNotificationData } from '../../../core/utils/notification_utils';
import { ApiRequestStatusEnum } from '../../../core/enums/common/api';
import useNotification from '../../../core/hooks/use_notification';
import { updateSupplierStatusAction, updateSupplierStatusState } from '../../../features/store/update_supplier_status';
import { getSupplierKeyAction, getSupplierKeyState } from '../../../features/store/get_supplier_key';
import { getNewSupplierKeyAction, getNewSupplierKeyState } from '../../../features/store/get_new_supplier_key';
import { GET_ROUTE_SUPPLIERS_EDIT } from '../../../core/constants';
import ConfirmDialog from '../common/confirm_dialog';

const SuppliersList = ({ refreshList }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { showNotification, showErrorMessage } = useNotification();

    const getSuppliers = useSelector(getSuppliersState);
    const updateSupplierStatus = useSelector(updateSupplierStatusState);
    const getSupplierKey = useSelector(getSupplierKeyState);
    const getNewSupplierKey = useSelector(getNewSupplierKeyState);

    const onUpdateSupplierStatus = (supplier) => {
        const command = getUpdateSupplierStatus(supplier?.active);

        dispatch(updateSupplierStatusAction({ id: supplier?.id, form: { command } }))
            .then(response => {
                showNotification(updateSupplierStatusNotificationData(response));
                response?.meta?.requestStatus === ApiRequestStatusEnum.FULFILLED && refreshList();
            });
    };

    const onGetSupplierKey = (supplier) => {
        dispatch(getSupplierKeyAction(supplier?.id))
            .then(response => showErrorMessage(getSupplierKeyNotificationData(response)));
    };

    const onGetNewSupplierKey = (supplier) => {
        dispatch(getNewSupplierKeyAction(supplier?.id))
            .then(response => showErrorMessage(getNewSupplierKeyNotificationData(response)));
    };

    return (
        <TableContainer>
            <Table stickyHeader>
                <TableHead>
                    <TableRow>
                        <TableCell>Imię</TableCell>
                        <TableCell align="center">Nazwisko</TableCell>
                        <TableCell align="center">Nr telefonu</TableCell>
                        <TableCell align="center">Email</TableCell>
                        <TableCell align="center">Aktywny</TableCell>
                        <TableCell align="center">Podgląd/edycja</TableCell>
                        <TableCell align="center">Zmiana statusu</TableCell>
                        <TableCell align="center">Pobierz kod QR</TableCell>
                        <TableCell align="center">Wygeneruj nowy kod QR</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        getSuppliers?.data?.content?.map(supplier => (
                            <TableRow
                                key={ supplier?.id }
                                hover
                            >
                                <TableCell>{ supplier?.name }</TableCell>
                                <TableCell align="center">{ supplier?.surname }</TableCell>
                                <TableCell align="center">{ supplier?.phoneNumber }</TableCell>
                                <TableCell align="center">{ supplier?.email }</TableCell>
                                <TableCell align="center">
                                    {
                                        getSupplierStatusIcon(supplier?.active)
                                    }
                                </TableCell>
                                <TableCell align="center">
                                    <EditIcon onClick={ () => navigate(GET_ROUTE_SUPPLIERS_EDIT(supplier?.id)) } cursor="pointer"/>
                                </TableCell>
                                <TableCell align="center">
                                    <SubmitButton onClick={ () => onUpdateSupplierStatus(supplier) } isLoading={ updateSupplierStatus?.loading }>
                                        {
                                            getButtonText(supplier?.active)
                                        }
                                    </SubmitButton>
                                </TableCell>
                                <TableCell align="center">
                                    <SubmitButton onClick={ () => onGetSupplierKey(supplier) } isLoading={ getSupplierKey?.loading }>
                                        Pobierz
                                    </SubmitButton>
                                </TableCell>
                                <TableCell align="center">
                                    <ConfirmDialog
                                        isLoading={ getNewSupplierKey?.loading }
                                        maxWidth="sm"
                                        title="Czy na pewno chcesz wygenerować nowy kod zaopatrzeniowca? Stary kod przestanie działać i nie będzie można go przywrócić."
                                        buttonText="Wygeneruj"
                                        secondaryAction={ () => {} }
                                        secondaryText="Anuluj"
                                        primaryAction={ () => onGetNewSupplierKey(supplier) }
                                        primaryText="Wygeneruj kod"/>
                                </TableCell>
                            </TableRow>
                        ))
                    }
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default SuppliersList;
