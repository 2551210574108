import React from 'react';

import { Grid } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import ProductAvailabilityFormDialog from './form/product_availability_form_dialog';
import DeleteProductAvailabilityFormDialog from './form/delete_product_availability_form_dialog';

const CalendarFilters = ({ from, onChangeTimeFrom, to, onChangeTimeTo, refreshCalendar }) => (
    <Grid container gap={ 4 } mb={ 4 } justifyContent="space-between">
        <Grid item>
            <Grid container gap={ 4 }>
                <DatePicker
                    displayWeekNumber
                    value={ from }
                    label="Data od"
                    onChange={ newDate => onChangeTimeFrom(newDate) }
                />
                <DatePicker
                    displayWeekNumber
                    value={ to }
                    label="Data do"
                    onChange={ newDate => onChangeTimeTo(newDate) }
                />
            </Grid>
        </Grid>
        <Grid item>
            <Grid container gap={ 4 }>
                <ProductAvailabilityFormDialog refreshCalendar={ refreshCalendar }/>
                <DeleteProductAvailabilityFormDialog refreshCalendar={ refreshCalendar }/>
            </Grid>
        </Grid>
    </Grid>
);

export default CalendarFilters;
