import React, { Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import moment from 'moment/moment';
import { extendMoment } from 'moment-range';

import { getProductsAvailabilityState } from '../../../../../features/reservation/get_products_availability';
import { DeleteButton } from '../../../../common/button';
import { isEmptyArray, isNullOrUndefined } from '../../../../../core/utils/misc_utils';
import useNotification from '../../../../../core/hooks/use_notification';
import { deleteProductAvailabilityAction, deleteProductAvailabilityState } from '../../../../../features/reservation/delete_product_availability';
import { deleteProductAvailabilityNotificationData } from '../../../../../core/utils/notification_utils';
import { ApiRequestStatusEnum } from '../../../../../core/enums/common/api';
import { MISC_DATE_ISO_DATE_ONLY_FORMAT, MISC_DATE_POLISH_DATE_ONLY_FORMAT } from '../../../../../core/constants';
import UpdateProductQuantityLimitDialog from './form/update_product_quantity_limit_dialog';
import ImportProductAvailabilityFormDialog from './form/import_product_availability_form_dialog';

const CalendarList = ({ from, to, refreshCalendar }) => {
    const dispatch = useDispatch();
    const { showNotification } = useNotification();

    const getProductsAvailability = useSelector(getProductsAvailabilityState);
    const deleteProductAvailability = useSelector(deleteProductAvailabilityState);

    const onDeleteProduct = (productId, date) => {
        dispatch(deleteProductAvailabilityAction({ productId, fromDate: date, toDate: date }))
            .then(response => {
                showNotification(deleteProductAvailabilityNotificationData(response));
                response?.meta?.requestStatus === ApiRequestStatusEnum.FULFILLED && refreshCalendar();
            });
    };

    const displayDates = () => {
        const momentRange = extendMoment(moment);

        return Array.from(momentRange.range(from, to).by('day'))
            .map(date => date.format(MISC_DATE_ISO_DATE_ONLY_FORMAT))
            .map(date => (
                <TableRow key={ date }>
                    <TableCell colSpan={ 6 } align="center">
                        <h3>
                            {
                                moment(date).format(MISC_DATE_POLISH_DATE_ONLY_FORMAT)
                            }
                            <ImportProductAvailabilityFormDialog
                                refreshCalendar={ refreshCalendar }
                                targetDate={ date }
                            />
                        </h3>
                    </TableCell>
                </TableRow>
            ));
    };

    return (
        <Grid container>
            <TableContainer>
                <Table stickyHeader>
                    <TableHead>
                        <TableRow>
                            <TableCell>Nazwa</TableCell>
                            <TableCell align="center">Dostawca</TableCell>
                            <TableCell align="center">Limit rezerwacji</TableCell>
                            <TableCell align="center">Ilość zarezerwowana</TableCell>
                            <TableCell align="center">Edytuj limit</TableCell>
                            <TableCell align="center">Usuń</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            getProductsAvailability?.data?.map((productsAvailability, index) => (
                                <Fragment key={ index }>
                                    <TableRow key={ productsAvailability?.date }>
                                        <TableCell colSpan={ 6 } align="center">
                                            <h3>
                                                {
                                                    moment(productsAvailability?.date, MISC_DATE_ISO_DATE_ONLY_FORMAT)
                                                        .format(MISC_DATE_POLISH_DATE_ONLY_FORMAT)
                                                }
                                                <ImportProductAvailabilityFormDialog
                                                    refreshCalendar={ refreshCalendar }
                                                    targetDate={ productsAvailability?.date }
                                                />
                                            </h3>
                                        </TableCell>
                                    </TableRow>
                                    {
                                        productsAvailability?.products?.map((product) => (
                                            <TableRow
                                                key={ product?.productName + productsAvailability?.date }
                                                hover
                                            >
                                                <TableCell>
                                                    {
                                                        product?.productName
                                                    }
                                                </TableCell>
                                                <TableCell align="center">
                                                    {
                                                        product?.foodPartners?.map(foodPartner =>  foodPartner?.name).join(", ")
                                                    }
                                                </TableCell>
                                                <TableCell align="center">
                                                    {
                                                        isNullOrUndefined(product?.generalQuantityLimit)
                                                            ? '-'
                                                            : product?.generalQuantityLimit
                                                    }
                                                </TableCell>
                                                <TableCell align="center">
                                                    {
                                                        isNullOrUndefined(product?.quantityReserved)
                                                            ? 0
                                                            : product?.quantityReserved
                                                    }
                                                </TableCell>
                                                <TableCell align="center">
                                                    <UpdateProductQuantityLimitDialog
                                                        product={ product }
                                                        date={ productsAvailability?.date }
                                                        refreshCalendar={ refreshCalendar }
                                                    />
                                                </TableCell>
                                                <TableCell align="center">
                                                    <DeleteButton onDeleteClick={ () => onDeleteProduct(product?.productId, productsAvailability?.date) } isLoading={ deleteProductAvailability?.loading }/>
                                                </TableCell>
                                            </TableRow>
                                        ))
                                    }
                                </Fragment>
                            ))
                        }
                        {
                            isEmptyArray(getProductsAvailability?.data) && displayDates()
                        }
                    </TableBody>
                </Table>
            </TableContainer>
        </Grid>
    );
};

export default CalendarList;
