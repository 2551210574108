import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';

import { GET_ROUTE_AUTOMATIC_COUPON_SETTING_FORMS } from '../../../core/constants';
import { SubmitButton } from '../../common/button';
import { getAutomaticCouponSettingsState } from '../../../features/coupon/get_automatic_coupon_settings';
import { getAutomaticCouponType } from '../../../core/enums/price/automatic_coupon_setting';
import { updateAutomaticCouponSettingStatusNotificationData } from '../../../core/utils/notification_utils';
import { ApiRequestStatusEnum } from '../../../core/enums/common/api';
import { updateAutomaticCouponSettingStatusAction, updateAutomaticCouponSettingStatusState } from '../../../features/coupon/update_automatic_coupon_setting_status';
import useNotification from '../../../core/hooks/use_notification';

const AutomaticCouponSettingList = ({ refreshAutomaticCouponSettingList }) => {
    const { showNotification } = useNotification();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const getAutomaticCouponSettings = useSelector(getAutomaticCouponSettingsState);
    const updateAutomaticCouponSettingStatus = useSelector(updateAutomaticCouponSettingStatusState);

    const onUpdateAutomaticCouponSettingStatus = (automaticCouponType) => {
        dispatch(updateAutomaticCouponSettingStatusAction(automaticCouponType))
            .then(response => {
                showNotification(updateAutomaticCouponSettingStatusNotificationData(response));
                response?.meta?.requestStatus === ApiRequestStatusEnum.FULFILLED && refreshAutomaticCouponSettingList();
            });
    };

    return (
        <TableContainer>
            <Table stickyHeader>
                <TableHead>
                    <TableRow>
                        <TableCell>Typ kuponu</TableCell>
                        <TableCell align="center">Status</TableCell>
                        <TableCell align="center">Zmiana statusu</TableCell>
                        <TableCell align="center">Edycja</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        getAutomaticCouponSettings?.data?.map(automaticCouponSetting => (
                            <TableRow
                                key={ automaticCouponSetting?.automaticCouponType }
                                hover
                            >
                                <TableCell>
                                    {
                                        getAutomaticCouponType(automaticCouponSetting?.automaticCouponType)?.message
                                    }
                                </TableCell>
                                <TableCell align="center">
                                    {
                                        automaticCouponSetting?.active
                                            ? 'Aktywny'
                                            : 'Nieaktywny'
                                    }
                                </TableCell>
                                <TableCell align="center">
                                    <SubmitButton onClick={ () => onUpdateAutomaticCouponSettingStatus(automaticCouponSetting?.automaticCouponType) } isLoading={ updateAutomaticCouponSettingStatus?.loading }>
                                        {
                                            automaticCouponSetting?.active
                                                ? 'Dezaktywuj'
                                                : 'Aktywuj'
                                        }
                                    </SubmitButton>
                                </TableCell>
                                <TableCell align="center">
                                    <EditIcon onClick={ () => navigate(GET_ROUTE_AUTOMATIC_COUPON_SETTING_FORMS(automaticCouponSetting?.automaticCouponType)) } cursor="pointer"/>
                                </TableCell>
                            </TableRow>
                        ))
                    }
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default AutomaticCouponSettingList;
