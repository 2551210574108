import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Box, FormHelperText, Grid, LinearProgress, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { useSnackbar } from 'notistack';

import useNotification from '../../../../core/hooks/use_notification';
import { getPodsNotificationData } from '../../../../core/utils/notification_utils';
import { getAllPodsAction, getAllPodsState } from '../../../../features/pod/get_all_pods';
import { isEmptyObject } from '../../../../core/utils/misc_utils';
import { Button } from '../../../common/button';
import { getAllWarehousesState } from '../../../../features/inventory/get_all_warehouses';
import { WarehouseTypeEnum } from '../../../../core/enums/inventory/warehouse';
import { getWarehouseName } from '../../../common/utils/warehouse_utils';

const AddDestinationDialog = ({ formik, onlyDelivery, sourceWarehouseId }) => {
    const { enqueueSnackbar } = useSnackbar();
    const dispatch = useDispatch();
    const { showErrorMessage } = useNotification();

    const getAllPods = useSelector(getAllPodsState);
    const getAllWarehouses = useSelector(getAllWarehousesState);

    const [open, setOpen] = useState(false);

    useEffect(() => {
        dispatch(getAllPodsAction())
            .then(response => showErrorMessage(getPodsNotificationData(response)));
    }, [dispatch, showErrorMessage]);

    const onClose = () => setOpen(false);

    const onAddDestination = (formik, destination) => {
        const destinationsList = formik?.values?.destinationsList || [];
        const lastItem = destinationsList[destinationsList.length - 1];
        const index = onlyDelivery
            ? destinationsList?.findIndex(d => d?.warehouseId === destination?.internalId)
            : destinationsList?.findIndex(d => d?.destinationPodId === destination?.id);

        if (index === -1) {
            if (onlyDelivery) {
                destinationsList.push({
                    position: destinationsList.length ? lastItem.position + 1 : 0,
                    name: getWarehouseName(destination),
                    destinationPodId: undefined,
                    warehouseId: destination?.internalId,
                    warehouseType: destination?.type
                });
            } else {
                destinationsList.push({
                    position: destinationsList.length ? lastItem.position + 1 : 0,
                    name: destination?.indoorPartner?.name + ' (' + destination?.description + ') - ' + (destination?.device?.serviceId || 'brak urządzenia'),
                    destinationPodId: destination?.id,
                    warehouseId: undefined,
                    warehouseType: undefined
                });
            }

            formik.setFieldValue(`destinationsList`, destinationsList);
        } else {
            enqueueSnackbar('Lokalizacja już dodana', { variant: 'error' });
        }
    };

    const getTable = () => {
        if (!onlyDelivery) {
            return (
                <TableContainer>
                    <Table stickyHeader>
                        <TableHead>
                            <TableRow>
                                <TableCell>Partner</TableCell>
                                <TableCell>Urządzenie</TableCell>
                                <TableCell>Opis</TableCell>
                                <TableCell>Adres</TableCell>
                                <TableCell align="center">Typ</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                getAllPods?.loading &&
                                <TableRow>
                                    <TableCell colSpan={ 2 } align="center">
                                        <LinearProgress/>
                                    </TableCell>
                                </TableRow>
                            }
                            {
                                getAllPods?.data?.map(pod => (
                                    <TableRow
                                        key={ pod?.id }
                                        hover
                                        sx={ { cursor: 'pointer' } }
                                        onClick={ () => {
                                            onAddDestination(formik, pod);
                                            onClose();
                                        } }
                                    >
                                        <TableCell>
                                            {
                                                pod?.indoorPartner?.name
                                            }
                                        </TableCell>
                                        <TableCell>{ pod?.device?.serviceId || '-'}</TableCell>
                                        <TableCell>{ pod?.description }</TableCell>
                                        <TableCell>
                                            {
                                                pod?.address
                                            }
                                        </TableCell>
                                        <TableCell align="center">
                                            {
                                                pod?.locationType
                                            }
                                        </TableCell>
                                    </TableRow>
                                ))
                            }
                        < /TableBody>
                    </Table>
                </TableContainer>
            );
        } else {
            return (
                <Grid container rowGap={ 6 }>
                    <TableContainer>
                        <Table stickyHeader>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Nazwa magazynu</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    getAllWarehouses?.data
                                        ?.filter(warehouse => warehouse?.type !== WarehouseTypeEnum.POD.value)
                                        ?.filter(warehouse => warehouse?.internalId !== sourceWarehouseId)
                                        ?.map(warehouse => (
                                            <TableRow
                                                key={ warehouse?.internalId }
                                                hover
                                                sx={ { cursor: 'pointer' } }
                                                onClick={ () => {
                                                    onAddDestination(formik, warehouse);
                                                    onClose();
                                                } }
                                            >
                                                <TableCell>
                                                    {
                                                        getWarehouseName(warehouse)
                                                    }
                                                </TableCell>
                                            </TableRow>
                                        ))
                                }
                            < /TableBody>
                        </Table>
                    </TableContainer>
                    <TableContainer>
                        <Table stickyHeader>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Partner</TableCell>
                                    <TableCell>Urządzenie</TableCell>
                                    <TableCell>Opis</TableCell>
                                    <TableCell>Adres</TableCell>
                                    <TableCell align="center">Typ</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    getAllWarehouses?.data
                                        ?.filter(warehouse => warehouse?.type === WarehouseTypeEnum.POD.value)
                                        ?.filter(warehouse => warehouse?.internalId !== sourceWarehouseId)
                                        ?.map(warehouse => (
                                            <TableRow
                                                key={ warehouse?.internalId }
                                                hover
                                                sx={ { cursor: 'pointer' } }
                                                onClick={ () => {
                                                    onAddDestination(formik, warehouse);
                                                    onClose();
                                                } }
                                            >
                                                <TableCell>
                                                    {
                                                        warehouse?.pod?.indoorPartnerName
                                                    }
                                                </TableCell>
                                                <TableCell>{ warehouse?.pod?.serviceId || '-'}</TableCell>
                                                <TableCell>{ warehouse?.pod?.description }</TableCell>
                                                <TableCell>
                                                    {
                                                        warehouse?.pod?.address
                                                    }
                                                </TableCell>
                                                <TableCell align="center">
                                                    {
                                                        warehouse?.pod?.locationType
                                                    }
                                                </TableCell>
                                            </TableRow>
                                        ))
                                }
                            < /TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            );
        }
    };

    return (
        <>
            <Box sx={ { width: '300px' } } onClick={ () => setOpen(true) } mt={ 4 }>
                <Stack direction="row" alignItems="center" gap={ 1 } sx={ { cursor: 'pointer' } }>
                    <AddCircleIcon/>
                    <Typography fontWeight={ 'bold' }>Dodaj kolejną lokalizację</Typography>
                </Stack>
                <FormHelperText error>
                    <span>
                        {
                            !isEmptyObject(formik?.errors?.destinationsList) &&
                            formik?.errors?.destinationsList
                        }
                    </span>
                </FormHelperText>
            </Box>
            <Dialog PaperProps={ { sx: { maxHeight: 800 } } } open={ open } onClose={ onClose } fullWidth maxWidth={ 'lg' }>
                <DialogContent sx={ { marginTop: 3 } }>
                    <Grid container rowGap={ 4 }>
                        <Grid container item>
                            {
                                getTable()
                            }
                        </Grid>
                        <Grid container item mt={ 2 } justifyContent="flex-end">
                            <DialogActions>
                                <Button onClick={ onClose }>Zamknij</Button>
                            </DialogActions>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
        </>
    );
};

export default AddDestinationDialog;
