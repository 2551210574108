import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useNavigate, useParams } from 'react-router-dom';
import { Box, Card, CardActions, CardContent, CardMedia, Grid, LinearProgress, Typography } from '@mui/material';
import { ListManager } from 'react-beautiful-dnd-grid';

import { Button, DeleteButton, SubmitButton } from '../../../common/button';
import { ROUTE_ADVERTISEMENTS } from '../../../../core/constants';
import { getAdvertisementsAction, getAdvertisementsState } from '../../../../features/pod/get_advertisements';
import useNotification from '../../../../core/hooks/use_notification';
import { deleteAdvertisementNotificationData, getAdvertisementsNotificationData, updateAdvertisementsDisplayOrderNotificationData } from '../../../../core/utils/notification_utils';
import AdvertisementsFormDialog from './advertisements_form_dialog';
import { ApiRequestStatusEnum } from '../../../../core/enums/common/api';
import useWindowDimensions from '../../../../core/hooks/use_window_dimensions';
import { deleteAdvertisementAction, deleteAdvertisementState } from '../../../../features/pod/delete_advertisement';
import { getAdvertisementFile } from '../../../../features/pod';
import { updateAdvertisementsDisplayOrderAction, updateAdvertisementsDisplayOrderState } from '../../../../features/pod/update_advertisements_display_order';
import AdvertisementDisplayTimeDialog from './advertisement_display_time_dialog';
import AdvertisementProductDialog from "./advertisement_product_dialog";

const AdvertisementDisplayOrderForm = () => {
    const { showNotification, showErrorMessage } = useNotification();
    const { width } = useWindowDimensions();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { podId } = useParams();

    const getAdvertisements = useSelector(getAdvertisementsState);
    const deleteAdvertisement = useSelector(deleteAdvertisementState);
    const updateAdvertisementsDisplayOrder = useSelector(updateAdvertisementsDisplayOrderState);

    const [advertisements, setAdvertisements] = useState([]);
    const [advertisementFiles, setAdvertisementFiles] = useState([]);

    const refreshList = useCallback(() => {
        setAdvertisementFiles([]);
        setAdvertisements([]);

        dispatch(getAdvertisementsAction({ podId }))
            .then(response => {
                showErrorMessage(getAdvertisementsNotificationData(response));
                if (response?.meta?.requestStatus === ApiRequestStatusEnum.FULFILLED) {
                    setAdvertisements(response?.payload);

                    response?.payload?.forEach(async advertisement => {
                        const file = await getAdvertisementFile(advertisement?.fileUrl);
                        file && setAdvertisementFiles(prevState => [...prevState, { id: advertisement.id, file: file }]);
                    });
                }
            });
    }, [dispatch, podId, showErrorMessage]);

    useEffect(() => {
        refreshList();
    }, [refreshList]);

    const onDragEnd = (sourceIndex, destinationIndex) => {
        if (destinationIndex === sourceIndex) {
            return;
        }

        const newAdvertisements = Array.from(advertisements);
        const deletedItem = newAdvertisements.splice(sourceIndex, 1)[0];

        newAdvertisements.splice(destinationIndex, 0, deletedItem);
        setAdvertisements(
            newAdvertisements.map((advertisement, index) => ({
                ...advertisement,
                displayOrder: index + 1
            }))
        );
    };

    const onDeleteAdvertisement = (advertisementId) => {
        dispatch(deleteAdvertisementAction(advertisementId))
            .then(response => {
                showNotification(deleteAdvertisementNotificationData(response));
                response?.meta?.requestStatus === ApiRequestStatusEnum.FULFILLED && refreshList();
            });
    };

    const onSubmit = () => {
        let advertisementsDisplayOrder = {};

        advertisements.forEach(advertisement => {
            advertisementsDisplayOrder[advertisement?.id] = advertisement?.displayOrder;
        });

        const form = {
            advertisementsDisplayOrder,
            podId
        };

        dispatch(updateAdvertisementsDisplayOrderAction(form))
            .then(response => {
                showNotification(updateAdvertisementsDisplayOrderNotificationData(response));
                response?.meta?.requestStatus === ApiRequestStatusEnum.FULFILLED && refreshList();
            });
    };

    const renderView = () => {
        if (getAdvertisements?.loading) {
            return (
                <Box mt={ 6 }>
                    <LinearProgress/>
                </Box>
            );
        }

        if (getAdvertisements?.data && advertisements && advertisementFiles) {
            return (
                <ListManager
                    items={ advertisements }
                    direction="horizontal"
                    maxItems={
                        width > 1760
                            ? 5
                            : width > 1470
                                ? 4
                                : 3
                    }
                    render={ advertisement =>
                        <Card sx={ { width: 250, margin: 2.5 } }>
                            <CardMedia
                                sx={ { height: 440 } }
                                image={ advertisementFiles?.find(ad => ad?.id === advertisement?.id)?.file }
                                title="Obrazek reklamy"
                            />
                            <CardContent>
                                <Typography variant="h4">
                                    Kolejność: { advertisement?.displayOrder }
                                </Typography>
                                <Typography variant="h5">
                                    Z datami: { (!!advertisement?.displayTimeFrom || !!advertisement?.displayTimeTo) ? 'tak' : 'nie' }
                                </Typography>
                            </CardContent>
                            <CardActions disableSpacing>
                                <DeleteButton onDeleteClick={ () => onDeleteAdvertisement(advertisement?.id) } isLoading={ deleteAdvertisement?.loading }/>
                                <AdvertisementDisplayTimeDialog
                                    advertisement={ advertisement }
                                    refreshList={ refreshList }/>
                                <AdvertisementProductDialog
                                    advertisement={ advertisement }
                                    refreshList={ refreshList }/>
                            </CardActions>
                        </Card>
                    }
                    onDragEnd={ onDragEnd }
                    sx={ { rowGap: 2 } }
                />
            );
        }

        return <></>;
    };

    return (
        <Grid mb={ 6 }>
            <Grid container mb={ 4 } justifyContent="space-between" rowGap={ 2 }>
                <Grid container item>
                    <Button onClick={ () => navigate(ROUTE_ADVERTISEMENTS) }>
                        Wróć
                    </Button>
                </Grid>
                <Grid container item>
                    <AdvertisementsFormDialog
                        podId={ podId }
                        advertisementCount={ advertisements.length }
                        refreshList={ refreshList }/>
                </Grid>
            </Grid>
            {
                renderView()
            }
            <Grid container mt={ 2 } justifyContent="flex-end">
                <SubmitButton onClick={ onSubmit } isLoading={ updateAdvertisementsDisplayOrder?.loading } disabled={ advertisements.length <= 1 }>Zapisz</SubmitButton>
            </Grid>
        </Grid>
    );
};

export default AdvertisementDisplayOrderForm;
