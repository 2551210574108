import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import { Collapse, List, ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import RestaurantIcon from '@mui/icons-material/RestaurantMenu';
import DiscountIcon from '@mui/icons-material/Discount';
import MessageIcon from '@mui/icons-material/Message';
import MoneyIcon from '@mui/icons-material/AttachMoney';
import LiveTvIcon from '@mui/icons-material/LiveTv';
import FoodBankIcon from '@mui/icons-material/FoodBank';
import CampaignIcon from '@mui/icons-material/Campaign';
import GroupsIcon from '@mui/icons-material/Groups';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import WarehouseIcon from '@mui/icons-material/Warehouse';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import DeliveryDiningIcon from '@mui/icons-material/DeliveryDining';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import StorefrontIcon from '@mui/icons-material/Storefront';

import { ROUTE_ADVERTISEMENTS, ROUTE_COUPONS, ROUTE_DELIVERY_NOTIFICATIONS, ROUTE_EMPLOYERS, ROUTE_EXPIRATION_PROMOTIONS, ROUTE_FOOD_PARTNER_ORDERS, ROUTE_FOOD_PARTNERS, ROUTE_NOTIFICATIONS, ROUTE_PODS, ROUTE_PRICES, ROUTE_PRODUCERS, ROUTE_PRODUCT_PROMOTIONS, ROUTE_PRODUCTS, ROUTE_PROMOTION_CAMPAIGNS, ROUTE_RESERVATIONS, ROUTE_SALES, ROUTE_SUPPLIERS, ROUTE_WAREHOUSE_DOCUMENTS, ROUTE_WAREHOUSE_REPORTS } from '../../../core/constants';

export const SidebarListItems = () => {
    const [openPromotion, setOpenPromotion] = useState(false);
    const [openWarehouse, setOpenWarehouse] = useState(false);
    const [openNotification, setOpenNotification] = useState(false);

    return (
        <>
            <ListItem disablePadding>
                <ListItemButton component={ Link } to={ ROUTE_SALES }>
                    <ListItemIcon>
                        <DiscountIcon/>
                    </ListItemIcon>
                    <ListItemText primary="Sprzedaż"/>
                </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
                <ListItemButton component={ Link } to={ ROUTE_PODS }>
                    <ListItemIcon>
                        <StorefrontIcon/>
                    </ListItemIcon>
                    <ListItemText primary="Punkty sprzedaży"/>
                </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
                <ListItemButton component={ Link } to={ ROUTE_PRODUCTS }>
                    <ListItemIcon>
                        <RestaurantIcon/>
                    </ListItemIcon>
                    <ListItemText primary="Produkty"/>
                </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
                <ListItemButton component={ Link } to={ ROUTE_PRODUCERS }>
                    <ListItemIcon>
                        <FoodBankIcon/>
                    </ListItemIcon>
                    <ListItemText primary="Producenci"/>
                </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
                <ListItemButton component={ Link } to={ ROUTE_FOOD_PARTNERS }>
                    <ListItemIcon>
                        <LocalShippingIcon/>
                    </ListItemIcon>
                    <ListItemText primary="Dostawcy"/>
                </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
                <ListItemButton component={ Link } to={ ROUTE_SUPPLIERS }>
                    <ListItemIcon>
                        <DeliveryDiningIcon/>
                    </ListItemIcon>
                    <ListItemText primary="Zaopatrzeniowcy"/>
                </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
                <ListItemButton component={ Link } to={ ROUTE_EMPLOYERS }>
                    <ListItemIcon>
                        <GroupsIcon/>
                    </ListItemIcon>
                    <ListItemText primary="Pracodawcy"/>
                </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
                <ListItemButton component={ Link } to={ ROUTE_PRICES }>
                    <ListItemIcon>
                        <MoneyIcon/>
                    </ListItemIcon>
                    <ListItemText primary="Ceny"/>
                </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
                <ListItemButton onClick={ () => setOpenPromotion(prevState => !prevState) }>
                    <ListItemIcon>
                        <CampaignIcon/>
                    </ListItemIcon>
                    <ListItemText primary="Promocje"/>
                    { openPromotion ? <ExpandLess/> : <ExpandMore/> }
                </ListItemButton>
            </ListItem>
            <Collapse in={ openPromotion } timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    <ListItemButton sx={ { pl: 4 } } component={ Link } to={ ROUTE_PRODUCT_PROMOTIONS }>
                        <ListItemIcon>
                            <ArrowRightIcon/>
                        </ListItemIcon>
                        <ListItemText primary="Promocje produktowe"/>
                    </ListItemButton>
                    <ListItemButton sx={ { pl: 4 } } component={ Link } to={ ROUTE_EXPIRATION_PROMOTIONS }>
                        <ListItemIcon>
                            <ArrowRightIcon/>
                        </ListItemIcon>
                        <ListItemText primary="Promocje na krótki termin przydatności"
                                      primaryTypographyProps={ { style: { whiteSpace: 'pre-wrap' } } }/>
                    </ListItemButton>
                    <ListItemButton sx={ { pl: 4 } } component={ Link } to={ ROUTE_PROMOTION_CAMPAIGNS }>
                        <ListItemIcon>
                            <ArrowRightIcon/>
                        </ListItemIcon>
                        <ListItemText primary="Akcje promocyjne"/>
                    </ListItemButton>
                    <ListItemButton sx={ { pl: 4 } } component={ Link } to={ ROUTE_COUPONS }>
                        <ListItemIcon>
                            <ArrowRightIcon/>
                        </ListItemIcon>
                        <ListItemText primary="Kupony"/>
                    </ListItemButton>
                </List>
            </Collapse>
            <ListItem disablePadding>
                <ListItemButton onClick={ () => setOpenWarehouse(prevState => !prevState) }>
                    <ListItemIcon>
                        <WarehouseIcon/>
                    </ListItemIcon>
                    <ListItemText primary="Magazyn"/>
                    { openWarehouse ? <ExpandLess/> : <ExpandMore/> }
                </ListItemButton>
            </ListItem>
            <Collapse in={ openWarehouse } timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    <ListItemButton sx={ { pl: 4 } } component={ Link } to={ ROUTE_FOOD_PARTNER_ORDERS }>
                        <ListItemIcon>
                            <ArrowRightIcon/>
                        </ListItemIcon>
                        <ListItemText primary="Zamówienia od dostawców"
                                      primaryTypographyProps={ { style: { whiteSpace: 'pre-wrap' } } }/>
                    </ListItemButton>
                    <ListItemButton sx={ { pl: 4 } } component={ Link } to={ ROUTE_WAREHOUSE_DOCUMENTS }>
                        <ListItemIcon>
                            <ArrowRightIcon/>
                        </ListItemIcon>
                        <ListItemText primary="Dokumenty magazynowe"/>
                    </ListItemButton>
                    <ListItemButton sx={ { pl: 4 } } component={ Link } to={ ROUTE_WAREHOUSE_REPORTS }>
                        <ListItemIcon>
                            <ArrowRightIcon/>
                        </ListItemIcon>
                        <ListItemText primary="Raporty"/>
                    </ListItemButton>
                </List>
            </Collapse>
            <ListItem disablePadding>
                <ListItemButton component={ Link } to={ ROUTE_RESERVATIONS }>
                    <ListItemIcon>
                        <ShoppingCartIcon/>
                    </ListItemIcon>
                    <ListItemText primary="Rezerwacje"/>
                </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
                <ListItemButton onClick={ () => setOpenNotification(prevState => !prevState) }>
                    <ListItemIcon>
                        <MessageIcon/>
                    </ListItemIcon>
                    <ListItemText primary="Powiadomienia"/>
                    { openNotification ? <ExpandLess/> : <ExpandMore/> }
                </ListItemButton>
            </ListItem>
            <Collapse in={ openNotification } timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    <ListItem disablePadding>
                        <ListItemButton sx={ { pl: 4 } } component={ Link } to={ ROUTE_NOTIFICATIONS }>
                            <ListItemIcon>
                                <ArrowRightIcon/>
                            </ListItemIcon>
                            <ListItemText primary="Wysyłka powiadomień"/>
                        </ListItemButton>
                    </ListItem>
                    <ListItem disablePadding>
                        <ListItemButton sx={ { pl: 4 } } component={ Link } to={ ROUTE_DELIVERY_NOTIFICATIONS }>
                            <ListItemIcon>
                                <ArrowRightIcon/>
                            </ListItemIcon>
                            <ListItemText primary="Wzory powiadomień dostaw"
                                          primaryTypographyProps={ { style: { whiteSpace: 'pre-wrap' } } }/>
                        </ListItemButton>
                    </ListItem>
                </List>
            </Collapse>
            <ListItem disablePadding>
                <ListItemButton component={ Link } to={ ROUTE_ADVERTISEMENTS }>
                    <ListItemIcon>
                        <LiveTvIcon/>
                    </ListItemIcon>
                    <ListItemText primary="Reklamy"/>
                </ListItemButton>
            </ListItem>
        </>
    );
};

export default SidebarListItems;
