import React, { useState } from 'react';
import { Button, SubmitButton } from '../../../../common/button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Edit } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import useNotification from '../../../../../core/hooks/use_notification';
import { Box, Grid, LinearProgress } from '@mui/material';
import { getContactPersonAction, getContactPersonState } from '../../../../../features/food_partner/get_contact_person';
import { createContactPersonNotificationData, getContactPersonNotificationData, updateContactPersonNotificationData } from '../../../../../core/utils/notification_utils';
import { updateContactPersonAction, updateContactPersonState } from '../../../../../features/food_partner/update_contact_person';
import { createContactPersonAction, createContactPersonState } from '../../../../../features/food_partner/create_contact_person';
import * as Yup from 'yup';
import { Form, Formik } from 'formik';
import TextFieldFormik from '../../../../common/text_field';
import RadioGroupFormik from '../../../../common/radio_group';
import { ApiRequestStatusEnum } from '../../../../../core/enums/common/api';

const contactPersonRoleOptions = [
    { value: 'COORDINATOR', label: 'Koordynator' },
    { value: 'MARKETING', label: 'Marketing' },
];

export default function ContactPersonDialog({ foodPartnerId, contactPersonId, refreshList, displaySmallIcon }) {
    const dispatch = useDispatch();
    const { showNotification, showErrorMessage } = useNotification();

    const [open, setOpen] = useState(false);
    const createForm = !contactPersonId;


    const getContactPerson = useSelector(getContactPersonState);
    const createContactPerson = useSelector(createContactPersonState);
    const updateContactPerson = useSelector(updateContactPersonState);

    const initialValues = {
        name: (!createForm && getContactPerson?.data?.name) || '',
        surname: (!createForm && getContactPerson?.data?.surname) || '',
        role: (!createForm && getContactPerson?.data?.role) || contactPersonRoleOptions[0].value,
        email: (!createForm && getContactPerson?.data?.email) || '',
        phoneNumber: (!createForm && getContactPerson?.data?.phoneNumber) || ''
    }

    const schema = Yup.object().shape({
        name: Yup.string().required(),
        surname: Yup.string().required(),
        role: Yup.string().required(),
        email: Yup.string().required(),
        phoneNumber: Yup.string()
    });

    const loadContactPerson = () => {
        if (!createForm) {
            dispatch(getContactPersonAction({ foodPartnerId, contactPersonId }))
                .then(response => showErrorMessage(getContactPersonNotificationData(response)));
        }
    };

    const onOpen = () => {
        loadContactPerson();
        setOpen(true);
    };

    const onSubmit = (values) => {
        const form = { ...values };

        if (createForm) {
            dispatch(createContactPersonAction({ form, foodPartnerId }))
                .then(response => {
                    showNotification(createContactPersonNotificationData(response));
                    response?.meta?.requestStatus === ApiRequestStatusEnum.FULFILLED && onClose();
                    response?.meta?.requestStatus === ApiRequestStatusEnum.FULFILLED && refreshList();
                });
        } else {
            dispatch(updateContactPersonAction({ form, foodPartnerId, contactPersonId }))
                .then(response => {
                    showNotification(updateContactPersonNotificationData(response));
                    response?.meta?.requestStatus === ApiRequestStatusEnum.FULFILLED && onClose();
                    response?.meta?.requestStatus === ApiRequestStatusEnum.FULFILLED && refreshList();
                });

        }
    };

    const onClose = () => {
        setOpen(false);
    };

    const renderView = () => {
        if (getContactPerson?.loading) {
            return (
                <>
                    <Box mt={ 6 }>
                        <LinearProgress/>
                    </Box>
                    <DialogActions>
                        <Button onClick={ onClose }>Zamknij</Button>
                    </DialogActions>
                </>
            );
        }

        return (
            <Formik
                initialValues={ initialValues }
                validationSchema={ schema }
                validateOnChange={ false }
                validateOnBlur={ false }
                onSubmit={ onSubmit }>
                { () =>
                    <Grid component={ Form } container rowGap={ 2 } paddingTop={ 2 }>
                        <Grid container item direction="column" rowGap={ 2 }>
                            <Grid container item>
                                <TextFieldFormik name="name" label="Imię" required/>
                            </Grid>
                            <Grid container item>
                                <TextFieldFormik name="surname" label="Nazwisko" required/>
                            </Grid>
                            <Grid container item>
                                <Grid item>
                                    <RadioGroupFormik
                                        name="role"
                                        label="Rola"
                                        options={ contactPersonRoleOptions }
                                        booleanOptions={ false }
                                    />
                                </Grid>
                            </Grid>
                            <Grid container item>
                                <TextFieldFormik name="email" label="Email" required/>
                            </Grid>
                            <Grid container item>
                                <TextFieldFormik name="phoneNumber" label="Numer telefonu"/>
                            </Grid>
                        </Grid>
                        <Grid container item mt={ 2 } justifyContent="flex-end">
                            <DialogActions sx={ { gap: 2 } }>
                                <SubmitButton isLoading={ createForm ? createContactPerson?.loading : updateContactPerson?.loading }>Zapisz</SubmitButton>
                                <Button onClick={ onClose }>Zamknij</Button>
                            </DialogActions>
                        </Grid>
                    </Grid>
                }
            </Formik>
        );
    }

    return (
        <>
            {
                displaySmallIcon &&
                <Edit onClick={ onOpen } cursor="pointer"/>
            }
            {
                !displaySmallIcon &&
                <Button onClick={ onOpen } cursor="pointer">
                    Dodaj osobę kontaktową
                </Button>
            }
            <Dialog open={ open } onClose={ onClose } fullWidth={ true } maxWidth={ 'md' }>
                <DialogTitle>Osoba kontaktowa</DialogTitle>
                <DialogContent>
                    {
                        renderView()
                    }
                </DialogContent>
            </Dialog>
        </>
    );
}
