import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { Card, CardContent, Divider, Grid, Typography } from '@mui/material';
import { Form, Formik } from 'formik';

import ThreeLevelSteps from '../../../../common/warehouse_and_food_partner_order_form/three_level_steps';
import AddProductsDialog from '../../../../common/warehouse_and_food_partner_order_form/add_products_dialog';
import { getFoodPartnerAction } from '../../../../../../features/food_partner/get_food_partner';
import useNotification from '../../../../../../core/hooks/use_notification';
import { MISC_DATE_ISO_DATE_ONLY_FORMAT, MISC_DATE_ISO_FORMAT } from '../../../../../../core/constants';
import { getFoodPartnerNotificationData } from '../../../../../../core/utils/notification_utils';
import ProductsList from '../common/products_list';
import Buttons from '../../../../common/warehouse_and_food_partner_order_form/buttons';
import AdditionalOptions from '../common/additional_options';
import moment from 'moment';
import UploadFiles from '../../../../common/warehouse_and_food_partner_order_form/upload_files';

const FormWithoutLocationDivision = ({ form, setView, data, setData }) => {
    const dispatch = useDispatch();
    const { showErrorMessage } = useNotification();

    useEffect(() => {
        dispatch(getFoodPartnerAction(form?.foodPartnerId))
            .then(response => showErrorMessage(getFoodPartnerNotificationData(response)));
    }, [dispatch, form, showErrorMessage]);

    const initialValues = {
        productsList: data?.formik?.productsList || [],
        attachments: data?.formik?.attachments || []
    };

    const onReturn = () => {
        setData({});
        setView(0);
    };

    const onCopyHistoricalOrder = (values, formik) => {
        const productsList = [];

        values?.forEach((product, index) => {
            productsList.push({
                position: index,
                name: product?.productName,
                productId: product?.productId,
                productBatchId: '',
                quantity: product?.quantity,
                expirationDate: product?.defaultDaysToExpiration ? moment(form?.orderDate).add(Math.max(product?.defaultDaysToExpiration - 1, 0), 'days') : '',
                price: product?.purchaseNetPrice || '',
                type: product?.type
            });
        });

        formik.setFieldValue(`productsList.${ 0 }`, productsList);
    };

    const onSubmit = (formik) => {
        const errors = validate(formik.values);

        if (errors.productsList.length ||
            errors.products.length) {
            formik.setErrors(errors);
            return;
        }

        const locationProducts = [{
            products: [
                ...formik?.values?.productsList?.[0]?.map(item => ({
                    productId: item?.productId,
                    quantity: item?.quantity,
                    price: item?.price,
                    expirationDate: item?.expirationDate?.format(MISC_DATE_ISO_DATE_ONLY_FORMAT),
                }))
            ]
        }];

        setData({
            form: { ...form, orderDate: form?.orderDate?.format(MISC_DATE_ISO_FORMAT), locationProducts, attachments: formik?.values?.attachments || [] },
            formik: formik?.values
        });
        setView(3);
    };

    const validate = (values) => {
        const errors = {
            productsList: [],
            products: []
        };

        if (values.productsList.length === 0) {
            errors.productsList[0] = 'Wybierz minimum 1 produkt';
        } else {
            values.productsList.forEach((products, index) => {
                if (products.length === 0) {
                    errors.productsList[index] = 'Wybierz minimum 1 produkt';
                } else {
                    products.forEach((item, itemIndex) => {
                        if (!item?.quantity) {
                            const oldValues = errors.products[index];
                            errors.products[index] = {
                                ...oldValues,
                                [itemIndex]: {
                                    ...errors.products?.[index]?.[itemIndex],
                                    quantity: 'Pole wymagane'
                                }
                            };
                        } else {
                            if (item?.quantity <= 0) {
                                const oldValues = errors.products[index];
                                errors.products[index] = {
                                    ...oldValues,
                                    [itemIndex]: {
                                        ...errors.products?.[index]?.[itemIndex],
                                        quantity: 'Proszę podać wartość całkowitą dodatnią'
                                    }
                                };
                            }
                        }

                        if (!item?.price) {
                            const oldValues = errors.products[index];
                            errors.products[index] = {
                                ...oldValues,
                                [itemIndex]: {
                                    ...errors.products?.[index]?.[itemIndex],
                                    price: 'Pole wymagane'
                                }
                            };
                        } else {
                            if (item?.price <= 0) {
                                const oldValues = errors.products[index];
                                errors.products[index] = {
                                    ...oldValues,
                                    [itemIndex]: {
                                        ...errors.products?.[index]?.[itemIndex],
                                        price: 'Proszę podać wartość dodatnią'
                                    }
                                };
                            }
                        }

                        if (!item?.expirationDate) {
                            const oldValues = errors.products[index];
                            errors.products[index] = {
                                ...oldValues,
                                [itemIndex]: {
                                    ...errors.products?.[index]?.[itemIndex],
                                    expirationDate: 'Pole wymagane'
                                }
                            };
                        }
                    });
                }
            });
        }

        return errors;
    };

    const onChangeSetDate = (newDate, allDates, formik) => {
        const productsList = formik?.values?.productsList[0]?.map(item => {
            if (!allDates && item?.expirationDate) {
                return item;
            }

            return ({ ...item, expirationDate: newDate });
        });

        formik.setFieldValue(`productsList.${ 0 }`, productsList);
    };

    return (
        <Formik initialValues={ initialValues }>
            { (formik) =>
                <Grid component={ Form } container rowGap={ 2 } paddingTop={ 2 }>
                    <ThreeLevelSteps secondStep/>
                    <AdditionalOptions
                        onChangeSetDate={ (newDate, allDates) => onChangeSetDate(newDate, allDates, formik) }
                        displayCopyHistoricalDialog
                        form={ form }
                        onCopyHistoricalOrder={ (values) => onCopyHistoricalOrder(values, formik) }/>
                    <Grid container item mb={ 2 }>
                        <Divider style={ { width: '100%' } }/>
                    </Grid>
                    <Card sx={ { width: '100%', backgroundColor: '#f5f5f5' } }>
                        <CardContent>
                            <Typography variant="h4" mb={ 5 }>
                                Lista produktów
                            </Typography>
                            <ProductsList
                                formik={ formik }
                                withExpirationDate
                                productsListIndex={ 0 }/>
                            <AddProductsDialog
                                foodPartnerId={ form?.foodPartnerId }
                                formik={ formik }
                                dateToCalculateExpirationDate={ form?.orderDate }
                                productsListIndex={ 0 }
                            />
                        </CardContent>
                    </Card>
                    <UploadFiles formik={ formik } addAndRemoveOptions/>
                    <Buttons
                        onReturn={ onReturn }
                        onSubmit={ () => onSubmit(formik) }
                    />
                </Grid>
            }
        </Formik>
    );
};

export default FormWithoutLocationDivision;
