import { deepFreeze } from '../../utils/misc_utils';

export const InventorySuccessMessageEnum = deepFreeze({
    UPDATE_PRODUCTS_DEFINITION: 'Pomyślnie zaktualizowano produkty',
    GET_INVENTORY_EXPORT: 'Pomyślnie pobrano plik Excel',
    CREATE_WAREHOUSE_DOCUMENT: 'Pomyślnie utworzono dokument/dokumenty',
    GENERATE_WAREHOUSE_DOCUMENT_LABELS: 'Pomyślnie wygenerowano naklejki dla dokumentu',
    PARTIALLY_CREATE_WAREHOUSE_DOCUMENT: 'Pomyślnie utworzono dokument/dokumenty. Niestety nie udało się wygenerować naklejek',
    UPDATE_FOOD_PARTNER_ORDER_STATUS: 'Pomyślnie zaktualizowano status zamówienia',
    SEND_FOOD_PARTNER_ORDER: 'Pomyślnie wysłano email z zamówieniem',
    CREATE_FOOD_PARTNER_ORDER: 'Pomyślnie utworzono zamówienie',
    PARTIALLY_CREATE_FOOD_PARTNER_ORDER: 'Pomyślnie utworzono zamówienie. Niestety nie udało sie wygenerować naklejek',
    UPDATE_FOOD_PARTNER_ORDER: 'Pomyślnie zaktualizowano zamówienie',
    PARTIALLY_UPDATE_FOOD_PARTNER_ORDER: 'Pomyślnie zaktualizowano zamówienie. Niestety nie udało sie wygenerować naklejek',
    GENERATE_FOOD_PARTNER_ORDER_LABELS: 'Pomyślnie wygenerowano naklejki dla zamówienia',
});

export const InventoryErrorMessageEnum = deepFreeze({
    GET_PRODUCT_BATCHES: 'Pobranie partii produktu nie powiodło się',
    UPDATE_PRODUCTS_DEFINITION: 'Aktualizacja produktów nie powiodła się',
    GET_INVENTORY_EXPORT: 'Pobranie pliku Excel nie powiodło się',
    GET_COUNTERPARTIES: 'Pobranie kontrahentów nie powiodło się',
    GET_ALL_PRODUCTS: 'Pobranie produktów nie powiodło się',
    GET_WAREHOUSE_DOCUMENTS: 'Pobranie dokumentów nie powiodło się',
    GET_WAREHOUSE_DOCUMENT: 'Pobranie dokumentu nie powiodło się',
    CREATE_WAREHOUSE_DOCUMENT: 'Utworzenie dokumentu/dokumentów nie powiodło się',
    GENERATE_WAREHOUSE_DOCUMENT_LABELS: 'Wygenerowanie naklejek dla dokumentu nie powiodło się',
    GET_WAREHOUSE_PRODUCT_BATCHES: 'Pobranie produktów nie powiodło się',
    GET_GENERATE_WAREHOUSE_DOCUMENT_REQUESTS: 'Pobranie historycznych dokumentów nie powiodło się',
    GET_FOOD_PARTNER_ORDERS: 'Pobranie zamówień nie powiodło się',
    GET_FOOD_PARTNER_ORDER: 'Pobranie zamówienia nie powiodło się',
    UPDATE_FOOD_PARTNER_ORDER_STATUS: 'Aktualizacja statusu zamówienia nie powiodła się',
    SEND_FOOD_PARTNER_ORDER: 'Wysłanie emaila z zamówieniem nie powiodło się',
    CREATE_FOOD_PARTNER_ORDER: 'Utworzenie zamówienia nie powiodło się',
    UPDATE_FOOD_PARTNER_ORDER: 'Aktualizacja zamówienia nie powiodła się',
    GET_FOOD_PARTNER_ORDER_HISTORY: 'Pobranie historycznych zamówień nie powiodło się',
    GET_ALL_WAREHOUSES: 'Pobranie magazynów nie powiodło się',
    GENERATE_FOOD_PARTNER_ORDER_LABELS: 'Wygenerowanie naklejek dla zamówienia nie powiodło się',
    GET_FOOD_PARTNER_ORDER_ATTACHMENT: 'Pobranie załącznika zamówienia nie powiodło się',
});

const InventoryServerErrorMessageEnum = deepFreeze({
    PRODUCT_PRICE_MISMATCH: {
        message: 'Podano różne ceny dla tego samego produktu',
        errorCode: '400.7'
    },
    EXCEEDED_ATTACHMENT_COUNT: {
        message: 'Przekroczono limit załączników',
        errorCode: '400.24'
    },
    EXCEEDED_ATTACHMENTS_SIZE: {
        message: 'Przekroczono limit wagi załączników',
        errorCode: '400.25'
    },
    INVALID_ATTACHMENT_EXTENSION: {
        message: 'Nieprawidłowe rozszerzenie załącznika',
        errorCode: '400.26'
    },
    CANNOT_SEND_EMAIL: {
        message: 'Wysyłka emaila nie powiodła się',
        errorCode: '400.27'
    },
    MISSING_HOMOGENOUS_EXPIRY_DATE: {
        message: 'Data przydatności dla tego samego produktu musi być taka sama',
        errorCode: '400.28'
    },
});

export const getInventoryErrorMessage = (message, defaultErrorMessage) => {
    for (let key in InventoryServerErrorMessageEnum) {
        if (InventoryServerErrorMessageEnum.hasOwnProperty(key)) {
            if (message?.substring(0, message?.indexOf(' ')) === InventoryServerErrorMessageEnum[key].errorCode) {
                return InventoryServerErrorMessageEnum[key].message;
            }
        }
    }

    return defaultErrorMessage;
};
