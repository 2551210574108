import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import * as Yup from 'yup';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import { Form, Formik } from 'formik';
import { Grid } from '@mui/material';
import DialogActions from '@mui/material/DialogActions';
import _ from 'lodash';

import useNotification from '../../../../../../core/hooks/use_notification';
import { Button, SubmitButton } from '../../../../../common/button';
import { AutocompleteFormik } from '../../../../../common/autocomplete';
import { deleteProductAvailabilityAction, deleteProductAvailabilityState } from '../../../../../../features/reservation/delete_product_availability';
import { getProductsAvailabilityState } from '../../../../../../features/reservation/get_products_availability';
import { parseDateString } from '../../../../../../core/utils/date_utils';
import DatePickerFormik from '../../../../../common/date_picker';
import { MISC_DATE_ISO_DATE_ONLY_FORMAT } from '../../../../../../core/constants';
import { deleteProductAvailabilityNotificationData } from '../../../../../../core/utils/notification_utils';
import { ApiRequestStatusEnum } from '../../../../../../core/enums/common/api';

const DeleteProductAvailabilityFormDialog = ({ refreshCalendar }) => {
    const dispatch = useDispatch();
    const { showNotification } = useNotification();

    const [open, setOpen] = useState(false);

    const getProductsAvailability = useSelector(getProductsAvailabilityState);
    const deleteProductAvailability = useSelector(deleteProductAvailabilityState);

    const mapProductOptions = useCallback((data) => {
        return data?.map(product => ({
            key: product?.productId + Math.random(),
            value: product?.productId,
            label: product?.productName
        }));
    }, []);

    const productOptions = mapProductOptions([...new Map(getProductsAvailability?.data?.map(productsAvailability => productsAvailability?.products)?.flat().map((item) => [item['productId'], item])).values()]) || [];

    const onClose = () => setOpen(false);

    const initialValues = {
        product: '',
        fromDate: '',
        toDate: ''
    };

    const schema = Yup.object().shape({
        product: Yup.object().notRequired(),
        fromDate: Yup.date()
            .transform(parseDateString)
            .nullable()
            .required(),
        toDate: Yup.date()
            .transform(parseDateString)
            .nullable()
            .required()
    });

    const onSubmit = (values) => {
        const productId = _.pick(values, ['product'])?.product?.value || '';
        const fromDate = values?.fromDate?.format(MISC_DATE_ISO_DATE_ONLY_FORMAT);
        const toDate = values?.toDate?.format(MISC_DATE_ISO_DATE_ONLY_FORMAT);

        const form = {
            fromDate,
            toDate,
            productId
        };

        dispatch(deleteProductAvailabilityAction(form))
            .then(response => {
                showNotification(deleteProductAvailabilityNotificationData(response));
                response?.meta?.requestStatus === ApiRequestStatusEnum.FULFILLED && refreshCalendar();
            });
    };

    return (
        <>
            <Button onClick={ () => setOpen(true) }>
                Usuń produkt z kalendarza
            </Button>
            <Dialog open={ open } onClose={ onClose } fullWidth maxWidth={ 'md' }>
                <DialogTitle>Usuń produkt z kalendarza</DialogTitle>
                <DialogContent sx={ { paddingTop: '6px !important' } }>
                    <Formik
                        initialValues={ initialValues }
                        validationSchema={ schema }
                        validateOnChange={ false }
                        validateOnBlur={ false }
                        onSubmit={ onSubmit }>
                        { () =>
                            <Grid component={ Form } container item rowGap={ 2 } alignContent="space-between">
                                <Grid container item rowGap={ 2 }>
                                    <Grid container item gap={ 2 }>
                                        <Grid item xs={ 7 }>
                                            <AutocompleteFormik
                                                name="product"
                                                label="Produkt"
                                                options={ productOptions }
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container item gap={ 2 }>
                                        <Grid item>
                                            <DatePickerFormik
                                                name="fromDate"
                                                label="Data od"
                                                required
                                                InputLabelProps={ { shrink: true } }
                                            />
                                        </Grid>
                                        <Grid item>
                                            <DatePickerFormik
                                                name="toDate"
                                                label="Data do"
                                                required
                                                InputLabelProps={ { shrink: true } }
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid container item mt={ 2 } justifyContent="flex-end">
                                    <DialogActions sx={ { gap: 2 } }>
                                        <Button onClick={ onClose }>Zamknij</Button>
                                        <SubmitButton isLoading={ deleteProductAvailability?.loading }>Usuń</SubmitButton>
                                    </DialogActions>
                                </Grid>
                            </Grid>
                        }
                    </Formik>
                </DialogContent>
            </Dialog>
        </>
    );
};

export default DeleteProductAvailabilityFormDialog;
