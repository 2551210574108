import React, { Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import moment from 'moment';

import { getReservationsState } from '../../../../../features/reservation/get_reservations';
import { DeleteButton } from '../../../../common/button';
import { MISC_DATE_ISO_DATE_ONLY_FORMAT, MISC_DATE_POLISH_DATE_ONLY_FORMAT } from '../../../../../core/constants';
import { getAllPodsState } from '../../../../../features/pod/get_all_pods';
import { deleteReservationNotificationData } from '../../../../../core/utils/notification_utils';
import { ApiRequestStatusEnum } from '../../../../../core/enums/common/api';
import useNotification from '../../../../../core/hooks/use_notification';
import { deleteReservationAction, deleteReservationState } from '../../../../../features/reservation/delete_reservation';
import ReservationModal from './reservation_modal';
import ReservationStoreStatusFormSelect from './form/reservation_store_status_form_select';

const ReservationsList = ({ refreshReservations }) => {
    const dispatch = useDispatch();
    const { showNotification } = useNotification();

    const getAllPods = useSelector(getAllPodsState);
    const getReservations = useSelector(getReservationsState);
    const deleteReservation = useSelector(deleteReservationState);

    const dates = [...new Set(...[getReservations?.data?.content?.map(reservation => reservation?.date)])];

    const onDeleteReservation = (id) => {
        dispatch(deleteReservationAction(id))
            .then(response => {
                showNotification(deleteReservationNotificationData(response));
                response?.meta?.requestStatus === ApiRequestStatusEnum.FULFILLED && refreshReservations();
            });
    };

    return (
        <Grid container>
            <TableContainer>
                <Table stickyHeader>
                    <TableHead>
                        <TableRow>
                            <TableCell>Numer rezerwacji</TableCell>
                            <TableCell align="center">Pod</TableCell>
                            <TableCell align="center">Szczegóły</TableCell>
                            <TableCell align="center">Status</TableCell>
                            <TableCell align="center">Wewnętrzny status</TableCell>
                            <TableCell align="center">Usuń</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            dates?.map((date, index) => (
                                <Fragment key={ index }>
                                    <TableRow key={ date }>
                                        <TableCell colSpan={ 5 } align="center">
                                            <h3>
                                                {
                                                    moment(date, MISC_DATE_ISO_DATE_ONLY_FORMAT)
                                                        .format(MISC_DATE_POLISH_DATE_ONLY_FORMAT)
                                                }
                                            </h3>
                                        </TableCell>
                                    </TableRow>
                                    {
                                        getReservations?.data?.content?.filter(reservation => moment(date).isSame(moment(reservation?.date)))
                                            ?.map((reservation) => (
                                                <TableRow
                                                    key={ reservation?.reservationId + date }
                                                    hover
                                                >
                                                    <TableCell>
                                                        {
                                                            reservation?.reservationId
                                                        }
                                                    </TableCell>
                                                    <TableCell align="center">
                                                        {
                                                            getAllPods?.data?.map(pod => ({ id: pod?.id, label: pod?.indoorPartner?.name + ' (' + pod?.description + ') - ' + (pod?.device?.serviceId || 'brak urządzenia') }))
                                                                .find(pod => pod?.id === reservation?.podId)?.label
                                                        }
                                                    </TableCell>
                                                    <TableCell align="center">
                                                        <ReservationModal
                                                            reservation={ reservation }
                                                            refreshReservations={ refreshReservations }/>
                                                    </TableCell>
                                                    <TableCell>
                                                        {
                                                            reservation?.displayStatusValue
                                                        }
                                                    </TableCell>
                                                    <TableCell align="center">
                                                        <ReservationStoreStatusFormSelect
                                                            reservationId={ reservation?.reservationId }
                                                            storeStatus={ reservation?.storeStatus }
                                                            refreshReservations={ refreshReservations }
                                                            center/>
                                                    </TableCell>
                                                    <TableCell align="center">
                                                        <DeleteButton onDeleteClick={ () => onDeleteReservation(reservation?.reservationId) } isLoading={ deleteReservation?.loading }/>
                                                    </TableCell>
                                                </TableRow>
                                            ))
                                    }
                                </Fragment>
                            ))
                        }
                    </TableBody>
                </Table>
            </TableContainer>
        </Grid>
    );
};

export default ReservationsList;
