import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useNavigate } from 'react-router-dom';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { DeleteButton } from '../../common/button';
import EditIcon from '@mui/icons-material/Edit';
import { ApiRequestStatusEnum } from '../../../core/enums/common/api';
import useNotification from '../../../core/hooks/use_notification';
import { getProducersState } from "../../../features/product/get_producers";
import { deleteProducerAction, deleteProducerState } from "../../../features/product/delete_producer";
import { GET_ROUTE_PRODUCER_FORMS } from "../../../core/constants";
import { deleteProducerNotificationData } from "../../../core/utils/notification_utils";

const ProducersList = ({refreshList}) => {
    const { showNotification } = useNotification();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const getProducers = useSelector(getProducersState);
    const deleteProducer = useSelector(deleteProducerState);

    const onDeleteProducer = (producerId) => {
        dispatch(deleteProducerAction(producerId))
            .then(response => {
                showNotification(deleteProducerNotificationData(response));
                response?.meta?.requestStatus === ApiRequestStatusEnum.FULFILLED && refreshList();
            });
    }

    return (
        <TableContainer>
            <Table stickyHeader>
                <TableHead>
                    <TableRow>
                        <TableCell>Nazwa</TableCell>
                        <TableCell align="center">Podgląd/edycja</TableCell>
                        <TableCell align="center">Usuń</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        getProducers?.data?.content?.map(producer => (
                            <TableRow
                                key={ producer?.id }
                                hover
                            >
                                <TableCell>{ producer?.name }</TableCell>
                                <TableCell align="center">
                                    <EditIcon onClick={ () => navigate(GET_ROUTE_PRODUCER_FORMS(producer?.id)) } cursor="pointer"/>
                                </TableCell>
                                <TableCell align="center">
                                    <DeleteButton onDeleteClick={ () => onDeleteProducer(producer?.id) } isLoading={ deleteProducer?.loading }/>
                                </TableCell>
                            </TableRow>
                        ))
                    }
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default ProducersList;
