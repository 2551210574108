import React from 'react';

import { Pagination } from '@mui/material';

const PaginationControls = ({ totalPages, currentPage, setPage }) => (
    <Pagination
        count={ totalPages }
        page={ currentPage + 1 }
        onChange={ (_, page) => setPage(page - 1) }
    />
);

export default PaginationControls;
