import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { DEFAULT_STATE, STATE__API__FAIL, STATE__API__STARTED, STATE__API__SUCCESS } from '../../core/utils/store_utils';
import { invalidateToken } from '../authorization/get_token';
import webClient from '../../core/web_client';
import { buildQueryString, extractErrorData, extractResponseData } from '../../core/utils/api_utils';
import { PRODUCTS_PATH } from '../../core/constants';

export const getProductsForPromotionAction = createAsyncThunk(
    'product/getProductsForPromotionAction',
    async (queryParams, { fulfillWithValue,rejectWithValue }) => {
        try {
            const response = await webClient.get(`${ PRODUCTS_PATH }/promotion${ buildQueryString(queryParams) }`);
            return fulfillWithValue(extractResponseData(response));
        } catch (error) {
            return rejectWithValue(extractErrorData(error));
        }
    }
);

const getProductsForPromotionSlice = createSlice({
    name: 'getProductsForPromotionSlice',
    initialState: DEFAULT_STATE,
    reducers: {},
    extraReducers: builder => {
        builder.addCase(getProductsForPromotionAction.pending, () => STATE__API__STARTED);
        builder.addCase(getProductsForPromotionAction.fulfilled, (_, { payload }) => STATE__API__SUCCESS(payload));
        builder.addCase(getProductsForPromotionAction.rejected, (_, { payload }) => STATE__API__FAIL(payload));
        builder.addCase(invalidateToken, () => DEFAULT_STATE);
    }
});

export const getProductsForPromotionState = (state) => state.product.getProductsForPromotion;
export default getProductsForPromotionSlice.reducer;
