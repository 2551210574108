import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { DEFAULT_STATE, STATE__API__FAIL, STATE__API__STARTED, STATE__API__SUCCESS } from '../../core/utils/store_utils';
import { invalidateToken } from '../authorization/get_token';
import webClient from '../../core/web_client';
import { buildQueryString, extractErrorData, extractResponseData } from '../../core/utils/api_utils';
import { WAREHOUSE_DOCUMENTS_PATH } from '../../core/constants';

export const getWarehouseDocumentsAction = createAsyncThunk(
    'inventory/getWarehouseDocumentsAction',
    async (queryParams, { fulfillWithValue, rejectWithValue }) => {
        try {
            const response = await webClient.get(`${ WAREHOUSE_DOCUMENTS_PATH }${ buildQueryString(queryParams) }`);
            return fulfillWithValue(extractResponseData(response));
        } catch (error) {
            return rejectWithValue(extractErrorData(error));
        }
    }
);

const getWarehouseDocumentsSlice = createSlice({
    name: 'getWarehouseDocumentsSlice',
    initialState: DEFAULT_STATE,
    reducers: {},
    extraReducers: builder => {
        builder.addCase(getWarehouseDocumentsAction.pending, () => STATE__API__STARTED);
        builder.addCase(getWarehouseDocumentsAction.fulfilled, (_, { payload }) => STATE__API__SUCCESS(payload));
        builder.addCase(getWarehouseDocumentsAction.rejected, (_, { payload }) => STATE__API__FAIL(payload));
        builder.addCase(invalidateToken, () => DEFAULT_STATE);
    }
});

export const getWarehouseDocumentsState = (state) => state.inventory.getWarehouseDocuments;
export default getWarehouseDocumentsSlice.reducer;
