import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import webClient from '../../core/web_client';
import { PRODUCT_PROMOTIONS_PATH } from '../../core/constants';
import { buildQueryString, extractErrorData, extractResponseData } from '../../core/utils/api_utils';
import { DEFAULT_STATE, STATE__API__FAIL, STATE__API__STARTED, STATE__API__SUCCESS } from '../../core/utils/store_utils';
import { invalidateToken } from '../authorization/get_token';

export const getProductPromotionAction = createAsyncThunk(
    'price/getProductPromotion',
    async (data, { fulfillWithValue, rejectWithValue }) => {
        try {
            const response = await webClient.get(`${ PRODUCT_PROMOTIONS_PATH }/next-or-active${ buildQueryString(data) }`);
            return fulfillWithValue(extractResponseData(response));
        } catch (error) {
            return rejectWithValue(extractErrorData(error));
        }
    }
);

const getProductPromotionSlice = createSlice({
    name: 'getProductPromotionSlice',
    initialState: DEFAULT_STATE,
    reducers: {
        invalidateProductPromotion() {
            return DEFAULT_STATE;
        }
    },
    extraReducers: builder => {
        builder.addCase(getProductPromotionAction.pending, () => STATE__API__STARTED);
        builder.addCase(getProductPromotionAction.fulfilled, (_, { payload }) => STATE__API__SUCCESS(payload));
        builder.addCase(getProductPromotionAction.rejected, (_, { payload }) => STATE__API__FAIL(payload));
        builder.addCase(invalidateToken, () => DEFAULT_STATE);
    }
});

export const { invalidateProductPromotion } = getProductPromotionSlice.actions;
export const getProductPromotionState = (state) => state.price.getProductPromotion;
export default getProductPromotionSlice.reducer;
