import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import deepmerge from 'deepmerge';
import { Box, Grid, LinearProgress, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';

import useNotification from '../../../core/hooks/use_notification';
import { getPodsNotificationData } from '../../../core/utils/notification_utils';
import PaginationControls from '../../common/pagination_controls';
import { getDefaultPodsPaginationQueryValues } from '../../../core/utils/api_utils';
import { getPodsAction, getPodsState } from '../../../features/pod/get_pods';
import { GET_ROUTE_PODS_EDIT, ROUTE_PODS_NEW } from '../../../core/constants';
import { Button } from "../../common/button";

const initialQueryParams = {
    ...getDefaultPodsPaginationQueryValues
};

const PodsList = ({ tab }) => {
    const dispatch = useDispatch();
    const { showErrorMessage } = useNotification();
    const navigate = useNavigate();

    const getPods = useSelector(getPodsState);

    const [queryParams, setQueryParams] = useState(initialQueryParams);

    useEffect(() => {
        dispatch(getPodsAction(queryParams))
            .then(response => showErrorMessage(getPodsNotificationData(response)));
    }, [dispatch, queryParams, showErrorMessage]);

    return (
        <Grid container>
            <Grid container item mb={ 4 }>
                <Button onClick={ () => navigate(ROUTE_PODS_NEW, { state: { tab } }) }>
                    Dodaj punkt sprzedaży
                </Button>
            </Grid>
            {
                getPods?.loading &&
                <LinearProgress/>
            }
            {
                getPods?.data?.content &&
                <Grid container>
                    <TableContainer>
                        <Table stickyHeader>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Partner</TableCell>
                                    <TableCell>Urządzenie</TableCell>
                                    <TableCell>Opis</TableCell>
                                    <TableCell>Adres</TableCell>
                                    <TableCell align="center">Typ</TableCell>
                                    <TableCell align="center">Edytuj</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    getPods?.data?.content?.map(pod => (
                                        <TableRow
                                            key={ pod?.id }
                                            hover>
                                            <TableCell>
                                                {
                                                    pod?.indoorPartner?.name
                                                }
                                            </TableCell>
                                            <TableCell>{ pod?.device?.serviceId || '-' }</TableCell>
                                            <TableCell>{ pod?.description }</TableCell>
                                            <TableCell>
                                                {
                                                    pod?.address
                                                }
                                            </TableCell>
                                            <TableCell align="center">
                                                {
                                                    pod?.locationType
                                                }
                                            </TableCell>
                                            <TableCell align="center">
                                                <EditIcon onClick={ () => navigate(GET_ROUTE_PODS_EDIT(pod?.id), { state: { tab } }) } cursor="pointer"/>
                                            </TableCell>
                                        </TableRow>
                                    ))
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <Box mt={ 2 }>
                        <PaginationControls
                            currentPage={ getPods?.data?.pageable?.pageNumber }
                            totalPages={ getPods?.data?.totalPages }
                            setPage={ page => setQueryParams(prevState => deepmerge(prevState, { page })) }
                        />
                    </Box>
                </Grid>
            }
        </Grid>
    );
};

export default PodsList;
