import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { FieldArray, Form, Formik } from 'formik';
import { Divider, Grid, Typography } from '@mui/material';
import * as Yup from 'yup';

import TextFieldFormik from '../../../common/text_field';
import { SelectFieldFormik, SelectFieldMultipleFormik } from '../../../common/select_field';
import { getAllPodsState } from '../../../../features/pod/get_all_pods';
import { SubmitButton } from '../../../common/button';
import RadioGroupFormik from '../../../common/radio_group';
import { createNotificationAction, createNotificationState } from '../../../../features/communication/create_notification';
import useNotification from '../../../../core/hooks/use_notification';
import { ApiRequestStatusEnum } from '../../../../core/enums/common/api';
import { createNotificationNotificationData } from '../../../../core/utils/notification_utils';
import { getLanguagesState } from '../../../../features/product/get_languages';
import { getNotificationTypesState } from '../../../../features/communication/get_notification_types';
import { MISC_DATE_ISO_FORMAT, ROUTE_NOTIFICATIONS } from '../../../../core/constants';
import { DateTimePickerFormik } from '../../../common/date_picker';
import { parseDateString } from '../../../../core/utils/date_utils';
import { yesNoOptions } from '../../../common/form_utils';

const NotificationForm = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const navigate = useNavigate();
    const getAllPods = useSelector(getAllPodsState);
    const createNotification = useSelector(createNotificationState);
    const { showNotification } = useNotification();

    const getLanguages = useSelector(getLanguagesState);
    const getNotificationTypes = useSelector(getNotificationTypesState);

    const initialValues = {
        notificationType: location?.state?.notificationRequest?.notificationType || 'GENERAL',
        requestedRealization: '',
        translatedMessages: getLanguages?.data?.map(language => (
            {
                languageCode: language.languageCode,
                title: location?.state?.notificationRequest?.translatedMessages?.find(n => n.languageCode === language.languageCode)?.title || '',
                body: location?.state?.notificationRequest?.translatedMessages?.find(n => n.languageCode === language.languageCode)?.body || ''
            }
        )),
        podIds: location?.state?.notificationRequest?.podIds || [],
        sendToAll: !(location?.state?.notificationRequest?.podIds?.length > 0)
    };

    const schema = Yup.object().shape({
        notificationType: Yup.string().required(),
        requestedRealization: Yup.date()
            .transform(parseDateString)
            .nullable()
            .notRequired(),
        translatedMessages: Yup.array().of(
            Yup.object().shape(
                {
                    languageCode: Yup.string().required(),
                    title: Yup.string().required(),
                    body: Yup.string().required(),
                },
                ['title', 'body']
            )
        ),
        sendToAll: Yup.boolean(),
        podIds: Yup.array().when('sendToAll', {
            is: false,
            then: Yup.array().min(1),
            otherwise: Yup.array().notRequired()
        }),
    });

    const onSubmit = (values) => {
        const form = {
            notificationType: values.notificationType,
            requestedRealization: values.requestedRealization ? values?.requestedRealization?.format(MISC_DATE_ISO_FORMAT) : null,
            translatedMessages: values.translatedMessages,
            podIds: values.podIds
        };

        dispatch(createNotificationAction(form))
            .then(response => {
                showNotification(createNotificationNotificationData(response));
                response?.meta?.requestStatus === ApiRequestStatusEnum.FULFILLED && navigate(ROUTE_NOTIFICATIONS);
            });
    };

    return (
        <Formik
            initialValues={ initialValues }
            validationSchema={ schema }
            validateOnChange={ false }
            validateOnBlur={ false }
            onSubmit={ onSubmit }>
            { (formik) =>
                <Grid component={ Form } container rowGap={ 2 }>
                    <FieldArray name="translatedMessages">
                        { () => (
                            getLanguages?.data?.map((language, index) => {
                                return <Grid container item key={ index }>
                                    <Typography variant="h6" gutterBottom>
                                        { language.languageName }
                                    </Typography>

                                    <Grid container rowGap={ 2 }>
                                        <Grid container item>
                                            <TextFieldFormik name={ `translatedMessages.${ index }.title` } label="Tytuł" required/>
                                        </Grid>

                                        <Grid container item>
                                            <TextFieldFormik name={ `translatedMessages.${ index }.body` } label="Treść" multiline rows={ 6 } required/>
                                        </Grid>
                                    </Grid>
                                </Grid>;
                            })
                        ) }
                    </FieldArray>
                    <Grid container item mb={ 2 } mt={ 2 }>
                        <Divider style={ { width: '100%' } }/>
                    </Grid>
                    <Grid container item>
                        <SelectFieldFormik
                            name="notificationType"
                            label="Typ notyfikacji"
                            options={ getNotificationTypes?.data?.map(type => ({ value: type, label: type })) }
                            required
                        />
                    </Grid>
                    <Grid container item>
                        <DateTimePickerFormik
                            name="requestedRealization"
                            label="Data wysyłki"
                            InputLabelProps={ { shrink: true } }
                        />
                    </Grid>

                    <Grid container item>
                        <RadioGroupFormik
                            name="sendToAll"
                            label="Wyślij do wszystkich"
                            options={ yesNoOptions }
                            onChange={ (value) => value === true && formik.setFieldValue('podIds', []) }
                        />
                    </Grid>
                    {
                        formik.values.sendToAll === false &&
                        <Grid container item>
                            <Grid item xs={ 6 }>
                                <SelectFieldMultipleFormik
                                    name="podIds"
                                    label="Wybierz pody - wiele"
                                    options={ getAllPods?.data?.map(pod => ({ value: pod?.id, label: pod?.indoorPartner?.name + ' (' + pod?.description + ') - ' + (pod?.device?.serviceId || 'brak urządzenia') })) }
                                />
                            </Grid>
                        </Grid>
                    }

                    <Grid container item mt={ 2 } justifyContent="flex-end">
                        <SubmitButton isLoading={ createNotification?.loading }>Wyślij</SubmitButton>
                    </Grid>
                </Grid>
            }
        </Formik>
    );
};

export default NotificationForm;
