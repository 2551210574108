import { EmployerStatusEnum, EmployerStatusFormEnum } from '../../../core/enums/benefit/employer';

export const getEmployerStatus = (status) => {
    switch (status) {
        case EmployerStatusEnum.ACTIVE:
            return 'Aktywny';
        case EmployerStatusEnum.INACTIVE:
            return 'Nieaktywny';
        default:
            return EmployerStatusEnum.UNKNOWN;
    }
};

export const getButtonText = (status) => {
    switch (status) {
        case EmployerStatusEnum.ACTIVE:
            return 'Dezaktywuj';
        case EmployerStatusEnum.INACTIVE:
            return 'Aktywuj';
        default:
            return EmployerStatusEnum.UNKNOWN;
    }
};

export const getUpdateEmployerStatus = (status) => {
    switch (status) {
        case EmployerStatusEnum.ACTIVE:
            return EmployerStatusFormEnum.DEACTIVATE;
        case EmployerStatusEnum.INACTIVE:
            return EmployerStatusFormEnum.ACTIVATE;
        default:
            return EmployerStatusEnum.UNKNOWN;
    }
};