import React from 'react';

import { Box, Button as MuiButton, IconButton, LinearProgress } from '@mui/material';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { makeStyles } from 'tss-react/mui';
import _ from 'lodash';

export const Button = (props) => {
    const { classes, cx } = useStyles();

    if (props?.isLoading) {
        return (
            <MuiButton { ..._.omit(props, ['isLoading']) } className={ cx((classes.root)) + ' ' + cx(classes.loadingButton) } component="div" variant="outlined" role="button">
                &nbsp;
                <LinearProgress className={ cx(classes.loader) }/>
            </MuiButton>
        );
    }

    return <MuiButton { ..._.omit(props, ['isLoading']) } className={ cx((classes.root)) + ' ' + props.className } variant="outlined" role="button"/>;
};

export const SubmitButton = (props) => {
    const { classes, cx } = useStyles();

    return (
        <Box>
            {
                props?.isLoading &&
                <Button component="div" className={ cx(classes.loadingButton) } disabled>
                    &nbsp;
                    <LinearProgress className={ cx(classes.loader) }/>
                </Button>
            }
            {
                !props?.isLoading &&
                <Button children={ props.children } onClick={ props.onClick } className={ cx(classes.submitButton) } disabled={ props.disabled } type="submit"/>
            }
        </Box>
    );
};

export const TileButton = (props) => {
    const { classes, cx } = useStyles();

    return (
        <Box>
            {
                props?.isLoading &&
                <Button component="div" className={ cx(classes.loadingButton, classes.tile) } disabled>
                    &nbsp;
                    <LinearProgress className={ cx(classes.loader) }/>
                </Button>
            }
            {
                !props?.isLoading &&
                <Button children={ props.children } onClick={ props.onClick } className={ cx(classes.submitButton, classes.tile) } disabled={ props.disabled } type="submit"/>
            }
        </Box>
    );
};

export const DeleteButton = (props) => {
    const { classes, cx } = useStyles();

    return (
        <Box>
            {
                props?.isLoading &&
                <Button component="div" className={ cx(classes.loadingButton) } disabled>
                    &nbsp;
                    <LinearProgress className={ cx(classes.loader) }/>
                </Button>
            }
            {
                !props?.isLoading &&
                <IconButton
                    title="Usuń"
                    onClick={ () => props.onDeleteClick() }
                >
                    <DeleteForeverIcon/>
                </IconButton>
            }
        </Box>
    );
};

export const DuplicateButton = (props) => {
    const { classes, cx } = useStyles();

    return (
        <Box>
            {
                props?.isLoading &&
                <Button component="div" className={ cx(classes.loadingButton) } disabled>
                    &nbsp;
                    <LinearProgress className={ cx(classes.loader) }/>
                </Button>
            }
            {
                !props?.isLoading &&
                <IconButton
                    title="Duplikuj"
                    onClick={ () => props.onClick() }
                >
                    <ContentCopyIcon/>
                </IconButton>
            }
        </Box>
    );
};

export const CustomIconButton = (props) => {
    const { classes, cx } = useStyles();

    return (
        <Box>
            {
                props?.isLoading &&
                <Button component="div" className={ cx(classes.loadingButton) } disabled>
                    &nbsp;
                    <LinearProgress className={ cx(classes.loader) }/>
                </Button>
            }
            {
                !props?.isLoading &&
                <IconButton title={ props.title } onClick={ props.onClick }>
                    {
                        props?.icon
                    }
                </IconButton>
            }
        </Box>
    );
};

const useStyles = makeStyles()(() => ({
    root: {
        textAlign: 'center',
    },
    tile: {
        width: '200px',
        height: '100px'
    },
    submitButton: {},
    loadingButton: {
        height: '100%',
        pointer: 'inherit',
    },
    loader: {
        width: '100%',
    }
}));
