import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import DialogActions from '@mui/material/DialogActions';
import { Grid } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import EditIcon from '@mui/icons-material/Edit';
import * as Yup from 'yup';
import { Form, Formik } from 'formik';

import useNotification from '../../../../core/hooks/use_notification';
import { Button, SubmitButton } from '../../../common/button';
import { SelectFieldFormik } from '../../../common/select_field';
import { updatePodPriceAssignmentNotificationData } from '../../../../core/utils/notification_utils';
import { ApiRequestStatusEnum } from '../../../../core/enums/common/api';
import { getPriceTypesState } from '../../../../features/price/get_price_types';
import { updatePodPriceAssignmentAction, updatePodPriceAssignmentState } from '../../../../features/price/update_pod_price_assignment';
import { getPodPriceAssignmentsState } from '../../../../features/price/get_pod_price_assignments';

const UpdatePodPriceAssignmentDialog = ({ podId, refreshList }) => {
    const dispatch = useDispatch();
    const { showNotification } = useNotification();

    const getPriceTypes = useSelector(getPriceTypesState);
    const getPodPriceAssignments = useSelector(getPodPriceAssignmentsState);
    const updatePodPriceAssignment = useSelector(updatePodPriceAssignmentState);

    const [open, setOpen] = useState(false);

    const onClose = () => setOpen(false);

    const initialValues = {
        priceTypeId: getPodPriceAssignments?.data?.find(podPriceAssignment => podPriceAssignment?.podId === podId)?.priceTypeId || ''
    };

    const schema = Yup.object().shape({
        priceTypeId: Yup.number().required()
    });

    const onSubmit = (values) => {
        dispatch(updatePodPriceAssignmentAction({ priceTypeId: values.priceTypeId, podId }))
            .then(response => {
                showNotification(updatePodPriceAssignmentNotificationData(response));
                if (response?.meta?.requestStatus === ApiRequestStatusEnum.FULFILLED) {
                    refreshList && refreshList();
                    onClose();
                }
            });
    };

    return (
        <>
            <EditIcon onClick={ () => setOpen(true) } cursor="pointer"/>
            <Dialog PaperProps={ { sx: { maxHeight: 800 } } } open={ open } onClose={ onClose } fullWidth maxWidth={ 'sm' }>
                <DialogTitle>Zaktualizuj cennik poda</DialogTitle>
                <DialogContent sx={ { paddingTop: '6px !important' } }>
                    <Formik
                        initialValues={ initialValues }
                        validationSchema={ schema }
                        validateOnChange={ false }
                        validateOnBlur={ false }
                        onSubmit={ onSubmit }>
                        { () =>
                            <Grid component={ Form } container rowGap={ 4 }>
                                <Grid container item>
                                    <SelectFieldFormik
                                        name="priceTypeId"
                                        label="Typ cennika"
                                        options={ getPriceTypes?.data?.map(priceType => ({ value: priceType?.id, label: priceType?.name })) }
                                        required
                                    />
                                </Grid>
                                <Grid container item mt={ 2 } justifyContent="flex-end">
                                    <DialogActions sx={ { gap: 2 } }>
                                        <Button onClick={ onClose }>Zamknij</Button>
                                        <SubmitButton isLoading={ updatePodPriceAssignment?.loading }>Zapisz</SubmitButton>
                                    </DialogActions>
                                </Grid>
                            </Grid>
                        }
                    </Formik>
                </DialogContent>
            </Dialog>
        </>
    );
};

export default UpdatePodPriceAssignmentDialog;