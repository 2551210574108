import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import webClient from '../../core/web_client';
import { PROMOTION_CAMPAIGN_PATH } from '../../core/constants';
import { extractErrorData, extractResponseData } from '../../core/utils/api_utils';
import { DEFAULT_STATE, STATE__API__FAIL, STATE__API__STARTED, STATE__API__SUCCESS } from '../../core/utils/store_utils';
import { invalidateToken } from '../authorization/get_token';

export const updatePromotionCampaignNotificationAction = createAsyncThunk(
    'price/updatePromotionCampaignNotificationAction',
    async (data, { fulfillWithValue, rejectWithValue }) => {
        try {
            const response = await webClient.put(`${ PROMOTION_CAMPAIGN_PATH }/${ data.promotionCampaignId }/notification`, data.form);
            return fulfillWithValue(extractResponseData(response));
        } catch (error) {
            return rejectWithValue(extractErrorData(error));
        }
    }
);

const updatePromotionCampaignNotificationSlice = createSlice({
    name: 'updatePromotionCampaignNotificationSlice',
    initialState: DEFAULT_STATE,
    reducers: {},
    extraReducers: builder => {
        builder.addCase(updatePromotionCampaignNotificationAction.pending, () => STATE__API__STARTED);
        builder.addCase(updatePromotionCampaignNotificationAction.fulfilled, (_, { payload }) => STATE__API__SUCCESS(payload));
        builder.addCase(updatePromotionCampaignNotificationAction.rejected, (_, { payload }) => STATE__API__FAIL(payload));
        builder.addCase(invalidateToken, () => DEFAULT_STATE);
    }
});

export const updatePromotionCampaignNotificationState = (state) => state.price.updatePromotionCampaignNotification;
export default updatePromotionCampaignNotificationSlice.reducer;
