import React, { useState } from 'react';

import { Grid, LinearProgress } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';

import SelectField from '../../../../../common/select_field';
import { getReservationStoreStatusOptions } from '../../../../../common/utils/reservation_utils';
import { isNullOrUndefined } from '../../../../../../core/utils/misc_utils';
import useNotification from '../../../../../../core/hooks/use_notification';
import { updateReservationStoreStatusAction, updateReservationStoreStatusState } from '../../../../../../features/reservation/update_reservation_store_status';
import { updateReservationStoreStatusNotificationData } from '../../../../../../core/utils/notification_utils';
import { ApiRequestStatusEnum } from '../../../../../../core/enums/common/api';

const ReservationStoreStatusFormSelect = ({ reservationId, storeStatus, refreshReservations, center }) => {
    const dispatch = useDispatch();
    const { showNotification } = useNotification();

    const updateReservationStoreStatus = useSelector(updateReservationStoreStatusState);

    const [newStatus, setNewStatus] = useState(storeStatus);

    const onSelectSearchCodeChange = (event) => {
        const status = event.target.value;
        setNewStatus(status);

        dispatch(updateReservationStoreStatusAction({ reservationId, form: { status } }))
            .then(response => {
                showNotification(updateReservationStoreStatusNotificationData(response));
                response?.meta?.requestStatus === ApiRequestStatusEnum.FULFILLED && refreshReservations();
            });
    };

    return (
        <>
            {
                isNullOrUndefined(storeStatus)
                    ? <></>
                    :
                    <Grid container mt={ 2 } justifyContent={ center && 'center' }>
                        {
                            updateReservationStoreStatus?.loading
                                ? <LinearProgress sx={ { width: '50%' } }/>
                                :
                                <Grid item>
                                    <SelectField
                                        label={ 'Wewnętrzny status' }
                                        value={ newStatus }
                                        onChange={ onSelectSearchCodeChange }
                                        options={ getReservationStoreStatusOptions }
                                    />
                                </Grid>
                        }
                    </Grid>
            }
        </>
    );
};

export default ReservationStoreStatusFormSelect;
