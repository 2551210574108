import React, { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { Box, Grid, LinearProgress, Stack } from '@mui/material';
import deepmerge from 'deepmerge';

import useNotification from '../../../../../core/hooks/use_notification';
import { Button } from '../../../../common/button';
import { ROUTE_EXPIRATION_PROMOTIONS } from '../../../../../core/constants';
import { getProductExpirationPromotionsAction, getProductExpirationPromotionsState, invalidateProductExpirationPromotions } from '../../../../../features/price/get_product_expiration_promotions';
import { getDefaultPaginationQueryValues } from '../../../../../core/utils/api_utils';
import ExpirationPromotionsFilters from './expiration_promotions_filters';
import { getPodsNotificationData, getPriceNotificationData, getProductBatchesNotificationData, getProductExpirationPromotionsNotificationData, getProductNotificationData } from '../../../../../core/utils/notification_utils';
import ExpirationPromotionsList from './expiration_promotions_list';
import PaginationControls from '../../../../common/pagination_controls';
import { getProductBatchesAction, getProductBatchesState } from '../../../../../features/inventory/get_product_batches';
import { getAllPodsAction, getAllPodsState } from '../../../../../features/pod/get_all_pods';
import PromotionsFormDialog from './form/promotions_form_dialog';
import DeletePromotionsFormDialog from './form/delete_promotions_form_dialog';
import { getPriceAction, getPriceState } from '../../../../../features/price/get_price';
import { getProductAction, getProductState } from '../../../../../features/product/get_product';
import Item from '../../../../common/item';

const initialQueryParams = {
    ...getDefaultPaginationQueryValues,
    podId: '',
    productBatchId: ''
};

const ExpirationPromotionsDetailsPage = () => {
    const dispatch = useDispatch();
    const { showErrorMessage } = useNotification();
    const navigate = useNavigate();
    const { productId } = useParams();
    const location = useLocation();

    const getProduct = useSelector(getProductState);
    const getProductExpirationPromotions = useSelector(getProductExpirationPromotionsState);
    const getProductBatches = useSelector(getProductBatchesState);
    const getAllPods = useSelector(getAllPodsState);
    const getPrice = useSelector(getPriceState);

    const [queryParams, setQueryParams] = useState({ ...initialQueryParams, productId });

    const refreshList = useCallback(() => {
        dispatch(getProductExpirationPromotionsAction(queryParams))
            .then(response => showErrorMessage(getProductExpirationPromotionsNotificationData(response)));
    }, [dispatch, queryParams, showErrorMessage]);

    useEffect(() => {
        refreshList();

        return () => {
            dispatch(invalidateProductExpirationPromotions());
        };
    }, [dispatch, refreshList]);

    useEffect(() => {
        dispatch(getProductAction({ id: productId }))
            .then(respone => showErrorMessage(getProductNotificationData(respone)));
        dispatch(getProductBatchesAction(productId))
            .then(response => showErrorMessage(getProductBatchesNotificationData(response)));
        dispatch(getAllPodsAction())
            .then(response => showErrorMessage(getPodsNotificationData(response)));
        dispatch(getPriceAction(productId))
            .then(response => showErrorMessage(getPriceNotificationData(response)));
    }, [dispatch, productId, showErrorMessage]);

    return (
        <Grid mb={ 6 }>
            <Grid container mb={ 4 } justifyContent="space-between" rowGap={ 2 }>
                <Grid item>
                    <Button onClick={ () => navigate(ROUTE_EXPIRATION_PROMOTIONS, { state: { queryParams: location?.state?.queryParams, tab: location?.state?.tab } }) }>
                        Wróć
                    </Button>
                </Grid>
                {
                    (getProductBatches?.loading || getAllPods?.loading || getPrice?.loading) &&
                    <LinearProgress/>
                }
                {
                    (getProductBatches?.data && getAllPods?.data && getPrice?.data && getProduct?.data) &&
                    <>
                        <Item>
                            <Stack direction={ 'row' } spacing={ 2 }>
                                <span><b>Promocja na towary z krótką datą przydatności</b></span>
                                <span>{ getProduct?.data.name }</span>
                            </Stack>
                        </Item>
                        <Grid container item>
                            <Grid container item rowGap={ 2 }>
                                <ExpirationPromotionsFilters
                                    onSelectPodChange={ podId => setQueryParams(prevState => deepmerge(prevState, { podId })) }
                                    onSelectProductBatchIdChange={ productBatchId => setQueryParams(prevState => deepmerge(prevState, { productBatchId })) }
                                />
                                <Grid container item columnGap={ 2 } rowGap={ 2 }>
                                    <Grid item>
                                        <PromotionsFormDialog
                                            productId={ productId }
                                            refreshList={ refreshList }
                                        />
                                    </Grid>
                                    <Grid item>
                                        <DeletePromotionsFormDialog
                                            productId={ productId }
                                            refreshList={ refreshList }
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </>
                }
            </Grid>
            {
                (getProductExpirationPromotions?.loading || getAllPods?.loading) &&
                <LinearProgress/>
            }
            {
                (getProductExpirationPromotions?.data?.content && getAllPods?.data) &&
                <Grid container>
                    <ExpirationPromotionsList
                        refreshList={ refreshList }/>
                    <Box mt={ 2 }>
                        <PaginationControls
                            currentPage={ getProductExpirationPromotions?.data?.pageable?.pageNumber }
                            totalPages={ getProductExpirationPromotions?.data?.totalPages }
                            setPage={ page => setQueryParams(prevState => deepmerge(prevState, { page })) }
                        />
                    </Box>
                </Grid>
            }
        </Grid>
    );
};

export default ExpirationPromotionsDetailsPage;