import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Box, Grid, LinearProgress, Typography } from '@mui/material';
import deepmerge from 'deepmerge';

import { getDefaultPodsPaginationQueryValues } from '../../../core/utils/api_utils';
import { getPodsNotificationData } from '../../../core/utils/notification_utils';
import { getPodsAction, getPodsState } from '../../../features/pod/get_pods';
import useNotification from '../../../core/hooks/use_notification';
import PaginationControls from '../../common/pagination_controls';
import PodsList from '../common/pods_list';
import { GET_ROUTE_POD_SALES } from '../../../core/constants';

const initialQueryParams = {
    ...getDefaultPodsPaginationQueryValues
};

const SalesPage = () => {
    const dispatch = useDispatch();
    const getPods = useSelector(getPodsState);
    const [queryParams, setQueryParams] = useState(initialQueryParams);
    const { showErrorMessage } = useNotification();

    useEffect(() => {
        dispatch(getPodsAction(queryParams))
            .then(response => showErrorMessage(getPodsNotificationData(response)));
    }, [dispatch, queryParams, showErrorMessage]);

    return (
        <Grid mb={ 6 }>
            <Box mb={ 4 }>
                <Typography variant="h1">Wybierz poda</Typography>
            </Box>
            {
                getPods?.loading &&
                <LinearProgress/>
            }
            {
                getPods?.data?.content &&
                <div>
                    <PodsList
                        route={ GET_ROUTE_POD_SALES }/>
                    <Box mt={ 2 }>
                        <PaginationControls
                            currentPage={ getPods?.data?.pageable?.pageNumber }
                            totalPages={ getPods?.data?.totalPages }
                            setPage={ page => setQueryParams(prevState => deepmerge(prevState, { page })) }
                        />
                    </Box>
                </div>
            }
        </Grid>
    );
};

export default SalesPage;
