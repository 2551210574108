import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useNavigate } from 'react-router-dom';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { getFoodPartnersState } from '../../../features/food_partner/get_food_partners';
import { DeleteButton } from '../../common/button';
import { GET_ROUTE_FOOD_PARTNER_FORMS } from '../../../core/constants';
import EditIcon from '@mui/icons-material/Edit';
import { ApiRequestStatusEnum } from '../../../core/enums/common/api';
import useNotification from '../../../core/hooks/use_notification';
import { deleteFoodPartnerAction, deleteFoodPartnerState } from '../../../features/food_partner/delete_food_partner';
import { deleteFoodPartnerNotificationData } from '../../../core/utils/notification_utils';


const FoodPartnersList = ({refreshList}) => {
    const { showNotification } = useNotification();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const getFoodPartners = useSelector(getFoodPartnersState);
    const deleteFoodPartner = useSelector(deleteFoodPartnerState);

    const onDeleteFoodPartner = (foodPartnerId) => {
        dispatch(deleteFoodPartnerAction(foodPartnerId))
            .then(response => {
                showNotification(deleteFoodPartnerNotificationData(response));
                response?.meta?.requestStatus === ApiRequestStatusEnum.FULFILLED && refreshList();
            });
    }

    return (
        <TableContainer>
            <Table stickyHeader>
                <TableHead>
                    <TableRow>
                        <TableCell>Nazwa</TableCell>
                        <TableCell>Nazwa skrócona</TableCell>
                        <TableCell>NIP</TableCell>
                        <TableCell align="center">Podgląd/edycja</TableCell>
                        <TableCell align="center">Usuń</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        getFoodPartners?.data?.content?.map(foodPartner => (
                            <TableRow
                                key={ foodPartner?.id }
                                hover
                            >
                                <TableCell>{ foodPartner?.name }</TableCell>
                                <TableCell>{ foodPartner?.shortName }</TableCell>
                                <TableCell>{ foodPartner?.nip }</TableCell>
                                <TableCell align="center">
                                    <EditIcon onClick={ () => navigate(GET_ROUTE_FOOD_PARTNER_FORMS(foodPartner?.id)) } cursor="pointer"/>
                                </TableCell>
                                <TableCell align="center">
                                    <DeleteButton onDeleteClick={ () => onDeleteFoodPartner(foodPartner?.id) } isLoading={ deleteFoodPartner?.loading }/>
                                </TableCell>
                            </TableRow>
                        ))
                    }
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default FoodPartnersList;
