import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import webClient from '../../core/web_client';
import { CONSTANTS_PATH } from '../../core/constants';
import { extractErrorData, extractResponseData } from '../../core/utils/api_utils';
import { DEFAULT_STATE, STATE__API__FAIL, STATE__API__STARTED, STATE__API__SUCCESS } from '../../core/utils/store_utils';
import { invalidateToken } from '../authorization/get_token';

export const getConstantsAction = createAsyncThunk(
    'product/getConstantsAction',
    async (languageCode, { fulfillWithValue, rejectWithValue }) => {
        try {
            const response = await webClient.get(`${ CONSTANTS_PATH }?languageCode=${ languageCode || 'PL'  }`);
            return fulfillWithValue(extractResponseData(response));
        } catch (error) {
            return rejectWithValue(extractErrorData(error));
        }
    }
);

const getConstantsSlice = createSlice({
    name: 'getConstantsSlice',
    initialState: DEFAULT_STATE,
    reducers: {
        invalidateConstants() {
            return DEFAULT_STATE;
        }
    },
    extraReducers: builder => {
        builder.addCase(getConstantsAction.pending, () => STATE__API__STARTED);
        builder.addCase(getConstantsAction.fulfilled, (_, { payload }) => STATE__API__SUCCESS(payload));
        builder.addCase(getConstantsAction.rejected, (_, { payload }) => STATE__API__FAIL(payload));
        builder.addCase(invalidateToken, () => DEFAULT_STATE);
    }
});

export const { invalidateConstants } = getConstantsSlice.actions;
export const getConstantsState = (state) => state.product.getConstants;
export default getConstantsSlice.reducer;