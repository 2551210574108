import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import webClient from '../../core/web_client';
import { EMPLOYERS_PATH } from '../../core/constants';
import { extractErrorData, extractResponseData } from '../../core/utils/api_utils';
import { DEFAULT_STATE, STATE__API__FAIL, STATE__API__STARTED, STATE__API__SUCCESS } from '../../core/utils/store_utils';
import { invalidateToken } from '../authorization/get_token';

export const updateEmployerDiscountAction = createAsyncThunk(
    'benefit/updateEmployerDiscountAction',
    async (data, { fulfillWithValue, rejectWithValue }) => {
        try {
            const response = await webClient.put(`${ EMPLOYERS_PATH }/${ data.employerId }/discounts`, data.form);
            return fulfillWithValue(extractResponseData(response));
        } catch (error) {
            return rejectWithValue(extractErrorData(error));
        }
    }
);

const updateEmployerDiscountSlice = createSlice({
    name: 'updateEmployerDiscountSlice',
    initialState: DEFAULT_STATE,
    reducers: {},
    extraReducers: builder => {
        builder.addCase(updateEmployerDiscountAction.pending, () => STATE__API__STARTED);
        builder.addCase(updateEmployerDiscountAction.fulfilled, (_, { payload }) => STATE__API__SUCCESS(payload));
        builder.addCase(updateEmployerDiscountAction.rejected, (_, { payload }) => STATE__API__FAIL(payload));
        builder.addCase(invalidateToken, () => DEFAULT_STATE);
    }
});

export const updateEmployerDiscountState = (state) => state.benefit.updateEmployerDiscount;
export default updateEmployerDiscountSlice.reducer;
