import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import moment from 'moment';
import { Grid, LinearProgress } from '@mui/material';

import useNotification from '../../../../../core/hooks/use_notification';
import { getProductsAvailabilityAction, getProductsAvailabilityState } from '../../../../../features/reservation/get_products_availability';
import { getFoodPartnersForReservationNotificationData, getPodsNotificationData, getProductsAvailabilityNotificationData } from '../../../../../core/utils/notification_utils';
import { MISC_DATE_ISO_DATE_ONLY_FORMAT } from '../../../../../core/constants';
import CalendarFilters from './calendar_filters';
import CalendarList from './calendar_list';
import { getFoodPartnersForReservationAction, getFoodPartnersForReservationState } from '../../../../../features/food_partner/get_food_partners_for_reservation';
import { getAllPodsAction, getAllPodsState } from '../../../../../features/pod/get_all_pods';

const initialQueryParams = {
    languageCode: 'PL',
    from: moment().add(1, 'day'),
    to: moment().add(1, 'day').endOf('week').add(1, 'week')
};

const Calendar = () => {
    const dispatch = useDispatch();
    const { showErrorMessage } = useNotification();

    const getProductsAvailability = useSelector(getProductsAvailabilityState);
    const getFoodPartnersForReservation = useSelector(getFoodPartnersForReservationState);
    const getAllPods = useSelector(getAllPodsState);

    const [queryParams, setQueryParams] = useState(initialQueryParams);

    const refreshCalendar = useCallback(() => {
        const params = {
            ...queryParams,
            from: queryParams?.from?.format(MISC_DATE_ISO_DATE_ONLY_FORMAT),
            to: queryParams?.to?.format(MISC_DATE_ISO_DATE_ONLY_FORMAT)
        };

        dispatch(getProductsAvailabilityAction(params))
            .then(response => showErrorMessage(getProductsAvailabilityNotificationData(response)));
    }, [dispatch, queryParams, showErrorMessage]);

    useEffect(() => {
        refreshCalendar();
    }, [refreshCalendar]);

    useEffect(() => {
        dispatch(getFoodPartnersForReservationAction())
            .then(response => showErrorMessage(getFoodPartnersForReservationNotificationData(response)));
        dispatch(getAllPodsAction())
            .then(response => showErrorMessage(getPodsNotificationData(response)));
    }, [dispatch, showErrorMessage]);

    return (
        <Grid rowGap={ 4 }>
            {
                (getProductsAvailability?.loading || getFoodPartnersForReservation?.loading || getAllPods?.loading) &&
                <LinearProgress/>
            }
            {
                (getProductsAvailability?.data && getFoodPartnersForReservation?.data && getAllPods?.data) &&
                <>
                    <CalendarFilters
                        from={ queryParams.from }
                        onChangeTimeFrom={ from => setQueryParams(prevState => ({ ...prevState, from })) }
                        to={ queryParams.to }
                        onChangeTimeTo={ to => setQueryParams(prevState => ({ ...prevState, to })) }
                        refreshCalendar={ refreshCalendar }/>
                    <CalendarList
                        from={ queryParams.from }
                        to={ queryParams.to }
                        refreshCalendar={ refreshCalendar }/>
                </>
            }
        </Grid>
    );
};

export default Calendar;
