import { deepFreeze } from '../../utils/misc_utils';

export const CustomerErrorMessageEnum = deepFreeze({
    GET_CUSTOMERS_BY_PHONE_NUMBER: 'Pobranie klientów nie powiodło się',
    GET_CUSTOMERS: 'Pobranie klientów nie powiodło się'
});

const CustomerServerErrorMessageEnum = deepFreeze({
});

export const getCustomerErrorMessage = (message, defaultErrorMessage) => {
    for (let key in CustomerServerErrorMessageEnum) {
        if (CustomerServerErrorMessageEnum.hasOwnProperty(key)) {
            if (message?.substring(0, message?.indexOf(' ')) === CustomerServerErrorMessageEnum[key].errorCode) {
                return CustomerServerErrorMessageEnum[key].message;
            }
        }
    }

    return defaultErrorMessage;
};
