import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import deepmerge from 'deepmerge';
import { Alert, Box, Grid, LinearProgress, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';

import { getPodsAction, getPodsState } from '../../../features/pod/get_pods';
import { deletePodPriceAssignmentNotificationData, getPodPriceAssignmentsNotificationData, getPodsNotificationData, getPriceTypesNotificationData } from '../../../core/utils/notification_utils';
import { getDefaultPodsPaginationQueryValues } from '../../../core/utils/api_utils';
import useNotification from '../../../core/hooks/use_notification';
import PaginationControls from '../../common/pagination_controls';
import { DeleteButton } from '../../common/button';
import { ApiRequestStatusEnum } from '../../../core/enums/common/api';
import { deletePodPriceAssignmentAction, deletePodPriceAssignmentState } from '../../../features/price/delete_pod_price_assignment';
import { getPriceTypesAction, getPriceTypesState } from '../../../features/price/get_price_types';
import { getPodPriceAssignmentsAction, getPodPriceAssignmentsState } from '../../../features/price/get_pod_price_assignments';
import UpdatePodPriceAssignmentDialog from './form/update_pod_price_assignment_dialog';

const initialQueryParams = {
    ...getDefaultPodsPaginationQueryValues
};

const PodPriceAssignmentList = () => {
    const dispatch = useDispatch();
    const { showErrorMessage, showNotification } = useNotification();

    const getPods = useSelector(getPodsState);
    const getPriceTypes = useSelector(getPriceTypesState);
    const getPodPriceAssignments = useSelector(getPodPriceAssignmentsState);
    const deletePodPriceAssignment = useSelector(deletePodPriceAssignmentState);

    const [queryParams, setQueryParams] = useState(initialQueryParams);

    const refreshList = useCallback(() => {
        dispatch(getPodsAction(queryParams))
            .then(response => {
                showErrorMessage(getPodsNotificationData(response));

                if (response?.meta?.requestStatus === ApiRequestStatusEnum.FULFILLED) {
                    const podIds = response?.payload?.content?.map(pod => pod?.id);

                    dispatch(getPodPriceAssignmentsAction(podIds))
                        .then(response => showErrorMessage(getPodPriceAssignmentsNotificationData(response)));
                }
            });
    }, [dispatch, queryParams, showErrorMessage]);

    useEffect(() => {
        refreshList();

        dispatch(getPriceTypesAction())
            .then(response => showErrorMessage(getPriceTypesNotificationData(response)));
    }, [dispatch, refreshList, showErrorMessage]);

    const getPodPriceAssignment = (podId) => getPodPriceAssignments?.data?.find(podPriceAssignment => podPriceAssignment?.podId === podId);

    const getPriceTypeName = (podId) => getPriceTypes?.data?.find(priceType => priceType?.id === getPodPriceAssignment(podId)?.priceTypeId)?.name;

    const onDeletePodPriceAssignment = (podPriceAssignmentId) => {
        dispatch(deletePodPriceAssignmentAction(podPriceAssignmentId))
            .then(response => {
                showNotification(deletePodPriceAssignmentNotificationData(response));
                response?.meta?.requestStatus === ApiRequestStatusEnum.FULFILLED && refreshList();
            });
    };

    return (
        <Grid>
            <Alert severity="warning">W przypadku braku przyporządkowania cennika do punktu sprzedaży, stosowany będzie domyślny cennik - "Detaliczny"</Alert>
            {
                (getPods?.loading || getPriceTypes?.loading || getPodPriceAssignments?.loading) &&
                <LinearProgress/>
            }
            {
                (getPods?.data?.content && getPriceTypes?.data && getPodPriceAssignments?.data) &&
                <Grid container>
                    <TableContainer>
                        <Table stickyHeader>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Partner</TableCell>
                                    <TableCell>Urządzenie</TableCell>
                                    <TableCell>Opis</TableCell>
                                    <TableCell>Adres</TableCell>
                                    <TableCell align="center">Typ</TableCell>
                                    <TableCell align="center">Cennik</TableCell>
                                    <TableCell align="center">Edycja</TableCell>
                                    <TableCell align="center">Usuń<br/>przyporządkowanie</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    getPods?.data?.content?.map(pod => (
                                        <TableRow
                                            key={ pod?.id }
                                            hover
                                        >
                                            <TableCell>
                                                {
                                                    pod?.indoorPartner?.name
                                                }
                                            </TableCell>
                                            <TableCell>{ pod?.device?.serviceId || '-' }</TableCell>
                                            <TableCell>{ pod?.description }</TableCell>
                                            <TableCell>
                                                {
                                                    pod?.address
                                                }
                                            </TableCell>
                                            <TableCell align="center">
                                                {
                                                    pod?.locationType
                                                }
                                            </TableCell>
                                            <TableCell align="center">
                                                {
                                                    getPriceTypeName(pod?.id)
                                                }
                                            </TableCell>
                                            <TableCell align="center">
                                                <UpdatePodPriceAssignmentDialog
                                                    podId={ pod?.id }
                                                    refreshList={ refreshList }
                                                />
                                            </TableCell>
                                            <TableCell align="center">
                                                {
                                                    getPodPriceAssignment(pod?.id) &&
                                                    <DeleteButton onDeleteClick={ () => onDeletePodPriceAssignment(getPodPriceAssignment(pod?.id)?.id) } isLoading={ deletePodPriceAssignment?.loading }/>
                                                }
                                            </TableCell>
                                        </TableRow>
                                    ))
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <Box mt={ 2 }>
                        <PaginationControls
                            currentPage={ getPods?.data?.pageable?.pageNumber }
                            totalPages={ getPods?.data?.totalPages }
                            setPage={ page => setQueryParams(prevState => deepmerge(prevState, { page })) }
                        />
                    </Box>
                </Grid>
            }
        </Grid>
    );
};

export default PodPriceAssignmentList;
