import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Box, Grid, LinearProgress } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import _ from 'lodash';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import { Button } from '../../../../../common/button';
import useNotification from '../../../../../../core/hooks/use_notification';
import { getFoodPartnerOrderHistoryNotificationData } from '../../../../../../core/utils/notification_utils';
import PaginationControls from '../../../../../common/pagination_controls';
import { getDefaultFoodPartnerOrderHistoryPaginationQueryValues } from '../../../../../../core/utils/api_utils';
import { MISC_DATE_ISO_DATE_ONLY_FORMAT } from '../../../../../../core/constants';
import { getFoodPartnerOrderHistoryAction, getFoodPartnerOrderHistoryState, invalidateFoodPartnerOrderHistory } from '../../../../../../features/inventory/get_food_partner_order_history';
import FoodPartnerOrderHistory from './food_partner_order_history';
import FoodPartnerOrderHistoryDetails from './food_partner_order_history_details';

const initialQueryParams = {
    ...getDefaultFoodPartnerOrderHistoryPaginationQueryValues,
    fromDate: '',
    toDate: ''
};

const CopyHistoricalDialog = ({ form, onCopyHistoricalOrder }) => {
    const dispatch = useDispatch();
    const { showErrorMessage } = useNotification();

    const getFoodPartnerOrderHistory = useSelector(getFoodPartnerOrderHistoryState);

    const [open, setOpen] = useState(false);
    const [tab, setTab] = useState(0);
    const [orderIndex, setOrderIndex] = useState(0);

    const [queryParams, setQueryParams] = useState(initialQueryParams);

    useEffect(() => {
        if (open) {
            const fromDate = queryParams?.fromDate && queryParams?.fromDate?.format(MISC_DATE_ISO_DATE_ONLY_FORMAT);
            const toDate = queryParams?.toDate && queryParams?.toDate?.format(MISC_DATE_ISO_DATE_ONLY_FORMAT);

            dispatch(getFoodPartnerOrderHistoryAction({ ..._.omit(form, ['orderDate']), ...queryParams, fromDate, toDate }))
                .then(response => {
                    showErrorMessage(getFoodPartnerOrderHistoryNotificationData(response));
                });
        }
    }, [dispatch, open, form, queryParams, showErrorMessage]);

    const onOpen = () => {
        setQueryParams(initialQueryParams);
        setOpen(true);
    };

    const onClose = () => {
        setOpen(false);
        setTab(0);
        dispatch(invalidateFoodPartnerOrderHistory());
    };

    const onCancel = () => {
        setOrderIndex(0);
        setTab(0);
    };

    const onChangeDateFrom = (fromDate) => {
        setTab(0);
        setQueryParams(prevState => ({ ...prevState, fromDate }));
    };

    const onChangeDateTo = (toDate) => {
        setTab(0);
        setQueryParams(prevState => ({ ...prevState, toDate }));
    };

    const onClickOrder = (index) => {
        setOrderIndex(index);
        setTab(1);
    };

    const renderView = () => {
        if (getFoodPartnerOrderHistory?.loading) {
            return (
                <Grid mt={ 6 } item width={ '100%' }>
                    <LinearProgress/>
                </Grid>
            );
        }

        if (getFoodPartnerOrderHistory?.data) {
            if (tab === 0) {
                return (
                    getFoodPartnerOrderHistory?.data?.content &&
                    <Grid container item>
                        <FoodPartnerOrderHistory
                            onClickOrder={ onClickOrder }
                        />
                        <Box mt={ 2 }>
                            <PaginationControls
                                currentPage={ getFoodPartnerOrderHistory?.data?.pageable?.pageNumber }
                                totalPages={ getFoodPartnerOrderHistory?.data?.totalPages }
                                setPage={ page => setQueryParams(prevState => ({ ...prevState, page })) }
                            />
                        </Box>
                    </Grid>
                );
            } else {
                return (
                    <FoodPartnerOrderHistoryDetails
                        orderIndex={ orderIndex }
                        locationDivision={ form?.locationDivision }/>
                );
            }
        }

        return <></>;
    };

    return (
        <>
            <Button onClick={ onOpen } cursor="pointer" sx={ { height: '60px' } }>
                Wczytaj dane z historycznego zamówienia
            </Button>
            <Dialog PaperProps={ { sx: { maxHeight: 800 } } } open={ open } onClose={ onClose } fullWidth maxWidth={ 'md' }>
                <DialogContent sx={ { marginTop: 3 } }>
                    <Grid container>
                        <Grid container item rowGap={ 4 }>
                            <Grid container item justifyContent={ 'space-between' }>
                                <Grid item>
                                    <Grid container gap={ 4 }>
                                        <Grid item>
                                            <DatePicker
                                                displayWeekNumber
                                                label="Data zamówienia od"
                                                onChange={ newDate => onChangeDateFrom(newDate) }
                                                slotProps={ {
                                                    textField: {
                                                        InputLabelProps: {
                                                            shrink: true
                                                        }
                                                    }
                                                } }
                                            />
                                        </Grid>
                                        <Grid item>
                                            <DatePicker
                                                displayWeekNumber
                                                label="Data zamówienia do"
                                                onChange={ newDate => onChangeDateTo(newDate) }
                                                slotProps={ {
                                                    textField: {
                                                        InputLabelProps: {
                                                            shrink: true
                                                        }
                                                    }
                                                } }
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                {
                                    tab === 1 &&
                                    <Grid item>
                                        <Grid container item gap={ 2 }>
                                            <Button onClick={ onCancel }>Wróć</Button>
                                            <Button onClick={ () => {
                                                onCopyHistoricalOrder(getFoodPartnerOrderHistory?.data?.content?.[orderIndex]?.positions);
                                                onClose();
                                            } }>Wybierz</Button>
                                        </Grid>
                                    </Grid>
                                }
                            </Grid>
                            {
                                renderView()
                            }
                        </Grid>
                        <Grid container item mt={ 2 } justifyContent="flex-end">
                            <DialogActions>
                                <Button onClick={ onClose }>Zamknij</Button>
                            </DialogActions>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
        </>
    );
};

export default CopyHistoricalDialog;
