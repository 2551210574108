import React from 'react';
import { useDispatch, useSelector } from "react-redux";
import useNotification from "../../../../core/hooks/use_notification";
import { useNavigate } from "react-router-dom";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { getWarehouseDocumentsState } from "../../../../features/inventory/get_warehouse_documents";
import { generateWarehouseDocumentLabelsAction, generateWarehouseDocumentLabelsState } from "../../../../features/inventory/generate_warehouse_document_labels";
import { generateWarehouseDocumentLabelsNotificationData } from "../../../../core/utils/notification_utils";
import moment from "moment";
import { GET_ROUTE_WAREHOUSE_DOCUMENT, MISC_DATE_ISO_FORMAT, MISC_DATE_POLISH_FORMAT } from "../../../../core/constants";
import { CustomIconButton } from "../../../common/button";
import DownloadIcon from "@mui/icons-material/Download";
import { FormRouteEnum } from "../../../../core/enums/common/route";
import SearchIcon from "@mui/icons-material/Search";
import { Check as CheckIcon, HourglassEmpty as HourglassEmptyIcon } from "@mui/icons-material";

const WarehouseDocumentsList = ({ refreshList }) => {
    const dispatch = useDispatch();
    const { showNotification } = useNotification();
    const navigate = useNavigate();

    const getWarehouseDocuments = useSelector(getWarehouseDocumentsState);
    const generateWarehouseDocumentLabels = useSelector(generateWarehouseDocumentLabelsState);

    const onGenerateWarehouseDocumentLabels = (warehouseDocumentId) => {
        dispatch(generateWarehouseDocumentLabelsAction({ id: warehouseDocumentId }))
            .then(response => {
                showNotification(generateWarehouseDocumentLabelsNotificationData(response));
            });
    };

    return (
        <TableContainer>
            <Table stickyHeader>
                <TableHead>
                    <TableRow>
                        <TableCell align="center">Typ</TableCell>
                        <TableCell align="center"></TableCell>
                        <TableCell>Numer</TableCell>
                        <TableCell align="center">Data utworzenia </TableCell>
                        <TableCell align="center">Data dostawy</TableCell>
                        <TableCell>Kontrahent</TableCell>
                        <TableCell align="center">Pobierz naklejki</TableCell>
                        <TableCell align="center">Podgląd</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        getWarehouseDocuments?.data?.content?.map(warehouseDocument => (
                            <TableRow
                                key={ warehouseDocument?.id }
                                hover
                            >
                                <TableCell align="center">{ warehouseDocument?.documentType }</TableCell>
                                <TableCell align="center">
                                    {
                                        warehouseDocument?.confirmed
                                            ? <CheckIcon color="success"/>
                                            : <HourglassEmptyIcon color="error"/>

                                    }
                                </TableCell>
                                <TableCell>{ warehouseDocument?.name }</TableCell>
                                <TableCell align="center">
                                    {
                                        moment(warehouseDocument?.issueDate, MISC_DATE_ISO_FORMAT)
                                            .format(MISC_DATE_POLISH_FORMAT)
                                    }
                                </TableCell>
                                <TableCell align="center">
                                    {
                                        warehouseDocument?.deliveryDate ? moment(warehouseDocument?.deliveryDate, MISC_DATE_ISO_FORMAT)
                                            .format(MISC_DATE_POLISH_FORMAT) : '-'
                                    }
                                </TableCell>
                                <TableCell>{ warehouseDocument?.counterpartyName }</TableCell>
                                <TableCell align="center">
                                    {
                                        <CustomIconButton
                                            title={ 'Pobierz naklejki' }
                                            isLoading={ generateWarehouseDocumentLabels?.loading }
                                            onClick={ () => onGenerateWarehouseDocumentLabels(warehouseDocument?.id) }
                                            icon={ <DownloadIcon/> }/>
                                    }
                                </TableCell>
                                <TableCell align="center">
                                    <SearchIcon onClick={ () => navigate(GET_ROUTE_WAREHOUSE_DOCUMENT(warehouseDocument?.id), { state: { action: FormRouteEnum.VIEW } }) } cursor="pointer"/>
                                </TableCell>
                            </TableRow>
                        ))
                    }
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default WarehouseDocumentsList;