import React from 'react';
import useNotification from '../../../../../core/hooks/use_notification';
import { useDispatch, useSelector } from 'react-redux';
import { getDocumentsState } from '../../../../../features/food_partner/get_documents';
import { deleteDocumentAction, deleteDocumentState } from '../../../../../features/food_partner/delete_document';
import { deleteDocumentNotificationData } from '../../../../../core/utils/notification_utils';
import { ApiRequestStatusEnum } from '../../../../../core/enums/common/api';
import { Link, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { DeleteButton } from '../../../../common/button';
import DownloadIcon from '@mui/icons-material/Download';

const DocumentsList = ({ foodPartnerId, refreshList }) => {
    const { showNotification } = useNotification();
    const dispatch = useDispatch();

    const getDocuments = useSelector(getDocumentsState);
    const deleteDocument = useSelector(deleteDocumentState);

    const onDeleteDocument = (documentId) => {
        dispatch(deleteDocumentAction({ foodPartnerId, documentId }))
            .then(response => {
                showNotification(deleteDocumentNotificationData(response));
                response?.meta?.requestStatus === ApiRequestStatusEnum.FULFILLED && refreshList();
            });
    }

    return (
        <TableContainer>
            <Table stickyHeader>
                <TableHead>
                    <TableRow>
                        <TableCell>Nazwa</TableCell>
                        <TableCell align="center">Pobierz</TableCell>
                        <TableCell align="center">Usuń</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        getDocuments?.data?.map(document => (
                            <TableRow
                                key={ document?.id }
                                hover
                            >
                                <TableCell>{ document?.name }</TableCell>
                                <TableCell align="center">
                                    <Link
                                        href={ document?.url }
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        <DownloadIcon cursor="pointer" />
                                    </Link>
                                </TableCell>
                                <TableCell align="center">
                                    <DeleteButton onDeleteClick={ () => onDeleteDocument(document?.id) } isLoading={ deleteDocument?.loading }/>
                                </TableCell>
                            </TableRow>
                        ))
                    }
                </TableBody>
            </Table>
        </TableContainer>
    );
}

export default DocumentsList;
