import React, { useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { Box, Grid, LinearProgress } from '@mui/material';
import * as Yup from 'yup';
import { Form, Formik } from 'formik';

import useNotification from '../../../../core/hooks/use_notification';
import { createPodNotificationData, getAllIndoorPartnersNotificationData, getDevicesNotificationData, getPodNotificationData, updatePodNotificationData } from '../../../../core/utils/notification_utils';
import { Button, SubmitButton } from '../../../common/button';
import { GET_ROUTE_PODS_EDIT, ROUTE_PODS } from '../../../../core/constants';
import TextFieldFormik from '../../../common/text_field';
import { getPodAction, getPodState } from '../../../../features/pod/get_pod';
import { getAllIndoorPartnersAction, getAllIndoorPartnersState } from '../../../../features/pod/get_all_indoor_partners';
import { SelectFieldFormik } from '../../../common/select_field';
import { getAccessTypeOptions, getLocationTypeOptions, getPodPurposeOptions } from '../../../common/utils/pod_utils';
import { updatePodAction, updatePodState } from '../../../../features/pod/update_pod';
import { getDevicesAction, getDevicesState } from "../../../../features/pod/get_devices";
import { ApiRequestStatusEnum } from "../../../../core/enums/common/api";
import { createPodAction } from "../../../../features/pod/create_pod";

const PodForm = () => {
    const { showErrorMessage, showNotification } = useNotification();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { podId } = useParams();
    const location = useLocation();

    const getPod = useSelector(getPodState);
    const getDevices = useSelector(getDevicesState);
    const getAllIndoorPartners = useSelector(getAllIndoorPartnersState);
    const updatePod = useSelector(updatePodState);

    const initialValues = {
        name: (podId && getPod?.data?.name) || '',
        deviceId: getDevices?.data?.find(device => device?.id === getPod?.data?.device?.id)?.id || '',
        description: (podId && getPod?.data?.description) || '',
        address: (podId && getPod?.data?.address) || '',
        longitude: (podId && getPod?.data?.longitude) || '',
        latitude: (podId && getPod?.data?.latitude) || '',
        accessType: (podId && getPod?.data?.accessType) || '',
        indoorPartnerId: getAllIndoorPartners?.data?.find(indoorPartner => indoorPartner?.partnerId === getPod?.data?.indoorPartner?.partnerId)?.partnerId || '',
        purpose: (podId && getPod?.data?.purpose) || '',
        locationType: (podId && getPod?.data?.locationTypeEnum) || ''
    };

    const schema = Yup.object().shape({
        name: Yup.string().required(),
        deviceId: Yup.string().nullable(),
        description: Yup.string().required(),
        address: Yup.string().required(),
        longitude: Yup.number().required(),
        latitude: Yup.number().required(),
        accessType: Yup.string().required(),
        indoorPartnerId: Yup.number().required(),
        purpose: Yup.string().required(),
        locationType: Yup.string().required(),
    });

    useEffect(() => {
        podId && dispatch(getPodAction({ id: podId }))
            .then(response => showErrorMessage(getPodNotificationData(response)));

        dispatch(getAllIndoorPartnersAction())
            .then(response => showErrorMessage(getAllIndoorPartnersNotificationData(response)));

        dispatch(getDevicesAction())
            .then(response => showErrorMessage(getDevicesNotificationData(response)));
    }, [podId, dispatch, showErrorMessage]);

    const onSubmit = (values) => {
        const form = { ...values };

        if(!podId) {
            dispatch(createPodAction(form))
                .then(response => {
                    showNotification(createPodNotificationData(response));
                    response?.meta?.requestStatus === ApiRequestStatusEnum.FULFILLED && navigate(GET_ROUTE_PODS_EDIT(response?.payload.id), { state: { tab: location?.state?.tab } });
                });
        } else {
            dispatch(updatePodAction({ id: podId, form }))
                .then(response => {
                    showNotification(updatePodNotificationData(response));
                });
        }
    };

    const renderView = () => {
        if (getPod?.loading || getAllIndoorPartners?.loading || getDevices?.loading) {
            return (
                <Box mt={ 6 }>
                    <LinearProgress/>
                </Box>
            );
        }

        return (
            <Formik
                initialValues={ initialValues }
                validationSchema={ schema }
                validateOnChange={ false }
                validateOnBlur={ false }
                onSubmit={ onSubmit }>
                { () =>
                    <Grid component={ Form } container rowGap={ 2 }>
                        <Grid container item>
                            <Grid item xs={ 6 }>
                                <SelectFieldFormik
                                    name="indoorPartnerId"
                                    label="Partner handlowy"
                                    options={ getAllIndoorPartners?.data?.map(indoorPartner => ({ value: indoorPartner?.partnerId, label: indoorPartner?.name })) }
                                    required
                                />
                            </Grid>
                        </Grid>
                        <Grid container item>
                            <Grid item xs={ 6 }>
                                <SelectFieldFormik
                                    name="deviceId"
                                    label="Urządzenie"
                                    options={ [{ value: null, label: 'Nie wybrano' }, ...getDevices?.data?.map(device => ({ value: device?.id, label: device?.serviceId })) || []] }
                                />
                            </Grid>
                        </Grid>
                        <Grid container item>
                            <TextFieldFormik name="name" label="Nazwa" required/>
                        </Grid>
                        <Grid container item>
                            <TextFieldFormik name="description" label="Opis" required/>
                        </Grid>
                        <Grid container item>
                            <TextFieldFormik name="address" label="Adres" required/>
                        </Grid>
                        <Grid container item>
                            <TextFieldFormik name="longitude" label="Długość geograficzna" required/>
                        </Grid>
                        <Grid container item>
                            <TextFieldFormik name="latitude" label="Szerokość geograficzna" required/>
                        </Grid>

                        <Grid container item>
                            <Grid item xs={ 6 }>
                                <SelectFieldFormik
                                    name="accessType"
                                    label="Typ dostępu"
                                    options={ getAccessTypeOptions }
                                    required
                                />
                            </Grid>
                        </Grid>
                        <Grid container item>
                            <Grid item xs={ 6 }>
                                <SelectFieldFormik
                                    name="purpose"
                                    label="Cel"
                                    options={ getPodPurposeOptions }
                                    required
                                />
                            </Grid>
                        </Grid>
                        <Grid container item>
                            <Grid item xs={ 6 }>
                                <SelectFieldFormik
                                    name="locationType"
                                    label="Typ lokalizacji"
                                    options={ getLocationTypeOptions }
                                    required
                                />
                            </Grid>
                        </Grid>
                        <Grid container item mt={ 2 } justifyContent="flex-end">
                            <SubmitButton isLoading={ updatePod?.loading }>
                                Zapisz
                            </SubmitButton>
                        </Grid>
                    </Grid>
                }
            </Formik>
        );
    };

    return (
        <Grid mb={ 6 }>
            <Grid container mb={ 4 } justifyContent="space-between" rowGap={ 2 }>
                <Grid container item>
                    <Button onClick={ () => navigate(ROUTE_PODS, { state: { tab: location?.state?.tab } }) }>
                        Wróć
                    </Button>
                </Grid>
            </Grid>
            {
                renderView()
            }
        </Grid>
    );
};

export default PodForm;
