import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import deepmerge from 'deepmerge';
import { Box, Grid, LinearProgress, Typography } from '@mui/material';

import useNotification from '../../../core/hooks/use_notification';
import { getDeliveryNotificationsNotificationData } from '../../../core/utils/notification_utils';
import { Button } from '../../common/button';
import { ROUTE_DELIVERY_NOTIFICATIONS_NEW } from '../../../core/constants';
import PaginationControls from '../../common/pagination_controls';
import { getDefaultPaginationQueryValues } from '../../../core/utils/api_utils';
import { getDeliveryNotificationsAction, getDeliveryNotificationsState } from '../../../features/communication/get_delivery_notifications';
import DeliveryNotificationsList from './delivery_notifications_list';
import { FormRouteEnum } from '../../../core/enums/common/route';

const initialQueryParams = {
    ...getDefaultPaginationQueryValues,
    sort: ['workingName', 'asc']
};

const DeliveryNotificationsPage = () => {
    const dispatch = useDispatch();
    const { showErrorMessage } = useNotification();
    const navigate = useNavigate();

    const getDeliveryNotifications = useSelector(getDeliveryNotificationsState);

    const [queryParams, setQueryParams] = useState(initialQueryParams);

    const refreshList = useCallback(() => {
        dispatch(getDeliveryNotificationsAction(queryParams))
            .then(response => showErrorMessage(getDeliveryNotificationsNotificationData(response)));
    }, [dispatch, queryParams, showErrorMessage]);

    useEffect(() => {
        refreshList();
    }, [refreshList]);

    return (
        <Grid mb={ 6 }>
            <Grid container mb={ 4 } rowGap={ 4 } justifyContent="space-between">
                <Grid container>
                    <Typography variant="h1">Powiadomienia dostaw</Typography>
                </Grid>
                <Grid container>
                    <Button onClick={ () => navigate(ROUTE_DELIVERY_NOTIFICATIONS_NEW, { state: { action: FormRouteEnum.NEW } }) }>
                        Dodaj powiadomienie
                    </Button>
                </Grid>
            </Grid>
            {
                getDeliveryNotifications?.loading &&
                <LinearProgress/>
            }
            {
                getDeliveryNotifications?.data?.content &&
                <>
                    <DeliveryNotificationsList
                        refreshList={ refreshList }/>
                    <Box mt={ 2 }>
                        <PaginationControls
                            currentPage={ getDeliveryNotifications?.data?.pageable?.pageNumber }
                            totalPages={ getDeliveryNotifications?.data?.totalPages }
                            setPage={ page => setQueryParams(prevState => deepmerge(prevState, { page })) }
                        />
                    </Box>
                </>
            }
        </Grid>
    );
};

export default DeliveryNotificationsPage;
