import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import { Box, Grid, IconButton, LinearProgress } from '@mui/material';
import DialogActions from '@mui/material/DialogActions';
import InventoryIcon from '@mui/icons-material/Inventory';

import useNotification from '../../../../core/hooks/use_notification';
import { Button } from '../../../common/button';
import { getAllProductProductsNotificationData, updateAdvertisementProductsNotificationData } from '../../../../core/utils/notification_utils';
import { ApiRequestStatusEnum } from '../../../../core/enums/common/api';
import { updateAdvertisementProductsAction, updateAdvertisementProductsState } from "../../../../features/pod/update_advertisement_products";
import { getAllProductsAction, getAllProductsState } from "../../../../features/product/get_all_products";
import _ from "lodash";
import AutocompleteMultipleSearchOnType from "../../../common/autocomplete";

const AdvertisementProductDialog = ({ advertisement, refreshList }) => {
    const { showNotification, showErrorMessage } = useNotification();
    const dispatch = useDispatch();

    const updateAdvertisementProducts = useSelector(updateAdvertisementProductsState);
    const getAllProducts = useSelector(getAllProductsState);

    const mapProductsOptions = useCallback((data) => {
        return data?.map(product => ({
            key: product?.id + Math.random(),
            value: product?.id,
            label: product?.name
        }));
    }, []);

    const [open, setOpen] = useState(false);
    const [productsOptions, setProductsOptions] = useState(mapProductsOptions(getAllProducts?.data) || []);
    const [loadingProductsOptions, setLoadingProductsOptions] = useState(false);
    const [initialLoading, setInitialLoading] = useState(false);
    const [chosenProducts, setChosenProducts] = useState([]);

    const onOpen = () => {
        setInitialLoading(true);
        dispatch(getAllProductsAction())
            .then(response => {
                showErrorMessage(getAllProductProductsNotificationData(response));
                if (response?.meta?.requestStatus === ApiRequestStatusEnum.FULFILLED) {
                    const initialProducts = mapProductsOptions(response?.payload.filter(product => advertisement?.productIds?.includes(product?.id))) || [];
                    setChosenProducts(initialProducts);
                    setProductsOptions(initialProducts);
                }
                setInitialLoading(false);
            });
        setOpen(true);
    }

    const onClose = () => setOpen(false);

    const onProductsInputChange = (query) => {
        if (query.length >= 3) {
            setProductsOptions([]);
            setLoadingProductsOptions(true);

            getProductsDebounced(query);
        }
    };

    const getProductsDebounced = _.debounce((query) => {
        dispatch(getAllProductsAction({ query }))
            .then(response => {
                showErrorMessage(getAllProductProductsNotificationData(response));
                if (response?.meta?.requestStatus === ApiRequestStatusEnum.FULFILLED) {
                    const newProductsOptions = mapProductsOptions(response?.payload).concat(chosenProducts);
                    const newDistinctProductsOptions = [...new Map(newProductsOptions.map((option) => [option['value'], option])).values()];
                    setProductsOptions(newDistinctProductsOptions);
                }
                setLoadingProductsOptions(false);
            });
    }, 600)


    const onSubmit = () => {
        const productIds = chosenProducts?.map(product => product?.value) || [];
        const form = {
            productIds
        };

        dispatch(updateAdvertisementProductsAction({ id: advertisement?.id, form }))
            .then(response => {
                showNotification(updateAdvertisementProductsNotificationData(response));
                if (response?.meta?.requestStatus === ApiRequestStatusEnum.FULFILLED) {
                    refreshList && refreshList();
                    onClose();
                }
            });
    };

    return (
        <>
            <IconButton title="Zmień powiązane produkty" onClick={ onOpen }>
                <InventoryIcon/>
            </IconButton>
            <Dialog open={ open } onClose={ onClose } fullWidth maxWidth={ 'md' }>
                <DialogTitle>Zmień powiązane produkty</DialogTitle>
                <DialogContent sx={ { paddingTop: '6px !important' } }>
                    {
                        initialLoading &&
                        <Box mt={ 6 }><LinearProgress/></Box>
                    }
                    {
                        !initialLoading &&
                        <Grid container rowGap={4}>
                            <Grid container item md={12} rowGap={2}>
                                <Grid container item>
                                    <AutocompleteMultipleSearchOnType
                                        options={productsOptions}
                                        value={chosenProducts}
                                        setValue={(newValue) => setChosenProducts(newValue)}
                                        onInputChange={(value) => onProductsInputChange(value)}
                                        name="products"
                                        label="Produkty"
                                        loading={loadingProductsOptions}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container item mt={2} justifyContent="flex-end">
                                <DialogActions sx={{ gap: 2 }}>
                                    <Button onClick={onSubmit}
                                            isLoading={updateAdvertisementProducts?.loading}>Zapisz</Button>
                                    <Button onClick={onClose}>Zamknij</Button>
                                </DialogActions>
                            </Grid>
                        </Grid>
                    }
                </DialogContent>
            </Dialog>
        </>
    );
};

export default AdvertisementProductDialog;