import { combineReducers, createAsyncThunk } from '@reduxjs/toolkit';

import getCoupon from './get_coupon';
import getCoupons from './get_coupons';
import updateCouponStatus from './update_coupon_status';
import getCouponTranslation from './get_coupon_translation';
import createCoupon from './create_coupon';
import updateCoupon from './update_coupon';
import updateCouponTranslation from './update_coupon_translation';
import getAutomaticCouponSettings from './get_automatic_coupon_settings';
import getAutomaticCouponSetting from './get_automatic_coupon_setting';
import updateAutomaticCouponSetting from './update_automatic_coupon_setting';
import updateAutomaticCouponSettingStatus from './update_automatic_coupon_setting_status';
import getAutomaticCouponSettingTranslation from './get_automatic_coupon_setting_translation';
import updateAutomaticCouponSettingTranslation from './update_automatic_coupon_setting_translation';
import { webClientFileDownload } from "../../core/web_client";
import { extractErrorData, extractResponseData } from "../../core/utils/api_utils";

export const getCouponPicture = createAsyncThunk(
    'product/getCouponPicture',
    async (data, { fulfillWithValue, rejectWithValue }) => {
        try {
            const response = await webClientFileDownload.get(`${ data.pictureUrl }`);
            return fulfillWithValue(extractResponseData(response));
        } catch (error) {
            return rejectWithValue(extractErrorData(error));
        }
    }
);

export default combineReducers({
    getCoupon, getCoupons, updateCouponStatus,
    createCoupon, updateCoupon, getCouponTranslation, updateCouponTranslation, getAutomaticCouponSettings,
    getAutomaticCouponSetting, updateAutomaticCouponSetting, updateAutomaticCouponSettingStatus, getAutomaticCouponSettingTranslation,
    updateAutomaticCouponSettingTranslation
});
