import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Divider, Grid } from '@mui/material';
import { Form, Formik } from 'formik';
import moment from 'moment';

import useNotification from '../../../../../../core/hooks/use_notification';
import { getFoodPartnerAction } from '../../../../../../features/food_partner/get_food_partner';
import { getFoodPartnerNotificationData } from '../../../../../../core/utils/notification_utils';
import { isEmptyObject } from '../../../../../../core/utils/misc_utils';
import { MISC_DATE_ISO_DATE_ONLY_FORMAT, MISC_DATE_ISO_FORMAT } from '../../../../../../core/constants';
import ThreeLevelSteps from '../../../../common/warehouse_and_food_partner_order_form/three_level_steps';
import AddDestinationDialog from '../../../../common/warehouse_and_food_partner_order_form/add_destination_dialog';
import DestinationsList from '../common/destination_list';
import Buttons from '../../../../common/warehouse_and_food_partner_order_form/buttons';
import AdditionalOptions from '../common/additional_options';
import { getAllPodsState } from '../../../../../../features/pod/get_all_pods';
import UploadFiles from '../../../../common/warehouse_and_food_partner_order_form/upload_files';

const FormWithLocationDivision = ({ form, setView, data, setData }) => {
    const dispatch = useDispatch();
    const { showErrorMessage } = useNotification();

    const getAllPods = useSelector(getAllPodsState);

    useEffect(() => {
        dispatch(getFoodPartnerAction(form?.foodPartnerId))
            .then(response => showErrorMessage(getFoodPartnerNotificationData(response)));
    }, [dispatch, form, showErrorMessage]);

    const initialValues = {
        destinationsList: data?.formik?.destinationsList || [],
        productsList: data?.formik?.productsList || [],
        attachments: data?.formik?.attachments || []
    };

    const onReturn = () => {
        setData({});
        setView(0);
    };

    const onChangeSetDate = (newDate, allDates, formik) => {
        formik?.values?.destinationsList?.forEach((_, index) => {
            const productsList = formik?.values?.productsList[index]?.map(product => {
                if (!allDates && product?.expirationDate) {
                    return product;
                }

                return ({ ...product, expirationDate: newDate });
            });

            if (productsList?.length !== 0) {
                formik.setFieldValue(`productsList.${ index }`, productsList);
            }
        });
    };

    const onCopyHistoricalOrder = (values, formik) => {
        const destinationsList = [];
        const productsList = [];

        if (!getAllPods?.data) return;

        Object.keys(values)?.forEach((key, index) => {
            destinationsList.push({
                position: index,
                name: getAllPods?.data?.map(pod => ({ id: pod?.id, label: pod?.indoorPartner?.name + ' (' + pod?.description + ') - ' + (pod?.device?.serviceId || 'brak urządzenia') })).find(pod => pod?.id === key)?.label,
                destinationPodId: key
            });

            productsList[index] = values?.[key]?.map((product, index) => ({
                position: index,
                name: product?.productName,
                productId: product?.productId,
                productBatchId: '',
                quantity: product?.quantity,
                expirationDate: product?.defaultDaysToExpiration ? moment(form?.orderDate).add(product?.defaultDaysToExpiration - 1, 'days') : '',
                price: product?.purchaseNetPrice || '',
                type: product?.type
            }));
        });

        formik.setFieldValue('destinationsList', destinationsList);
        formik.setFieldValue('productsList', productsList);
    };

    const onSubmit = (formik) => {
        const errors = validate(formik.values);

        if (errors.productsList.length ||
            errors.products.length ||
            !isEmptyObject(errors.destinationsList)) {
            formik.setErrors(errors);
            return;
        }

        const locationProducts = [
            ...formik.values.destinationsList.map((destination, index) => (
                {
                    podId: destination.destinationPodId,
                    products: [
                        ...formik?.values?.productsList?.[index]?.map(product => ({
                            productId: product?.productId,
                            quantity: product?.quantity,
                            price: product?.price,
                            expirationDate: product?.expirationDate?.format(MISC_DATE_ISO_DATE_ONLY_FORMAT),
                        }))
                    ]
                }
            ))
        ];

        setData({
            form: { ...form, orderDate: form?.orderDate?.format(MISC_DATE_ISO_FORMAT), locationProducts, attachments: formik?.values?.attachments || [] },
            formik: formik?.values
        });
        setView(3);
    };

    const validate = (values) => {
        const errors = {
            productsList: [],
            products: [],
            destinationsList: {}
        };

        if (values.destinationsList.length === 0) {
            errors.destinationsList = 'Wybierz minimum 1 lokalizację';
        } else {
            values.destinationsList.forEach((_, index) => {
                if (values.productsList[index] === undefined || values.productsList[index].length === 0) {
                    errors.productsList[index] = 'Wybierz minimum 1 produkt';
                } else {
                    values.productsList[index].forEach((product, productIndex) => {
                        if (!product?.quantity) {
                            const oldValues = errors.products[index];
                            errors.products[index] = {
                                ...oldValues,
                                [productIndex]: {
                                    ...errors.products?.[index]?.[productIndex],
                                    quantity: 'Pole wymagane'
                                }
                            };
                        } else {
                            if (product?.quantity <= 0) {
                                const oldValues = errors.products[index];
                                errors.products[index] = {
                                    ...oldValues,
                                    [productIndex]: {
                                        ...errors.products?.[index]?.[productIndex],
                                        quantity: 'Proszę podać wartość całkowitą dodatnią'
                                    }
                                };
                            }
                        }

                        if (!product?.price) {
                            const oldValues = errors.products[index];
                            errors.products[index] = {
                                ...oldValues,
                                [productIndex]: {
                                    ...errors.products?.[index]?.[productIndex],
                                    price: 'Pole wymagane'
                                }
                            };
                        } else {
                            if (product?.price <= 0) {
                                const oldValues = errors.products[index];
                                errors.products[index] = {
                                    ...oldValues,
                                    [productIndex]: {
                                        ...errors.products?.[index]?.[productIndex],
                                        price: 'Proszę podać wartość dodatnią'
                                    }
                                };
                            }
                        }

                        if (!product?.expirationDate) {
                            const oldValues = errors.products[index];
                            errors.products[index] = {
                                ...oldValues,
                                [productIndex]: {
                                    ...errors.products?.[index]?.[productIndex],
                                    expirationDate: 'Pole wymagane'
                                }
                            };
                        }
                    });
                }
            });
        }

        return errors;
    };

    return (
        <Formik initialValues={ initialValues }>
            { (formik) =>
                <Grid component={ Form } container rowGap={ 2 } paddingTop={ 2 }>
                    <ThreeLevelSteps secondStep/>
                    <AdditionalOptions
                        onChangeSetDate={ (newDate, allDates) => onChangeSetDate(newDate, allDates, formik) }
                        displayCopyHistoricalDialog
                        form={ form }
                        onCopyHistoricalOrder={ (values) => onCopyHistoricalOrder(values, formik) }/>
                    <Grid container item mb={ 2 }>
                        <Divider style={ { width: '100%' } }/>
                    </Grid>
                    <DestinationsList
                        form={ form }
                        withExpirationDate
                        formik={ formik }/>
                    <AddDestinationDialog
                        formik={ formik }/>
                    <UploadFiles formik={ formik } addAndRemoveOptions/>
                    <Buttons
                        onReturn={ onReturn }
                        onSubmit={ () => onSubmit(formik) }
                    />
                </Grid>
            }
        </Formik>
    );
};

export default FormWithLocationDivision;
