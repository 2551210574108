import { configureStore } from '@reduxjs/toolkit';
import storage from 'redux-storage';
import createEngine from 'redux-storage-engine-sessionstorage';

import reducers from '../features';
import { MISC_STORAGE_ENGINE_NAME } from './constants';
import { injectStore } from './web_client';

const reducer = storage.reducer(reducers);
const engine = createEngine(MISC_STORAGE_ENGINE_NAME);

const middleware = storage.createMiddleware(engine);

export const store = configureStore({
    reducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
        serializableCheck: {
            ignoredActions: ['REDUX_STORAGE_SAVE', 'product/getCouponPicture/fulfilled'],
        },
    }).concat(middleware)
});

const load = storage.createLoader(engine);
load(store);

injectStore(store);
