import { combineReducers } from '@reduxjs/toolkit';

import getBusinessData from './get_business_data';
import saveBusinessData from './save_business_data';
import getBusinessClassifications from './get_business_classifications';
import getBusinessDecisions from './get_business_decisions';
import updateBusinessNote from './update_business_note';
import deleteBusinessNote from './delete_business_note';
import getSuppliers from './get_suppliers';
import updateSupplierStatus from './update_supplier_status';
import getSupplierKey from './get_supplier_key';
import getNewSupplierKey from './get_new_supplier_key';
import createSupplier from './create_supplier';
import getSupplier from './get_supplier';
import updateSupplier from './update_supplier';

export default combineReducers({
    getBusinessData, saveBusinessData, getBusinessClassifications, getBusinessDecisions,
    updateBusinessNote, deleteBusinessNote, getSuppliers, updateSupplierStatus, getSupplierKey, getNewSupplierKey,
    createSupplier, getSupplier, updateSupplier
});
