import { deepFreeze } from '../../utils/misc_utils';

export const PromotionCampaignStatusEnum = deepFreeze({
    ACTIVE: 'ACTIVE',
    READY_TO_ACTIVE: 'READY_TO_ACTIVE',
    DEACTIVATED: 'DEACTIVATED',
    EXPIRED: 'EXPIRED',
    DELETED: 'DELETED',
    UNKNOWN: 'UNKNOWN'
});

export const PromotionCampaignTypeEnum = deepFreeze({
    PRODUCTS: {
        value: 'PRODUCTS',
        label: 'Produkty'
    },
    FOOD_TYPE: {
        value: 'FOOD_TYPE',
        label: 'Typy produktu'
    },
    ATTRIBUTE: {
        value: 'ATTRIBUTE',
        label: 'Atrybuty'
    },
    FOOD_PARTNER: {
        value: 'FOOD_PARTNER',
        label: 'Dostawcy'
    },
    UNKNOWN: {
        value: 'UNKNOWN',
        label: 'Błąd'
    }
});
