import { deepFreeze } from '../../utils/misc_utils';

export const DayOfWeekEnum = deepFreeze({
    MONDAY: {
        value: 'MONDAY',
        label: 'Poniedziałek'
    },
    TUESDAY: {
        value: 'TUESDAY',
        label: 'Wtorek'
    },
    WEDNESDAY: {
        value: 'WEDNESDAY',
        label: 'Środa'
    },
    THURSDAY: {
        value: 'THURSDAY',
        label: 'Czwartek'
    },
    FRIDAY: {
        value: 'FRIDAY',
        label: 'Piątek'
    },
    SATURDAY: {
        value: 'SATURDAY',
        label: 'Sobota'
    },
    SUNDAY: {
        value: 'SUNDAY',
        label: 'Niedziela'
    },
});
