import React from 'react';

import { Field, getIn } from 'formik';
import { FormControl } from '@mui/material';
import { isString } from 'lodash';
import { TimePicker } from '@mui/x-date-pickers';

const TimePickerFormik = ({ name, label, required, disabled, onChange, fullWidth, InputLabelProps, views }) => (
    <Field name={ name }>
        { ({ form: { errors, setFieldValue }, field }) => (
            <FormControl fullWidth={ fullWidth }>
                <TimePicker
                    { ...field }
                    value={ (isString(field?.value) && field?.value === '') ? null : field?.value }
                    label={ label }
                    disabled={ disabled }
                    onChange={ (newTime) => {
                        setFieldValue(name, newTime);
                        onChange && onChange(newTime);
                    } }
                    slotProps={ {
                        textField: {
                            required: required,
                            helperText: getIn(errors, field?.name),
                            error: !!getIn(errors, field?.name),
                            InputLabelProps: {
                                ...InputLabelProps
                            }
                        }
                    } }
                    views={ views }
                />
            </FormControl>
        ) }
    </Field>
);

export default TimePickerFormik;
