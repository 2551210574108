import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Grid } from '@mui/material';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';

import { SubmitButton } from '../../../../common/button';
import { SelectFieldFormik } from '../../../../common/select_field';
import { EmployerReportType } from '../../../../../core/enums/benefit/employer';
import { getEmployerReport } from '../../../../../features/benefit';
import { getEmployerState } from '../../../../../features/benefit/get_employer';
import { getEmployerReportNotificationData } from '../../../../../core/utils/notification_utils';
import useNotification from '../../../../../core/hooks/use_notification';
import { parseDateString } from '../../../../../core/utils/date_utils';
import { MISC_DATE_ISO_FORMAT } from '../../../../../core/constants';
import { DateTimePickerFormik } from '../../../../common/date_picker';

const reportTypeOptions = [
    { value: EmployerReportType.GENERAL.value, label: EmployerReportType.GENERAL.message },
    { value: EmployerReportType.EMPLOYEE.value, label: EmployerReportType.EMPLOYEE.message }
];

const ReportForm = () => {
    const { showNotification } = useNotification();
    const dispatch = useDispatch();

    const getEmployer = useSelector(getEmployerState);

    const initialValues = {
        fromDate: '',
        toDate: '',
        reportType: ''
    };

    const schema = Yup.object().shape({
        fromDate: Yup.date()
            .transform(parseDateString)
            .nullable()
            .required(),
        toDate: Yup.date()
            .transform(parseDateString)
            .nullable()
            .required(),
        reportType: Yup.string().required()
    });

    const onSubmit = (values) => {
        const fromDate = values?.fromDate?.format(MISC_DATE_ISO_FORMAT);
        const toDate = values?.toDate?.format(MISC_DATE_ISO_FORMAT);

        const form = {
            ...values,
            fromDate,
            toDate
        };

        dispatch(getEmployerReport({ employerId: getEmployer?.data?.id, form }))
            .then(response => showNotification(getEmployerReportNotificationData(response)));
    };

    return (
        <Formik
            initialValues={ initialValues }
            validationSchema={ schema }
            validateOnChange={ false }
            validateOnBlur={ false }
            onSubmit={ onSubmit }
        >
            { () =>
                <Grid component={ Form } container rowGap={ 4 }>
                    <Grid container item gap={ 4 }>
                        <Grid item>
                            <DateTimePickerFormik
                                name="fromDate"
                                label="Data od"
                                required
                                InputLabelProps={ { shrink: true } }
                            />
                        </Grid>
                        <Grid item>
                            <DateTimePickerFormik
                                name="toDate"
                                label="Data do"
                                required
                                InputLabelProps={ { shrink: true } }
                            />
                        </Grid>
                        <Grid item xs={ 3 }>
                            <SelectFieldFormik
                                name="reportType"
                                label="Typ raportu"
                                options={ reportTypeOptions }
                                required
                            />
                        </Grid>
                    </Grid>
                    <Grid container item mt={ 2 } justifyContent="flex-end">
                        <SubmitButton>Pobierz</SubmitButton>
                    </Grid>
                </Grid>
            }
        </Formik>
    );
};

export default ReportForm;
