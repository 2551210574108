import { deepFreeze } from '../../utils/misc_utils';

export const AuthorizationSuccessMessageEnum = deepFreeze({
    SIGN_IN: 'Pomyślnie zalogowano',
    SIGN_OUT: 'Pomyślnie wylogowano',
});

export const AuthorizationErrorMessageEnum = deepFreeze({
    SIGN_IN: 'Logowanie nie powiodło się, spróbuj ponownie później',
});

const AuthorizationServerErrorMessageEnum = deepFreeze({
    INCORRECT_CREDENTIALS: {
        message: 'Niepoprawny login lub hasło',
        errorCode: '400.5'
    }
});

export const getAuthorizationErrorMessage = (message, defaultErrorMessage) => {
    for (let key in AuthorizationServerErrorMessageEnum) {
        if (AuthorizationServerErrorMessageEnum.hasOwnProperty(key)) {
            if (message?.substring(0, message?.indexOf(' ')) === AuthorizationServerErrorMessageEnum[key].errorCode) {
                return AuthorizationServerErrorMessageEnum[key].message;
            }
        }
    }

    return defaultErrorMessage;
};
