import { deepFreeze } from '../../utils/misc_utils';

export const CommunicationSuccessMessageEnum = deepFreeze({
    CREATE_NOTIFICATION: 'Pomyślnie wysłano żądanie powiadomienie',
    DELETE_NOTIFICATION: 'Pomyślnie anulowano wysyłkę powiadomienia',
    DELETE_DELIVERY_NOTIFICATION: 'Pomyślnie usunięto powiadomienie dostawy',
    CREATE_DELIVERY_NOTIFICATION: 'Pomyślnie utworzono powiadomienie dostawy',
    UPDATE_DELIVERY_NOTIFICATION: 'Pomyślnie zaktualizowano powiadomienie dostawy',
});

export const CommunicationErrorMessageEnum = deepFreeze({
    CREATE_NOTIFICATION: 'Wysłanie żądania powiadomienia nie powiodło się',
    GET_NOTIFICATIONS: 'Nie udało się pobrać historii notyfikacji',
    GET_NOTIFICATION_TYPES: 'Nie udało się pobrać typów notyfikacji',
    DELETE_NOTIFICATION: 'Anulowanie powiadomienia nie powiodło się',
    GET_DELIVERY_NOTIFICATIONS: 'Pobranie powiadomień dostaw nie powiodło się',
    DELETE_DELIVERY_NOTIFICATION: 'Usunięcie powiadomienia dostawy nie powiodło się',
    GET_DELIVERY_NOTIFICATION: 'Pobranie powiadomienia dostawy nie powiodło się',
    CREATE_DELIVERY_NOTIFICATION: 'Utworzenie powiadomienia dostawy nie powiodło się',
    UPDATE_DELIVERY_NOTIFICATION: 'Aktualizacja powiadomienia dostawy nie powiodła się',
});

const CommunicationServerErrorMessageEnum = deepFreeze({
    NOTIFICATION_BODY_TOO_LONG: {
        message: 'Treść wiadomości zbyt długa',
        errorCode: '400.1'
    },
    TITLE_OR_BODY_IS_REQUIRED: {
        message: 'Należy uzpełnić tytuł i/lub treść',
        errorCode: '400.2'
    },
    INVALID_NOTIFICATION_TYPE: {
        message: 'Nieprawidłowy typ notyfikacji',
        errorCode: '400.5'
    },
    THIS_NOTIFICATION_REQUEST_CANNOT_BE_CANCELED: {
        message: 'Nie można anulować powiadomienia',
        errorCode: '400.9'
    }
});

export const getCommunicationErrorMessage = (message, defaultErrorMessage) => {
    for (let key in CommunicationServerErrorMessageEnum) {
        if (CommunicationServerErrorMessageEnum.hasOwnProperty(key)) {
            if (message?.substring(0, message?.indexOf(' ')) === CommunicationServerErrorMessageEnum[key].errorCode) {
                return CommunicationServerErrorMessageEnum[key].message;
            }
        }
    }

    return defaultErrorMessage;
};
