import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { webClientFileDownload } from '../../core/web_client';
import { INVENTORIES_PATH } from '../../core/constants';
import { exportFile, extractErrorDataFromFile, extractResponseData } from '../../core/utils/api_utils';
import { DEFAULT_STATE, STATE__API__FAIL, STATE__API__STARTED, STATE__API__SUCCESS } from '../../core/utils/store_utils';
import { invalidateToken } from '../authorization/get_token';

export const getInventoryExportAction = createAsyncThunk(
    'inventory/getInventoryExportAction',
    async (_, { fulfillWithValue, rejectWithValue }) => {
        try {
            const response = await webClientFileDownload.get(`${ INVENTORIES_PATH }/export`);
            const fileName = response.headers['content-disposition']?.split('filename=')[1]?.replaceAll("\"", "");
            exportFile(extractResponseData(response), fileName ? fileName : 'zestawienie.xlsx');
            return fulfillWithValue();
        } catch (error) {
            const response = await extractErrorDataFromFile(extractResponseData(error?.response));
            return rejectWithValue(response);
        }
    }
);

const getInventoryExportSlice = createSlice({
    name: 'getInventoryExportSlice',
    initialState: DEFAULT_STATE,
    reducers: {},
    extraReducers: builder => {
        builder.addCase(getInventoryExportAction.pending, () => STATE__API__STARTED);
        builder.addCase(getInventoryExportAction.fulfilled, (_, { payload }) => STATE__API__SUCCESS(payload));
        builder.addCase(getInventoryExportAction.rejected, (_, { payload }) => STATE__API__FAIL(payload));
        builder.addCase(invalidateToken, () => DEFAULT_STATE);
    }
});

export const getInventoryExportState = (state) => state.inventory.getInventoryExport;
export default getInventoryExportSlice.reducer;