import React, { useState } from 'react';

import { Box, Card, CardContent, Grid, IconButton, Stack, Typography } from '@mui/material';
import { FieldArray } from 'formik';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';

import ProductsList from './products_list';
import AddProductsDialog from '../../../../common/warehouse_and_food_partner_order_form/add_products_dialog';
import CopyAllIcon from "@mui/icons-material/CopyAll";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

const DestinationsList = ({ form, formik, onlyDelivery }) => {
    const [productClipboard, setProductClipboard] = useState([]);

    const onRemoveDestination = (formik, position) => {
        const destinationsList = formik?.values?.destinationsList;
        const productsList = formik?.values?.productsList;
        const index = destinationsList?.findIndex(destination => destination?.position === position);

        if (index !== -1) {
            const lastIndex = destinationsList?.length - 1;

            destinationsList?.filter(destination => destination?.position > position)
                ?.forEach(destination => {
                    productsList[destination?.position - 1] = productsList[destination?.position] || [];
                    destinationsList[destination?.position - 1] = {
                        ...destination,
                        position: destination?.position - 1
                    };
                });

            destinationsList.splice(lastIndex, 1);
            productsList.splice(lastIndex, 1);

            formik.setFieldValue(`destinationsList`, destinationsList);
            formik.setFieldValue(`productsList`, productsList);
        }
    };

    const copyProductsToClipboard = (formik, index) => {
        setProductClipboard(formik?.values?.productsList[index]?.slice() || [])
    }

    const pasteProductsFromClipboard = (formik, index) => {
        formik.setFieldValue(`productsList.${ index }`, productClipboard.slice());
    }

    return (
        <FieldArray
            name={ `destinationsList` }
            render={ () => (
                <>
                    {
                        formik?.values?.destinationsList?.map((destination, index) => (
                            <Grid container key={ index } gap={ 2 }>
                                <Card sx={ { width: '93%', backgroundColor: '#f5f5f5' } }>
                                    <CardContent>
                                        <Typography variant="h4" mb={ 5 }>
                                            {
                                                destination?.name
                                            }
                                        </Typography>
                                        <Grid container mb={2} gap={4} justifyContent="flex-end">
                                            {
                                                formik?.values?.productsList[index] && formik?.values?.productsList[index].length > 0 &&
                                                <Grid item>
                                                    <Box sx={{ width: '200px' }}
                                                         onClick={() => copyProductsToClipboard(formik, index)}>
                                                        <Stack direction="row" alignItems="center" gap={1}
                                                               sx={{ cursor: 'pointer' }}>
                                                            <CopyAllIcon/>
                                                            <Typography fontWeight={'bold'}>Kopiuj listę
                                                                produktów</Typography>
                                                        </Stack>
                                                    </Box>
                                                </Grid>
                                            }
                                            {
                                                productClipboard.length > 0 &&
                                                <Grid item>
                                                    <Box sx={{ width: '200px' }}
                                                         onClick={() => pasteProductsFromClipboard(formik, index)}>
                                                        <Stack direction="row" alignItems="center" gap={1}
                                                               sx={{ cursor: 'pointer' }}>
                                                            <ContentCopyIcon/>
                                                            <Typography fontWeight={'bold'}>Wklej listę
                                                                produktów</Typography>
                                                        </Stack>
                                                    </Box>
                                                </Grid>
                                            }
                                        </Grid>
                                        <ProductsList
                                            formik={ formik }
                                            onlyDelivery={ onlyDelivery }
                                            productsListIndex={ index }/>
                                        <AddProductsDialog
                                            foodPartnerId={ form?.foodPartnerId }
                                            formik={ formik }
                                            dateToCalculateExpirationDate={ form?.deliveryDate }
                                            productsListIndex={ index }
                                            onlyDelivery={ onlyDelivery }
                                            sourceWarehouseId={ form?.sourceWarehouseId }
                                        />
                                    </CardContent>
                                </Card>
                                <Grid item>
                                    <IconButton
                                        onClick={ () => onRemoveDestination(formik, destination?.position) }
                                        title="Usuń">
                                        <RemoveCircleIcon/>
                                    </IconButton>
                                </Grid>
                            </Grid>
                        ))
                    }
                </>
            ) }
        />
    );
};

export default DestinationsList;
