import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { DEFAULT_STATE, STATE__API__FAIL, STATE__API__STARTED, STATE__API__SUCCESS } from '../../core/utils/store_utils';
import webClient from '../../core/web_client';
import { MISC_REST_CONTENT_TYPE_URLENCODED, TOKEN_PATH } from '../../core/constants';
import { extractErrorData, extractResponseData } from '../../core/utils/api_utils';

export const getTokenAction = createAsyncThunk(
    'authorization/getTokenAction',
    async (data, { fulfillWithValue, rejectWithValue }) => {
        try {
            const params = new URLSearchParams();
            params.append('grant_type', 'password');
            params.append('client', 'STORE_WEB_APP');
            params.append('username', data.username);
            params.append('password', data.password);

            const response = await webClient.post(TOKEN_PATH, params, {
                headers: {
                    'content-type': MISC_REST_CONTENT_TYPE_URLENCODED
                }
            });
            return fulfillWithValue(extractResponseData(response));
        } catch (error) {
            return rejectWithValue(extractErrorData(error));
        }
    }
);

const getTokenSlice = createSlice({
    name: 'getTokenSlice',
    initialState: DEFAULT_STATE,
    reducers: {
        invalidateToken() {
            return DEFAULT_STATE;
        }
    },
    extraReducers: builder => {
        builder.addCase(getTokenAction.pending, () => STATE__API__STARTED);
        builder.addCase(getTokenAction.fulfilled, (_, { payload }) => STATE__API__SUCCESS(payload));
        builder.addCase(getTokenAction.rejected, (_, { payload }) => STATE__API__FAIL(payload));
    }
});

export const { invalidateToken } = getTokenSlice.actions;
export const getTokenState = (state) => state.authorization.getToken;
export default getTokenSlice.reducer;