import React from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Box, Card, Divider, Grid, Stack, Typography } from "@mui/material";
import { getInventoryExportAction, getInventoryExportState } from "../../../../features/inventory/get_inventory_export";
import { getInventoryExportNotificationData } from "../../../../core/utils/notification_utils";
import useNotification from "../../../../core/hooks/use_notification";
import { CustomIconButton } from "../../../common/button";
import DownloadIcon from "@mui/icons-material/Download";

const WarehouseReportsPage = () => {
    const { showNotification } = useNotification();
    const dispatch = useDispatch();

    const getInventoryExport = useSelector(getInventoryExportState);

    const onSubmit = () => {
        dispatch(getInventoryExportAction())
            .then(response => showNotification(getInventoryExportNotificationData(response)));
    };

    return (
        <Grid mb={ 6 }>
            <Grid container mb={ 4 } rowGap={ 4 } justifyContent="space-between">
                <Grid container>
                    <Typography variant="h1">Raporty</Typography>
                </Grid>
                <Grid container>
                    <Card variant="outlined" sx={{ maxWidth: 360 }}>
                        <Box sx={{ p: 2 }}>
                            <Stack direction="row" justifyContent="space-between" alignItems="center">
                                <Typography gutterBottom variant="h5" component="div">
                                    Zestawienie
                                </Typography>
                            </Stack>
                            <Typography color="text.secondary" variant="body2">
                                Raport przedstawiający stany magazynowe wszystkich punktów sprzedaży sklepu.
                            </Typography>
                        </Box>
                        <Divider />
                        <Stack sx={{ p: 2 }} direction="row" justifyContent={"flex-end"}>
                            {
                                <CustomIconButton
                                    title={ 'Pobierz raport' }
                                    isLoading={ getInventoryExport?.loading }
                                    onClick={ onSubmit }
                                    icon={ <DownloadIcon/> }/>
                            }
                        </Stack>
                    </Card>
                </Grid>
            </Grid>
        </Grid>
    );

}

export default WarehouseReportsPage;