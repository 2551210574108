import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Grid, LinearProgress } from '@mui/material';
import moment from 'moment/moment';

import useNotification from '../../../../../core/hooks/use_notification';
import { getPodsNotificationData, getReservationsNotificationData } from '../../../../../core/utils/notification_utils';
import { MISC_DATE_ISO_DATE_ONLY_FORMAT } from '../../../../../core/constants';
import { getReservationsAction, getReservationsState } from '../../../../../features/reservation/get_reservations';
import { getDefaultPaginationQueryValues } from '../../../../../core/utils/api_utils';
import ReservationFilters from './reservation_filters';
import { getAllPodsAction, getAllPodsState } from '../../../../../features/pod/get_all_pods';
import ReservationsList from './reservations_list';

const initialQueryParams = {
    ...getDefaultPaginationQueryValues,
    languageCode: 'PL',
    from: moment().startOf('day'),
    to: moment().startOf('day').add(10, 'days'),
    showArchive: false,
    podIds: []
};

const Reservation = () => {
    const dispatch = useDispatch();
    const { showErrorMessage } = useNotification();

    const getAllPods = useSelector(getAllPodsState);
    const getReservations = useSelector(getReservationsState);

    const [queryParams, setQueryParams] = useState(initialQueryParams);

    const refreshReservations = useCallback(() => {
        const params = {
            ...queryParams,
            from: queryParams?.from?.format(MISC_DATE_ISO_DATE_ONLY_FORMAT),
            to: queryParams?.to?.format(MISC_DATE_ISO_DATE_ONLY_FORMAT)
        };

        dispatch(getReservationsAction(params))
            .then(response => showErrorMessage(getReservationsNotificationData(response)));
    }, [dispatch, queryParams, showErrorMessage]);

    useEffect(() => {
        refreshReservations();
    }, [refreshReservations]);

    useEffect(() => {
        dispatch(getAllPodsAction())
            .then(response => showErrorMessage(getPodsNotificationData(response)));
    }, [dispatch, showErrorMessage]);

    return (
        <Grid rowGap={ 4 }>
            {
                getAllPods?.loading
                    ? <></>
                    : getAllPods?.data &&
                    <ReservationFilters
                        from={ queryParams.from }
                        onChangeTimeFrom={ from => setQueryParams(prevState => ({ ...prevState, from })) }
                        to={ queryParams.to }
                        onChangeTimeTo={ to => setQueryParams(prevState => ({ ...prevState, to })) }
                        showArchive={ queryParams.showArchive }
                        onSelectShowArchiveChange={ showArchive => setQueryParams(prevState => ({ ...prevState, showArchive })) }
                        onSelectPodsChange={ podIds => setQueryParams(prevState => ({ ...prevState, podIds })) }/>
            }
            {
                (getAllPods?.loading || getReservations?.loading) &&
                <LinearProgress/>
            }
            {
                (getAllPods?.data && getReservations?.data) &&
                <ReservationsList refreshReservations={ refreshReservations }/>
            }
        </Grid>
    );
};

export default Reservation;
