import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';

import { Grid, LinearProgress } from '@mui/material';

import useNotification from '../../../../../../core/hooks/use_notification';
import { getFoodPartnerOrderNotificationData } from '../../../../../../core/utils/notification_utils';
import FormWithoutLocationDivision from './form_without_location_division';
import { getFoodPartnerOrderAction, getFoodPartnerOrderState } from '../../../../../../features/inventory/get_food_partner_order';
import FormWithLocationDivision from './form_with_location_division';

const FoodPartnerOrderEditForm = () => {
    const dispatch = useDispatch();
    const { showErrorMessage } = useNotification();
    const location = useLocation();
    const { foodPartnerOrderId } = useParams();

    const getFoodPartnerOrder = useSelector(getFoodPartnerOrderState);

    const [action, setAction] = useState(location?.state?.action);

    useEffect(() => {
        dispatch(getFoodPartnerOrderAction(foodPartnerOrderId))
            .then(response => showErrorMessage(getFoodPartnerOrderNotificationData(response)));
    }, [dispatch, foodPartnerOrderId, showErrorMessage, action]);

    const renderView = () => {
        if (getFoodPartnerOrder?.loading) {
            return (
                <Grid mt={ 6 } item width={ '100%' }>
                    <LinearProgress/>
                </Grid>
            );
        }

        if (getFoodPartnerOrder?.data) {
            if (getFoodPartnerOrder?.data?.locationDivision) {
                return (
                    <FormWithLocationDivision
                        action={ action }
                        setAction={ setAction }/>
                );
            } else {
                return (
                    <FormWithoutLocationDivision
                        action={ action }
                        setAction={ setAction }/>
                );
            }
        }

        return <></>;
    };

    return (
        <Grid container mb={ 6 } pl={ 15 } pr={ 15 }>
            {
                renderView()
            }
        </Grid>
    );
};

export default FoodPartnerOrderEditForm;
