import { deepFreeze } from '../../utils/misc_utils';

export const StoreSuccessMessageEnum = deepFreeze({
    UPDATE_SUPPLIER_STATUS: 'Pomyślnie zaktualizowano status zaopatrzeniowca',
    CREATE_SUPPLIER: 'Pomyślnie utworzono zaopatrzeniowca',
    UPDATE_SUPPLIER: 'Pomyślnie zaktualizowano zaopatrzeniowca',
});

export const StoreErrorMessageEnum = deepFreeze({
    GET_BUSINESS_DATA: 'Pobranie danych nie powiodło się',
    POST_BUSINESS_DATA: 'Zapisanie danych nie powiodło się',
    GET_BUSINESS_CLASSIFICATIONS: 'Pobranie słownika klasyfikacji nie powiodło się',
    GET_BUSINESS_DECISIONS: 'Pobranie słownika decyzji nie powiodło się',
    UPDATE_BUSINESS_NOTE: 'Aktualizacja notatki nie powiodła się',
    DELETE_BUSINESS_NOTE: 'Usunięcie notatki nie powiodło się',
    GET_SUPPLIERS: 'Pobranie zaopatrzeniowców nie powiodło się',
    GET_SUPPLIER: 'Pobranie zaopatrzeniowca nie powiodło się',
    UPDATE_SUPPLIER_STATUS: 'Aktualizacja statusu zaopatrzeniowca nie powiodła się',
    GET_SUPPLIER_KEY: 'Pobranie kodu QR nie powiodło się',
    GET_NEW_SUPPLIER_KEY: 'Wygenerowanie nowego kodu QR nie powiodło się',
    CREATE_SUPPLIER: 'Utworzenie zaopatrzeniowca nie powiodło się',
    UPDATE_SUPPLIER: 'Aktualizacja zaopatrzeniowca nie powiodła się',
});

const StoreServerErrorMessageEnum = deepFreeze({
    SUPPLIER_NOT_ACTIVE: {
        message: 'Zaopatrzeniowiec nieaktywny, brak możliwości pobrania kodu',
        errorCode: '400.6'
    },
});

export const getStoreErrorMessage = (message, defaultErrorMessage) => {
    for (let key in StoreServerErrorMessageEnum) {
        if (StoreServerErrorMessageEnum.hasOwnProperty(key)) {
            if (message?.substring(0, message?.indexOf(' ')) === StoreServerErrorMessageEnum[key].errorCode) {
                return StoreServerErrorMessageEnum[key].message;
            }
        }
    }

    return defaultErrorMessage;
};
