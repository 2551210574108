import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { Box, Grid, LinearProgress, Typography } from '@mui/material';
import deepmerge from 'deepmerge';
import _ from 'lodash';

import { getProductsAction, getProductsState } from '../../../features/product/get_products';
import ProductsList from './products_list';
import PaginationControls from '../../common/pagination_controls';
import { getDefaultPaginationQueryValues } from '../../../core/utils/api_utils';
import ProductFilters from './product_filters';
import useNotification from '../../../core/hooks/use_notification';
import { getAllFoodPartnersNotificationData, getConstantsNotificationData, getProductsNotificationData, updateProductsNotificationData } from '../../../core/utils/notification_utils';
import { SubmitButton } from '../../common/button';
import { updateProductsAction, updateProductsState } from '../../../features/inventory/update_products';
import { getAllFoodPartnersAction, getAllFoodPartnersState } from '../../../features/food_partner/get_all_food_partners';
import { getConstantsAction, getConstantsState } from '../../../features/product/get_constants';

const initialQueryParams = {
    ...getDefaultPaginationQueryValues,
    query: '',
    searchCode: 'all'
};

const ProductsPage = () => {
    const dispatch = useDispatch();
    const { showNotification, showErrorMessage } = useNotification();
    const location = useLocation();

    const getConstants = useSelector(getConstantsState);
    const getAllFoodPartners = useSelector(getAllFoodPartnersState);
    const getProducts = useSelector(getProductsState);
    const updateProducts = useSelector(updateProductsState);

    const [queryParams, setQueryParams] = useState(location?.state?.queryParams || initialQueryParams);

    useEffect(() => {
        const params = {
            ..._.omit(queryParams, [
                queryParams?.attributeId === 'none' && 'attributeId',
                queryParams?.foodPartnerId === 'none' && 'foodPartnerId',
                queryParams?.recommended === 'none' && 'recommended',
                queryParams?.defaultPicture === 'none' && 'defaultPicture'
            ])
        };

        dispatch(getProductsAction(params))
            .then(response => showErrorMessage(getProductsNotificationData(response)));
    }, [dispatch, queryParams, showErrorMessage]);

    useEffect(() => {
        dispatch(getAllFoodPartnersAction())
            .then(response => showErrorMessage(getAllFoodPartnersNotificationData(response)));
        dispatch(getConstantsAction())
            .then(response => showErrorMessage(getConstantsNotificationData(response)));
    }, [dispatch, showErrorMessage]);

    return (
        <Grid mb={ 6 }>
            <Box mb={ 4 }>
                <Typography variant="h1">Produkty</Typography>
            </Box>
            <Box mb={ 4 }>
                {
                    (getAllFoodPartners?.data && getConstants?.data) &&
                    <ProductFilters queryParams={ queryParams } setQueryParams={ setQueryParams }/>
                }
            </Box>
            {
                getProducts?.loading &&
                <LinearProgress/>
            }
            {
                getProducts?.data?.content &&
                <Box mb={ 4 }>
                    <ProductsList queryParams={ queryParams }/>
                    <Box mt={ 2 }>
                        <PaginationControls
                            currentPage={ getProducts?.data?.pageable?.pageNumber }
                            totalPages={ getProducts?.data?.totalPages }
                            setPage={ page => setQueryParams(prevState => deepmerge(prevState, { page })) }
                        />
                    </Box>
                </Box>
            }
            <Box mt={ 4 } sx={ { textAlign: 'right' } }>
                <SubmitButton isLoading={ updateProducts?.loading } onClick={ () => {
                    dispatch(updateProductsAction())
                        .then(response => showNotification(updateProductsNotificationData(response)));
                } }>
                    Zaktualizuj definicje produktów
                </SubmitButton>
            </Box>
        </Grid>
    );
};

export default ProductsPage;
