import { DayOfWeekEnum } from '../../../core/enums/common/day_of_week';

export const getDayOfWeekOptions = [
    { value: DayOfWeekEnum.MONDAY.value, label: DayOfWeekEnum.MONDAY.label },
    { value: DayOfWeekEnum.TUESDAY.value, label: DayOfWeekEnum.TUESDAY.label },
    { value: DayOfWeekEnum.WEDNESDAY.value, label: DayOfWeekEnum.WEDNESDAY.label },
    { value: DayOfWeekEnum.THURSDAY.value, label: DayOfWeekEnum.THURSDAY.label },
    { value: DayOfWeekEnum.FRIDAY.value, label: DayOfWeekEnum.FRIDAY.label },
    { value: DayOfWeekEnum.SATURDAY.value, label: DayOfWeekEnum.SATURDAY.label },
    { value: DayOfWeekEnum.SUNDAY.value, label: DayOfWeekEnum.SUNDAY.label },
];
