import { deepFreeze } from '../../utils/misc_utils';

export const AutomaticCouponType = deepFreeze({
    LACK_OF_PURCHASE_AFTER_REGISTRATION: {
        value: 'LACK_OF_PURCHASE_AFTER_REGISTRATION',
        message: 'Brak zakupu 5 dni po rejestracji'
    },
    LACK_OF_PURCHASE_AFTER_LAST_ORDER_7_DAYS: {
        value: 'LACK_OF_PURCHASE_AFTER_LAST_ORDER_7_DAYS',
        message: 'Brak zakupu 7 dni od ostatniego zamówienia'
    },
    LACK_OF_PURCHASE_AFTER_LAST_ORDER_14_DAYS: {
        value: 'LACK_OF_PURCHASE_AFTER_LAST_ORDER_14_DAYS',
        message: 'Brak zakupu 14 dni od ostatniego zamówienia'
    },
    LACK_OF_PURCHASE_AFTER_LAST_ORDER_30_DAYS: {
        value: 'LACK_OF_PURCHASE_AFTER_LAST_ORDER_30_DAYS',
        message: 'Brak zakupu 30 dni od ostatniego zamówienia'
    },
    LACK_OF_PURCHASE_AFTER_LAST_ORDER_45_DAYS: {
        value: 'LACK_OF_PURCHASE_AFTER_LAST_ORDER_45_DAYS',
        message: 'Brak zakupu 45 dni od ostatniego zamówienia'
    },
    UNKNOWN: {
        value: 'UNKNOWN',
        message: 'UNKNOWN'
    }
});

export const getAutomaticCouponType = (enumName) => {
    for (let key in AutomaticCouponType) {
        if (AutomaticCouponType.hasOwnProperty(key)) {
            if (enumName === AutomaticCouponType[key].value) {
                return AutomaticCouponType[key];
            }
        }
    }

    return AutomaticCouponType.UNKNOWN;
};
