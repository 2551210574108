import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import webClient from '../../core/web_client';
import { AUTOMATIC_EXPIRATION_PROMOTION_SETTINGS_PATH } from '../../core/constants';
import { extractErrorData, extractResponseData } from '../../core/utils/api_utils';
import { DEFAULT_STATE, STATE__API__FAIL, STATE__API__STARTED, STATE__API__SUCCESS } from '../../core/utils/store_utils';
import { invalidateToken } from '../authorization/get_token';

export const updateAutomaticExpirationPromotionSettingAction = createAsyncThunk(
    'price/updateAutomaticExpirationPromotionSettingAction',
    async (data, { fulfillWithValue, rejectWithValue }) => {
        try {
            const response = await webClient.put(`${ AUTOMATIC_EXPIRATION_PROMOTION_SETTINGS_PATH }/${ data.type }`, data.form);
            return fulfillWithValue(extractResponseData(response));
        } catch (error) {
            return rejectWithValue(extractErrorData(error));
        }
    }
);

const updateAutomaticExpirationPromotionSettingSlice = createSlice({
    name: 'updateAutomaticExpirationPromotionSettingSlice',
    initialState: DEFAULT_STATE,
    reducers: {},
    extraReducers: builder => {
        builder.addCase(updateAutomaticExpirationPromotionSettingAction.pending, () => STATE__API__STARTED);
        builder.addCase(updateAutomaticExpirationPromotionSettingAction.fulfilled, (_, { payload }) => STATE__API__SUCCESS(payload));
        builder.addCase(updateAutomaticExpirationPromotionSettingAction.rejected, (_, { payload }) => STATE__API__FAIL(payload));
        builder.addCase(invalidateToken, () => DEFAULT_STATE);
    }
});

export const updateAutomaticExpirationPromotionSettingState = (state) => state.price.updateAutomaticExpirationPromotionSetting;
export default updateAutomaticExpirationPromotionSettingSlice.reducer;