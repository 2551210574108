import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Check as CheckIcon, Clear as ClearIcon, Sell } from '@mui/icons-material';
import { Checkbox, LinearProgress, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from '@mui/material';
import moment from 'moment';
import { DateTimePicker } from '@mui/x-date-pickers';

import useNotification from '../../../../core/hooks/use_notification';
import { MISC_DATE_ISO_FORMAT } from '../../../../core/constants';
import { DeleteButton } from '../../../common/button';
import { deletePromotionNotificationData, getPromotionNotificationData, updatePromotionNotificationData } from '../../../../core/utils/notification_utils';
import Item from '../../../common/item';
import { getProductPromotionAction, getProductPromotionState, invalidateProductPromotion } from '../../../../features/price/get_product_promotion';
import { deleteProductPromotionAction } from '../../../../features/price/delete_product_promotion';
import { createProductPromotionsAction } from '../../../../features/price/create_product_promotions';
import { updateProductPromotionAction } from '../../../../features/price/update_product_promotion';

export default function ProductPromotionDialog({ resource, podId, refreshList }) {
    const [open, setOpen] = useState(false);
    const [discount, setDiscount] = useState('');
    const [timeFrom, setTimeFrom] = useState(null);
    const [timeTo, setTimeTo] = useState(null);
    const [activeExpirationPromotion, setActiveExpirationPromotion] = useState(false);
    const [productPromotionId, setProductPromotionId] = useState(false);

    const getProductPromotion = useSelector(getProductPromotionState);

    const dispatch = useDispatch();
    const { showNotification, showErrorMessage } = useNotification();

    const calculateDiscount = (base, percentage) => (parseFloat(base).toFixed(2) - parseFloat((percentage / 100 * base).toFixed(2))).toFixed(2);

    const loadPromotion = () => {
        dispatch(getProductPromotionAction({ productId: resource?.productId, podId }))
            .then(response => {
                showErrorMessage(getPromotionNotificationData(response));
                reloadValues(response?.payload);
            });
    };

    const reloadValues = (initialValue) => {
        setDiscount(initialValue?.discount ? parseFloat(initialValue?.discount).toFixed(2) : '');
        setTimeFrom(initialValue?.timeFrom ? moment(initialValue?.timeFrom) : null);
        setTimeTo(initialValue?.timeTo ? moment(initialValue?.timeTo) : null);
        setProductPromotionId(initialValue?.productPromotionId);
        setActiveExpirationPromotion(!initialValue?.excludeExpirationPromotion || false);
    };

    const onOpen = () => {
        loadPromotion();
        setOpen(true);
    };

    const onSubmit = () => {
        if (discount && timeFrom?._isValid && timeTo?._isValid) {
            const excludeExpirationPromotion = !activeExpirationPromotion;
            if (productPromotionId) {
                const form = {
                    timeFrom: moment(timeFrom).format(MISC_DATE_ISO_FORMAT),
                    timeTo: moment(timeTo).format(MISC_DATE_ISO_FORMAT),
                    discount,
                    excludeExpirationPromotion
                };

                dispatch(updateProductPromotionAction({ productPromotionId, form }))
                    .then(response => showNotification(updatePromotionNotificationData(response)));
            } else {
                const form = {
                    podIds: [podId],
                    timeFrom: moment(timeFrom).format(MISC_DATE_ISO_FORMAT),
                    timeTo: moment(timeTo).format(MISC_DATE_ISO_FORMAT),
                    discount,
                    excludeExpirationPromotion
                };

                dispatch(createProductPromotionsAction({ productId: resource?.productId, form }))
                    .then(response => showNotification(updatePromotionNotificationData(response)));
            }

            refreshList();
        }

        onClose();
    };

    const onDelete = () => {
        setDiscount('');
        setTimeFrom(null);
        setActiveExpirationPromotion(false);

        if (productPromotionId) {
            dispatch(deleteProductPromotionAction(productPromotionId))
                .then(response => showNotification(deletePromotionNotificationData(response)));
            refreshList();
        }
    };

    const onClose = () => {
        setOpen(false);
        dispatch(invalidateProductPromotion());
    };

    return (
        <div>
            <Sell onClick={ onOpen } cursor="pointer"/>
            <Dialog open={ open } onClose={ onClose } fullWidth={ true } maxWidth={ 'lg' }>
                <DialogTitle>Promocja na towar</DialogTitle>
                <DialogContent>
                    <Item>
                        <b>{ resource?.productName }</b><br/>
                    </Item>
                    <br/>
                    {
                        getProductPromotion?.loading &&
                        <LinearProgress/>
                    }
                    {
                        getProductPromotion?.data &&
                        <TableContainer>
                            <Table stickyHeader>
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="center">Akcja promocyjna</TableCell>
                                        <TableCell align="center">Cena zakupu</TableCell>
                                        <TableCell align="center">Cena bazowa</TableCell>
                                        <TableCell align="center" sx={ { minWidth: 120, maxWidth: 120 } }>Upust [%]</TableCell>
                                        <TableCell align="center">Cena promocyjna</TableCell>
                                        <TableCell align="center">Promocja od</TableCell>
                                        <TableCell align="center">Promocja do</TableCell>
                                        <TableCell align="center">Aktywacja promocji na krótką datę przydatności</TableCell>
                                        <TableCell align="center"></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow>
                                        <TableCell align="center">
                                            {
                                                getProductPromotion?.data?.isPromotionCampaign
                                                    ? <CheckIcon color="success"/>
                                                    : <ClearIcon color="error"/>
                                            }
                                        </TableCell>
                                        <TableCell align="center">{ resource?.purchaseGrossPrice?.toFixed(2) }</TableCell>
                                        <TableCell align="center">{ resource?.basicGrossPrice?.toFixed(2) }</TableCell>
                                        <TableCell align="center">
                                            <TextField
                                                value={ discount }
                                                type="number"
                                                inputProps={ { min: '0.01', max: '100.00', step: '0.01' } }
                                                onChange={ event => setDiscount(event.target.value) }
                                            />
                                        </TableCell>
                                        <TableCell align="center">
                                            {
                                                getProductPromotion?.data?.promotionGrossPrice?.toFixed(2)
                                                || discount ? calculateDiscount(resource?.basicGrossPrice, discount) : ''
                                            }
                                        </TableCell>
                                        <TableCell>
                                            <DateTimePicker
                                                value={ timeFrom }
                                                onChange={ newDate => setTimeFrom(newDate) }
                                                sx={ { width: 220 } }
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <DateTimePicker
                                                value={ timeTo }
                                                onChange={ newDate => setTimeTo(newDate) }
                                                sx={ { width: 220 } }
                                            />
                                        </TableCell>
                                        <TableCell align="center">
                                            <Checkbox
                                                checked={ activeExpirationPromotion }
                                                onChange={ (event) => setActiveExpirationPromotion(event.target.checked) }
                                            />
                                        </TableCell>
                                        <TableCell align="center">
                                            {
                                                productPromotionId && !getProductPromotion?.data?.isPromotionCampaign &&
                                                <DeleteButton onDeleteClick={ () => onDelete() }/>
                                            }
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    }
                </DialogContent>
                <DialogActions>
                    <Button onClick={ onSubmit } disabled={ !discount || !timeFrom || !timeTo }>Zapisz</Button>
                    <Button onClick={ onClose }>Zamknij</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
