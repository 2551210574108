import React from 'react';

import { Grid, TextField } from '@mui/material';
import _ from 'lodash';

const ProducerFilters = ({ onSearchInputChange }) => {

    const onSearchInputChangeDebounced = _.debounce((value) => onSearchInputChange(value), 600);

    return (
        <Grid container columnGap={ 6 } rowGap={ 2 }>
            <Grid item flexBasis="45%">
                <TextField
                    label="Szukaj po nazwie"
                    fullWidth
                    onChange={ event => onSearchInputChangeDebounced(event.target.value) }
                />
            </Grid>
        </Grid>
    );
};
export default ProducerFilters;
