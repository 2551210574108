import React from 'react';

import { TextField } from '@mui/material';
import { Field, getIn } from 'formik';

const TextFieldFormik = ({ name, label, required, placeholder, type, multiline, rows, disabled, inputProps, inputLabelProps, onChange, style }) => (
    <Field name={ name }>
        { ({ form: { errors, setFieldValue }, field }) => (
            <TextField
                fullWidth
                { ...field }
                label={ label }
                required={ required }
                type={ type }
                error={ !!getIn(errors, field?.name) }
                helperText={ getIn(errors, field?.name) }
                placeholder={ placeholder }
                multiline={ multiline }
                minRows={ rows }
                disabled={ disabled }
                onChange={ (event) => {
                    const value = type === 'number'
                        ? (parseFloat(event.target.value) || event.target.value)
                        : event.target.value;
                    setFieldValue(name, value);
                    onChange && onChange(value);
                } }
                style={ style }
                inputProps={ inputProps }
                onWheel={ e => type === 'number' && e.target.blur() }
                InputLabelProps={ inputLabelProps }
            />
        ) }
    </Field>
);

export default TextFieldFormik;
