import { Icon } from '@mui/material';
import { Check as CheckIcon, Clear as ClearIcon } from '@mui/icons-material';

import { FoodPartnerOrderEmailStatusEnum } from '../../../core/enums/inventory/food_partner_order';
import { SupplierStatusFormEnum } from '../../../core/enums/store/supplier';

export const getSupplierStatusIcon = (status) => {
    if (status) {
        return (
            <Icon title={ FoodPartnerOrderEmailStatusEnum.SENT.label }>
                <CheckIcon color="success"/>;
            </Icon>
        );
    } else {
        return (
            <Icon title={ FoodPartnerOrderEmailStatusEnum.UNSENT.label }>
                <ClearIcon color="error"/>;
            </Icon>
        );
    }
};

export const getButtonText = (active) => {
    return active ? 'Dezaktywuj' : 'Aktywuj';
};

export const getUpdateSupplierStatus = (active) => {
    return active ? SupplierStatusFormEnum.DEACTIVATE : SupplierStatusFormEnum.ACTIVATE;
};
