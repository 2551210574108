import React from 'react';

import { Link, Typography } from '@mui/material';

const Copyright = () => (
    <Typography variant="body2" color="textSecondary" align="center">
        Copyright ©&nbsp;
        <Link color="inherit" href="https://ammsystems.pl/">
            AMM Systems
        </Link>
        &nbsp;2022
    </Typography>
);

export default Copyright;
