import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import webClient from '../../core/web_client';
import { MISC_REST_CONTENT_TYPE_MULTIPART, PROMOTION_CAMPAIGN_PATH } from '../../core/constants';
import { extractErrorData, extractResponseData } from '../../core/utils/api_utils';
import { DEFAULT_STATE, STATE__API__FAIL, STATE__API__STARTED, STATE__API__SUCCESS } from '../../core/utils/store_utils';
import { invalidateToken } from '../authorization/get_token';

export const updatePromotionCampaignAdvertisementAction = createAsyncThunk(
    'price/updatePromotionCampaignAdvertisementAction',
    async (data, { fulfillWithValue, rejectWithValue }) => {
        try {
            let body = new FormData();
            body.append('file', data.picture);

            const response = await webClient.put(`${ PROMOTION_CAMPAIGN_PATH }/${ data.promotionCampaignId }/advertisement`, body, {
                headers: {
                    'content-type': MISC_REST_CONTENT_TYPE_MULTIPART
                }
            });

            return fulfillWithValue(extractResponseData(response));
        } catch (error) {
            return rejectWithValue(extractErrorData(error));
        }
    }
);

const updatePromotionCampaignAdvertisementSlice = createSlice({
    name: 'updatePromotionCampaignAdvertisementSlice',
    initialState: DEFAULT_STATE,
    reducers: {},
    extraReducers: builder => {
        builder.addCase(updatePromotionCampaignAdvertisementAction.pending, () => STATE__API__STARTED);
        builder.addCase(updatePromotionCampaignAdvertisementAction.fulfilled, (_, { payload }) => STATE__API__SUCCESS(payload));
        builder.addCase(updatePromotionCampaignAdvertisementAction.rejected, (_, { payload }) => STATE__API__FAIL(payload));
        builder.addCase(invalidateToken, () => DEFAULT_STATE);
    }
});

export const updatePromotionCampaignAdvertisementState = (state) => state.price.updatePromotionCampaignAdvertisement;
export default updatePromotionCampaignAdvertisementSlice.reducer;
