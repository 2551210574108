import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { tabsClasses } from '@mui/material/Tabs';
import { Box, Grid, LinearProgress, Tab, Tabs } from '@mui/material';

import useNotification from '../../../../core/hooks/use_notification';
import { Button } from '../../../common/button';
import { ROUTE_EMPLOYERS } from '../../../../core/constants';
import EmployerForm from './forms/employer_form';
import { getEmployerDiscountNotificationData, getEmployerNotificationData, getPodsNotificationData } from '../../../../core/utils/notification_utils';
import { getEmployerAction, getEmployerState } from '../../../../features/benefit/get_employer';
import ReportForm from './forms/report_form';
import DiscountForm from './forms/discount_form';
import { getEmployerDiscountAction, getEmployerDiscountState } from '../../../../features/benefit/get_employer_discount';
import { getAllPodsAction, getAllPodsState } from '../../../../features/pod/get_all_pods';
import EmployeesList from './employees_list';

const EmployerPage = () => {
    const { showErrorMessage } = useNotification();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { employerId } = useParams();

    const getEmployer = useSelector(getEmployerState);
    const getAllPods = useSelector(getAllPodsState);
    const getEmployerDiscount = useSelector(getEmployerDiscountState);

    const [tab, setTab] = useState(0);

    const refreshDiscount = useCallback(() => {
        dispatch(getEmployerDiscountAction(employerId))
            .then(response => showErrorMessage(getEmployerDiscountNotificationData(response)));
    }, [dispatch, employerId, showErrorMessage]);

    const refreshEmployer = useCallback(() => {
        dispatch(getEmployerAction(employerId))
            .then(response => showErrorMessage(getEmployerNotificationData(response)));
        dispatch(getAllPodsAction())
            .then(response => showErrorMessage(getPodsNotificationData(response)));
        refreshDiscount();
    }, [dispatch, employerId, refreshDiscount, showErrorMessage]);

    useEffect(() => {
        refreshEmployer();
    }, [refreshEmployer]);

    const renderView = () => {
        if (getEmployer?.loading || getAllPods?.loading || getEmployerDiscount?.loading) {
            return (
                <Box mt={ 6 }>
                    <LinearProgress/>
                </Box>
            );
        }

        if (getEmployer?.data && getAllPods?.data && getEmployerDiscount?.data) {
            if (tab === 0) {
                return <EmployerForm refreshEmployer={ refreshEmployer }/>;
            } else if (tab === 1) {
                return <DiscountForm employerId={ employerId } refreshDiscount={ refreshDiscount }/>;
            } else if (tab === 2) {
                return <EmployeesList employerId={ employerId }/>;
            } else if (tab === 3) {
                return <ReportForm/>;
            }
        }

        return <></>;
    };

    return (
        <Grid mb={ 6 }>
            <Grid container mb={ 4 } justifyContent="space-between" rowGap={ 2 }>
                <Grid container item>
                    <Button onClick={ () => navigate(ROUTE_EMPLOYERS) }>
                        Wróć
                    </Button>
                </Grid>
                <Grid container item>
                    <Tabs
                        value={ tab }
                        onChange={ (_, value) => setTab(value) }
                        variant="scrollable"
                        scrollButtons
                        sx={ {
                            [`& .${ tabsClasses.scrollButtons }`]: {
                                '&.Mui-disabled': { opacity: 0.3 },
                            },
                        } }
                    >
                        <Tab label="Informacje ogólne"/>
                        <Tab label="Obniżka"/>
                        <Tab label="Pracownicy"/>
                        <Tab label="Raporty"/>
                    </Tabs>
                </Grid>
            </Grid>
            {
                renderView()
            }
        </Grid>
    );
};

export default EmployerPage;
