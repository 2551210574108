import React, { useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import * as Yup from 'yup';
import { Box, Grid, LinearProgress, Typography } from '@mui/material';
import { FieldArray, Form, Formik } from 'formik';

import useNotification from '../../../../core/hooks/use_notification';
import { createDeliveryNotificationNotificationData, getDeliveryNotificationNotificationData, updateDeliveryNotificationNotificationData } from '../../../../core/utils/notification_utils';
import { ApiRequestStatusEnum } from '../../../../core/enums/common/api';
import { GET_ROUTE_ROUTE_DELIVERY_NOTIFICATIONS_EDIT, ROUTE_DELIVERY_NOTIFICATIONS } from '../../../../core/constants';
import TextFieldFormik from '../../../common/text_field';
import { Button, SubmitButton } from '../../../common/button';
import { FormRouteEnum } from '../../../../core/enums/common/route';
import { getLanguagesState } from '../../../../features/product/get_languages';
import { getDeliveryNotificationAction, getDeliveryNotificationState } from '../../../../features/communication/get_delivery_notification';
import { createDeliveryNotificationAction, createDeliveryNotificationState } from '../../../../features/communication/create_delivery_notification';
import { updateDeliveryNotificationAction, updateDeliveryNotificationState } from '../../../../features/communication/update_delivery_notification';

const DeliveryNotificationForm = () => {
    const { showErrorMessage, showNotification } = useNotification();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { deliveryNotificationId } = useParams();
    const location = useLocation();

    const getDeliveryNotification = useSelector(getDeliveryNotificationState);
    const createDeliveryNotification = useSelector(createDeliveryNotificationState);
    const updateDeliveryNotification = useSelector(updateDeliveryNotificationState);
    const getLanguages = useSelector(getLanguagesState);

    const createNewForm = location?.state?.action === FormRouteEnum.NEW;
    const deliveryNotificationIdToDuplicate = location?.state?.deliveryNotificationIdToDuplicate;

    const initialValues = {
        workingName: ((deliveryNotificationId || deliveryNotificationIdToDuplicate) && getDeliveryNotification?.data?.workingName) || '',
        translatedMessages: getLanguages?.data?.map(language => (
            {
                languageCode: language.languageCode,
                title: ((deliveryNotificationId || deliveryNotificationIdToDuplicate) && getDeliveryNotification?.data?.translatedMessages?.find(dn => dn.languageCode === language.languageCode)?.title) || '',
                body: ((deliveryNotificationId || deliveryNotificationIdToDuplicate) && getDeliveryNotification?.data?.translatedMessages?.find(dn => dn.languageCode === language.languageCode)?.body) || '',
            }
        ))
    };

    const schema = Yup.object().shape({
        workingName: Yup.string().required(),
        translatedMessages: Yup.array().of(
            Yup.object().shape(
                {
                    languageCode: Yup.string().required(),
                    title: Yup.string().required(),
                    body: Yup.string().required(),
                },
                ['title', 'body']
            )
        ),
    });

    useEffect(() => {
        const id = deliveryNotificationId || deliveryNotificationIdToDuplicate;

        id && dispatch(getDeliveryNotificationAction(id))
            .then(response => showErrorMessage((getDeliveryNotificationNotificationData(response))));
    }, [deliveryNotificationId, deliveryNotificationIdToDuplicate, dispatch, showErrorMessage]);

    const onSubmit = (values) => {
        const form = { ...values };

        if (createNewForm) {
            dispatch(createDeliveryNotificationAction(form))
                .then(response => {
                    showNotification(createDeliveryNotificationNotificationData(response));
                    response?.meta?.requestStatus === ApiRequestStatusEnum.FULFILLED && navigate(GET_ROUTE_ROUTE_DELIVERY_NOTIFICATIONS_EDIT(response?.payload.id));
                });
        } else {
            dispatch(updateDeliveryNotificationAction({ form, id: deliveryNotificationId }))
                .then(response => {
                    showNotification(updateDeliveryNotificationNotificationData(response));
                });
        }
    };

    const renderView = () => {
        if (getDeliveryNotification?.loading) {
            return (
                <Box mt={ 6 }>
                    <LinearProgress/>
                </Box>
            );
        }

        return (
            <Formik
                initialValues={ initialValues }
                validationSchema={ schema }
                validateOnChange={ false }
                validateOnBlur={ false }
                onSubmit={ onSubmit }>
                { () =>
                    <Grid component={ Form } container rowGap={ 2 }>
                        <Grid container item>
                            <TextFieldFormik name="workingName" label="Nazwa robocza" required/>
                        </Grid>
                        <FieldArray name="translatedMessages">
                            { () => (
                                getLanguages?.data?.map((language, index) => {
                                    return <Grid container item key={ index }>
                                        <Typography variant="h6" gutterBottom>
                                            { language.languageName }
                                        </Typography>

                                        <Grid container rowGap={ 2 }>
                                            <Grid container item>
                                                <TextFieldFormik name={ `translatedMessages.${ index }.title` } label="Tytuł" required/>
                                            </Grid>

                                            <Grid container item>
                                                <TextFieldFormik name={ `translatedMessages.${ index }.body` } label="Treść" multiline rows={ 6 } required/>
                                            </Grid>
                                        </Grid>
                                    </Grid>;
                                })
                            ) }
                        </FieldArray>
                        <Grid container item mt={ 2 } justifyContent="flex-end">
                            <SubmitButton isLoading={ createDeliveryNotification?.loading || updateDeliveryNotification?.loading }>Zapisz</SubmitButton>
                        </Grid>
                    </Grid>
                }
            </Formik>
        );
    };

    return (
        <Grid mb={ 6 }>
            <Grid container mb={ 4 } justifyContent="space-between" rowGap={ 2 }>
                <Grid container item>
                    <Button onClick={ () => navigate(ROUTE_DELIVERY_NOTIFICATIONS) }>
                        Wróć
                    </Button>
                </Grid>
            </Grid>
            {
                renderView()
            }
        </Grid>
    );
};

export default DeliveryNotificationForm;
