import React from 'react';

import { Box, Checkbox, Chip, FormControl, FormHelperText, InputLabel, ListItemText, MenuItem, OutlinedInput, Select } from '@mui/material';
import { ErrorMessage, Field, getIn } from 'formik';

const SelectField = ({ label, onChange, value, options }) => {
    const randomId = Date.now().toString();

    return (
        <FormControl fullWidth>
            <InputLabel id={ randomId }>{ label }</InputLabel>
            <Select
                labelId={ randomId }
                value={ value }
                label={ label }
                onChange={ onChange }
            >
                {
                    options?.map(option => (
                        <MenuItem key={ option?.value } value={ option?.value }>{ option?.label }</MenuItem>
                    ))
                }
            </Select>
        </FormControl>
    );
};

export const SelectFieldMultiple = ({ label, options, value, onChange, onClose }) => {
    const randomId = Date.now().toString();

    return (
        <FormControl fullWidth>
            <InputLabel id={ randomId }>{ label }</InputLabel>
            <Select
                labelId={ randomId }
                multiple
                value={ value }
                onClose={ onClose }
                onChange={ onChange }
                input={ <OutlinedInput label={ label }/> }
                renderValue={ selectedValues => (
                    <Box sx={ { display: 'flex', flexWrap: 'wrap', gap: 0.5 } }>
                        {
                            selectedValues.map(selectedValue => (
                                <Chip key={ selectedValue } label={ options?.find(option => option?.value === selectedValue).label }/>
                            ))
                        }
                    </Box>
                ) }
            >
                {
                    options?.map(option => (
                        <MenuItem key={ option?.value } value={ option?.value }>
                            <Checkbox checked={ value.find(v => v === option?.value) !== undefined }/>
                            <ListItemText primary={ option?.label }/>
                        </MenuItem>
                    ))
                }
            </Select>
        </FormControl>
    );
};

export const SelectFieldFormik = ({ name, label, options, disabled, required, onChange }) => {
    const randomId = Date.now().toString();

    return (
        <Field name={ name }>
            { ({ form: { errors, setFieldValue }, field: { value } }) => (
                <FormControl fullWidth disabled={ disabled } required={ required }>
                    <InputLabel id={ randomId }>{ label }</InputLabel>
                    <Select
                        labelId={ randomId }
                        error={ !!getIn(errors, name) }
                        value={ value }
                        label={ label }
                        onChange={ (event) => {
                            setFieldValue(name, event.target.value);
                            onChange && onChange(event.target.value);
                        } }
                        MenuProps={ {
                            style: {
                                maxHeight: 400,
                            },
                        } }
                    >
                        {
                            options?.map(option => (
                                <MenuItem key={ option?.value } value={ option?.value }>{ option?.label }</MenuItem>
                            ))
                        }
                    </Select>
                    <FormHelperText error>
                        <ErrorMessage name={ name }/>
                    </FormHelperText>
                </FormControl>
            ) }
        </Field>

    );
};

export const SelectFieldMultipleFormik = ({ name, label, options, disabled, required, onChange }) => {
    const randomId = Date.now().toString();

    return (
        <Field name={ name }>
            { ({ form: { errors, setFieldValue }, field: { value } }) => (
                <FormControl fullWidth disabled={ disabled } required={ required }>
                    <InputLabel id={ randomId }>{ label }</InputLabel>
                    <Select
                        labelId={ randomId }
                        multiple
                        error={ !!getIn(errors, name) }
                        value={ value }
                        onChange={ (event) => {
                            setFieldValue(name, event.target.value);
                            onChange && onChange(event.target.value);
                        } }
                        input={ <OutlinedInput label={ label }/> }
                        renderValue={ selectedValues => (
                            <Box sx={ { display: 'flex', flexWrap: 'wrap', gap: 0.5 } }>
                                {
                                    selectedValues.map(selectedValue => (
                                        <Chip key={ selectedValue } label={ options?.find(option => option?.value === selectedValue).label }/>
                                    ))
                                }
                            </Box>
                        ) }
                    >
                        {
                            options?.map(option => (
                                <MenuItem key={ option?.value } value={ option?.value }>
                                    <Checkbox checked={ value.find(v => v === option?.value) !== undefined }/>
                                    <ListItemText primary={ option?.label }/>
                                </MenuItem>
                            ))
                        }
                    </Select>
                    <FormHelperText error>
                        <ErrorMessage name={ name }/>
                    </FormHelperText>
                </FormControl>
            ) }
        </Field>
    );
};

export default SelectField;
