import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import { makeStyles } from 'tss-react/mui';
import { Close as CloseIcon, ExitToApp as ExitToAppIcon, Menu as MenuIcon } from '@mui/icons-material';
import { AppBar, Box, Container, Divider, Drawer, IconButton, List, Paper, Toolbar, Typography } from '@mui/material';

import SidebarListItems from './sidebar_items/sidebar_list_items';
import Copyright from './component/copyright';
import { invalidateToken } from '../../features/authorization/get_token';
import useNotification from '../../core/hooks/use_notification';
import { deleteTokenNotificationData } from '../../core/utils/notification_utils';

const SignedInLayout = ({ children }) => {
    const { classes, cx } = useStyles();
    const dispatch = useDispatch();
    const { showNotification } = useNotification();
    const [open, setOpen] = useState(true);

    return (
        <div className={ cx(classes.root) }>
            <AppBar position="absolute" className={ cx(classes.appBar, open && classes.appBarShift) }>
                <Toolbar className={ cx(classes.toolbar) }>
                    <IconButton
                        edge="start"
                        color="inherit"
                        aria-label="open drawer"
                        onClick={ () => setOpen(true) }
                        className={ cx(classes.menuButton, open && classes.menuButtonHidden) }
                    >
                        <MenuIcon/>
                    </IconButton>
                    <Typography component="h1" variant="h6" color="inherit" noWrap className={ cx(classes.title) }>
                        Panel
                    </Typography>
                    <IconButton
                        color="inherit"
                        title="Wyloguj"
                        onClick={ () => {
                            dispatch(invalidateToken());
                            // todo jak będzie endpoint to poprawić
                            showNotification(deleteTokenNotificationData());
                        } }
                    >
                        <ExitToAppIcon/>
                    </IconButton>
                </Toolbar>
            </AppBar>
            <Drawer
                variant="permanent"
                classes={ {
                    paper: cx(classes.drawerPaper, !open && classes.drawerPaperClose),
                } }
                open={ open }
            >
                <div className={ cx(classes.toolbarIcon) }>
                    <IconButton onClick={ () => setOpen(false) }>
                        <CloseIcon/>
                    </IconButton>
                </div>
                <Divider/>
                <List
                    sx={ {
                        maxHeight: '92vh',
                        overflow: 'auto',
                    } }>
                    <SidebarListItems/>
                </List>
            </Drawer>
            <main className={ cx(classes.content) }>
                <div className={ cx(classes.appBarSpacer) }/>
                <Container maxWidth="xl" className={ cx(classes.container) }>
                    <Paper className={ cx(classes.paper) }>
                        {
                            children
                        }
                    </Paper>

                    <Box pt={ 4 }>
                        <Copyright/>
                    </Box>
                </Container>
            </main>
        </div>
    );
};

const drawerWidth = 300;

const useStyles = makeStyles()((theme) => ({
    root: {
        display: 'flex',
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${ drawerWidth }px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    toolbar: {
        paddingRight: 24, // keep right padding when drawer closed
    },
    menuButton: {
        marginRight: 36,
    },
    menuButtonHidden: {
        display: 'none',
    },
    title: {
        flexGrow: 1,
    },
    drawerPaper: {
        position: 'relative',
        whiteSpace: 'nowrap',
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerPaperClose: {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9),
        },
    },
    toolbarIcon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        ...theme.mixins.toolbar,
    },
    content: {
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
    },
    appBarSpacer: theme.mixins.toolbar,
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column',
    },
}));

export default SignedInLayout;
