import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { DEFAULT_STATE, STATE__API__FAIL, STATE__API__STARTED, STATE__API__SUCCESS } from '../../core/utils/store_utils';
import { invalidateToken } from '../authorization/get_token';
import webClient from '../../core/web_client';
import { extractErrorData, extractResponseData } from '../../core/utils/api_utils';
import { PRODUCERS_PATH } from '../../core/constants';

export const getProducerAction = createAsyncThunk(
    'product/getProducerAction',
    async (id, { fulfillWithValue,rejectWithValue }) => {
        try {
            const response = await webClient.get(`${ PRODUCERS_PATH }/${ id }`);
            return fulfillWithValue(extractResponseData(response));
        } catch (error) {
            return rejectWithValue(extractErrorData(error));
        }
    }
);

const getProducerSlice = createSlice({
    name: 'getProducerSlice',
    initialState: DEFAULT_STATE,
    reducers: {},
    extraReducers: builder => {
        builder.addCase(getProducerAction.pending, () => STATE__API__STARTED);
        builder.addCase(getProducerAction.fulfilled, (_, { payload }) => STATE__API__SUCCESS(payload));
        builder.addCase(getProducerAction.rejected, (_, { payload }) => STATE__API__FAIL(payload));
        builder.addCase(invalidateToken, () => DEFAULT_STATE);
    }
});

export const getProducerState = (state) => state.product.getProducer;
export default getProducerSlice.reducer;
