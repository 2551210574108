import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';

import { Autocomplete, Grid, TextField } from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';

import { getProductBatchesAction } from '../../features/inventory/get_product_batches';
import { getProductLabel } from '../../features/product';
import useNotification from '../../core/hooks/use_notification';
import { ApiRequestStatusEnum } from '../../core/enums/common/api';
import { getProductBatchesNotificationData, getQRCodeNotificationData } from '../../core/utils/notification_utils';

const QRCodeDownload = ({ productId, productName }) => {
    const dispatch = useDispatch();
    const { showErrorMessage } = useNotification();

    const [isOpen, setIsOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [options, setOptions] = useState([]);
    const [productBatch, setProductBatch] = useState(null);

    const mapOptions = useCallback((data) => {
        return data?.map(productBatch => ({
            key: productBatch?.warehouseBatchNumber + Math.random(),
            id: productBatch?.productBatchId,
            label: productBatch?.productBatchId,
            expiryDate: productBatch?.expiryDate
        }));
    }, []);

    const onOpen = () => {
        setProductBatch(null);
        setIsOpen(true);
        setIsLoading(true);

        dispatch(getProductBatchesAction(productId))
            .then(response => {
                showErrorMessage(getProductBatchesNotificationData(response));
                response?.meta?.requestStatus === ApiRequestStatusEnum.FULFILLED && setOptions(mapOptions(response?.payload));
            })
            .finally(() => setIsLoading(false));
    };

    const onClose = () => {
        setIsOpen(false);
        setOptions([]);
    };

    return (
        <Grid container justifyContent="space-between" alignItems="center" sx={ { minWidth: 210, maxWidth: 210 } }>
            <Autocomplete
                options={ options }
                loading={ isLoading }
                open={ isOpen }
                onOpen={ onOpen }
                onClose={ onClose }
                sx={ { minWidth: 170, maxWidth: 170 } }
                value={ productBatch }
                groupBy={ option => option.expiryDate }
                isOptionEqualToValue={ (option, value) => option.key === value.key }
                onChange={ (_, value) => setProductBatch(value) }
                renderInput={ (params) => <TextField { ...params } label="Partia produktu"/> }
                renderOption={ (props, option) => (
                    <li { ...props } key={ option.key }>
                        { option.label }
                    </li>
                ) }
            />
            <DownloadIcon cursor="pointer"
                          onClick={ () => {
                              dispatch(getProductLabel({ id: productId, productBatchId: productBatch.id, title: productName }))
                                  .then(response => showErrorMessage(getQRCodeNotificationData(response)));
                          } }
            />
        </Grid>
    );
};

export default QRCodeDownload;