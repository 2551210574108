import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import webClient from '../../core/web_client';
import { PRODUCTS_PATH } from '../../core/constants';
import { extractErrorData, extractResponseData } from '../../core/utils/api_utils';
import { DEFAULT_STATE, STATE__API__FAIL, STATE__API__STARTED, STATE__API__SUCCESS } from '../../core/utils/store_utils';
import { invalidateToken } from '../authorization/get_token';

export const getProductAction = createAsyncThunk(
    'product/getProductAction',
    async (data, { fulfillWithValue, rejectWithValue }) => {
        try {
            const response = await webClient.get(`${ PRODUCTS_PATH }/${ data.id }?languageCode=${ data.languageCode || 'PL' }`);
            return fulfillWithValue(extractResponseData(response));
        } catch (error) {
            return rejectWithValue(extractErrorData(error));
        }
    }
);

const getProductSlice = createSlice({
    name: 'getProductSlice',
    initialState: DEFAULT_STATE,
    reducers: {
        invalidateProduct() {
            return DEFAULT_STATE;
        }
    },
    extraReducers: builder => {
        builder.addCase(getProductAction.pending, () => STATE__API__STARTED);
        builder.addCase(getProductAction.fulfilled, (_, { payload }) => STATE__API__SUCCESS(payload));
        builder.addCase(getProductAction.rejected, (_, { payload }) => STATE__API__FAIL(payload));
        builder.addCase(invalidateToken, () => DEFAULT_STATE);
    }
});

export const { invalidateProduct } = getProductSlice.actions;
export const getProductState = (state) => state.product.getProduct;
export default getProductSlice.reducer;