import { deepFreeze } from '../../utils/misc_utils';

export const ProductSuccessMessageEnum = deepFreeze({
    UPDATE_PRODUCT: 'Pomyślnie zaktualizowano produkt',
    UPDATE_PRODUCT_DETAILS: 'Pomyślnie zaktualizowano wersję językową produktu',
    CREATE_PRODUCER: 'Pomyślnie utworzono producenta',
    UPDATE_PRODUCER: 'Pomyslnie zaktualizowano producenta',
    DELETE_PRODUCER: 'Pomyślnie usunięto producenta',
});

export const ProductErrorMessageEnum = deepFreeze({
    GET_PRODUCTS: 'Pobranie produktów nie powiodło się',
    GET_ALL_PRODUCTS: 'Pobranie produktów nie powiodło się',
    GET_PRODUCTS_FOR_PROMOTION: 'Pobranie produktów nie powiodło się',
    GET_PRODUCT: 'Pobranie szczegółów produktu nie powiodło się',
    GET_PRODUCTS_FOR_RESERVATION: 'Pobranie produktów pod rezerwacje nie powiodło się',
    UPDATE_PRODUCT: 'Aktualizacja produktu nie powiodła się',
    UPDATE_PRODUCT_DETAILS: 'Aktualizacja wersji językowej produktu nie powiodła się',
    GET_LABEL: 'Pobranie kodu QR nie powiodło się',
    GET_LANGUAGES: 'Pobranie języków nie powiodło się, niektóre opcje mogą nie działać',
    GET_CONSTANTS: 'Pobranie zmiennych produktu nie powiodło się',
    GET_PRODUCERS: 'Pobranie producentów nie powiodło się',
    GET_ALL_PRODUCERS: 'Pobranie producentów nie powiodło się',
    GET_PRODUCER: 'Pobranie producenta nie powiodło się',
    CREATE_PRODUCER: 'Utworzenie producenta nie powiodło się',
    UPDATE_PRODUCER: 'Aktualizacja producenta nie powiodła się',
    DELETE_PRODUCER: 'Nie udało się usunąć producenta'
});

const ProductServerErrorMessageEnum = deepFreeze({
    MISSING_LABEL_FORMAT: {
        message: 'Nie znaleziono formatu etykiety, uzupełnij produkt',
        errorCode: '400.18'
    },
    NAME_MUST_BE_UNIQUE: {
        message: 'Nazwa nie jest unikatowa',
        errorCode: '400.28'
    },
    PRODUCER_CONNECTED_WITH_PRODUCT: {
        message: 'Producent powiązany jest z produktami',
        errorCode: '400.29'
    }
});

export const getProductErrorMessage = (message, defaultErrorMessage) => {
    for (let key in ProductServerErrorMessageEnum) {
        if (ProductServerErrorMessageEnum.hasOwnProperty(key)) {
            if (message?.substring(0, message?.indexOf(' ')) === ProductServerErrorMessageEnum[key].errorCode) {
                return ProductServerErrorMessageEnum[key].message;
            }
        }
    }

    return defaultErrorMessage;
};
