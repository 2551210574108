import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import webClient from '../../core/web_client';
import { INDOOR_PARTNERS_PATH } from '../../core/constants';
import { extractErrorData, extractResponseData } from '../../core/utils/api_utils';
import { DEFAULT_STATE, STATE__API__FAIL, STATE__API__STARTED, STATE__API__SUCCESS } from '../../core/utils/store_utils';
import { invalidateToken } from '../authorization/get_token';

export const createIndoorPartnerAction = createAsyncThunk(
    'pod/createIndoorPartnerAction',
    async (data, { fulfillWithValue, rejectWithValue }) => {
        try {
            const response = await webClient.post(INDOOR_PARTNERS_PATH, data);
            return fulfillWithValue(extractResponseData(response));
        } catch (error) {
            return rejectWithValue(extractErrorData(error));
        }
    }
);

const createIndoorPartnerSlice = createSlice({
    name: 'createIndoorPartnerSlice',
    initialState: DEFAULT_STATE,
    reducers: {},
    extraReducers: builder => {
        builder.addCase(createIndoorPartnerAction.pending, () => STATE__API__STARTED);
        builder.addCase(createIndoorPartnerAction.fulfilled, (_, { payload }) => STATE__API__SUCCESS(payload));
        builder.addCase(createIndoorPartnerAction.rejected, (_, { payload }) => STATE__API__FAIL(payload));
        builder.addCase(invalidateToken, () => DEFAULT_STATE);
    }
});

export const createIndoorPartnerState = (state) => state.pod.createIndoorPartner;
export default createIndoorPartnerSlice.reducer;