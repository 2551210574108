import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Box, Grid, LinearProgress, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import moment from 'moment/moment';
import deepmerge from 'deepmerge';

import { getAllEmployersNotificationData, getEmployeesToConfirmNotificationData } from '../../../core/utils/notification_utils';
import useNotification from '../../../core/hooks/use_notification';
import { getEmployeesToConfirmAction, getEmployeesToConfirmState } from '../../../features/benefit/get_employees_to_confirm';
import { MISC_DATE_ISO_FORMAT, MISC_DATE_POLISH_FORMAT } from '../../../core/constants';
import { getDefaultPaginationQueryValues } from '../../../core/utils/api_utils';
import PaginationControls from '../../common/pagination_controls';
import { getAllEmployersAction, getAllEmployersState } from '../../../features/benefit/get_all_employers';
import ConfirmEmployeeDialog from './form/confirm_employee_dialog';

const initialQueryParams = {
    ...getDefaultPaginationQueryValues,
    sort: ['createDate', 'desc']
};

const EmployeesToConfirmList = () => {
    const dispatch = useDispatch();
    const { showErrorMessage } = useNotification();

    const getEmployeesToConfirm = useSelector(getEmployeesToConfirmState);
    const getAllEmployers = useSelector(getAllEmployersState);

    const [queryParams, setQueryParams] = useState(initialQueryParams);

    const refreshList = useCallback(() => {
        dispatch(getEmployeesToConfirmAction(queryParams))
            .then(response => showErrorMessage(getEmployeesToConfirmNotificationData(response)));
    }, [dispatch, queryParams, showErrorMessage]);

    useEffect(() => {
        refreshList();

        dispatch(getAllEmployersAction())
            .then(response => showErrorMessage(getAllEmployersNotificationData(response)));
    }, [dispatch, refreshList, showErrorMessage]);

    return (
        <Grid>
            {
                getEmployeesToConfirm?.loading &&
                <LinearProgress/>
            }
            {
                getEmployeesToConfirm?.data?.content &&
                <Grid container>
                    <TableContainer>
                        <Table stickyHeader>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Email</TableCell>
                                    <TableCell align="center">Data utworzenia</TableCell>
                                    <TableCell align="center">Zatwierdź pracownika</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    getEmployeesToConfirm?.data?.content?.map(employee => (
                                        <TableRow
                                            key={ employee?.employeeId }
                                            hover
                                        >
                                            <TableCell>{ employee?.email }</TableCell>
                                            <TableCell align="center">
                                                {
                                                    moment(employee?.createDate, MISC_DATE_ISO_FORMAT)
                                                        .format(MISC_DATE_POLISH_FORMAT)
                                                }
                                            </TableCell>
                                            <TableCell align="center">
                                                {
                                                    getAllEmployers?.data &&
                                                    <ConfirmEmployeeDialog
                                                        refreshList={ refreshList }
                                                        employeeId={ employee?.employeeId }
                                                    />
                                                }
                                            </TableCell>
                                        </TableRow>
                                    ))
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <Box mt={ 2 }>
                        <PaginationControls
                            currentPage={ getEmployeesToConfirm?.data?.pageable?.pageNumber }
                            totalPages={ getEmployeesToConfirm?.data?.totalPages }
                            setPage={ page => setQueryParams(prevState => deepmerge(prevState, { page })) }
                        />
                    </Box>
                </Grid>
            }
        </Grid>
    );
};

export default EmployeesToConfirmList;
