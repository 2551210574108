import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { DEFAULT_STATE, STATE__API__FAIL, STATE__API__STARTED, STATE__API__SUCCESS } from '../../core/utils/store_utils';
import { invalidateToken } from '../authorization/get_token';
import webClient from '../../core/web_client';
import { buildQueryString, extractErrorData, extractResponseData } from '../../core/utils/api_utils';
import { PRODUCERS_PATH } from '../../core/constants';

export const getProducersAction = createAsyncThunk(
    'product/getProducersAction',
    async (queryParams, { fulfillWithValue,rejectWithValue }) => {
        try {
            const response = await webClient.get(`${ PRODUCERS_PATH }${ buildQueryString(queryParams) }`);
            return fulfillWithValue(extractResponseData(response));
        } catch (error) {
            return rejectWithValue(extractErrorData(error));
        }
    }
);

const getProducersSlice = createSlice({
    name: 'getProducersSlice',
    initialState: DEFAULT_STATE,
    reducers: {},
    extraReducers: builder => {
        builder.addCase(getProducersAction.pending, () => STATE__API__STARTED);
        builder.addCase(getProducersAction.fulfilled, (_, { payload }) => STATE__API__SUCCESS(payload));
        builder.addCase(getProducersAction.rejected, (_, { payload }) => STATE__API__FAIL(payload));
        builder.addCase(invalidateToken, () => DEFAULT_STATE);
    }
});

export const getProducersState = (state) => state.product.getProducers;
export default getProducersSlice.reducer;
