import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import webClient from '../../core/web_client';
import { COUPONS_PATH } from '../../core/constants';
import { extractErrorData, extractResponseData } from '../../core/utils/api_utils';
import { DEFAULT_STATE, STATE__API__FAIL, STATE__API__STARTED, STATE__API__SUCCESS } from '../../core/utils/store_utils';
import { invalidateToken } from '../authorization/get_token';

export const updateCouponStatusAction = createAsyncThunk(
    'coupon/updateCouponStatusAction',
    async (data, { fulfillWithValue, rejectWithValue }) => {
        try {
            const response = await webClient.patch(`${ COUPONS_PATH }/${ data.couponId }/status?status=${ data.status }`);
            return fulfillWithValue(extractResponseData(response));
        } catch (error) {
            return rejectWithValue(extractErrorData(error));
        }
    }
);

const updateCouponStatusSlice = createSlice({
    name: 'updateCouponStatusSlice',
    initialState: DEFAULT_STATE,
    reducers: {},
    extraReducers: builder => {
        builder.addCase(updateCouponStatusAction.pending, () => STATE__API__STARTED);
        builder.addCase(updateCouponStatusAction.fulfilled, (_, { payload }) => STATE__API__SUCCESS(payload));
        builder.addCase(updateCouponStatusAction.rejected, (_, { payload }) => STATE__API__FAIL(payload));
        builder.addCase(invalidateToken, () => DEFAULT_STATE);
    }
});

export const updateCouponStatusState = (state) => state.coupon.updateCouponStatus;
export default updateCouponStatusSlice.reducer;
