import { AutomaticExpirationPromotionSettingTypeEnum } from '../../../core/enums/price/automatic_expiration_promotion_setting';

export const getAutomaticExpirationPromotionSettingType = (type) => {
    switch (type) {
        case AutomaticExpirationPromotionSettingTypeEnum.DAY_BEFORE_EXPIRATION.value:
            return AutomaticExpirationPromotionSettingTypeEnum.DAY_BEFORE_EXPIRATION.label;
        case AutomaticExpirationPromotionSettingTypeEnum.FIRST_ON_EXPIRATION_DAY.value:
            return AutomaticExpirationPromotionSettingTypeEnum.FIRST_ON_EXPIRATION_DAY.label;
        case AutomaticExpirationPromotionSettingTypeEnum.SECOND_ON_EXPIRATION_DAY.value:
            return AutomaticExpirationPromotionSettingTypeEnum.SECOND_ON_EXPIRATION_DAY.label;
        default:
            return AutomaticExpirationPromotionSettingTypeEnum.UNKNOWN.label;
    }
};