import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import deepmerge from 'deepmerge';
import { Box, Grid, LinearProgress, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';

import useNotification from '../../../core/hooks/use_notification';
import { deleteIndoorPartnerNotificationData, getIndoorPartnersNotificationData } from '../../../core/utils/notification_utils';
import PaginationControls from '../../common/pagination_controls';
import { getDefaultPaginationQueryValues } from '../../../core/utils/api_utils';
import { GET_ROUTE_INDOOR_PARTNER_EDIT, ROUTE_INDOOR_PARTNER_NEW } from '../../../core/constants';
import { getIndoorPartnersAction, getIndoorPartnersState } from '../../../features/pod/get_indoor_partners';
import { Button, DeleteButton } from '../../common/button';
import { ApiRequestStatusEnum } from '../../../core/enums/common/api';
import { deleteIndoorPartnerAction, deleteIndoorPartnerState } from '../../../features/pod/delete_indoor_partner';

const initialQueryParams = {
    ...getDefaultPaginationQueryValues,
    name: ''
};

const IndoorPartnersList = ({ tab }) => {
    const dispatch = useDispatch();
    const { showErrorMessage, showNotification } = useNotification();
    const navigate = useNavigate();

    const getIndoorPartners = useSelector(getIndoorPartnersState);
    const deleteIndoorPartner = useSelector(deleteIndoorPartnerState);

    const [queryParams, setQueryParams] = useState(initialQueryParams);

    const refreshList = useCallback(() => {
        dispatch(getIndoorPartnersAction(queryParams))
            .then(response => showErrorMessage(getIndoorPartnersNotificationData(response)));
    }, [dispatch, queryParams, showErrorMessage]);

    useEffect(() => {
        refreshList();
    }, [refreshList]);

    const onDeleteIndoorPartner = (partnerId) => {
        dispatch(deleteIndoorPartnerAction(partnerId))
            .then(response => {
                showNotification(deleteIndoorPartnerNotificationData(response));
                response?.meta?.requestStatus === ApiRequestStatusEnum.FULFILLED && refreshList();
            });
    };

    return (
        <Grid container>
            <Grid container item mb={ 4 }>
                <Button onClick={ () => navigate(ROUTE_INDOOR_PARTNER_NEW, { state: { tab } }) }>
                    Dodaj partnera handlowego
                </Button>
            </Grid>
            {
                getIndoorPartners?.loading &&
                <Grid item width={ '100%' }>
                    <LinearProgress/>
                </Grid>
            }
            {
                getIndoorPartners?.data?.content &&
                <Grid container>
                    <TableContainer>
                        <Table stickyHeader>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Nazwa</TableCell>
                                    <TableCell>Adres</TableCell>
                                    <TableCell align="center">Edytuj</TableCell>
                                    <TableCell align="center">Usuń</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    getIndoorPartners?.data?.content?.map(indoorPartner => (
                                        <TableRow
                                            key={ indoorPartner?.partnerId }
                                            hover
                                        >
                                            <TableCell>{ indoorPartner?.name }</TableCell>
                                            <TableCell>{ indoorPartner?.address }</TableCell>
                                            <TableCell align="center">
                                                <EditIcon onClick={ () => navigate(GET_ROUTE_INDOOR_PARTNER_EDIT(indoorPartner?.partnerId), { state: { tab } }) } cursor="pointer"/>
                                            </TableCell>
                                            <TableCell align="center">
                                                <DeleteButton onDeleteClick={ () => onDeleteIndoorPartner(indoorPartner?.partnerId) } isLoading={ deleteIndoorPartner?.loading }/>
                                            </TableCell>
                                        </TableRow>
                                    ))
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <Box mt={ 2 }>
                        <PaginationControls
                            currentPage={ getIndoorPartners?.data?.pageable?.pageNumber }
                            totalPages={ getIndoorPartners?.data?.totalPages }
                            setPage={ page => setQueryParams(prevState => deepmerge(prevState, { page })) }
                        />
                    </Box>
                </Grid>
            }
        </Grid>
    );
};

export default IndoorPartnersList;
