import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { Check as CheckIcon, Clear as ClearIcon, Edit as EditIcon } from '@mui/icons-material';

import { getProductsState } from '../../../features/product/get_products';
import QRCodeDownload from '../../common/qr_code_download';
import { GET_ROUTE_PRODUCT_FORMS } from '../../../core/constants';

const ProductsList = ({ queryParams }) => {
    const getProducts = useSelector(getProductsState);
    const navigate = useNavigate();

    return (
        <TableContainer>
            <Table stickyHeader>
                <TableHead>
                    <TableRow>
                        <TableCell>Nazwa</TableCell>
                        <TableCell align="center">Produkt uzupełniony</TableCell>
                        <TableCell align="center">Podgląd/Edycja</TableCell>
                        <TableCell align="center">QR kod</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        getProducts?.data?.content?.map(product => (
                            <TableRow
                                key={ product?.id }
                                hover
                            >
                                <TableCell>{ product?.name }</TableCell>
                                <TableCell align="center">
                                    {
                                        product?.productStatus === 'UPDATED'
                                            ? <CheckIcon color="success"/>
                                            : <ClearIcon color="error"/>
                                    }
                                </TableCell>
                                <TableCell align="center">
                                    <EditIcon onClick={ () => navigate(GET_ROUTE_PRODUCT_FORMS(product?.id), { state: { queryParams } }) } cursor="pointer"/>
                                </TableCell>
                                <TableCell align="center">
                                    <Grid container justifyContent="center">
                                        <QRCodeDownload
                                            productId={ product?.id }
                                            productName={ product?.name }
                                        />
                                    </Grid>
                                </TableCell>
                            </TableRow>
                        ))
                    }
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default ProductsList;