import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import useNotification from '../../../../core/hooks/use_notification';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Grid, LinearProgress, Tab, Tabs } from '@mui/material';
import { Button } from '../../../common/button';
import { ROUTE_FOOD_PARTNERS } from '../../../../core/constants';
import { tabsClasses } from '@mui/material/Tabs';
import FoodPartnerForm from './food_partner/food_partner_form';
import { getFoodPartnerAction, getFoodPartnerState } from '../../../../features/food_partner/get_food_partner';
import { getFoodPartnerNotificationData } from '../../../../core/utils/notification_utils';
import DocumentsForm from './document/documents_form';
import ContactPersonsForm from './contact_person/contact_persons_form';

const FoodPartnerPage = () => {
    const { showErrorMessage } = useNotification();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { foodPartnerId } = useParams();

    const [tab, setTab] = useState(0);

    const getFoodPartner = useSelector(getFoodPartnerState);

    useEffect(() => {
        foodPartnerId && dispatch(getFoodPartnerAction(foodPartnerId))
            .then(response => showErrorMessage((getFoodPartnerNotificationData(response))));
    }, [foodPartnerId, dispatch, showErrorMessage]);

    const renderView = () => {
        if (getFoodPartner?.loading) {
            return (
                <Box mt={ 6 }>
                    <LinearProgress/>
                </Box>
            );
        }

        switch(tab) {
            case 1:
                return (
                    <ContactPersonsForm foodPartnerId={ foodPartnerId }/>
                );
            case 2:
                return (
                    <DocumentsForm foodPartnerId={ foodPartnerId }/>
                );
            default:
                return (
                    <FoodPartnerForm foodPartnerId={ foodPartnerId }/>
                );
        }
    }

    return (
        <Grid mb={ 6 }>
            <Grid container mb={ 4 } justifyContent="space-between" rowGap={ 2 }>
                <Grid container item>
                    <Button onClick={ () => navigate(ROUTE_FOOD_PARTNERS) }>
                        Wróć
                    </Button>
                </Grid>
                <Grid container item>
                    <Tabs
                        value={ tab }
                        onChange={ (_, value) => {
                            setTab(value);
                        } }
                        variant="scrollable"
                        scrollButtons
                        sx={ {
                            [`& .${ tabsClasses.scrollButtons }`]: {
                                '&.Mui-disabled': { opacity: 0.3 },
                            },
                        } }
                    >
                        <Tab label="Informacje ogólne" sx={ { justifyContent: 'flexEnd' } }/>
                        {
                            foodPartnerId &&
                            <Tab label="Osoby kontaktowe" disabled={ !foodPartnerId } sx={ { justifyContent: 'flexEnd' } }/>
                        }
                        {
                            foodPartnerId &&
                            <Tab label="Dokumenty" disabled={ !foodPartnerId } sx={ { justifyContent: 'flexEnd' } }/>
                        }
                    </Tabs>
                </Grid>
            </Grid>
            {
                renderView()
            }
        </Grid>
    );
}

export default FoodPartnerPage;
