import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { Box, Grid, LinearProgress, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import _ from 'lodash';
import deepmerge from 'deepmerge';

import ProductFilters from '../common/product_filters';
import useNotification from '../../../../core/hooks/use_notification';
import { getProductsForPromotionAction, getProductsForPromotionState } from '../../../../features/product/get_products_for_promotion';
import { getConstantsAction, getConstantsState, invalidateConstants } from '../../../../features/product/get_constants';
import { getAllFoodPartnersAction, getAllFoodPartnersState } from '../../../../features/food_partner/get_all_food_partners';
import { getAllFoodPartnersNotificationData, getConstantsNotificationData, getProductsForPromotionNotificationData } from '../../../../core/utils/notification_utils';
import { GET_ROUTE_PRODUCT_PROMOTIONS_DETAILS } from '../../../../core/constants';
import PaginationControls from '../../../common/pagination_controls';
import { getDefaultPaginationQueryValues } from '../../../../core/utils/api_utils';

const initialQueryParams = {
    ...getDefaultPaginationQueryValues,
    query: '',
};

const ProductPromotionsPage = () => {
    const dispatch = useDispatch();
    const { showErrorMessage } = useNotification();
    const navigate = useNavigate();
    const location = useLocation();

    const getProductsForPromotion = useSelector(getProductsForPromotionState);
    const getConstants = useSelector(getConstantsState);
    const getAllFoodPartners = useSelector(getAllFoodPartnersState);

    const [queryParams, setQueryParams] = useState(location?.state?.queryParams || initialQueryParams);

    useEffect(() => {
        dispatch(getConstantsAction())
            .then(response => showErrorMessage(getConstantsNotificationData(response)));
        dispatch(getAllFoodPartnersAction())
            .then(response => showErrorMessage(getAllFoodPartnersNotificationData(response)));

        return () => {
            dispatch(invalidateConstants());
        };
    }, [dispatch, showErrorMessage]);

    useEffect(() => {
        const params = {
            ..._.omit(queryParams, [
                queryParams?.foodTypeId === 'none' && 'foodTypeId',
                queryParams?.foodPartnerId === 'none' && 'foodPartnerId',
            ])
        };

        dispatch(getProductsForPromotionAction(params))
            .then(response => showErrorMessage(getProductsForPromotionNotificationData(response)));
    }, [dispatch, queryParams, showErrorMessage]);

    return (
        <Grid mb={ 6 }>
            <Grid container mb={ 4 } rowGap={ 4 } justifyContent="space-between">
                <Grid container>
                    <Typography variant="h1">Wybierz produkt</Typography>
                </Grid>
                {
                    (getConstants?.data && getAllFoodPartners?.data) &&
                    <ProductFilters
                        isProductPromotionList
                        queryParams={ queryParams }
                        setQueryParams={ setQueryParams }
                    />
                }
            </Grid>
            {
                getProductsForPromotion?.loading &&
                <LinearProgress/>
            }
            {
                getProductsForPromotion?.data?.content &&
                <Grid container>
                    <TableContainer>
                        <Table stickyHeader>
                            <TableHead>
                                <TableRow>
                                    <TableCell>
                                        Nazwa
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    getProductsForPromotion?.data?.content?.map(product => (
                                        <TableRow
                                            key={ product?.id }
                                            hover
                                            sx={ { cursor: 'pointer' } }
                                            onClick={ () => navigate(GET_ROUTE_PRODUCT_PROMOTIONS_DETAILS(product?.id), { state: { queryParams } }) }
                                        >
                                            <TableCell>{ product?.name }</TableCell>
                                        </TableRow>
                                    ))
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <Box mt={ 2 }>
                        <PaginationControls
                            currentPage={ getProductsForPromotion?.data?.pageable?.pageNumber }
                            totalPages={ getProductsForPromotion?.data?.totalPages }
                            setPage={ page => setQueryParams(prevState => deepmerge(prevState, { page })) }
                        />
                    </Box>
                </Grid>
            }
        </Grid>
    );
};

export default ProductPromotionsPage;
