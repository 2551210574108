import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { Box, Grid, LinearProgress } from '@mui/material';
import * as Yup from 'yup';
import { Form, Formik } from 'formik';

import useNotification from '../../../../core/hooks/use_notification';
import { createSupplierNotificationData, getSupplierNotificationData, updateSupplierNotificationData } from '../../../../core/utils/notification_utils';
import { Button, SubmitButton } from '../../../common/button';
import { GET_ROUTE_SUPPLIERS_EDIT, ROUTE_SUPPLIERS } from '../../../../core/constants';
import { ApiRequestStatusEnum } from '../../../../core/enums/common/api';
import TextFieldFormik from '../../../common/text_field';
import { createSupplierAction, createSupplierState } from '../../../../features/store/create_supplier';
import { getSupplierAction, getSupplierState } from '../../../../features/store/get_supplier';
import { updateSupplierAction, updateSupplierState } from '../../../../features/store/update_supplier';

const SupplierForm = () => {
    const { showErrorMessage, showNotification } = useNotification();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { supplierId } = useParams();

    const getSupplier = useSelector(getSupplierState);
    const createSupplier = useSelector(createSupplierState);
    const updateSupplier = useSelector(updateSupplierState);

    const initialValues = {
        name: (supplierId && getSupplier?.data?.name) || '',
        surname: (supplierId && getSupplier?.data?.surname) || '',
        phoneNumber: (supplierId && getSupplier?.data?.phoneNumber) || '',
        email: (supplierId && getSupplier?.data?.email) || '',
    };

    const schema = Yup.object().shape({
        name: Yup.string().required(),
        surname: Yup.string().required(),
        phoneNumber: Yup.string().notRequired(),
        email: Yup.string().notRequired(),
    });

    useEffect(() => {
        supplierId && dispatch(getSupplierAction(supplierId))
            .then(response => showErrorMessage((getSupplierNotificationData(response))));
    }, [supplierId, dispatch, showErrorMessage]);

    const onSubmit = (values) => {
        const form = { ...values };

        if (!supplierId) {
            dispatch(createSupplierAction(form))
                .then(response => {
                    showNotification(createSupplierNotificationData(response));
                    response?.meta?.requestStatus === ApiRequestStatusEnum.FULFILLED && navigate(GET_ROUTE_SUPPLIERS_EDIT(response?.payload.id));
                });
        } else {
            dispatch(updateSupplierAction({ form, id: supplierId }))
                .then(response => {
                    showNotification(updateSupplierNotificationData(response));
                });
        }
    };

    const renderView = () => {
        if (getSupplier?.loading) {
            return (
                <Box mt={ 6 }>
                    <LinearProgress/>
                </Box>
            );
        }

        return (
            <Formik
                initialValues={ initialValues }
                validationSchema={ schema }
                validateOnChange={ false }
                validateOnBlur={ false }
                onSubmit={ onSubmit }>
                { () =>
                    <Grid component={ Form } container rowGap={ 2 }>
                        <Grid container item>
                            <TextFieldFormik name="name" label="Imię" required/>
                        </Grid>
                        <Grid container item>
                            <TextFieldFormik name="surname" label="Nazwisko" required/>
                        </Grid>
                        <Grid container item>
                            <TextFieldFormik name="phoneNumber" label="Numer telefonu"/>
                        </Grid>
                        <Grid container item>
                            <TextFieldFormik name="email" label="Email"/>
                        </Grid>
                        <Grid container item mt={ 2 } justifyContent="flex-end">
                            <SubmitButton isLoading={ supplierId ? updateSupplier?.loading : createSupplier?.loading }>
                                Zapisz
                            </SubmitButton>
                        </Grid>
                    </Grid>
                }
            </Formik>
        );
    };

    return (
        <Grid mb={ 6 }>
            <Grid container mb={ 4 } justifyContent="space-between" rowGap={ 2 }>
                <Grid container item>
                    <Button onClick={ () => navigate(ROUTE_SUPPLIERS) }>
                        Wróć
                    </Button>
                </Grid>
            </Grid>
            {
                renderView()
            }
        </Grid>
    );
};

export default SupplierForm;
