import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { DEFAULT_STATE, STATE__API__FAIL, STATE__API__STARTED, STATE__API__SUCCESS } from '../../core/utils/store_utils';
import { invalidateToken } from '../authorization/get_token';
import webClient from '../../core/web_client';
import { extractErrorData, extractResponseData } from '../../core/utils/api_utils';
import { RESERVATIONS_PATH } from '../../core/constants';

export const deleteReservationAction = createAsyncThunk(
    'reservation/deleteReservationAction',
    async (id, { fulfillWithValue, rejectWithValue }) => {
        try {
            const response = await webClient.delete(`${ RESERVATIONS_PATH }/${ id }`);
            return fulfillWithValue(extractResponseData(response));
        } catch (error) {
            return rejectWithValue(extractErrorData(error));
        }
    }
);

const deleteReservationSlice = createSlice({
    name: 'deleteReservationSlice',
    initialState: DEFAULT_STATE,
    reducers: {},
    extraReducers: builder => {
        builder.addCase(deleteReservationAction.pending, () => STATE__API__STARTED);
        builder.addCase(deleteReservationAction.fulfilled, (_, { payload }) => STATE__API__SUCCESS(payload));
        builder.addCase(deleteReservationAction.rejected, (_, { payload }) => STATE__API__FAIL(payload));
        builder.addCase(invalidateToken, () => DEFAULT_STATE);
    }
});

export const deleteReservationState = (state) => state.reservation.deleteReservation;
export default deleteReservationSlice.reducer;
