import { styled, Tooltip, tooltipClasses } from '@mui/material';

const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: '#fafafa',
        color: '#505050',
        maxWidth: 220,
        paddingLeft: 15,
        paddingRight: 15,
        fontSize: theme.typography.pxToRem(12),
        border: '1px solid rgba(224, 224, 224, 1)',
    },
}));

export default HtmlTooltip;
