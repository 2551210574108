import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { Divider, Grid, Typography } from '@mui/material';

import TextFieldFormik from '../../../../common/text_field';
import { SubmitButton } from '../../../../common/button';
import { getEmployerState } from '../../../../../features/benefit/get_employer';
import useNotification from '../../../../../core/hooks/use_notification';
import { updateEmployerAction, updateEmployerState } from '../../../../../features/benefit/update_employer';
import { updateEmployerNotificationData, updateEmployerStatusNotificationData } from '../../../../../core/utils/notification_utils';
import { ApiRequestStatusEnum } from '../../../../../core/enums/common/api';
import { getButtonText, getEmployerStatus, getUpdateEmployerStatus } from '../../../../common/utils/employer_utils';
import { updateEmployerStatusAction, updateEmployerStatusState } from '../../../../../features/benefit/update_employer_status';

const EmployerForm = ({ refreshEmployer }) => {
    const dispatch = useDispatch();
    const { showNotification } = useNotification();

    const getEmployer = useSelector(getEmployerState);
    const updateEmployerStatus = useSelector(updateEmployerStatusState);
    const updateEmployer = useSelector(updateEmployerState);

    const initialValues = {
        name: getEmployer?.data?.name || '',
        nip: getEmployer?.data?.nip || '',
        address: getEmployer?.data?.address || '',
        mailingAddress: getEmployer?.data?.mailingAddress || '',
    };

    const schema = Yup.object().shape({
        name: Yup.string().required(),
        nip: Yup.string().required(),
        address: Yup.string().required(),
        mailingAddress: Yup.string()
            .email('Wprowadź prawidłowy email')
            .nullable()
            .notRequired()
    });

    const onUpdateEmployerStatus = (employer) => {
        const employerStatus = getUpdateEmployerStatus(employer?.status);

        dispatch(updateEmployerStatusAction({ employerId: employer?.id, form: { command: employerStatus } }))
            .then(response => {
                showNotification(updateEmployerStatusNotificationData(response));
                response?.meta?.requestStatus === ApiRequestStatusEnum.FULFILLED && refreshEmployer();
            });
    };

    const onSubmit = (values) => {
        dispatch(updateEmployerAction({ employerId: getEmployer?.data?.id, form: values }))
            .then(response => {
                showNotification(updateEmployerNotificationData(response));
                response?.meta?.requestStatus === ApiRequestStatusEnum.FULFILLED && refreshEmployer();
            });
    };

    return (
        <Grid container rowGap={ 4 }>
            <Grid container item gap={ 2 }>
                <Typography variant="h6" gutterBottom>
                    Status: { getEmployerStatus(getEmployer?.data?.status) }
                </Typography>
                <SubmitButton onClick={ () => onUpdateEmployerStatus(getEmployer?.data) } isLoading={ updateEmployerStatus?.loading }>
                    {
                        getButtonText(getEmployer?.data?.status)
                    }
                </SubmitButton>
            </Grid>
            <Grid container item>
                <Divider style={ { width: '100%' } }/>
            </Grid>
            <Formik
                initialValues={ initialValues }
                validationSchema={ schema }
                validateOnChange={ false }
                validateOnBlur={ false }
                onSubmit={ onSubmit }
            >
                { () =>
                    <Grid component={ Form } container rowGap={ 4 }>
                        <Grid container item gap={ 2 }>
                            <Grid item xs={ 6 }>
                                <TextFieldFormik name="name" label="Nazwa" required/>
                            </Grid>
                            <Grid item xs={ 4 }>
                                <TextFieldFormik name="nip" label="NIP" required/>
                            </Grid>
                        </Grid>
                        <Grid container item gap={ 2 }>
                            <Grid item xs={ 6 }>
                                <TextFieldFormik name="address" label="Adres" required/>
                            </Grid>
                            <Grid item xs={ 4 }>
                                <TextFieldFormik name="mailingAddress" label="Email"/>
                            </Grid>
                        </Grid>
                        <Grid container item mt={ 2 } justifyContent="flex-end">
                            <SubmitButton isLoading={ updateEmployer?.loading }>Zapisz</SubmitButton>
                        </Grid>
                    </Grid>
                }
            </Formik>
        </Grid>
    );
};

export default EmployerForm;