import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { Grid } from '@mui/material';

import SelectField from '../../../../common/select_field';
import { getAllPodsState } from '../../../../../features/pod/get_all_pods';
import { getProductBatchesState } from '../../../../../features/inventory/get_product_batches';

const ExpirationPromotionsFilters = ({ onSelectProductBatchIdChange, onSelectPodChange }) => {
    const [productBatchNumber, setProductBatchNumber] = useState('none');
    const [podId, setPodId] = useState('none');

    const getProductBatches = useSelector(getProductBatchesState);
    const getAllPods = useSelector(getAllPodsState);

    const _onSelectProductBatchIdChange = (event) => {
        const value = event.target.value;
        setProductBatchNumber(value);
        onSelectProductBatchIdChange(value === 'none' ? '' : value);
    };

    const _onSelectPodChange = (event) => {
        const value = event.target.value;
        setPodId(value);
        onSelectPodChange(value === 'none' ? '' : value);
    };

    return (
        <Grid container columnGap={ 2 } rowGap={ 2 }>
            <Grid item flexBasis="20%">
                <SelectField
                    label={ 'Szukaj po numerze partii' }
                    value={ productBatchNumber }
                    onChange={ _onSelectProductBatchIdChange }
                    options={ [{ value: 'none', label: 'Nie wybrano' }, ...getProductBatches?.data?.map(productBatch => ({ value: productBatch?.productBatchId, label: productBatch?.productBatchId }))] }
                />
            </Grid>
            <Grid item flexBasis="40%">
                <SelectField
                    label={ 'Szukaj po podzie' }
                    value={ podId }
                    onChange={ _onSelectPodChange }
                    options={ [{ value: 'none', label: 'Nie wybrano' }, ...getAllPods?.data?.map(pod => ({ value: pod?.id, label: pod?.indoorPartner?.name + ' (' + pod?.description + ') - ' + (pod?.device?.serviceId || 'brak urządzenia') }))] }
                />
            </Grid>
        </Grid>
    );
};

export default ExpirationPromotionsFilters;
