import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import webClient from '../../core/web_client';
import { INDOOR_PARTNERS_PATH } from '../../core/constants';
import { extractErrorData, extractResponseData } from '../../core/utils/api_utils';
import { DEFAULT_STATE, STATE__API__FAIL, STATE__API__STARTED, STATE__API__SUCCESS } from '../../core/utils/store_utils';
import { invalidateToken } from '../authorization/get_token';

export const getAllIndoorPartnersAction = createAsyncThunk(
    'pod/getAllIndoorPartnersAction',
    async (_, { fulfillWithValue, rejectWithValue }) => {
        try {
            const response = await webClient.get(`${ INDOOR_PARTNERS_PATH }/all`);
            return fulfillWithValue(extractResponseData(response));
        } catch (error) {
            return rejectWithValue(extractErrorData(error));
        }
    }
);

const getAllIndoorPartnersSlice = createSlice({
    name: 'getAllIndoorPartnersSlice',
    initialState: DEFAULT_STATE,
    reducers: {},
    extraReducers: builder => {
        builder.addCase(getAllIndoorPartnersAction.pending, () => STATE__API__STARTED);
        builder.addCase(getAllIndoorPartnersAction.fulfilled, (_, { payload }) => STATE__API__SUCCESS(payload));
        builder.addCase(getAllIndoorPartnersAction.rejected, (_, { payload }) => STATE__API__FAIL(payload));
        builder.addCase(invalidateToken, () => DEFAULT_STATE);
    }
});

export const getAllIndoorPartnersState = (state) => state.pod.getAllIndoorPartners;
export default getAllIndoorPartnersSlice.reducer;