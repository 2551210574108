import { deepFreeze } from '../../utils/misc_utils';

export const FoodPartnerOrderStatusEnum = deepFreeze({
    UNKNOWN: {
        value: 'UNKNOWN',
        label: 'Błąd'
    },
    CREATED: {
        value: 'CREATED',
        label: 'Utworzone'
    },
    IN_PROGRESS: {
        value: 'IN_PROGRESS',
        label: 'W realizacji'
    },
    DELIVERED: {
        value: 'DELIVERED',
        label: 'Dostarczone'
    },
    COMPLETED: {
        value: 'COMPLETED',
        label: 'Zrealizowane'
    },
    CANCELLED: {
        value: 'CANCELLED',
        label: 'Anulowane'
    },
});

export const FoodPartnerOrderEmailStatusEnum = deepFreeze({
    UNKNOWN: {
        value: 'UNKNOWN',
        label: 'Błąd'
    },
    UNSENT: {
        value: 'UNSENT',
        label: 'Niewysłane'
    },
    IN_PROGRESS: {
        value: 'IN_PROGRESS',
        label: 'W realizacji'
    },
    SENT: {
        value: 'SENT',
        label: 'Wysłane'
    }
});