import { deepFreeze } from '../../utils/misc_utils';

export const AutomaticExpirationPromotionSettingTypeEnum = deepFreeze({
    UNKNOWN: {
        value: 'UNKNOWN',
        label: 'Błąd'
    },
    DAY_BEFORE_EXPIRATION: {
        value: 'DAY_BEFORE_EXPIRATION',
        label: 'Dzień przed upłynięciem ważności'
    },
    FIRST_ON_EXPIRATION_DAY: {
        value: 'FIRST_ON_EXPIRATION_DAY',
        label: 'W dniu upłynięcia ważności - pierwsza promocja'
    },
    SECOND_ON_EXPIRATION_DAY: {
        value: 'SECOND_ON_EXPIRATION_DAY',
        label: 'W dniu upłynięcia ważności - druga promocja'
    }
});