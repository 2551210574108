import React, { Fragment, useState } from 'react';

import { Box, Grid, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';

import { Button } from '../../../common/button';

const DestinationProductsModal = ({ type, destinationsList, destinationProducts }) => {
    const [open, setOpen] = useState(false);

    const onClose = () => setOpen(false);

    return (
        <>
            <Box onClick={ () => setOpen(true) }>
                <Stack direction="row" alignItems="center" gap={ 1 } sx={ { cursor: 'pointer' } }>
                    <InfoIcon/>
                    <Typography fontWeight={ 'bold' }>Zobacz szczegóły</Typography>
                </Stack>
            </Box>
            <Dialog PaperProps={ { sx: { maxHeight: 800 } } } open={ open } onClose={ onClose } fullWidth maxWidth={ 'md' }>
                <DialogTitle variant="h4">{ type }</DialogTitle>
                <DialogContent sx={ { marginTop: 3 } }>
                    <Grid container rowGap={ 4 }>
                        <Grid container item rowGap={ 6 }>
                            {
                                destinationsList?.map((destination, index) => {
                                    if (destinationProducts[index].length === 0) {
                                        return (
                                            <Fragment key={ index }></Fragment>
                                        );
                                    }

                                    return (
                                        <Grid container rowGap={ 2 } key={ index }>
                                            <Grid container justifyContent="space-between" gap={ 2 }>
                                                <Grid item>
                                                    <Typography variant="h5">
                                                        {
                                                            destination?.name
                                                        }
                                                    </Typography>
                                                </Grid>
                                                <Grid item>
                                                    <Typography variant="h6">
                                                        Suma: { destinationProducts[index]?.reduce((accumulator, current) => accumulator + current?.quantity, 0) } szt.
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                            <TableContainer>
                                                <Table stickyHeader>
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell>Nazwa</TableCell>
                                                            <TableCell align="center" style={ { width: '15%' } }>Liczba szt.</TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {
                                                            destinationProducts[index]?.map((product, productIndex) => (
                                                                <TableRow
                                                                    key={ productIndex }
                                                                    hover
                                                                >
                                                                    <TableCell>
                                                                        <TextField
                                                                            label={ product?.name }
                                                                            disabled
                                                                            fullWidth/>
                                                                    </TableCell>
                                                                    <TableCell align="center">
                                                                        <TextField
                                                                            label={ product?.quantity }
                                                                            disabled
                                                                            fullWidth/>
                                                                    </TableCell>
                                                                </TableRow>
                                                            ))
                                                        }
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </Grid>
                                    );
                                })
                            }
                        </Grid>
                        <Grid container item mt={ 2 } justifyContent="flex-end">
                            <DialogActions>
                                <Button onClick={ onClose }>Zamknij</Button>
                            </DialogActions>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
        </>
    );
};

export default DestinationProductsModal;
