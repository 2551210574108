import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import webClient from '../../core/web_client';
import { EMPLOYERS_PATH } from '../../core/constants';
import { extractErrorData, extractResponseData } from '../../core/utils/api_utils';
import { DEFAULT_STATE, STATE__API__FAIL, STATE__API__STARTED, STATE__API__SUCCESS } from '../../core/utils/store_utils';
import { invalidateToken } from '../authorization/get_token';

export const getEmployerAction = createAsyncThunk(
    'benefit/getEmployerAction',
    async (employerId, { fulfillWithValue, rejectWithValue }) => {
        try {
            const response = await webClient.get(`${EMPLOYERS_PATH}/${employerId}`);
            return fulfillWithValue(extractResponseData(response));
        } catch (error) {
            return rejectWithValue(extractErrorData(error));
        }
    }
);

const getEmployerSlice = createSlice({
    name: 'getEmployerSlice',
    initialState: DEFAULT_STATE,
    reducers: {},
    extraReducers: builder => {
        builder.addCase(getEmployerAction.pending, () => STATE__API__STARTED);
        builder.addCase(getEmployerAction.fulfilled, (_, { payload }) => STATE__API__SUCCESS(payload));
        builder.addCase(getEmployerAction.rejected, (_, { payload }) => STATE__API__FAIL(payload));
        builder.addCase(invalidateToken, () => DEFAULT_STATE);
    }
});

export const getEmployerState = (state) => state.benefit.getEmployer;
export default getEmployerSlice.reducer;
