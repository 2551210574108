import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Check as CheckIcon, Clear as ClearIcon, PriceChange } from '@mui/icons-material';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from '@mui/material';
import moment from 'moment';
import { DateTimePicker } from '@mui/x-date-pickers';

import useNotification from '../../../../core/hooks/use_notification';
import { MISC_DATE_ISO_DATE_ONLY_FORMAT, MISC_DATE_ISO_FORMAT, MISC_DATE_POLISH_DATE_ONLY_FORMAT, MISC_DATE_POLISH_FORMAT } from '../../../../core/constants';
import { DeleteButton } from '../../../common/button';
import { deletePromotionNotificationData, getPromotionNotificationData, updatePromotionNotificationData } from '../../../../core/utils/notification_utils';
import Item from '../../../common/item';
import { getProductExpirationPromotionAction } from '../../../../features/price/get_product_expiration_promotion';
import { createOrUpdateProductExpirationPromotionsAction } from '../../../../features/price/create_or_update_product_expiration_promotions';
import { deleteProductExpirationPromotionAction } from '../../../../features/price/delete_product_expiration_promotion';
import { updateProductExpirationPromotionAction } from '../../../../features/price/update_product_expiration_promotion';

export default function ExpirationPromotionDialog({ resource, podId, refreshList }) {
    const [open, setOpen] = useState(false);
    const [discount, setDiscount] = useState('');
    const [currentGrossPrice, setCurrentGrossPrice] = useState('');
    const [timeFrom, setTimeFrom] = useState(null);
    const [expirationPromotionId, setExpirationPromotionId] = useState(false);

    const dispatch = useDispatch();
    const { showNotification, showErrorMessage } = useNotification();

    const calculateDiscount = (base, percentage) => (parseFloat(base).toFixed(2) - parseFloat((percentage / 100 * base).toFixed(2))).toFixed(2);

    const loadPromotion = () => {
        dispatch(getProductExpirationPromotionAction({ productId: resource?.productId, productBatchId: resource?.productBatchId, podId }))
            .then(response => {
                showErrorMessage(getPromotionNotificationData(response));
                reloadValues(response?.payload);
            });
    };

    const reloadValues = (initialValue) => {
        setDiscount(initialValue?.discount ? parseFloat(initialValue?.discount).toFixed(2) : '');
        setCurrentGrossPrice(initialValue?.currentProductGrossPrice ? parseFloat(initialValue?.currentProductGrossPrice).toFixed(2) : '');
        setTimeFrom(initialValue?.timeFrom ? moment(initialValue?.timeFrom) : null);
        setExpirationPromotionId(initialValue?.expirationPromotionId);
    };

    const onOpen = () => {
        loadPromotion();
        setOpen(true);
    };

    const onSubmit = () => {
        if (discount && timeFrom?._isValid) {
            if (expirationPromotionId) {
                const form = {
                    timeFrom: moment(timeFrom).format(MISC_DATE_ISO_FORMAT),
                    timeTo: moment(resource?.expiryDate + 'T23:59:59', MISC_DATE_ISO_FORMAT).format(MISC_DATE_ISO_FORMAT),
                    discount
                };

                dispatch(updateProductExpirationPromotionAction({ expirationPromotionId, form }))
                    .then(response => showNotification(updatePromotionNotificationData(response)));
            } else {
                const form = {
                    podIds: [podId],
                    productBatchIds: [resource?.productBatchId],
                    timeFrom: moment(timeFrom).format(MISC_DATE_ISO_FORMAT),
                    timeTo: moment(resource?.expiryDate + 'T23:59:59', MISC_DATE_ISO_FORMAT).format(MISC_DATE_ISO_FORMAT),
                    discount
                };

                dispatch(createOrUpdateProductExpirationPromotionsAction({ productId: resource?.productId, form }))
                    .then(response => showNotification(updatePromotionNotificationData(response)));
            }

            refreshList();
        }

        onClose();
    };

    const onDelete = () => {
        setDiscount('');
        setTimeFrom(null);

        if (expirationPromotionId) {
            dispatch(deleteProductExpirationPromotionAction(expirationPromotionId))
                .then(response => showNotification(deletePromotionNotificationData(response)));
            refreshList();
        }
    };

    const onClose = () => {
        setOpen(false);
    };

    return (
        <div>
            <PriceChange onClick={ onOpen } cursor="pointer"/>
            <Dialog open={ open } onClose={ onClose } fullWidth={ true } maxWidth={ 'lg' }>
                <DialogTitle>Promocja na krótki termin przydatności</DialogTitle>
                <DialogContent>
                    <Item>
                        <b>{ resource?.productName }, partia: { resource?.productBatchId }</b>, nr magazynowy: { resource?.warehouseBatchNumber }<br/>
                        Data zatowarowania:
                        &nbsp;
                        {
                            moment(resource?.stockingDate, MISC_DATE_ISO_FORMAT)
                                .format(MISC_DATE_POLISH_FORMAT)
                        },
                        Data przydatności:
                        &nbsp;
                        {
                            moment(resource?.expiryDate, MISC_DATE_ISO_DATE_ONLY_FORMAT)
                                .format(MISC_DATE_POLISH_DATE_ONLY_FORMAT)
                        }
                    </Item>
                    <br/>
                    <TableContainer>
                        <Table stickyHeader>
                            <TableHead>
                                <TableRow>
                                    <TableCell align="center">Aktualna liczba produktów</TableCell>
                                    <TableCell align="center" sx={ { borderLeft: '1px solid black' } }>Zablokowana przez promocję na produkt</TableCell>
                                    <TableCell align="center">Cena zakupu</TableCell>
                                    <TableCell align="center">Cena bazowa</TableCell>
                                    <TableCell align="center">Aktualna cena produktu</TableCell>
                                    <TableCell align="center" sx={ { minWidth: 120, maxWidth: 120 } }>Upust [%]</TableCell>
                                    <TableCell align="center">Cena promocyjna końcowa</TableCell>
                                    <TableCell align="center">Promocja od</TableCell>
                                    <TableCell align="center"></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                    <TableCell align="right">{ resource?.numberOfStockedItems - resource?.numberOfSoldItems }</TableCell>
                                    <TableCell align="center" sx={ { borderLeft: '1px solid black' } }>
                                        {
                                            resource?.excludeExpirationPromotion
                                                ? <CheckIcon color="success"/>
                                                : <ClearIcon color="error"/>
                                        }
                                    </TableCell>
                                    <TableCell align="center">{ resource?.purchaseGrossPrice?.toFixed(2) }</TableCell>
                                    <TableCell align="center">{ resource?.basicGrossPrice?.toFixed(2) }</TableCell>
                                    <TableCell align="center">{ currentGrossPrice }</TableCell>
                                    <TableCell align="center">
                                        <TextField
                                            value={ discount }
                                            type="number"
                                            inputProps={ { min: '0.01', max: '100.00', step: '0.01' } }
                                            onChange={ event => setDiscount(event.target.value) }
                                        />
                                    </TableCell>
                                    <TableCell align="center">
                                        {
                                            resource?.excludeExpirationPromotion
                                                ? currentGrossPrice
                                                : discount ? calculateDiscount(currentGrossPrice, discount) : ''
                                        }
                                    </TableCell>
                                    <TableCell>
                                        <DateTimePicker
                                            value={ timeFrom }
                                            onChange={ newDate => setTimeFrom(newDate) }
                                            sx={ { width: 220 } }
                                        />
                                    </TableCell>
                                    <TableCell align="center">
                                        {
                                            expirationPromotionId &&
                                            <DeleteButton onDeleteClick={ () => onDelete() }/>
                                        }
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </DialogContent>
                <DialogActions>
                    <Button onClick={ onSubmit } disabled={ !discount || !timeFrom }>Zapisz</Button>
                    <Button onClick={ onClose }>Zamknij</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
