import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import * as Yup from 'yup';
import { Form, Formik } from 'formik';
import { Grid } from '@mui/material';
import moment from 'moment/moment';

import TextFieldFormik from '../../../../../common/text_field';
import { SubmitButton } from '../../../../../common/button';
import { getAutomaticExpirationPromotionSettingType } from '../../../../../common/utils/automatic_expiration_promotion_setting';
import { parseDateString } from '../../../../../../core/utils/date_utils';
import { getAutomaticExpirationPromotionSettingAction, getAutomaticExpirationPromotionSettingState } from '../../../../../../features/price/get_automatic_expiration_promotion_setting';
import useNotification from '../../../../../../core/hooks/use_notification';
import TimePickerFormik from '../../../../../common/time_picker';
import { updateAutomaticExpirationPromotionSettingAction, updateAutomaticExpirationPromotionSettingState } from '../../../../../../features/price/update_automatic_expiration_promotion_setting';
import { MISC_DATE_ISO_TIME_ONLY_FORMAT } from '../../../../../../core/constants';
import { getAutomaticExpirationPromotionSettingNotificationData, updateAutomaticExpirationPromotionSettingNotificationData } from '../../../../../../core/utils/notification_utils';
import { ApiRequestStatusEnum } from '../../../../../../core/enums/common/api';

const AutomaticExpirationPromotionSettingForm = ({ type }) => {
    const dispatch = useDispatch();
    const { showNotification, showErrorMessage } = useNotification();

    const getAutomaticExpirationPromotionSetting = useSelector(getAutomaticExpirationPromotionSettingState);
    const updateAutomaticExpirationPromotionSetting = useSelector(updateAutomaticExpirationPromotionSettingState);

    const initialValues = {
        type: getAutomaticExpirationPromotionSettingType(type),
        discount: getAutomaticExpirationPromotionSetting?.data?.discount || '',
        expirationPromotionCreationTime: getAutomaticExpirationPromotionSetting?.data?.expirationPromotionCreationTime
            ? moment(getAutomaticExpirationPromotionSetting?.data?.expirationPromotionCreationTime, MISC_DATE_ISO_TIME_ONLY_FORMAT)
            : ''
    };

    const schema = Yup.object().shape({
        type: Yup.string().required(),
        discount: Yup.number()
            .required()
            .min(0.01)
            .max(100.00),
        expirationPromotionCreationTime: Yup.date()
            .transform(parseDateString)
            .nullable()
            .required(),
    });

    const onSubmit = (values) => {
        const expirationPromotionCreationTime = values?.expirationPromotionCreationTime?.format(MISC_DATE_ISO_TIME_ONLY_FORMAT);

        const form = {
            discount: values?.discount,
            expirationPromotionCreationTime
        };

        dispatch(updateAutomaticExpirationPromotionSettingAction({ form, type }))
            .then(response => {
                showNotification(updateAutomaticExpirationPromotionSettingNotificationData(response));

                response?.meta?.requestStatus === ApiRequestStatusEnum.FULFILLED && dispatch(getAutomaticExpirationPromotionSettingAction(type))
                    .then(response => showErrorMessage(getAutomaticExpirationPromotionSettingNotificationData(response)));
            });
    };

    return (
        <Formik
            initialValues={ initialValues }
            validationSchema={ schema }
            validateOnChange={ false }
            validateOnBlur={ false }
            onSubmit={ onSubmit }>
            { () =>
                <Grid component={ Form } container rowGap={ 4 }>
                    <Grid container item>
                        <TextFieldFormik name="type" label="Typ promocji" disabled/>
                    </Grid>
                    <Grid container item gap={ 2 }>
                        <Grid item xs={ 2 }>
                            <TextFieldFormik name="discount" label="Obniżka" type="number" required inputProps={ { min: '0.01', max: '100.00', step: '0.01' } }/>
                        </Grid>
                        <Grid item xs={ 2 }>
                            <TimePickerFormik
                                name="expirationPromotionCreationTime"
                                required
                                label="Godzina promocji"
                                InputLabelProps={ { shrink: true } }
                                views={ ['hours'] }/>
                        </Grid>
                    </Grid>
                    <Grid container item mt={ 2 } justifyContent="flex-end">
                        <SubmitButton isLoading={ updateAutomaticExpirationPromotionSetting?.loading }>Zapisz</SubmitButton>
                    </Grid>
                </Grid>
            }
        </Formik>
    );
};

export default AutomaticExpirationPromotionSettingForm;
