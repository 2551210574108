import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Grid, Typography } from '@mui/material';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import _ from 'lodash';

import useNotification from '../../../../../core/hooks/use_notification';
import { getProductAction, getProductState } from '../../../../../features/product/get_product';
import { getConstantsState } from '../../../../../features/product/get_constants';
import TextFieldFormik from '../../../../common/text_field';
import { SubmitButton } from '../../../../common/button';
import { SelectFieldFormik, SelectFieldMultipleFormik } from '../../../../common/select_field';
import RadioGroupFormik from '../../../../common/radio_group';
import { isNullOrUndefined } from '../../../../../core/utils/misc_utils';
import FileInput from '../../../../common/file_input';
import { updateProductAction, updateProductState } from '../../../../../features/product/update_product';
import { getProductNotificationData, updateProductNotificationData } from '../../../../../core/utils/notification_utils';
import { getAllFoodPartnersState } from '../../../../../features/food_partner/get_all_food_partners';
import { SuggestedShelfEnum } from '../../../../../core/enums/product/product';
import { yesNoOptions } from '../../../../common/form_utils';
import { getAllProducersState } from '../../../../../features/product/get_all_producers';

const productLabelTypeOptions = [
    { value: 'INTERNAL', label: 'Własna' },
    { value: 'EXTERNAL_WITH_CODE', label: 'Zewnętrzna z kodem' },
    { value: 'EXTERNAL_WITHOUT_CODE', label: 'Zewnętrzna bez kodu' },
];

const suggestedShelfOptions = [
    { value: SuggestedShelfEnum.F1_WRAPS, label: '1L - Wrapy' },
    { value: SuggestedShelfEnum.F2_LUNCHES_OR_WRAPS, label: '2L - Lunche / Wrapy' },
    { value: SuggestedShelfEnum.F3_SALADS_OR_SUSHI, label: '3L - Sałatki / Sushi' },
    { value: SuggestedShelfEnum.F4_BAGELS, label: '4L - Bajgle' },
    { value: SuggestedShelfEnum.F5_SANDWICHES, label: '5L - Kanapki' },
    { value: SuggestedShelfEnum.F6_DAIRY, label: '6L - Nabiał' },
    { value: SuggestedShelfEnum.F7_DRINKS, label: '7L - Napoje szkło' },
    { value: SuggestedShelfEnum.D1_BARS, label: '1S - Batony' },
    { value: SuggestedShelfEnum.D2_CHIPS_OR_MOUSSES, label: '2S - Chrupki / Musy' },
    { value: SuggestedShelfEnum.NOT_SPECIFIED, label: 'Nieokreślona' }
];

const ProductForm = ({ languageCode }) => {
    const dispatch = useDispatch();
    const { showNotification, showErrorMessage } = useNotification();

    const getProduct = useSelector(getProductState);
    const getConstants = useSelector(getConstantsState);
    const getProducers = useSelector(getAllProducersState);
    const getFoodPartners = useSelector(getAllFoodPartnersState);
    const updateProduct = useSelector(updateProductState);

    const [picture, setPicture] = useState(null);

    const initialValues = {
        workingName: getProduct?.data?.workingName || '',
        producerId: getProducers?.data?.find(producer => producer?.id === getProduct?.data?.producer?.id)?.id || '',
        typeId: getConstants?.data?.productConstants?.FOOD_TYPE?.find(productConstant => productConstant?.body === getProduct?.data?.type)?.id || '',
        allergenIds: [...getConstants?.data?.productConstants?.ALLERGEN?.filter(productConstant => getProduct?.data?.allergens?.includes(productConstant?.body)).map(productConstant => productConstant?.id)],
        possibleAllergenIds: [...getConstants?.data?.productConstants?.ALLERGEN?.filter(productConstant => getProduct?.data?.possibleAllergens?.includes(productConstant?.body)).map(productConstant => productConstant?.id)],
        typeCuisineId: getConstants?.data?.productConstants?.TYPE_CUISINE?.find(productConstant => productConstant?.body === getProduct?.data?.typeCuisine)?.id || '',
        dietIds: [...getConstants?.data?.productConstants?.DIET?.filter(productConstant => getProduct?.data?.diets?.includes(productConstant?.body)).map(productConstant => productConstant?.id)],
        flavorId: getConstants?.data?.productConstants?.FLAVOR?.find(productConstant => productConstant?.body === getProduct?.data?.flavor)?.id || '',
        attributeIds: [...getConstants?.data?.productConstants?.ATTRIBUTE?.filter(productConstant => getProduct?.data?.attributes?.includes(productConstant?.body)).map(productConstant => productConstant?.id)],
        foodPartnerIds: [...getFoodPartners?.data?.filter(foodPartner => getProduct?.data?.foodPartnerIds?.includes(foodPartner?.id)).map(foodPartner => foodPartner?.id)],
        defaultDaysToExpiration: getProduct?.data?.defaultDaysToExpiration || '',
        weight: getProduct?.data?.weight || '',
        unitId: getConstants?.data?.units?.find(unit => unit?.symbol === getProduct?.data?.unit)?.id || '',
        calories: getProduct?.data?.calories || '',
        carbons: getProduct?.data?.carbons || '',
        protein: getProduct?.data?.protein || '',
        fat: getProduct?.data?.fat || '',
        productLabelType: productLabelTypeOptions.find(productLabelTypeOption => productLabelTypeOption.value === getProduct?.data?.productLabel)?.value || '',
        suggestedShelf: suggestedShelfOptions.find(suggestedShelf => suggestedShelf.value === getProduct?.data?.suggestedShelf)?.value || '',
        maxRowCount: getProduct?.data?.maxRowCount || '',
        maxShelfCount: getProduct?.data?.maxShelfCount || '',
        productLabelFormatId: getConstants?.data?.labelFormats?.find(labelFormat => labelFormat?.format === getProduct?.data?.productLabelFormat)?.id || '',
        recommended: getProduct?.data?.recommended || false,
        picture: null
    };

    const schema = Yup.object().shape({
        workingName: Yup.string().required(),
        producerId: Yup.number(),
        typeId: Yup.number().required(),
        weight: Yup.number().required(),
        unitId: Yup.number().required(),
        calories: Yup.number().required(),
        carbons: Yup.number().required(),
        protein: Yup.number().required(),
        fat: Yup.number().required(),
        productLabelType: Yup.string().required(),
        productLabelFormatId: Yup.number().required(),
        suggestedShelf: Yup.string().required(),
        maxRowCount: Yup.number().required(),
        maxShelfCount: Yup.number().required(),
        recommended: Yup.bool().required()
    });

    const onAttach = (file, formik) => {
        let reader = new FileReader();
        reader.onloadend = () => setPicture(reader.result);
        reader.readAsDataURL(file);

        formik.setFieldValue('picture', file);
    };

    const onRemove = (formik) => {
        formik.setFieldValue('picture', null);
        setPicture(null);
        formik.setFieldValue('deletePicture', true);
    };

    const onSubmit = (values) => {
        const form = _.omit(values, ['picture']);
        const id = getProduct?.data?.id;
        const picture = values.picture;

        dispatch(updateProductAction({ form, id, picture }))
            .then(response => showNotification(updateProductNotificationData(response)))
            .then(() => {
                dispatch(getProductAction({ id, languageCode }))
                    .then(response => showErrorMessage(getProductNotificationData(response)));
            });
    };

    return (
        <Formik
            initialValues={ initialValues }
            validationSchema={ schema }
            validateOnChange={ false }
            validateOnBlur={ false }
            onSubmit={ onSubmit }>
            { (formik) =>
                <Grid component={ Form } container rowGap={ 4 }>
                    <Grid container item md={ 12 } lg={ 6 } direction="column" rowGap={ 4 }>
                        <Grid container item justifyContent="center">
                            {
                                picture || (!formik?.values?.deletePicture && !getProduct?.data?.defaultPicture && getProduct?.data?.pictureUrl)
                                    ?
                                    <img src={ picture || getProduct?.data?.pictureUrl }
                                         alt="Nie udało się wczytać zdjęcia produktu"
                                         width={ 400 }
                                         height={ 300 }/>
                                    : <Typography width={ 400 } textAlign="center" variant="h6">Brak zdjęcia</Typography>
                            }
                        </Grid>
                        <Grid container item>
                            <FileInput
                                label="Zdjęcie produktu"
                                text="Dodaj lub przeciągnij plik png/jpg"
                                errorMessage="Niepoprawny plik. Wymagany png lub jpg."
                                validate={ picture => ['image/png', 'image/jpeg'].includes(picture.type) }
                                isAttached={ !isNullOrUndefined(formik.values.picture) || (!formik?.values?.deletePicture && !getProduct?.data?.defaultPicture && getProduct?.data?.pictureUrl) }
                                onAttach={ (picture) => onAttach(picture, formik) }
                                onRemove={ () => onRemove(formik) }
                                uploadedFileName={ 'Aktualne zdjęcie produktu' }
                            />
                        </Grid>
                    </Grid>
                    <Grid container item md={ 12 } lg={ 6 } rowGap={ 2 } alignContent="flex-start">
                        <Grid container item>
                            <Grid item xs={ 12 }>
                                <TextFieldFormik
                                    name="workingName"
                                    label="Nazwa robocza"
                                    required
                                />
                            </Grid>
                        </Grid>
                        <Grid container item>
                            <Grid item xs={ 6 }>
                                <SelectFieldFormik
                                    name="producerId"
                                    label="Producent"
                                    options={ getProducers?.data?.map(producer => ({ value: producer?.id, label: producer?.name })) }
                                />
                            </Grid>
                        </Grid>
                        <Grid container item>
                            <Grid item xs={ 6 }>
                                <SelectFieldFormik
                                    name="typeId"
                                    label="Typ produktu"
                                    options={ getConstants?.data?.productConstants?.FOOD_TYPE?.map(productConstant => ({ value: productConstant?.id, label: productConstant?.body })) }
                                    required
                                />
                            </Grid>
                        </Grid>
                        <Grid container item>
                            <SelectFieldMultipleFormik
                                name="allergenIds"
                                label="Alergeny - wiele"
                                options={ getConstants?.data?.productConstants?.ALLERGEN?.map(productConstant => ({ value: productConstant?.id, label: productConstant?.body })) }
                            />
                        </Grid>
                        <Grid container item>
                            <SelectFieldMultipleFormik
                                name="possibleAllergenIds"
                                label="Możliwe alergeny - wiele"
                                options={ getConstants?.data?.productConstants?.ALLERGEN?.map(productConstant => ({ value: productConstant?.id, label: productConstant?.body })) }
                            />
                        </Grid>
                        <Grid container item>
                            <SelectFieldMultipleFormik
                                name="dietIds"
                                label="Diety - wiele"
                                options={ getConstants?.data?.productConstants?.DIET?.map(productConstant => ({ value: productConstant?.id, label: productConstant?.body })) }
                            />
                        </Grid>
                        <Grid container item>
                            <Grid item xs={ 6 }>
                                <SelectFieldFormik
                                    name="typeCuisineId"
                                    label="Typ kuchni"
                                    options={ getConstants?.data?.productConstants?.TYPE_CUISINE?.map(productConstant => ({ value: productConstant?.id, label: productConstant?.body })) }
                                />
                            </Grid>
                        </Grid>
                        <Grid container item>
                            <Grid item xs={ 6 }>
                                <SelectFieldFormik
                                    name="flavorId"
                                    label="Smak"
                                    options={ getConstants?.data?.productConstants?.FLAVOR?.map(productConstant => ({ value: productConstant?.id, label: productConstant?.body })) }
                                />
                            </Grid>
                        </Grid>
                        <Grid container item>
                            <SelectFieldMultipleFormik
                                name="attributeIds"
                                label="Tagi - wiele"
                                options={ getConstants?.data?.productConstants?.ATTRIBUTE?.map(productConstant => ({ value: productConstant?.id, label: productConstant?.body })) }
                            />
                        </Grid>
                        <Grid container item>
                            <SelectFieldMultipleFormik
                                name="foodPartnerIds"
                                label="Dostawca - wiele"
                                options={ getFoodPartners?.data?.map(foodPartner => ({ value: foodPartner?.id, label: foodPartner?.name })) }
                                required
                            />
                        </Grid>
                        <Grid container item>
                            <TextFieldFormik name="defaultDaysToExpiration" label="Domyślna liczba dni przydatności produktu" type="number" inputProps={ { min: '1', step: '1' } }/>
                        </Grid>
                        <Grid container item>
                            <TextFieldFormik name="weight" label="Waga" type="number" required/>
                        </Grid>
                        <Grid container item>
                            <Grid item xs={ 6 }>
                                <SelectFieldFormik
                                    name="unitId"
                                    label="Jednostka wagi"
                                    options={ getConstants?.data?.units?.map(unit => ({ value: unit?.id, label: unit?.symbol })) }
                                    required
                                />
                            </Grid>
                        </Grid>
                        <Grid container item>
                            <TextFieldFormik name="calories" label="Kalorie" type="number" required/>
                        </Grid>
                        <Grid container item>
                            <TextFieldFormik name="carbons" label="Węglowodany" type="number" required/>
                        </Grid>
                        <Grid container item>
                            <TextFieldFormik name="protein" label="Białko" type="number" required/>
                        </Grid>
                        <Grid container item>
                            <TextFieldFormik name="fat" label="Tłuszcz" type="number" required/>
                        </Grid>
                        <Grid container item>
                            <Grid item xs={ 6 }>
                                <SelectFieldFormik
                                    name="productLabelType"
                                    label="Typ etykiety"
                                    options={ productLabelTypeOptions }
                                    required
                                />
                            </Grid>
                        </Grid>
                        <Grid container item>
                            <Grid item xs={ 6 }>
                                <SelectFieldFormik
                                    name="productLabelFormatId"
                                    label="Format etykiety"
                                    options={ getConstants?.data?.labelFormats?.map(labelFormat => ({ value: labelFormat?.id, label: labelFormat?.format })) }
                                    required
                                />
                            </Grid>
                        </Grid>
                        <Grid container item>
                            <Grid item xs={ 6 }>
                                <SelectFieldFormik
                                    name="suggestedShelf"
                                    label="Sugerowana półka"
                                    options={ suggestedShelfOptions }
                                    required
                                />
                            </Grid>
                        </Grid>
                        <Grid container item gap={ 2 }>
                            <Grid item xs={ 4 }>
                                <TextFieldFormik name="maxRowCount" label="Maksymalna ilość w rzędzie" type="number" required inputProps={ { min: '1', step: '1' } }/>
                            </Grid>
                            <Grid item xs={ 4 }>
                                <TextFieldFormik name="maxShelfCount" label="Maksymalna ilość na półce" type="number" required inputProps={ { min: '1', step: '1' } }/>
                            </Grid>
                        </Grid>
                        <Grid container item>
                            <RadioGroupFormik
                                name="recommended"
                                label="Polecane"
                                options={ yesNoOptions }
                            />
                        </Grid>
                        <Grid container item mt={ 2 } justifyContent="flex-end">
                            <SubmitButton isLoading={ updateProduct?.loading }>Zapisz</SubmitButton>
                        </Grid>
                    </Grid>
                </Grid>
            }
        </Formik>
    );
};

export default ProductForm;
