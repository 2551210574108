import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import webClient from '../../core/web_client';
import { SUPPLIERS_PATH } from '../../core/constants';
import { extractErrorData, extractResponseData } from '../../core/utils/api_utils';
import { DEFAULT_STATE, STATE__API__FAIL, STATE__API__STARTED, STATE__API__SUCCESS } from '../../core/utils/store_utils';
import { invalidateToken } from '../authorization/get_token';

export const updateSupplierStatusAction = createAsyncThunk(
    'store/updateSupplierStatusAction',
    async (data, { fulfillWithValue, rejectWithValue }) => {
        try {
            const response = await webClient.patch(`${ SUPPLIERS_PATH }/${ data.id }`, data.form);
            return fulfillWithValue(extractResponseData(response));
        } catch (error) {
            return rejectWithValue(extractErrorData(error));
        }
    }
);

const updateSupplierStatusSlice = createSlice({
    name: 'updateSupplierStatusSlice',
    initialState: DEFAULT_STATE,
    reducers: {},
    extraReducers: builder => {
        builder.addCase(updateSupplierStatusAction.pending, () => STATE__API__STARTED);
        builder.addCase(updateSupplierStatusAction.fulfilled, (_, { payload }) => STATE__API__SUCCESS(payload));
        builder.addCase(updateSupplierStatusAction.rejected, (_, { payload }) => STATE__API__FAIL(payload));
        builder.addCase(invalidateToken, () => DEFAULT_STATE);
    }
});

export const updateSupplierStatusState = (state) => state.store.updateSupplierStatus;
export default updateSupplierStatusSlice.reducer;
