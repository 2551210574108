import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import webClient from '../../core/web_client';
import { AUTOMATIC_EXPIRATION_PROMOTION_SETTINGS_PATH } from '../../core/constants';
import { extractErrorData, extractResponseData } from '../../core/utils/api_utils';
import { DEFAULT_STATE, STATE__API__FAIL, STATE__API__STARTED, STATE__API__SUCCESS } from '../../core/utils/store_utils';
import { invalidateToken } from '../authorization/get_token';

export const getAutomaticExpirationPromotionSettingAction = createAsyncThunk(
    'price/getAutomaticExpirationPromotionSettingAction',
    async (type, { fulfillWithValue, rejectWithValue }) => {
        try {
            const response = await webClient.get(`${AUTOMATIC_EXPIRATION_PROMOTION_SETTINGS_PATH}/${type}`);
            return fulfillWithValue(extractResponseData(response));
        } catch (error) {
            return rejectWithValue(extractErrorData(error));
        }
    }
);

const getAutomaticExpirationPromotionSettingSlice = createSlice({
    name: 'getAutomaticExpirationPromotionSettingsSlice',
    initialState: DEFAULT_STATE,
    reducers: {
        invalidateAutomaticExpirationPromotionSetting() {
            return DEFAULT_STATE;
        }
    },
    extraReducers: builder => {
        builder.addCase(getAutomaticExpirationPromotionSettingAction.pending, () => STATE__API__STARTED);
        builder.addCase(getAutomaticExpirationPromotionSettingAction.fulfilled, (_, { payload }) => STATE__API__SUCCESS(payload));
        builder.addCase(getAutomaticExpirationPromotionSettingAction.rejected, (_, { payload }) => STATE__API__FAIL(payload));
        builder.addCase(invalidateToken, () => DEFAULT_STATE);
    }
});

export const { invalidateAutomaticExpirationPromotionSetting } = getAutomaticExpirationPromotionSettingSlice.actions;
export const getAutomaticExpirationPromotionSettingState = (state) => state.price.getAutomaticExpirationPromotionSetting;
export default getAutomaticExpirationPromotionSettingSlice.reducer;