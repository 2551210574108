import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import webClient from '../../core/web_client';
import { COUPONS_PATH, MISC_REST_CONTENT_TYPE_APPLICATION_JSON, MISC_REST_CONTENT_TYPE_MULTIPART } from '../../core/constants';
import { extractErrorData, extractResponseData } from '../../core/utils/api_utils';
import { DEFAULT_STATE, STATE__API__FAIL, STATE__API__STARTED, STATE__API__SUCCESS } from '../../core/utils/store_utils';
import { invalidateToken } from '../authorization/get_token';

export const updateCouponAction = createAsyncThunk(
    'coupon/updateCouponAction',
    async (data, { fulfillWithValue, rejectWithValue }) => {
        try {
            let body = new FormData();
            const formJson = JSON.stringify(data.form);
            const formBlob = new Blob([formJson], {
                type: MISC_REST_CONTENT_TYPE_APPLICATION_JSON
            });

            body.append('picture', data.picture);
            body.append('form', formBlob);

            const response = await webClient.put(`${ COUPONS_PATH }/${ data.couponId }`, body, {
                headers: {
                    'content-type': MISC_REST_CONTENT_TYPE_MULTIPART
                }
            });
            return fulfillWithValue(extractResponseData(response));
        } catch (error) {
            return rejectWithValue(extractErrorData(error));
        }
    }
);

const updateCouponSlice = createSlice({
    name: 'updateCouponSlice',
    initialState: DEFAULT_STATE,
    reducers: {},
    extraReducers: builder => {
        builder.addCase(updateCouponAction.pending, () => STATE__API__STARTED);
        builder.addCase(updateCouponAction.fulfilled, (_, { payload }) => STATE__API__SUCCESS(payload));
        builder.addCase(updateCouponAction.rejected, (_, { payload }) => STATE__API__FAIL(payload));
        builder.addCase(invalidateToken, () => DEFAULT_STATE);
    }
});

export const updateCouponState = (state) => state.coupon.updateCoupon;
export default updateCouponSlice.reducer;
