import { combineReducers, createAsyncThunk } from '@reduxjs/toolkit';

import getEmployers from './get_employers';
import getEmployer from './get_employer';
import getAllEmployers from './get_all_employers';
import getEmployerDiscount from './get_employer_discount';
import updateEmployerDiscount from './update_employer_discount';
import deleteEmployerDiscount from './delete_employer_discount';
import updateEmployer from './update_employer';
import updateEmployerStatus from './update_employer_status';
import getEmployees from './get_employees';
import getEmployeesToConfirm from './get_employees_to_confirm';
import updateEmployee from './update_employee';
import { webClientFileDownload } from '../../core/web_client';
import { EMPLOYERS_PATH } from '../../core/constants';
import { exportFile, extractErrorDataFromFile, extractResponseData } from '../../core/utils/api_utils';

export const getEmployerReport = createAsyncThunk(
    'benefit/getEmployerReport',
    async (data, { fulfillWithValue, rejectWithValue }) => {
        try {
            const response = await webClientFileDownload.post(`${ EMPLOYERS_PATH }/${ data.employerId }/reports`, data.form);
            const fileName = response.headers['content-disposition']?.split('filename=')[1]?.replaceAll("\"", "");
            exportFile(extractResponseData(response), fileName ? fileName : data.form.reportType + '.pdf');
            return fulfillWithValue();
        } catch (error) {
            const response = await extractErrorDataFromFile(extractResponseData(error?.response));
            return rejectWithValue(response);
        }
    }
);

export default combineReducers({
    getEmployers, getEmployer, getAllEmployers, getEmployerDiscount, updateEmployerDiscount, deleteEmployerDiscount,
    updateEmployer, updateEmployerStatus, getEmployees, getEmployeesToConfirm, updateEmployee
});
