import { GenerateWarehouseDocumentTypeRequestEnum, WarehouseDocumentTypeEnum } from '../../../core/enums/inventory/warehouse_document';
import { WarehouseTypeEnum } from '../../../core/enums/inventory/warehouse';

export const getGenerateWarehouseDocumentTypeRequestOptions = [
    { value: GenerateWarehouseDocumentTypeRequestEnum.PZ_DOCUMENT.value, label: GenerateWarehouseDocumentTypeRequestEnum.PZ_DOCUMENT.label },
    { value: GenerateWarehouseDocumentTypeRequestEnum.PZ_AND_MM_DOCUMENTS.value, label: GenerateWarehouseDocumentTypeRequestEnum.PZ_AND_MM_DOCUMENTS.label },
    { value: GenerateWarehouseDocumentTypeRequestEnum.MM_DOCUMENTS.value, label: GenerateWarehouseDocumentTypeRequestEnum.MM_DOCUMENTS.label },
    { value: GenerateWarehouseDocumentTypeRequestEnum.PW_DOCUMENT.value, label: GenerateWarehouseDocumentTypeRequestEnum.PW_DOCUMENT.label },
];

export const getWarehouseDocumentTypeOptions = [
    { value: WarehouseDocumentTypeEnum.PZ_DOCUMENT.value, label: WarehouseDocumentTypeEnum.PZ_DOCUMENT.label },
    { value: WarehouseDocumentTypeEnum.PW_DOCUMENT.value, label: WarehouseDocumentTypeEnum.PW_DOCUMENT.label },
    { value: WarehouseDocumentTypeEnum.MP_DOCUMENT.value, label: WarehouseDocumentTypeEnum.MP_DOCUMENT.label },
    { value: WarehouseDocumentTypeEnum.MW_DOCUMENT.value, label: WarehouseDocumentTypeEnum.MW_DOCUMENT.label },
    { value: WarehouseDocumentTypeEnum.RW_DOCUMENT.value, label: WarehouseDocumentTypeEnum.RW_DOCUMENT.label },
];

export const getWarehouseName = (warehouse) => {
    switch (warehouse?.type) {
        case WarehouseTypeEnum.MAIN.value:
            return 'Magazyn ' + WarehouseTypeEnum.MAIN.label;
        case WarehouseTypeEnum.INVENTORY.value:
            return 'Magazyn ' + WarehouseTypeEnum.INVENTORY.label;
        case WarehouseTypeEnum.POD.value:
            return warehouse?.pod?.indoorPartnerName + ' (' + warehouse?.pod?.description + ') - ' + (warehouse?.pod?.serviceId || 'brak urządzenia');
        default:
            return warehouse?.name;
    }
};