import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import webClient from '../../core/web_client';
import { PRODUCTS_PATH } from '../../core/constants';
import { extractErrorData, extractResponseData } from '../../core/utils/api_utils';
import { DEFAULT_STATE, STATE__API__FAIL, STATE__API__STARTED, STATE__API__SUCCESS } from '../../core/utils/store_utils';
import { invalidateToken } from '../authorization/get_token';

export const updateProductDetailsAction = createAsyncThunk(
    'product/updateProductDetailsAction',
    async (data, { fulfillWithValue, rejectWithValue }) => {
        try {
            const response = await webClient.put(`${ PRODUCTS_PATH }/${ data.id }/details`, data.form);
            return fulfillWithValue(extractResponseData(response));
        } catch (error) {
            return rejectWithValue(extractErrorData(error));
        }
    }
);

const updateProductDetailsSlice = createSlice({
    name: 'updateProductDetailsSlice',
    initialState: DEFAULT_STATE,
    reducers: {},
    extraReducers: builder => {
        builder.addCase(updateProductDetailsAction.pending, () => STATE__API__STARTED);
        builder.addCase(updateProductDetailsAction.fulfilled, (_, { payload }) => STATE__API__SUCCESS(payload));
        builder.addCase(updateProductDetailsAction.rejected, (_, { payload }) => STATE__API__FAIL(payload));
        builder.addCase(invalidateToken, () => DEFAULT_STATE);
    }
});

export const updateProductDetailsState = (state) => state.product.updateProductDetails;
export default updateProductDetailsSlice.reducer;