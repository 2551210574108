import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Grid, LinearProgress } from '@mui/material';

import PZForm from './pz_form';
import GeneralDataForm from './general_data_form';
import { getAllFoodPartnersNotificationData, getAllWarehousesNotificationData } from '../../../../../core/utils/notification_utils';
import useNotification from '../../../../../core/hooks/use_notification';
import { GenerateWarehouseDocumentTypeRequestEnum } from '../../../../../core/enums/inventory/warehouse_document';
import PZAndMMForm from './pz_and_mm_form';
import MMForm from './mm_form';
import { getAllFoodPartnersAction, getAllFoodPartnersState } from '../../../../../features/food_partner/get_all_food_partners';
import { getAllWarehousesAction, getAllWarehousesState } from '../../../../../features/inventory/get_all_warehouses';
import Summary from './summary';
import PWForm from './pw_form';

const GenerateWarehouseDocumentForm = () => {
    const dispatch = useDispatch();
    const { showErrorMessage } = useNotification();

    const getFoodPartners = useSelector(getAllFoodPartnersState);
    const getAllWarehouses = useSelector(getAllWarehousesState);

    const [view, setView] = useState(0);
    const [form, setForm] = useState({});
    const [data, setData] = useState({});

    useEffect(() => {
        dispatch(getAllFoodPartnersAction())
            .then(response => showErrorMessage(getAllFoodPartnersNotificationData(response)));
        dispatch(getAllWarehousesAction())
            .then(response => showErrorMessage(getAllWarehousesNotificationData(response)));
    }, [dispatch, showErrorMessage]);

    const onSubmitGeneralData = (values) => {
        const type = values?.type;
        setForm(values);

        if (type === GenerateWarehouseDocumentTypeRequestEnum.PZ_DOCUMENT.value) {
            setView(1);
        } else if (type === GenerateWarehouseDocumentTypeRequestEnum.PZ_AND_MM_DOCUMENTS.value) {
            setView(2);
        } else if (type === GenerateWarehouseDocumentTypeRequestEnum.MM_DOCUMENTS.value) {
            setView(3);
        } else if (type === GenerateWarehouseDocumentTypeRequestEnum.PW_DOCUMENT.value) {
            setView(4);
        }
    };

    const renderView = () => {
        if (getFoodPartners?.loading || getAllWarehouses?.loading) {
            return (
                <Grid mt={ 6 } item width={ '100%' }>
                    <LinearProgress/>
                </Grid>
            );
        }

        if (getFoodPartners?.data && getAllWarehouses?.data) {
            if (view === 1) {
                return (
                    <PZForm form={ form } setView={ setView } data={ data } setData={ setData }/>
                );
            } else if (view === 2) {
                return (
                    <PZAndMMForm form={ form } setView={ setView } data={ data } setData={ setData }/>
                );
            } else if (view === 3) {
                return (
                    <MMForm form={ form } setView={ setView } data={ data } setData={ setData }/>
                );
            } else if (view === 4) {
                return (
                    <PWForm form={ form } setView={ setView } data={ data } setData={ setData }/>
                );
            } else if (view === 5) {
                return (
                    <Summary setView={ setView } data={ data }/>
                );
            } else {
                return (
                    <GeneralDataForm form={ form } onSubmitGeneralData={ onSubmitGeneralData }/>
                );
            }
        }

        return <></>;
    };

    return (
        <Grid container mb={ 6 } pl={ 15 } pr={ 15 }>
            {
                renderView()
            }
        </Grid>
    );
};

export default GenerateWarehouseDocumentForm;
