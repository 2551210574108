import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Form, Formik } from 'formik';
import { Grid } from '@mui/material';
import * as Yup from 'yup';

import useNotification from '../../../../../core/hooks/use_notification';
import { updateAutomaticCouponSettingTranslationNotificationData } from '../../../../../core/utils/notification_utils';
import TextFieldFormik from '../../../../common/text_field';
import { SubmitButton } from '../../../../common/button';
import { getAutomaticCouponSettingTranslationState } from '../../../../../features/coupon/get_automatic_coupon_setting_translation';
import { updateAutomaticCouponSettingTranslationAction, updateAutomaticCouponSettingTranslationState } from '../../../../../features/coupon/update_automatic_coupon_setting_translation';

const AutomaticCouponSettingTranslationForm = ({ automaticCouponType, languageCode }) => {
    const dispatch = useDispatch();
    const { showNotification } = useNotification();

    const getAutomaticCouponSettingTranslation = useSelector(getAutomaticCouponSettingTranslationState);
    const updateAutomaticCouponSettingTranslation = useSelector(updateAutomaticCouponSettingTranslationState);

    const initialValues = {
        name: getAutomaticCouponSettingTranslation?.data?.name || '',
        description: getAutomaticCouponSettingTranslation?.data?.description || ''
    };

    const schema = Yup.object().shape({
        name: Yup.string().required(),
        description: Yup.string().required()
    });

    const onSubmit = (values) => {
        const form = {
            ...values,
            languageCode
        };

        dispatch(updateAutomaticCouponSettingTranslationAction({ form, automaticCouponType }))
            .then(response => showNotification(updateAutomaticCouponSettingTranslationNotificationData(response)));
    };

    return (
        <Formik
            initialValues={ initialValues }
            validationSchema={ schema }
            validateOnChange={ false }
            validateOnBlur={ false }
            onSubmit={ onSubmit }>
            { () =>
                <Grid component={ Form } container rowGap={ 4 }>
                    <Grid container item>
                        <TextFieldFormik name="name" label="Nazwa" required/>
                    </Grid>
                    <Grid container item>
                        <TextFieldFormik name="description" label="Opis" required/>
                    </Grid>
                    <Grid container item mt={ 2 } justifyContent="flex-end">
                        <SubmitButton isLoading={ updateAutomaticCouponSettingTranslation?.loading }>Zapisz</SubmitButton>
                    </Grid>
                </Grid>
            }
        </Formik>
    );
};

export default AutomaticCouponSettingTranslationForm;
