import { setLocale } from 'yup';

setLocale({
    mixed: {
        required: 'Wymagane'
    },
    array: {
        min: ({ min }) => {
            return 'Wybierz minimum ' + min
        },
    }
})