import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import webClient from '../../core/web_client';
import { POD_PRICE_ASSIGNMENT_PATH } from '../../core/constants';
import { extractErrorData, extractResponseData } from '../../core/utils/api_utils';
import { DEFAULT_STATE, STATE__API__FAIL, STATE__API__STARTED, STATE__API__SUCCESS } from '../../core/utils/store_utils';
import { invalidateToken } from '../authorization/get_token';

export const deletePodPriceAssignmentAction = createAsyncThunk(
    'price/deletePodPriceAssignmentAction',
    async (id, { fulfillWithValue, rejectWithValue }) => {
        try {
            const response = await webClient.delete(`${ POD_PRICE_ASSIGNMENT_PATH }/${ id }`);
            return fulfillWithValue(extractResponseData(response));
        } catch (error) {
            return rejectWithValue(extractErrorData(error));
        }
    }
);

const deletePodPriceAssignmentSlice = createSlice({
    name: 'deletePodPriceAssignmentSlice',
    initialState: DEFAULT_STATE,
    reducers: {},
    extraReducers: builder => {
        builder.addCase(deletePodPriceAssignmentAction.pending, () => STATE__API__STARTED);
        builder.addCase(deletePodPriceAssignmentAction.fulfilled, (_, { payload }) => STATE__API__SUCCESS(payload));
        builder.addCase(deletePodPriceAssignmentAction.rejected, (_, { payload }) => STATE__API__FAIL(payload));
        builder.addCase(invalidateToken, () => DEFAULT_STATE);
    }
});

export const deletePodPriceAssignmentState = (state) => state.price.deletePodPriceAssignment;
export default deletePodPriceAssignmentSlice.reducer;
