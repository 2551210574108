import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import webClient from '../../core/web_client';
import { SUPPLIERS_PATH } from '../../core/constants';
import { exportBase64File, extractErrorData, extractResponseData } from '../../core/utils/api_utils';
import { DEFAULT_STATE, STATE__API__FAIL, STATE__API__STARTED, STATE__API__SUCCESS } from '../../core/utils/store_utils';
import { invalidateToken } from '../authorization/get_token';

export const getSupplierKeyAction = createAsyncThunk(
    'store/getSupplierKeyAction',
    async (id, { fulfillWithValue, rejectWithValue }) => {
        try {
            const response = await webClient.get(`${ SUPPLIERS_PATH }/${ id }/keys`);
            const fileName = 'Kod_QR';
            const extension = 'png';
            exportBase64File(extractResponseData(response)?.qrCode, fileName, extension);
            return fulfillWithValue();
        } catch (error) {
            return rejectWithValue(extractErrorData(error));
        }
    }
);

const getSupplierKeySlice = createSlice({
    name: 'getSupplierKeySlice',
    initialState: DEFAULT_STATE,
    reducers: {},
    extraReducers: builder => {
        builder.addCase(getSupplierKeyAction.pending, () => STATE__API__STARTED);
        builder.addCase(getSupplierKeyAction.fulfilled, (_, { payload }) => STATE__API__SUCCESS(payload));
        builder.addCase(getSupplierKeyAction.rejected, (_, { payload }) => STATE__API__FAIL(payload));
        builder.addCase(invalidateToken, () => DEFAULT_STATE);
    }
});

export const getSupplierKeyState = (state) => state.store.getSupplierKey;
export default getSupplierKeySlice.reducer;