import React, { useState } from 'react';

import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';

import { Button } from '../../../../../common/button';
import ProductAvailabilityForm from './product_availability_form';
import ProductAvailabilityPodsForm from './product_availability_pods_form';

const ProductAvailabilityFormDialog = ({ refreshCalendar }) => {
    const [view, setView] = useState(0);
    const [open, setOpen] = useState(false);
    const [data, setData] = useState({});

    const onClose = () => {
        setData({});
        setView(0);
        setOpen(false);
    };

    const renderView = () => {
        if (view === 1) {
            return (
                <ProductAvailabilityPodsForm
                    data={ data }
                    onClose={ onClose }
                    setView={ setView }
                    refreshCalendar={ refreshCalendar }/>
            );
        } else {
            return (
                <ProductAvailabilityForm
                    data={ data }
                    onClose={ onClose }
                    setData={ setData }
                    setView={ setView }
                    refreshCalendar={ refreshCalendar }/>
            );
        }
    };

    return (
        <>
            <Button onClick={ () => setOpen(true) }>
                Dodaj produkt do kalendarza
            </Button>
            <Dialog PaperProps={ { sx: { maxHeight: 800 } } } open={ open } onClose={ onClose } fullWidth maxWidth={ 'lg' }>
                <DialogTitle>Dodaj produkt do kalendarza</DialogTitle>
                <DialogContent sx={ { paddingTop: '6px !important' } }>
                    {
                        renderView()
                    }
                </DialogContent>
            </Dialog>
        </>
    );
};

export default ProductAvailabilityFormDialog;
