import React from 'react';

import { Card, CardContent, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from '@mui/material';

import DestinationProductsModal from './destination_products_modal';

const SummaryList = ({ showDestinationProductsModal, types, productsByType, products, destinationsList, destinationProducts }) => (
    types?.map((type, index) => {
        const quantityOfType = productsByType[index]
            ?.map(product => product?.quantity)
            ?.reduce((accumulator, current) => accumulator + current, 0);

        return (
            <Card sx={ { width: '100%', backgroundColor: '#f5f5f5' } } key={ index }>
                <CardContent>
                    <Grid container gap={ 4 } mb={ 5 } justifyContent="space-between">
                        <Grid item>
                            <Typography variant="h4">
                                { type } - { quantityOfType } szt.
                            </Typography>
                        </Grid>
                        <Grid item>
                            {
                                showDestinationProductsModal &&
                                <DestinationProductsModal
                                    type={ type }
                                    destinationsList={ destinationsList }
                                    destinationProducts={ destinationProducts[index] }/>
                            }
                        </Grid>
                    </Grid>
                    <TableContainer>
                        <Table stickyHeader>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Nazwa</TableCell>
                                    <TableCell align="center" style={ { width: '15%' } }>Liczba szt.</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    products[index]?.map((product, productIndex) => (
                                        <TableRow
                                            key={ productIndex }
                                            hover
                                        >
                                            <TableCell>
                                                <TextField
                                                    label={ product?.name }
                                                    disabled
                                                    fullWidth/>
                                            </TableCell>
                                            <TableCell align="center">
                                                <TextField
                                                    label={ product?.quantity }
                                                    disabled
                                                    fullWidth/>
                                            </TableCell>
                                        </TableRow>
                                    ))
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                </CardContent>
            </Card>
        );
    })
);

export default SummaryList;
