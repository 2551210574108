import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { DEFAULT_STATE, STATE__API__FAIL, STATE__API__STARTED, STATE__API__SUCCESS } from '../../core/utils/store_utils';
import { invalidateToken } from '../authorization/get_token';
import webClient from '../../core/web_client';
import { extractErrorData, extractResponseData } from '../../core/utils/api_utils';
import { WAREHOUSES_PATH } from '../../core/constants';

export const getAllWarehousesAction = createAsyncThunk(
    'inventory/getAllWarehousesAction',
    async (_, { fulfillWithValue, rejectWithValue }) => {
        try {
            const response = await webClient.get(WAREHOUSES_PATH);
            return fulfillWithValue(extractResponseData(response));
        } catch (error) {
            return rejectWithValue(extractErrorData(error));
        }
    }
);

const getAllWarehousesSlice = createSlice({
    name: 'getAllWarehousesSlice',
    initialState: DEFAULT_STATE,
    reducers: {},
    extraReducers: builder => {
        builder.addCase(getAllWarehousesAction.pending, () => STATE__API__STARTED);
        builder.addCase(getAllWarehousesAction.fulfilled, (_, { payload }) => STATE__API__SUCCESS(payload));
        builder.addCase(getAllWarehousesAction.rejected, (_, { payload }) => STATE__API__FAIL(payload));
        builder.addCase(invalidateToken, () => DEFAULT_STATE);
    }
});

export const getAllWarehousesState = (state) => state.inventory.getAllWarehouses;
export default getAllWarehousesSlice.reducer;