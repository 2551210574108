import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { Grid, Tab, Tabs } from '@mui/material';
import { tabsClasses } from '@mui/material/Tabs';

import PodsList from './pods_list';
import IndoorPartnersList from './indoor_partners_list';

const PodsPage = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [tab, setTab] = useState(location?.state?.tab || 0);

    const onTabChange = (tabValue) => {
        navigate(location.pathname, {});
        setTab(tabValue);
    }

    const renderView = () => {
        if (tab === 0) {
            return <PodsList tab={ tab }/>;
        }

        return <IndoorPartnersList tab={ tab }/>;
    };

    return (
        <Grid mb={ 6 }>
            <Grid container mb={ 4 }>
                <Tabs
                    value={ tab }
                    onChange={ (_, value) => onTabChange(value) }
                    variant="scrollable"
                    scrollButtons
                    sx={ {
                        [`& .${ tabsClasses.scrollButtons }`]: {
                            '&.Mui-disabled': { opacity: 0.3 },
                        },
                    } }
                >
                    <Tab label="Punkty sprzedaży"/>
                    <Tab label="Partnerzy handlowi"/>
                </Tabs>
            </Grid>
            {
                renderView()
            }
        </Grid>
    );
};

export default PodsPage;
