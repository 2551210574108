import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import webClient from '../../core/web_client';
import { SUPPLIERS_PATH } from '../../core/constants';
import { extractErrorData, extractResponseData } from '../../core/utils/api_utils';
import { DEFAULT_STATE, STATE__API__FAIL, STATE__API__STARTED, STATE__API__SUCCESS } from '../../core/utils/store_utils';
import { invalidateToken } from '../authorization/get_token';

export const updateSupplierAction = createAsyncThunk(
    'store/updateSupplierAction',
    async (data, { fulfillWithValue, rejectWithValue }) => {
        try {
            const response = await webClient.put(`${ SUPPLIERS_PATH }/${ data.id }`, data.form);
            return fulfillWithValue(extractResponseData(response));
        } catch (error) {
            return rejectWithValue(extractErrorData(error));
        }
    }
);

const updateSupplierSlice = createSlice({
    name: 'updateSupplierSlice',
    initialState: DEFAULT_STATE,
    reducers: {},
    extraReducers: builder => {
        builder.addCase(updateSupplierAction.pending, () => STATE__API__STARTED);
        builder.addCase(updateSupplierAction.fulfilled, (_, { payload }) => STATE__API__SUCCESS(payload));
        builder.addCase(updateSupplierAction.rejected, (_, { payload }) => STATE__API__FAIL(payload));
        builder.addCase(invalidateToken, () => DEFAULT_STATE);
    }
});

export const updateSupplierState = (state) => state.store.updateSupplier;
export default updateSupplierSlice.reducer;
