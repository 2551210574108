import React, { useState } from 'react';

import { Grid, TextField } from '@mui/material';
import _ from 'lodash';
import { SelectFieldMultiple } from "../../../common/select_field";
import { useSelector } from "react-redux";
import { getWarehouseDocumentTypeOptions, getWarehouseName } from "../../../common/utils/warehouse_utils";
import { getAllWarehousesState } from "../../../../features/inventory/get_all_warehouses";

const WarehouseDocumentFilters = ({ onSearchInputChange, onSelectWarehousesChange, onSelectTypesChange }) => {
    const getAllWarehouses = useSelector(getAllWarehousesState);

    const [warehouseIds, setWarehouseIds] = useState([]);
    const [types, setTypes] = useState([]);
    const onSearchInputChangeDebounced = _.debounce((value) => onSearchInputChange(value), 600);

    return (
        <Grid container columnGap={ 6 } rowGap={ 2 }>
            <Grid container columnGap={ 2 }>
                <Grid item xs={ 4 }>
                    <SelectFieldMultiple
                        label="Rodzaj dokumentu"
                        options={ getWarehouseDocumentTypeOptions }
                        required
                        value={ types }
                        onClose={ () => onSelectTypesChange(types) }
                        onChange={ event => setTypes(event.target.value) }
                    />
                </Grid>
                <Grid item xs={ 5 }>
                    <TextField
                        label="Szukaj po numerze"
                        fullWidth
                        onChange={ event => onSearchInputChangeDebounced(event.target.value) }
                    />
                </Grid>
            </Grid>
            <Grid container>
                <Grid item xs={ 9 }>
                    <SelectFieldMultiple
                        label="Pokaż dla magazynów"
                        options={ getAllWarehouses?.data?.map(warehouse => ({ value: warehouse?.internalId, label: getWarehouseName(warehouse) })) }
                        required
                        value={ warehouseIds }
                        onClose={ () => onSelectWarehousesChange(warehouseIds) }
                        onChange={ event => setWarehouseIds(event.target.value) }
                    />
                </Grid>
            </Grid>
        </Grid>
    );
};
export default WarehouseDocumentFilters;