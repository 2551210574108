import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import DialogContent from '@mui/material/DialogContent';
import { Grid, TextField } from '@mui/material';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import * as Yup from 'yup';
import { Form, Formik } from 'formik';
import _ from 'lodash';

import { SelectFieldMultipleFormik } from '../../../../../common/select_field';
import useNotification from '../../../../../../core/hooks/use_notification';
import { getAllPodsState } from '../../../../../../features/pod/get_all_pods';
import { Button, SubmitButton } from '../../../../../common/button';
import TextFieldFormik from '../../../../../common/text_field';
import { updatePromotionNotificationData } from '../../../../../../core/utils/notification_utils';
import { ApiRequestStatusEnum } from '../../../../../../core/enums/common/api';
import { getPriceState } from '../../../../../../features/price/get_price';
import { parseDateString } from '../../../../../../core/utils/date_utils';
import { DateTimePickerFormik } from '../../../../../common/date_picker';
import { MISC_DATE_ISO_FORMAT } from '../../../../../../core/constants';
import { createProductPromotionsAction, createProductPromotionsState } from '../../../../../../features/price/create_product_promotions';
import RadioGroupFormik from '../../../../../common/radio_group';
import { yesNoOptions } from '../../../../../common/form_utils';

const PromotionsFormDialog = ({ productId, purchasePrice, refreshList }) => {
    const { showNotification } = useNotification();
    const dispatch = useDispatch();

    const getAllPods = useSelector(getAllPodsState);
    const getPrice = useSelector(getPriceState);
    const createProductPromotions = useSelector(createProductPromotionsState);

    const [open, setOpen] = useState(false);
    const [startPrice, setStartPrice] = useState(0.00);
    const [discountPrice, setDiscountPrice] = useState(0.00);

    const calculateStartingPrice = (podIds, discount) => {
        const minPrice = Math.min(...podIds?.map(podId => [getPrice?.data?.find(item => item.podId === podId)].map(item => item.basicGrossPrice?.toFixed(2))[0]) || 0.00);
        setStartPrice(minPrice);
        discountPrice !== 0.00 && setDiscountPrice((parseFloat(minPrice).toFixed(2) - parseFloat((discount / 100 * minPrice).toFixed(2))).toFixed(2));
    };

    const initialValues = {
        podIds: [],
        timeFrom: '',
        timeTo: '',
        discount: '',
        activeExpirationPromotion: false
    };

    const schema = Yup.object().shape({
        podIds: Yup.array().min(1),
        timeFrom: Yup.date()
            .transform(parseDateString)
            .nullable()
            .required(),
        timeTo: Yup.date()
            .transform(parseDateString)
            .nullable()
            .required(),
        discount: Yup.number().required(),
        activeExpirationPromotion: Yup.boolean().required()
    });

    const onClose = () => setOpen(false);

    const onSubmit = (values) => {
        const timeFrom = values?.timeFrom?.format(MISC_DATE_ISO_FORMAT);
        const timeTo = values?.timeTo?.format(MISC_DATE_ISO_FORMAT);
        const excludeExpirationPromotion = !values?.activeExpirationPromotion;

        const form = {
            ..._.omit(values, ['timeFrom', 'timeTo', 'activeExpirationPromotion']),
            timeFrom,
            timeTo,
            excludeExpirationPromotion
        };

        dispatch(createProductPromotionsAction({ productId, form }))
            .then(response => {
                showNotification(updatePromotionNotificationData(response));
                if (response?.meta?.requestStatus === ApiRequestStatusEnum.FULFILLED) {
                    refreshList && refreshList();
                    onClose();
                }
            });
    };

    return (
        <>
            <Button onClick={ () => setOpen(true) }>
                Dodaj promocje
            </Button>
            <Dialog open={ open } onClose={ onClose } fullWidth={ true } maxWidth={ 'lg' }>
                <DialogTitle>Promocja na produkt</DialogTitle>
                <DialogContent sx={ { paddingTop: '6px !important' } }>
                    <Formik
                        initialValues={ initialValues }
                        validationSchema={ schema }
                        validateOnChange={ false }
                        validateOnBlur={ false }
                        onSubmit={ onSubmit }>
                        { (formik) =>
                            <Grid component={ Form } container rowGap={ 4 }>
                                <Grid container item md={ 12 } direction="column" rowGap={ 4 }>
                                    <Grid container item columnGap={ 4 }>
                                        <Grid item xs={ 6 }>
                                            <SelectFieldMultipleFormik
                                                name="podIds"
                                                label="Wybierz pody - wiele"
                                                options={ getAllPods?.data?.map(pod => (
                                                    {
                                                        value: pod?.id,
                                                        label: pod?.indoorPartner?.name + ' (' + pod?.description + ') - ' + (pod?.device?.serviceId || 'brak urządzenia') + ' cena bazowa: '
                                                            + ([getPrice?.data?.find(item => item.podId === pod?.id)].map(item => item.basicGrossPrice?.toFixed(2))[0] || '-')
                                                    }
                                                )) }
                                                required
                                                onChange={ (value) => calculateStartingPrice(value, formik.values.discount) }
                                            />
                                        </Grid>
                                        <Grid item xs={ 5 } container alignItems="center">
                                            {
                                                purchasePrice &&
                                                <>
                                                    Cena zakupu (brutto): { parseFloat(purchasePrice).toFixed(2) }
                                                </>
                                            }
                                        </Grid>
                                    </Grid>
                                    <Grid container item columnGap={ 4 }>
                                        <Grid item xs={ 2 }>
                                            <TextField
                                                label="Cena bazowa"
                                                type="number"
                                                disabled
                                                value={ startPrice?.toFixed(2) }
                                            />
                                        </Grid>
                                        <Grid item xs={ 4 }>
                                            <TextFieldFormik
                                                name="discount"
                                                label="Upust [%]"
                                                type="number"
                                                required
                                                inputProps={ { min: '0.01', max: '100.00', step: '0.01' } }
                                                onChange={ (value) => setDiscountPrice((parseFloat(startPrice).toFixed(2) - parseFloat((value / 100 * startPrice).toFixed(2))).toFixed(2)) }
                                            />
                                        </Grid>
                                        <Grid item xs={ 2 }>
                                            <TextField
                                                label="Cena promocyjna"
                                                type="number"
                                                disabled
                                                value={ discountPrice }
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container item gap={ 4 }>
                                        <Grid item>
                                            <DateTimePickerFormik
                                                name="timeFrom"
                                                label="Data od"
                                                required
                                                InputLabelProps={ { shrink: true } }
                                            />
                                        </Grid>
                                        <Grid item>
                                            <DateTimePickerFormik
                                                name="timeTo"
                                                label="Data do"
                                                required
                                                InputLabelProps={ { shrink: true } }
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container item columnGap={ 4 }>
                                        <Grid container item>
                                            <RadioGroupFormik
                                                name="activeExpirationPromotion"
                                                label="Aktywować promocję na krótką datę przydatności"
                                                options={ yesNoOptions }
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container item mt={ 2 } justifyContent="flex-end">
                                        <DialogActions sx={ { gap: 2 } }>
                                            <Button onClick={ onClose }>Zamknij</Button>
                                            <SubmitButton isLoading={ createProductPromotions?.loading }>Zapisz</SubmitButton>
                                        </DialogActions>
                                    </Grid>
                                </Grid>
                            </Grid>
                        }
                    </Formik>
                </DialogContent>
            </Dialog>
        </>
    );
};

export default PromotionsFormDialog;
