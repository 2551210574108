import React, { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { tabsClasses } from '@mui/material/Tabs';
import { Box, Grid, LinearProgress, Tab, Tabs } from '@mui/material';

import useNotification from '../../../../core/hooks/use_notification';
import { getLanguagesState } from '../../../../features/product/get_languages';
import { getConstantsAction, getConstantsState, invalidateConstants } from '../../../../features/product/get_constants';
import { getCouponAction, getCouponState, invalidateCoupon } from '../../../../features/coupon/get_coupon';
import { getAllProductProductsNotificationData, getConstantsNotificationData, getCouponNotificationData, getCouponTranslationNotificationData, getCustomersNotificationData, getPodsNotificationData, getProductBatchesNotificationData } from '../../../../core/utils/notification_utils';
import { Button } from '../../../common/button';
import { ROUTE_COUPONS } from '../../../../core/constants';
import { FormRouteEnum } from '../../../../core/enums/common/route';
import CouponForm from './forms/coupon_form';
import { getAllPodsAction, getAllPodsState } from '../../../../features/pod/get_all_pods';
import { getAllProductsAction, getAllProductsState } from '../../../../features/product/get_all_products';
import { getCouponTranslationAction, getCouponTranslationState } from '../../../../features/coupon/get_coupon_translation';
import CouponTranslationForm from './forms/coupon_translation_form';
import { getProductBatchesAction, invalidateProductBatches } from '../../../../features/inventory/get_product_batches';
import { invalidateCustomersByPhoneNumber } from '../../../../features/customer/get_customers_by_phone_number';
import { getCustomersAction, invalidateCustomers } from '../../../../features/customer/get_customers';
import { ApiRequestStatusEnum } from '../../../../core/enums/common/api';
import { CouponTypeEnum } from '../../../../core/enums/price/coupon';
import { isEmptyObject } from '../../../../core/utils/misc_utils';

const CouponFormsPage = () => {
    const { showErrorMessage } = useNotification();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    const { couponId } = useParams();

    const getLanguages = useSelector(getLanguagesState);
    const getCoupon = useSelector(getCouponState);
    const getConstants = useSelector(getConstantsState);
    const getAllPods = useSelector(getAllPodsState);
    const getAllProducts = useSelector(getAllProductsState);
    const getCouponTranslation = useSelector(getCouponTranslationState);

    const [languageCode, setLanguageCode] = useState(null);
    const [tab, setTab] = useState(0);
    const [batchesInitialized, setBatchesInitialized] = useState(location?.state?.action === FormRouteEnum.NEW);
    const [customersInitialized, setCustomersInitialized] = useState(location?.state?.action === FormRouteEnum.NEW);
    const [translationToDuplicate] = useState([]);

    const createNewForm = location?.state?.action === FormRouteEnum.NEW;
    const disableForm = location?.state?.action === FormRouteEnum.VIEW;

    const couponIdToDuplicate = location?.state?.couponIdToDuplicate;
    const duplicateMode = !isEmptyObject(couponIdToDuplicate);

    const fetchData = useCallback(() => {
        const id = couponId || couponIdToDuplicate;
        id && dispatch(getCouponAction(id))
            .then(response => {
                showErrorMessage(getCouponNotificationData(response));

                if (response?.meta?.requestStatus === ApiRequestStatusEnum.FULFILLED) {
                    if (response?.payload?.productId && response?.payload?.couponType === CouponTypeEnum.PRODUCT_BATCH) {
                        dispatch(getProductBatchesAction(response?.payload?.productId))
                            .then(response => {
                                showErrorMessage(getProductBatchesNotificationData(response));
                                setBatchesInitialized(true);
                            });
                    } else {
                        setBatchesInitialized(true);
                    }

                    if (!response?.payload?.allCustomers && response?.payload?.customerIds) {
                        dispatch(getCustomersAction(response?.payload?.customerIds))
                            .then(response => {
                                showErrorMessage(getCustomersNotificationData(response));
                                setCustomersInitialized(true);
                            });
                    } else {
                        setCustomersInitialized(true);
                    }
                } else {
                    setBatchesInitialized(true);
                    setCustomersInitialized(true);
                }
            });

        couponIdToDuplicate && createNewForm && getLanguages?.data?.forEach(language => {
            dispatch(getCouponTranslationAction({ couponId: couponIdToDuplicate, languageCode: language?.languageCode }))
                .then(response => {
                    response?.meta?.requestStatus === ApiRequestStatusEnum.FULFILLED && translationToDuplicate.push(response?.payload);
                });
        });

        dispatch(getConstantsAction())
            .then(response => showErrorMessage(getConstantsNotificationData(response)));
        dispatch(getAllPodsAction())
            .then(response => showErrorMessage(getPodsNotificationData(response)));
        dispatch(getAllProductsAction())
            .then(response => showErrorMessage(getAllProductProductsNotificationData(response)));
    }, [couponId, createNewForm, couponIdToDuplicate, translationToDuplicate, getLanguages, dispatch, showErrorMessage]);

    useEffect(() => {
        (!createNewForm && languageCode !== null) && dispatch(getCouponTranslationAction({ couponId: couponId, languageCode: languageCode }))
            .then(response => {
                showErrorMessage(getCouponTranslationNotificationData(response));
            });
    }, [couponId, createNewForm, dispatch, languageCode, showErrorMessage]);

    useEffect(() => {
        fetchData();

        return () => {
            dispatch(invalidateConstants());
            dispatch(invalidateCoupon());
            dispatch(invalidateProductBatches());
            dispatch(invalidateCustomersByPhoneNumber());
            dispatch(invalidateCustomers());
            setBatchesInitialized(false);
            setCustomersInitialized(false);
        };
    }, [couponId, dispatch, fetchData]);

    const refreshData = () => {
        setBatchesInitialized(false);
        setCustomersInitialized(false);
        fetchData();
    };

    const renderView = () => {
        if (
            getCoupon?.loading
            || getConstants?.loading
            || getAllPods?.loading
            || getAllProducts?.loading
            || getCouponTranslation?.loading
            || !batchesInitialized
            || !customersInitialized
        ) {
            return (
                <Box mt={ 6 }>
                    <LinearProgress/>
                </Box>
            );
        }

        if (getConstants?.data && getAllPods?.data && getAllProducts?.data &&
            (!createNewForm ? (getCoupon?.data && batchesInitialized && customersInitialized) : true)) {
            if (tab === 0) {
                return (
                    <CouponForm
                        createNewForm={ createNewForm }
                        couponId={ couponId }
                        refreshData={ refreshData }
                        duplicateMode={ duplicateMode }
                        translationToDuplicate={ translationToDuplicate }
                        disableForm={ disableForm }/>
                );
            } else {
                return (
                    <CouponTranslationForm
                        disableForm={ disableForm }
                        couponId={ couponId }
                        languageCode={ languageCode }/>
                );
            }
        }

        return <></>;
    };

    return (
        <Grid mb={ 6 }>
            <Grid container mb={ 4 } justifyContent="space-between" rowGap={ 2 }>
                <Grid container item>
                    <Button onClick={ () => navigate(ROUTE_COUPONS) }>
                        Wróć
                    </Button>
                </Grid>
                <Grid container item>
                    <Tabs
                        value={ tab }
                        onChange={ (_, value) => {
                            setTab(value);
                            setLanguageCode(getLanguages?.data[value - 1]?.languageCode || null);
                        } }
                        variant="scrollable"
                        scrollButtons
                        sx={ {
                            [`& .${ tabsClasses.scrollButtons }`]: {
                                '&.Mui-disabled': { opacity: 0.3 },
                            },
                        } }
                    >
                        <Tab label="Informacje ogólne" sx={ { justifyContent: 'flexEnd' } }/>
                        {
                            getLanguages?.data?.map(language => {
                                return <Tab key={ language?.languageCode } label={ language?.languageName }
                                            disabled={ !(getCoupon?.data?.workingName && (!duplicateMode || couponIdToDuplicate !== getCoupon?.data?.couponId)) }/>;
                            })
                        }
                    </Tabs>
                </Grid>
            </Grid>
            {
                renderView()
            }
        </Grid>
    );
};

export default CouponFormsPage;
