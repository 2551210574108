import React, { useState } from 'react';

import { tabsClasses } from '@mui/material/Tabs';
import { Grid, Tab, Tabs } from '@mui/material';

import EmployersList from './employers_list';
import EmployeesToConfirmList from './employees_to_confirm_list';

const EmployersPage = () => {
    const [tab, setTab] = useState(0);

    const renderView = () => {
        if (tab === 0) {
            return <EmployersList/>;
        }

        return <EmployeesToConfirmList/>;
    };

    return (
        <Grid mb={ 6 }>
            <Grid container mb={ 4 }>
                <Tabs
                    value={ tab }
                    onChange={ (_, value) => setTab(value) }
                    variant="scrollable"
                    scrollButtons
                    sx={ {
                        [`& .${ tabsClasses.scrollButtons }`]: {
                            '&.Mui-disabled': { opacity: 0.3 },
                        },
                    } }
                >
                    <Tab label="Pracodawcy"/>
                    <Tab label="Pracownicy do weryfikacji"/>
                </Tabs>
            </Grid>
            {
                renderView()
            }
        </Grid>
    );
};

export default EmployersPage;
