import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Box, Grid, LinearProgress, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import deepmerge from 'deepmerge';

import { getEmployersAction, getEmployersState } from '../../../features/benefit/get_employers';
import { GET_ROUTE_EMPLOYER } from '../../../core/constants';
import { getEmployerStatus } from '../../common/utils/employer_utils';
import PaginationControls from '../../common/pagination_controls';
import { getEmployersNotificationData } from '../../../core/utils/notification_utils';
import useNotification from '../../../core/hooks/use_notification';
import { getDefaultPaginationQueryValues } from '../../../core/utils/api_utils';

const initialQueryParams = {
    ...getDefaultPaginationQueryValues
};

const EmployersList = () => {
    const dispatch = useDispatch();
    const { showErrorMessage } = useNotification();
    const navigate = useNavigate();

    const getEmployers = useSelector(getEmployersState);

    const [queryParams, setQueryParams] = useState(initialQueryParams);

    useEffect(() => {
        dispatch(getEmployersAction(queryParams))
            .then(response => showErrorMessage(getEmployersNotificationData(response)));
    }, [dispatch, queryParams, showErrorMessage]);

    return (
        <Grid>
            {
                getEmployers?.loading &&
                <LinearProgress/>
            }
            {
                getEmployers?.data?.content &&
                <Grid container>
                    <TableContainer>
                        <Table stickyHeader>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Nazwa</TableCell>
                                    <TableCell align="center">Adres</TableCell>
                                    <TableCell align="center">NIP</TableCell>
                                    <TableCell align="center">Email</TableCell>
                                    <TableCell align="center">Status</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    getEmployers?.data?.content?.map(employer => (
                                        <TableRow
                                            key={ employer?.id }
                                            hover
                                            sx={ { cursor: 'pointer' } }
                                            onClick={ () => navigate(GET_ROUTE_EMPLOYER(employer?.id)) }
                                        >
                                            <TableCell>{ employer?.name }</TableCell>
                                            <TableCell align="center">{ employer?.address }</TableCell>
                                            <TableCell align="center">{ employer?.nip }</TableCell>
                                            <TableCell align="center">{ employer?.mailingAddress }</TableCell>
                                            <TableCell align="center">{ getEmployerStatus(employer?.status) }</TableCell>
                                        </TableRow>
                                    ))
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <Box mt={ 2 }>
                        <PaginationControls
                            currentPage={ getEmployers?.data?.pageable?.pageNumber }
                            totalPages={ getEmployers?.data?.totalPages }
                            setPage={ page => setQueryParams(prevState => deepmerge(prevState, { page })) }
                        />
                    </Box>
                </Grid>
            }
        </Grid>
    );
};

export default EmployersList;
