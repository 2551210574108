import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import webClient from '../../core/web_client';
import { NOTIFICATIONS_PATH } from '../../core/constants';
import { extractErrorData, extractResponseData } from '../../core/utils/api_utils';
import { DEFAULT_STATE, STATE__API__FAIL, STATE__API__STARTED, STATE__API__SUCCESS } from '../../core/utils/store_utils';
import { invalidateToken } from '../authorization/get_token';

export const deleteNotificationAction = createAsyncThunk(
    'communication/deleteNotificationAction',
    async (id, { fulfillWithValue, rejectWithValue }) => {
        try {
            const response = await webClient.delete(`${ NOTIFICATIONS_PATH }/${ id }`);
            return fulfillWithValue(extractResponseData(response));
        } catch (error) {
            return rejectWithValue(extractErrorData(error));
        }
    }
);

const deleteNotificationSlice = createSlice({
    name: 'deleteNotificationSlice',
    initialState: DEFAULT_STATE,
    reducers: {},
    extraReducers: builder => {
        builder.addCase(deleteNotificationAction.pending, () => STATE__API__STARTED);
        builder.addCase(deleteNotificationAction.fulfilled, (_, { payload }) => STATE__API__SUCCESS(payload));
        builder.addCase(deleteNotificationAction.rejected, (_, { payload }) => STATE__API__FAIL(payload));
        builder.addCase(invalidateToken, () => DEFAULT_STATE);
    }
});

export const deleteNotificationState = (state) => state.communication.deleteNotification;
export default deleteNotificationSlice.reducer;