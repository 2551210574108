import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import * as Yup from 'yup';
import { Form, Formik } from 'formik';
import { Grid } from '@mui/material';
import _ from 'lodash';

import useNotification from '../../../../../core/hooks/use_notification';
import { getAllPodsState } from '../../../../../features/pod/get_all_pods';
import { getAutomaticCouponSettingAction, getAutomaticCouponSettingState } from '../../../../../features/coupon/get_automatic_coupon_setting';
import TextFieldFormik from '../../../../common/text_field';
import RadioGroupFormik from '../../../../common/radio_group';
import { SelectFieldMultipleFormik } from '../../../../common/select_field';
import { SubmitButton } from '../../../../common/button';
import { getAutomaticCouponSettingNotificationData, updateAutomaticCouponSettingNotificationData } from '../../../../../core/utils/notification_utils';
import { ApiRequestStatusEnum } from '../../../../../core/enums/common/api';
import { updateAutomaticCouponSettingAction, updateAutomaticCouponSettingState } from '../../../../../features/coupon/update_automatic_coupon_setting';
import { getAutomaticCouponType } from '../../../../../core/enums/price/automatic_coupon_setting';
import { yesNoOptions } from '../../../../common/form_utils';

const AutomaticCouponSettingForm = ({ automaticCouponType }) => {
    const dispatch = useDispatch();
    const { showNotification, showErrorMessage } = useNotification();

    const updateAutomaticCouponSetting = useSelector(updateAutomaticCouponSettingState);
    const getAutomaticCouponSetting = useSelector(getAutomaticCouponSettingState);
    const getAllPods = useSelector(getAllPodsState);

    const initialValues = {
        automaticCouponType: getAutomaticCouponType(getAutomaticCouponSetting?.data?.automaticCouponType)?.message,
        discount: getAutomaticCouponSetting?.data?.discount || '',
        numberOfValidityDays: getAutomaticCouponSetting?.data?.numberOfValidityDays || '',
        allPods: getAutomaticCouponSetting?.data?.allPods || false,
        podIds: getAutomaticCouponSetting?.data?.podIds || []
    };

    const schema = Yup.object().shape({
        discount: Yup.number()
            .required()
            .min(0.01)
            .max(100.00),
        numberOfValidityDays: Yup.number()
            .required()
            .min(1),
        allPods: Yup.bool().required(),
        podIds: Yup.array().when('allPods', {
            is: false,
            then: Yup.array().min(1),
            otherwise: Yup.array().notRequired()
        })
    });

    const onSubmit = (values) => {
        const form = _.omit(values, ['automaticCouponType']);

        dispatch(updateAutomaticCouponSettingAction({ form, automaticCouponType }))
            .then(response => {
                showNotification(updateAutomaticCouponSettingNotificationData(response));

                response?.meta?.requestStatus === ApiRequestStatusEnum.FULFILLED && dispatch(getAutomaticCouponSettingAction(automaticCouponType))
                    .then(response => showErrorMessage(getAutomaticCouponSettingNotificationData(response)));
            });
    };

    return (
        <Formik
            initialValues={ initialValues }
            validationSchema={ schema }
            validateOnChange={ false }
            validateOnBlur={ false }
            onSubmit={ onSubmit }>
            { (formik) =>
                <Grid component={ Form } container rowGap={ 4 }>
                    <Grid container item>
                        <TextFieldFormik name="automaticCouponType" label="Typ kuponu" disabled/>
                    </Grid>
                    <Grid container item>
                        <Grid item xs={ 6 }>
                            <TextFieldFormik name="discount" label="Obniżka" type="number" required inputProps={ { min: '0.01', max: '100.00', step: '0.01' } }/>
                        </Grid>
                    </Grid>
                    <Grid container item>
                        <Grid item xs={ 6 }>
                            <TextFieldFormik name="numberOfValidityDays" label="Ważność kuponu (w dniach)" type="number" required inputProps={ { min: '1', step: '1' } }/>
                        </Grid>
                    </Grid>
                    <Grid container item>
                        <RadioGroupFormik
                            name="allPods"
                            label="Wszystkie pody"
                            options={ yesNoOptions }
                            onChange={ (value) => value === true && formik.setFieldValue('podIds', []) }
                        />
                    </Grid>
                    {
                        formik.values.allPods === false &&
                        <Grid container item>
                            <SelectFieldMultipleFormik
                                name="podIds"
                                label="Wybierz pody - wiele"
                                options={ getAllPods?.data?.map(pod => ({ value: pod?.id, label: pod?.indoorPartner?.name + " (" + pod?.description + ') - ' + (pod?.device?.serviceId || 'brak urządzenia') })) }
                                required={ formik.values.allPods === false }
                            />
                        </Grid>
                    }
                    <Grid container item mt={ 2 } justifyContent="flex-end">
                        <SubmitButton isLoading={ updateAutomaticCouponSetting?.loading }>Zapisz</SubmitButton>
                    </Grid>
                </Grid>
            }
        </Formik>
    );
};

export default AutomaticCouponSettingForm;
