import React from 'react';

import { FormControl, FormControlLabel, FormHelperText, FormLabel, Radio, RadioGroup, useTheme } from '@mui/material';
import { ErrorMessage, Field, getIn } from 'formik';

export const CustomRadioGroup = ({ value, options, label, disabled, onChange, booleanOptions = true }) => {
    const randomId = Date.now().toString();

    return (
        <FormControl fullWidth disabled={ disabled }>
            <FormLabel id={ randomId }>{ label }</FormLabel>
            <RadioGroup
                aria-labelledby={ randomId }
                value={ value }
                sx={ { flexDirection: 'row' } }
                onChange={ (_, value) => onChange && onChange(booleanOptions ? value === 'true' : value) }>
                {
                    options?.map((option) => (
                        <FormControlLabel
                            key={ option?.label }
                            value={ option?.value }
                            label={ option?.label }
                            control={ <Radio/> }
                        />
                    ))
                }
            </RadioGroup>
        </FormControl>
    );
};

const RadioGroupFormik = ({ name, options, label, disabled, onChange, booleanOptions = true }) => {
    const randomId = Date.now().toString();
    const theme = useTheme();

    return (
        <Field name={ name } type="radios">
            { ({ field: { value }, form: { errors, setFieldValue } }) => (
                <FormControl fullWidth disabled={ disabled } required>
                    <FormLabel id={ randomId }>{ label }</FormLabel>
                    <RadioGroup
                        aria-labelledby={ randomId }
                        value={ value }
                        sx={ { flexDirection: 'row' } }
                        name={ name }
                        onChange={ (_, value) => {
                            setFieldValue(name, booleanOptions ? value === 'true' : value);
                            onChange && onChange(booleanOptions ? value === 'true' : value);
                        } }>
                        {
                            options?.map((option) => (
                                <FormControlLabel
                                    key={ option?.label }
                                    value={ option?.value }
                                    label={ option?.label }
                                    control={ <Radio sx={ !!getIn(errors, name) ? { color: `${ theme.palette.error.main }` } : {} }/> }
                                />
                            ))
                        }
                    </RadioGroup>
                    <FormHelperText error>
                        <ErrorMessage name={ name }/>
                    </FormHelperText>
                </FormControl>
            ) }
        </Field>
    );
};

export default RadioGroupFormik;
