import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { DEFAULT_STATE, STATE__API__FAIL, STATE__API__STARTED, STATE__API__SUCCESS } from '../../core/utils/store_utils';
import { invalidateToken } from '../authorization/get_token';
import webClient from '../../core/web_client';
import { extractErrorData, extractResponseData } from '../../core/utils/api_utils';
import { PRODUCTS_AVAILABILITY_PATH } from '../../core/constants';

export const updateProductQuantityLimitAction = createAsyncThunk(
    'reservation/updateProductQuantityLimitAction',
    async (data, { fulfillWithValue, rejectWithValue }) => {
        try {
            const response = await webClient.patch(`${ PRODUCTS_AVAILABILITY_PATH }/quantity-limit`, data);
            return fulfillWithValue(extractResponseData(response));
        } catch (error) {
            return rejectWithValue(extractErrorData(error));
        }
    }
);

const updateProductQuantityLimitSlice = createSlice({
    name: 'updateProductQuantityLimitSlice',
    initialState: DEFAULT_STATE,
    reducers: {},
    extraReducers: builder => {
        builder.addCase(updateProductQuantityLimitAction.pending, () => STATE__API__STARTED);
        builder.addCase(updateProductQuantityLimitAction.fulfilled, (_, { payload }) => STATE__API__SUCCESS(payload));
        builder.addCase(updateProductQuantityLimitAction.rejected, (_, { payload }) => STATE__API__FAIL(payload));
        builder.addCase(invalidateToken, () => DEFAULT_STATE);
    }
});

export const updateProductQuantityLimitState = (state) => state.reservation.updateProductQuantityLimit;
export default updateProductQuantityLimitSlice.reducer;
