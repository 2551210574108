import React, { useState } from 'react';

import { Grid } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import _ from 'lodash';

import CopyHistoricalDialog from './copy_historical_dialog';
import { yesNoOptions } from '../../../../../common/form_utils';
import { CustomRadioGroup } from '../../../../../common/radio_group';

const AdditionalOptions = ({ onChangeSetDate, form, onCopyHistoricalDocument }) => {
    const [allDates, setAllDates] = useState(false);

    const onChangeSetDateDebounced = _.debounce((value) => onChangeSetDate(value, allDates), 600);

    return (
        <Grid container item justifyContent="space-between" gap={ 2 }>
            <Grid item sx={ { border: '1px solid darkgray' } } p={ 2 }>
                <Grid container gap={ 2 }>
                    <Grid item>
                        <CustomRadioGroup
                            label="Uzupełnij dla wszystkich"
                            options={ yesNoOptions }
                            onChange={ (value) => setAllDates(value) }
                            value={ allDates }
                        />
                    </Grid>
                    <Grid item>
                        <DatePicker
                            displayWeekNumber
                            label="Ustaw datę przydatności"
                            onChange={ newDate => newDate?.isValid() && onChangeSetDateDebounced(newDate) }
                            slotProps={ {
                                textField: {
                                    InputLabelProps: {
                                        shrink: true
                                    }
                                }
                            } }
                            sx={ { width: 220 } }
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Grid sx={ { display: 'flex', alignItems: 'flex-end' } }>
                {
                    onCopyHistoricalDocument &&
                    <CopyHistoricalDialog
                        form={ form }
                        onCopyHistoricalDocument={ onCopyHistoricalDocument }/>
                }
            </Grid>
        </Grid>
    );
};

export default AdditionalOptions;
