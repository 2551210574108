import React from 'react';

import { AppBar, Box, Container, Toolbar, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import Copyright from './component/copyright';

const SignedOutLayout = ({ children }) => {
    const { classes, cx } = useStyles();

    return (
        <div className={ cx(classes.root) }>
            <AppBar position="absolute" className={ cx(classes.appBar) }>
                <Toolbar className={ cx(classes.toolbar) }>
                    <Typography component="h1" variant="h6" color="inherit" noWrap className={ cx(classes.title) }>
                        Store Web Panel
                    </Typography>
                </Toolbar>
            </AppBar>

            <main className={ cx(classes.content) }>
                <div className={ cx(classes.appBarSpacer) }/>
                <Container maxWidth="lg" className={ cx(classes.container) }>
                    {
                        children
                    }
                    <Box pt={ 4 }>
                        <Copyright/>
                    </Box>
                </Container>
            </main>
        </div>
    );
};

const useStyles = makeStyles()((theme) => ({
    root: {
        display: 'flex'
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    toolbar: {
        paddingRight: 24, // keep right padding when drawer closed
    },
    title: {
        flexGrow: 1,
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
    },
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
        alignItems: 'center'
    },
}));

export default SignedOutLayout;
