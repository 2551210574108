import { deepFreeze } from '../../utils/misc_utils';

export const ReservationStoreStatusEnum = deepFreeze({
    UNKNOWN: {
        value: 'UNKNOWN',
        label: 'Błąd'
    },
    READY_FOR_REALIZATION: {
        value: 'READY_FOR_REALIZATION',
        label: 'Gotowe do realizacji'
    },
    ERROR_IN_RESERVATION: {
        value: 'ERROR_IN_RESERVATION',
        label: 'Błąd w zamówieniu'
    },
    IN_PROGRESS: {
        value: 'IN_PROGRESS',
        label: 'W trakcie realizacji'
    },
    COMPLETED: {
        value: 'COMPLETED',
        label: 'Zrealizowane'
    }
});