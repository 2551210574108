import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useNotification from '../../../../../core/hooks/use_notification';
import { getPromotionCampaignNotificationAction, getPromotionCampaignNotificationState } from '../../../../../features/price/get_promotion_campaign_notification';
import { deletePromotionCampaignNotificationNotificationData, getPromotionCampaignNotificationNotificationData, updatePromotionCampaignNotificationNotificationData } from '../../../../../core/utils/notification_utils';
import { getLanguagesState } from '../../../../../features/product/get_languages';
import * as Yup from 'yup';
import { Grid, LinearProgress, Typography } from '@mui/material';
import { FieldArray, Form, Formik } from 'formik';
import TextFieldFormik from '../../../../common/text_field';
import { SubmitButton } from '../../../../common/button';
import { updatePromotionCampaignNotificationAction, updatePromotionCampaignNotificationState } from '../../../../../features/price/update_promotion_campaign_notification';
import RadioGroupFormik from '../../../../common/radio_group';
import { yesNoOptions } from '../../../../common/form_utils';
import { getPromotionCampaignState } from '../../../../../features/price/get_promotion_campaign';
import { parseDateString } from '../../../../../core/utils/date_utils';
import moment, { isMoment } from 'moment/moment';
import { MISC_DATE_ISO_FORMAT, MISC_DATE_ISO_TIME_ONLY_FORMAT } from '../../../../../core/constants';
import TimePickerFormik from '../../../../common/time_picker';
import { DateTimePickerFormik } from '../../../../common/date_picker';
import _ from 'lodash';
import { deletePromotionCampaignNotificationAction, deletePromotionCampaignNotificationState } from '../../../../../features/price/delete_promotion_campaign_notification';
import { ApiRequestStatusEnum } from '../../../../../core/enums/common/api';

const PromotionCampaignNotificationForm = ({ promotionCampaignId, disableForm }) => {
    const { showErrorMessage, showNotification } = useNotification();
    const dispatch = useDispatch();

    const getLanguages = useSelector(getLanguagesState);
    const getPromotionCampaign = useSelector(getPromotionCampaignState);
    const getPromotionCampaignNotification = useSelector(getPromotionCampaignNotificationState);
    const updatePromotionCampaignNotification = useSelector(updatePromotionCampaignNotificationState);
    const deletePromotionCampaignNotification = useSelector(deletePromotionCampaignNotificationState);

    const formikRef = useRef();

    useEffect(() => {
        dispatch(getPromotionCampaignNotificationAction(promotionCampaignId))
            .then(response => showErrorMessage(getPromotionCampaignNotificationNotificationData(response)));
    }, [dispatch, showErrorMessage, promotionCampaignId]);


    const initialValues = {
        repeatable: getPromotionCampaign?.data?.repeatable || false,
        hasNotification: getPromotionCampaignNotification?.data?.hasNotification || false,
        notificationTime: getPromotionCampaignNotification?.data?.notificationTime ? moment(getPromotionCampaignNotification?.data?.notificationTime) : '',
        repeatableNotificationTime: getPromotionCampaignNotification?.data?.repeatableNotificationTime
            ? moment(getPromotionCampaignNotification?.data?.repeatableNotificationTime, MISC_DATE_ISO_TIME_ONLY_FORMAT)
            : '',
        translatedMessages: getLanguages?.data?.map(language => (
            {
                languageCode: language.languageCode,
                title: getPromotionCampaignNotification?.data?.translatedMessages?.find(message => message?.languageCode === language.languageCode)?.title || '',
                body: getPromotionCampaignNotification?.data?.translatedMessages?.find(message => message?.languageCode === language.languageCode)?.body || ''
            }
        )),
    };

    const schema = Yup.object().shape({
        hasNotification: Yup.bool().required(),
        notificationTime: Yup.date().when('repeatable', {
            is: false,
            then: Yup.date()
                .transform(parseDateString)
                .nullable()
                .required('Data wysyłki wymagana'),
            otherwise: Yup.date()
                .transform(parseDateString)
                .nullable()
                .notRequired()
        }),
        repeatableNotificationTime: Yup.date().when('repeatable', {
            is: true,
            then: Yup.date()
                .transform(parseDateString)
                .nullable()
                .required('Godzina wysyłki wymagana'),
            otherwise: Yup.date()
                .transform(parseDateString)
                .nullable()
                .notRequired()
        }),
        translatedMessages: Yup.array().of(
            Yup.object().shape(
                {
                    languageCode: Yup.string().when('hasNotification', {
                        is: true,
                        then: Yup.string().required(),
                        otherwise: Yup.string().notRequired()
                    }),
                    title: Yup.string().when('hasNotification', {
                        is: true,
                        then: Yup.string().required(),
                        otherwise: Yup.string().notRequired()
                    }),
                    body: Yup.string().when('hasNotification', {
                        is: true,
                        then: Yup.string().required(),
                        otherwise: Yup.string().notRequired()
                    })
                },
                ['title', 'body']
            )
        )
    });

    const onSubmit = (values) => {
        if (disableForm) return;

        if (values.hasNotification) {
            const notificationTime = isMoment(_.pick(values, ['notificationTime'])?.notificationTime) ? _.pick(values, ['notificationTime'])?.notificationTime?.format(MISC_DATE_ISO_FORMAT) : null;
            const repeatableNotificationTime = isMoment(_.pick(values, ['repeatableNotificationTime'])?.repeatableNotificationTime) ? _.pick(values, ['repeatableNotificationTime'])?.repeatableNotificationTime?.format(MISC_DATE_ISO_TIME_ONLY_FORMAT) : null;

            const form = {
                ..._.omit(values, ['hasNotification', 'repeatable', 'notificationTime', 'repeatableNotificationTime']),
                notificationTime,
                repeatableNotificationTime
            };

            dispatch(updatePromotionCampaignNotificationAction({ form, promotionCampaignId }))
                .then(response => {
                    showNotification(updatePromotionCampaignNotificationNotificationData(response));
                });
        } else {
            dispatch(deletePromotionCampaignNotificationAction(promotionCampaignId))
                .then(response => {
                    showNotification(deletePromotionCampaignNotificationNotificationData(response));
                    if (response?.meta?.requestStatus === ApiRequestStatusEnum.FULFILLED) {
                        formikRef.current?.setFieldValue('notificationTime', null);
                        formikRef.current?.setFieldValue('repeatableNotificationTime', null);
                        for (let i = 0; i < getLanguages?.data?.length; i++) {
                            formikRef.current?.setFieldValue(`translatedMessages.${ i }.title`, '');
                            formikRef.current?.setFieldValue(`translatedMessages.${ i }.body`, '');
                        }
                    }
                });
        }
    };

    return (
        <>
            {
                getPromotionCampaignNotification?.loading &&
                <Grid item width={ '100%' }>
                    <LinearProgress/>
                </Grid>
            }
            {
                getPromotionCampaignNotification?.data &&
                <Formik
                    initialValues={ initialValues }
                    validationSchema={ schema }
                    validateOnChange={ false }
                    validateOnBlur={ false }
                    onSubmit={ onSubmit }
                    innerRef={ formikRef }>
                    { (formik) =>
                        <Grid component={ Form } container rowGap={ 2 }>
                            <Grid container item>
                                <RadioGroupFormik
                                    name="hasNotification"
                                    label="Wysyłaj notyfikacje"
                                    options={ yesNoOptions }
                                    disabled={ disableForm }
                                />
                            </Grid>
                            {
                                formik.values.hasNotification &&
                                <>
                                    <Grid container item mb={ 2 } mt={ 2 }>
                                        {
                                            formik.values.repeatable &&
                                            <>
                                                <Grid container item>
                                                    <Grid container gap={ 2 }>
                                                        <Grid item xs={ 6 }>
                                                            <TimePickerFormik
                                                                name="repeatableNotificationTime"
                                                                required
                                                                label="Godzina wysyłki"
                                                                InputLabelProps={ { shrink: true } }
                                                                views={ ['hours', 'minutes'] }
                                                                disabled={ disableForm }/>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </>
                                        }
                                        {
                                            !formik.values.repeatable &&
                                            <>
                                                <Grid container item>
                                                    <Grid item xs={ 6 }>
                                                        <DateTimePickerFormik
                                                            name="notificationTime"
                                                            label="Data wysyłki"
                                                            required
                                                            disabled={ disableForm }
                                                            InputLabelProps={ { shrink: true } }
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </>
                                        }
                                    </Grid>
                                    <FieldArray name="translatedMessages">
                                        { () => (
                                            getLanguages?.data?.map((language, index) => {
                                                return <Grid container item key={ index }>
                                                    <Typography variant="h6" gutterBottom>
                                                        { language.languageName }
                                                    </Typography>

                                                    <Grid container rowGap={ 2 }>
                                                        <Grid container item>
                                                            <TextFieldFormik name={ `translatedMessages.${ index }.title` }
                                                                             label="Tytuł" required disabled={ disableForm }/>
                                                        </Grid>

                                                        <Grid container item>
                                                            <TextFieldFormik name={ `translatedMessages.${ index }.body` }
                                                                             label="Treść" multiline rows={ 6 } required disabled={ disableForm }/>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>;
                                            })
                                        ) }
                                    </FieldArray>
                                </>
                            }
                            {
                                !disableForm &&
                                <Grid container item mt={ 2 } justifyContent="flex-end">
                                    <SubmitButton
                                        isLoading={ updatePromotionCampaignNotification?.loading || deletePromotionCampaignNotification?.loading }>
                                        Zapisz
                                    </SubmitButton>
                                </Grid>
                            }
                        </Grid>
                    }
                </Formik>
            }
        </>
    );
};

export default PromotionCampaignNotificationForm;